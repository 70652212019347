import React from 'react';
import config from '../../../../../config';
import request from 'superagent';

class FlightPreBuildWidget extends React.Component {
    state = {
        flight: this.props.flight
    }
    componentWillMount() {
        const { flight } = this.props;
        this.setState({ flight });
    }

    isOdd = (n) => {
        if (n % 2) return true;
        else return false
    }
    update = () => {
        const { flight } = this.state;
        request.patch(`${config.apiEndpoint}/api/v4/flights/${flight.IdFlight || flight.Id}`)
            .send({
                flightName: flight.FlightName,
                minimumGamesPerTeam: flight.MinimumGamesPerTeam,
                position: flight.Position
            })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(() => { });
    }
    up = () => {
        const { flight } = this.props;
        flight.MinimumGamesPerTeam = parseInt(flight.MinimumGamesPerTeam, 10) + 1;
        this.setState({ flight }, () => {
            this.txt.value = flight.MinimumGamesPerTeam;
            this.update();
        });
    }
    down = () => {
        const { flight } = this.props;
        if (parseInt(flight.MinimumGamesPerTeam, 10) > 0) {
            flight.MinimumGamesPerTeam = parseInt(flight.MinimumGamesPerTeam, 10) - 1;
            this.setState({ flight }, () => {
                this.txt.value = flight.MinimumGamesPerTeam;
                this.update();
            });
        }
    }
    render() {
        const { flight } = this.state, { fnSelectFlight } = this.props;        
        return (
            <div className="d-flex flex-column p-2">
                <span className="white">Flight: {flight.FlightName}</span>
                <span className="white underlined" onClick={() => fnSelectFlight(flight)}>Teams: {flight.FlightedTeams.length}</span>
                <span className="white">* Games: {flight.MinimumGamesPerTeam}</span>
                <span className="white">= Pairings: {flight.MinimumGamesPerTeam * flight.FlightedTeams.length / 2}</span>
                <span className="white">Duration: {flight.Duration} mins.</span>
                {/*<table className="table table-sm">
                    <thead>
                        <tr>
                            <th colSpan="3" align="center" className="white">{flight.FlightName}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="center" className="font-16 white underlined" onClick={() => fnSelectFlight(flight)}>{flight.FlightedTeams.length}</td>
                            <td align="center"><div className="d-flex flex-row justify-content-center">
                                <input style={{ width: 60, backgroundColor: 'transparent', borderWidth: 0 }} className="white text-center control-sm font-18" type="text" disabled="disabled" ref={(i) => this.txt = i} defaultValue={flight.MinimumGamesPerTeam} />
                                <div className="d-flex flex-column align-self-center">
                                    <i className="fa fa-caret-up white font-20 align-self-center" onClick={this.up} />
                                    <i className="fa fa-caret-down white font-20 align-self-center" onClick={this.down} />
                                </div>
                            </div></td>
                            <td align="center" className="font-16 white">{flight.MinimumGamesPerTeam * flight.FlightedTeams.length / 2}</td>
                        </tr>
                        <tr>
                            <td align="center" className="underlined white" onClick={() => fnSelectFlight(flight)}>teams</td>
                            <td align="center" className="white">games</td>
                            <td align="center" className="white">pairings</td>
                        </tr>
                    </tbody>
                    {error && <tfoot>
                        <tr>
                            <td colSpan="3" className="bg-danger font-8 white" align="center">
                                You cannot have odd number of games for an odd number of teams
                            </td>
                        </tr>
                    </tfoot>}
                    </table>*/}
            </div>
        )
    }
}

export default FlightPreBuildWidget;