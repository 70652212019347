import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody } from 'reactstrap';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { getBase64 } from '../../../helpers';
import request from 'superagent';
import config from '../../../config';

class AddLeague extends React.Component {

  state = {
    bootstrapped: false,
    league: {},
    owner_mode: 1,
    selected: {
      country: null,
      state: null,
      time_zone: null,
      community: null,
      //CountryCode: _.first(config.country_codes),
      country_code: null,
    }
  }

  // Lifecycle
  componentWillMount() {
    const { familyActions, geoActions } = this.props;
    familyActions.fetchCommunities();
    geoActions.fetchCountries();
    geoActions.fetchCountryCodes();
    geoActions.fetchTimeZones();
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.country_codes) this.setState({ country_codes: nextProps.country_codes }, () => {
      if (this.state.country_codes && !this.state.selected.country_code && this.state.country_codes.northAmericanCountries) {
        const { selected } = this.state;
        selected.country_code = _.first(this.state.country_codes.northAmericanCountries);
        selected.mask = _.chain(selected.country_code.phoneNumberMask)
          .map(a => {
            switch (a) {
              case '/[2-9]/': return /[2-9]/;
              case '/[1-9]/': return /[1-9]/;
              case '/[0-9]/': return /[0-9]/;
              case '/[5-9]/': return /[5-9]/;
              case '/[2-5]/': return /[2-5]/;
              case '/[2-6]/': return /[2-6]/;
              case '/\d/': return /\d/;
              default: return a;
            }
          }).value();
        this.setState({ selected });
      }
    });
  }
  // 

  handleAvatar = e => {
    const { league, selected } = this.state;
    getBase64(e.target.files[0]).then(data => {
      league.LeagueImage = data;
      selected.Logo = data;
      this.setState({ league, selected });
    });
  }
  search_email = () => {
    if (this.state.fetching_user) return;
    const email = this.txtSearchEmail.value;
    // TODO: validate this email    
    this.setState({ fetching_user: true, no_users_found: false, invalid_sqwad_owner: false, error: null }, () => {
      // Call API
      request.post(`${config.apiEndpoint}/api/v4/family/members/by_email`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({ email })
        .then((data) => {
          if (data.body.success) {

            // Decrypt User Info
            //data.body.users.forEach(humanize_user);            

            this.setState({
              fetching_user: false,
              users: data.body.users,
              no_users_found: data.body.users.length ? false : true,
              user_index: 0
            });
          } else
            this.setState({ fetching_user: false, no_users_found: true });

        });
    });
  }
  save = () => {

    const { selected, users, user_index, owner_mode, microTransac } = this.state;
    if (microTransac) return;

    this.setState({ invalid_league_info: false, invalid_new_owner: false, invalid_sqwad_owner: false, error: null }, () => {

      // Validations
      if (!this.txtLeagueName.value ||
        !selected.community ||
        !selected.time_zone ||
        !this.txtAddress.value ||
        !this.txtCity.value ||
        !selected.time_zone ||
        !selected.state ||
        !this.txtZip.value) {
        this.setState({ dirty_league_info: true, invalid_league_info: true, error: 'Please complete the missing fields' });
        return;
      }

      if (owner_mode === 1 && (!users || !users.length)) {
        this.setState({ dirty_sqwad_owner: true, invalid_sqwad_owner: true, error: 'Please select a SQWAD user as owner' });
        return;
      }

      if (owner_mode === 2 && (
        !this.txtNewEmail.value ||
        !this.txtNameFirst.value ||
        !this.txtNameLast.value ||
        !this.txtPhone.inputElement.value ||
        !this.txtDob.inputElement.value ||
        !selected.gender ||
        !this.txtPassword.value
      )) {
        this.setState({ dirty_new_owner: true, invalid_new_owner: true, error: 'Please complete the missing fields' });
        return;
      }

      this.setState({ microTransac: true }, () => {
        request.post(`${config.apiEndpoint}/api/v4/leagues`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({

            NameLeague: this.txtLeagueName.value,
            IdCommunity: selected.community.IdCommunity,
            IdTimeZone: selected.time_zone.Id,
            AddressLine1: this.txtAddress.value,
            City: this.txtCity.value,
            State: selected.state.StateName,
            ZipCode: this.txtZip.value,
            Logo: selected.Logo,
            IdCountry: selected.country.IdCountry || selected.country.Id,
            IdState: selected.state.IdState || selected.state.Id,

            IdUserLeagueAdmin: owner_mode === 1 && users && users.length ? users[user_index].IdUser : null,
            NewUser: owner_mode === 2 ? {
              EmailAddress: this.txtNewEmail.value,
              NameFirst: this.txtNameFirst.value,
              NameLast: this.txtNameLast.value,
              PhoneNumber: (ReactDOM.findDOMNode(this.txtPhone).value),
              DOB: (ReactDOM.findDOMNode(this.txtDob).value),
              CountryCode: selected.country_code.countryCode,
              Password: this.txtPassword.value,
              IdGender: selected.gender.IdGender || selected.gender.Id
            } : null

          })
          .then((data) => {
            if (data.body.success) {
              // close popup and reload leagues
              this.props.leagueActions && this.props.leagueActions.fetchLeaguesAdmin();
              this.props.toggle && this.props.toggle();
            } else {
              // error
              this.setState({ microTransact: false });
            }
          }, error => {            
            this.setState({ microTransact: false });
          });
      });

    });

  }

  onPickCommunity = community => {
    const { selected } = this.state;
    selected.community = community;
    selected.IdCommunity = community.IdCommunity;
    this.setState({ selected, pickCommunity: false });
  }
  selectCountry = (c) => {
    const { selected } = this.state;
    selected.country = c;
    this.props.geoActions && this.props.geoActions.clearStates && this.props.geoActions.clearStates();
    this.props.geoActions && this.props.geoActions.fetchStates && this.props.geoActions.fetchStates(c.IdCountry);
    this.setState({ selected, countryOpened: false });
  }
  selectState = (c) => {
    const { selected } = this.state;
    selected.state = c;
    this.setState({ selected, stateOpen: false });
  }
  selectTimeZone = (c) => {
    const { selected } = this.state;
    selected.time_zone = c;
    this.setState({ selected, timeZoneOpen: false });
  }

  renderLeagueInfo = () => {
    const { isOpen, toggle, leagueImage, communities, time_zones, } = this.props,
      { league, selected, country_codes, dirty_league_info, invalid_league_info } = this.state;

    const { countries = [], states = [] } = this.props;

    // To set US and Canada first on the Countries selection
    const splitCountries = _.partition(countries, c => {
      return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
    });

    return <section>
      <h4 className="px-3 pt-3">League Information</h4>
      <hr />
      <section className="d-flex flex-row ">
        {/* Avatar */}
        <div className="mt-3 px-4 py-2 ">
          <div className="m-auto" style={{ position: 'relative', width: 160, height: 160 }}>
            <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />
            <label htmlFor="fi">
              {(leagueImage || league.LeagueImage) && <div className="contain" style={{
                width: 160, height: 160,
                background: ['url(', (leagueImage || league.LeagueImage), ') no-repeat center center'].join('')
              }} />}
              {!(leagueImage || league.LeagueImage) && <i className="fa fa-shield-alt align-self-center gray" style={{ fontSize: '10em' }} />}
              <i className="fas fa-camera green font-20" style={{ position: 'absolute', bottom: 0, right: 0 }} htmlFor="fi" />
            </label>
          </div>
        </div>
        <div className="w-100 pr-3 ">
          <div className="d-flex w3-content">
            {/* Leagues Name */}
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !this.txtLeagueName.value ? 'error-input' : ''} d-flex mt-2`}>
                <input className="w-100 p-3" type="text" name="name" placeholder="League's Name" ref={(input) => this.txtLeagueName = input} />
              </div>
            </div>
            <div className="p-2" />
            {/* Community */}
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !selected.community ? 'error-input' : ''} d-flex mt-2`}>
                <Dropdown isOpen={this.state.pickCommunity} toggle={() => this.setState({ pickCommunity: !this.state.pickCommunity })} className="w-100 h-100">
                  <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                    <span className="black align-self-center w-100">{selected.community ? selected.community.CommunityName : 'Select Community'}</span>
                    <i className="fas fa-chevron-down font-16 align-self-center mx-auto black" />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {communities && communities.map((c) => <DropdownItem key={c.IdCommunity} onClick={() => this.onPickCommunity(c)}>
                      {c.CommunityName}
                    </DropdownItem>)}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="d-flex w3-content">
            {/* Address */}
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !this.txtAddress.value ? 'error-input' : ''} d-flex mt-2`}>
                <i className="fas fa-map font-16 align-self-center ml-3 black" />
                <input className="w-100 p-3 " type="text" name="address" placeholder="Address" ref={(input) => this.txtAddress = input} />
              </div>
            </div>
          </div>
          {countries && countries.length > 0 && <div className="d-flex w3-content">
            {/* Country */}
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !selected.country ? 'error-input' : ''} d-flex mt-2`}>
                <Dropdown isOpen={this.state.countryOpened} toggle={() => this.setState({ countryOpened: !this.state.countryOpened })} className="w-100 h-100">
                  <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                    <i className="fas fa-globe font-16 align-self-center mr-3 black" />
                    <span className="black align-self-center w-100">{selected.country ? selected.country.CountryName : 'Country'}</span>
                    <i className="fas fa-chevron-down font-16 align-self-center mx-auto black" />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {splitCountries.length >= 1 && splitCountries[0].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                      {c.CountryName || c.Name}
                    </DropdownItem>)}
                    <DropdownItem divider />
                    {splitCountries.length > 1 && splitCountries[1].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                      {c.CountryName || c.Name}
                    </DropdownItem>)}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            {selected.country && selected.country.IdCountry && <div className="p-2" />}
            {/* State */}
            {selected.country && selected.country.IdCountry && <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !selected.state ? 'error-input' : ''} d-flex mt-2`}>
                <Dropdown isOpen={this.state.stateOpen} toggle={() => this.setState({ stateOpen: !this.state.stateOpen })} className="w-100 h-100">
                  <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                    <span className="black align-self-center w-100">{selected.state ? selected.state.StateName : 'State'}</span>
                    <i className="fas fa-chevron-down font-16 align-self-center mx-auto black" />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {states.length >= 1 && states.map((s) => <DropdownItem key={s.IdState} onClick={() => this.selectState(s)}>
                      {s.StateName}
                      {' ('}
                      {s.StateCode}
                      {') '}</DropdownItem>)}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            }
          </div>}
          <div className="d-flex w3-content">
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !selected.time_zone ? 'error-input' : ''} d-flex mt-2`}>
                <Dropdown isOpen={this.state.timeZoneOpen} toggle={() => this.setState({ timeZoneOpen: !this.state.timeZoneOpen })} className="w-100 h-100">
                  <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                    <span className="black align-self-center w-100">{selected.time_zone ? `${selected.time_zone.Name} (${selected.time_zone.Abbreviation})` : 'Time Zone'}</span>
                    <i className="fas fa-chevron-down font-16 align-self-center mx-auto black" />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {time_zones.length > 0 && time_zones.map((s) => <DropdownItem key={s.Id} onClick={() => this.selectTimeZone(s)}>
                      {s.Name}
                      {' ('}
                      {s.Abbreviation}
                      {') '}</DropdownItem>)}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="p-2" />
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !this.txtCity.value ? 'error-input' : ''} d-flex mt-2`}>
                <input className="w-100 p-3 " type="text" name="zip" placeholder="City" ref={(input) => this.txtCity = input} />
              </div>
            </div>
            <div className="p-2" />
            <div className="w-75 mt-3">
              <div className={`black-input ${dirty_league_info && invalid_league_info && !this.txtZip.value ? 'error-input' : ''} d-flex mt-2`}>
                <input className="w-100 p-3 " type="text" name="zip" placeholder="Zip | Postal Code" ref={(input) => this.txtZip = input} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  }
  renderOwnerInfo = () => {

    const { selected, country_codes, dirty_sqwad_owner, invalid_sqwad_owner, dirty_new_owner, invalid_new_owner } = this.state;

    return <section>
      <h4 className="px-3 pt-3">Owner's Information</h4>
      <hr />
      <section className="d-flex flex-row">
        <div className="w-75">
          <div className="d-flex" onClick={() => this.setState({ owner_mode: 1 })}>
            <i className={`far fa-circle${this.state.owner_mode === 1 ? '-check green' : 'black'} ml-3 align-self-center`} />
            <h6 className="px-2 align-self-center">SQWAD User</h6>
          </div>
          <section className="pl-3">
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_sqwad_owner && invalid_sqwad_owner ? 'error-input' : ''} d-flex mt-2`} id="txt_search">
                <input className="w-100 p-3 " type="text" name="search_email" placeholder="Search by Email" ref={(input) => this.txtSearchEmail = input} />
                <i onClick={this.search_email} className="fas fa-search font-16 align-self-center ml-auto mr-3 black" />
              </div>
              <Popover placement="bottom" isOpen={this.state.no_users_found} target={`txt_search`} toggle={() => this.setState({ no_users_found: !this.state.no_users_found })} className="bg-gray-2">
                <PopoverBody className="d-flex flex-row justify-content-center">
                  <i className="fas fa-times red font-8 align-self-center" />
                  <span className="black font-8 poppins align-self-center ml-3" style={{ maxWidth: 100 }}>Invalid email</span>
                </PopoverBody>
              </Popover>
            </div>
          </section>
          {this.state.users && this.state.users.length > 0 && <section>
            <section className="pl-3 d-flex flex-row mt-4">
              <div className="p-2 align-self-center w-50 d-flex justify-content-center" onClick={() => {
                const { user_index, users } = this.state;
                if (user_index - 1 < 0) this.setState({ user_index: users.length - 1 });
                else this.setState({ user_index: user_index - 1 });
              }}>
                <i className="fas fa-chevron-left black font-30" />
              </div>
              <div className="w-100 align-self-center justify-content-center d-flex">
                <div className="d-flex justify-content-center cover bordered" style={{ width: 100, height: 100, borderRadius: '50%', background: `url(${this.state.users[this.state.user_index].UserImageUrl}?full=80) no-repeat center center #CCCCCC` }} >

                </div>
              </div>
              <div className="p-2 align-self-center w-50 d-flex justify-content-center" onClick={() => {
                const { user_index, users } = this.state;
                if (user_index + 1 < users.length) this.setState({ user_index: user_index + 1 });
                else this.setState({ user_index: 0 });
              }}>
                <i className="fas fa-chevron-right black font-30" />
              </div>
            </section>
            <div className="pl-3 d-flex justify-content-center">
              <span>{`${this.state.users[this.state.user_index].UserName}`}</span>
            </div>
          </section>}
        </div>
        <div className="p-2" />
        <div className="w-100">
          <div className="d-flex" onClick={() => this.setState({ owner_mode: 2 })}>
            <i className={`far fa-circle${this.state.owner_mode === 2 ? '-check green' : ' black'} ml-0 align-self-center`} />
            <h6 className="px-2 align-self-center">New User</h6>
          </div>
          <section className="pr-3">
            <div className="w-100 mt-3">
              <div className={`black-input ${dirty_new_owner && invalid_new_owner && !this.txtNewEmail.value ? 'error-input' : ''} d-flex mt-2`}>
                <i className="fas fa-at font-16 align-self-center ml-3 black" />
                <input className="w-100 p-3 " type="text" name="new_email" placeholder="Email" ref={(input) => this.txtNewEmail = input} />
              </div>
            </div>
            <div className="d-flex p-0">
              <div className="w-100 mt-3">
                <div className={`black-input ${dirty_new_owner && invalid_new_owner && !this.txtNameFirst.value ? 'error-input' : ''} d-flex mt-2`}>
                  <input className="w-100 p-3 " type="text" name="firstname" placeholder="First Name" ref={(input) => this.txtNameFirst = input} />
                </div>
              </div>
              <div className="p-2" />
              <div className="w-100 mt-3">
                <div className={`black-input ${dirty_new_owner && invalid_new_owner && !this.txtNameLast.value ? 'error-input' : ''} d-flex mt-2`}>
                  <input className="w-100 p-3 " type="text" name="lastname" placeholder="Last Name" ref={(input) => this.txtNameLast = input} />
                </div>
              </div>
            </div>
            {country_codes && country_codes.northAmericanCountries && <div className="d-flex p-0">
              <div className="w-100 mt-2">
                <div className={`black-input ${dirty_new_owner && invalid_new_owner && !this.txtPhone.inputElement.value ? 'error-input' : ''} d-flex mt-2`}>
                  <Dropdown isOpen={this.state.isCCO ? true : false} toggle={() => this.setState({ isCCO: !this.state.isCCO })} className="h-100">
                    <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                      <img src={selected.country_code ? `https://api.sportslogic.net/images/flags/${selected.country_code.countryISOCode}.png` : ''} alt="" style={{ height: 20 }} className="align-self-center mr-1" />
                      <span className="black align-self-center w-100">{selected.country_code ? selected.country_code.countryCode : 'Code'}</span>
                      <i className="ml-2 fas fa-chevron-down font-16 align-self-center black" />
                    </DropdownToggle>
                    <DropdownMenu>

                      <DropdownItem header>North America</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.northAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>South America</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.southAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>America Central</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.centralAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>Europe</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.europeanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>Africa</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.africanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>Asia</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.asianCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                      <DropdownItem header>Oceania</DropdownItem>
                      <DropdownItem divider />
                      {country_codes.oceaniaCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                        const { selected } = this.state;
                        selected.country_code = c;
                        selected.mask = _.chain(c.phoneNumberMask)
                          .map(a => {
                            switch (a) {
                              case '/[2-9]/': return /[2-9]/;
                              case '/[1-9]/': return /[1-9]/;
                              case '/[0-9]/': return /[0-9]/;
                              case '/[5-9]/': return /[5-9]/;
                              case '/[2-5]/': return /[2-5]/;
                              case '/[2-6]/': return /[2-6]/;
                              case '/\d/': return /\d/;
                              default: return a;
                            }
                          }).value();
                        this.setState({ selected });
                      }}>
                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                      </DropdownItem>)}

                    </DropdownMenu>
                  </Dropdown>
                  {selected.country_code && <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="w-100 p-3 bg-transparent" placeholder="Phone Number" defaultValue={selected.phoneNumber}
                    mask={selected.mask} />}
                </div>
              </div>
              <div className="p-2" />
              <div className="w-75 mt-2">
                <div className={`black-input ${dirty_new_owner && invalid_new_owner && !selected.gender ? 'error-input' : ''} d-flex mt-2`}>
                  <Dropdown isOpen={this.state.genderOpen} toggle={() => this.setState({ genderOpen: !this.state.genderOpen })} className="w-100 h-100">
                    <DropdownToggle className="no-border text-left d-flex w-100 bg-transparent py-3" style={{ borderRadius: 12 }}>
                      <span className="black align-self-center w-100">{selected.gender ? `${selected.gender.Name}` : 'Gender'}</span>
                      <i className="fas fa-chevron-down font-16 align-self-center mx-auto black" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {[{ IdGender: 1, Name: 'Male' }, { IdGender: 2, Name: 'Female' }].map((s) => <DropdownItem key={s.IdGender} onClick={() => {
                        const { selected } = this.state;
                        selected.gender = s;
                        this.setState({ selected });
                      }}>
                        {s.Name}
                      </DropdownItem>)}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>}
            <div className="d-flex p-0">
              <div className="w-100 mt-3">
                <div className={`black-input ${dirty_new_owner && invalid_new_owner && !this.txtPassword.value ? 'error-input' : ''} d-flex mt-2`}>
                  <input className="w-100 p-3 " type={this.state.password_visible ? `text` : `password`} name="password" placeholder="Password" ref={(input) => this.txtPassword = input} />
                  <i onClick={() => this.setState({ password_visible: !this.state.password_visible })} className={`fas fa-eye${this.state.password_visible ? '-slash' : ''} font-16 align-self-center ml-auto mr-3 black`} />
                </div>
              </div>
              <div className="p-2" />
              <div className="w-100 mt-3">
                <div className={`black-input d-flex mt-2`}>
                  <MaskedInput placeholder="DOB" id="txtDoB" ref={(i) => this.txtDob = i} guide={true} className="w-100 p-3 bg-transparent" mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
                </div>
                <div className={`d-flex flex-row`}>
                  <span className="font-10 black align-self-center">(MM/DD/YYYY)</span>
                </div>
              </div>

            </div>
            <div style={{ height: 100 }} />
          </section>
        </div >
      </section >
    </section >
  }

  render() {
    const { isOpen, toggle, } = this.props, { microTransac, invalid_league_info, invalid_new_owner, invalid_sqwad_owner, error } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="bg-blue-dark white" toggle={toggle}>
          Add League
        </ModalHeader>
        <ModalBody className="container m-0 p-0">
          {this.renderLeagueInfo()}
          <hr />
          {this.renderOwnerInfo()}
        </ModalBody>
        {(error) && <ModalBody className="bg-danger p-3 text-center">
          <span className="white font-16">{error}</span>
        </ModalBody>}
        <ModalFooter className="d-flex flex-row">
          <button style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20 }} className="green p-2" onClick={toggle}>Cancel</button>
          <button style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20, backgroundColor: '#2cad3c' }} className="white ml-auto p-2" onClick={this.save}>
            {!microTransac && <span>Create League</span>}
            {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
          </button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default AddLeague;
