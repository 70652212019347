import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import { formatPriceWithoutCurrency, formatPrice } from '../../../helpers';
import BlueishHeader from '../things/BlueishHeader';
import request from 'superagent';
import config from '../../../config';

class Comp extends React.Component {

  state = {
    card: null,
    failed: false,
    invalidCC: false,
    step: 1,
    total: this.props.total,
    nope: false,
    max: this.props.max,
    transactionId: null,
    ccType: 10,
    paying: false,
    mode: 0 // =[1:CC, 2:Cash, 3:Check, 4:Offline]
  }

  handleChange = () => {
    // tODO: validate that what's in is a number
    this.setState({
      total: parseFloat(this.txtAmount.value),
      nope: !_.isNumber(parseFloat(this.txtAmount.value))
        || parseFloat(this.txtAmount.value) > this.props.max
        || parseFloat(this.txtAmount.value) === 0
        || !this.txtAmount.value
    });
  }

  continue = (e) => {
    e.preventDefault && e.preventDefault();
    const { nope } = this.state;
    if (!nope) {
      this.setState({ step: 2, total: parseFloat(this.txtAmount.value) });
    }
  }

  selectMode = (mode) => {
    this.setState({
      mode,
      failed: false
    });
  }

  pay = () => {

    // tODO: evaluate what mode I'm in
    const { mode } = this.state;
    switch (mode) {
      case 1: // CC
        this.payWithPaypal();
        break;
      case 4: // Offline
        this.payOffline();
        break;
      default:
        break;
    }
  }

  payWithPaypal = () => {
    const { selectedPayment } = this.props;
    this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      // CALL API      
      request.post(`${config.apiEndpoint}/api/v4/payments/${selectedPayment.IsFee ? 'refund_fee' : 'refund'}/paypal/${selectedPayment.IdTransaction}`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({
          idLeague: selectedPayment.IdLeague,
          amount: this.state.total,
          idPaymentMethod: selectedPayment.IdPaymentMethod,
          externalId: selectedPayment.DocumentNumber,
          idOrderItem: selectedPayment.IdOrderItem,
          full: parseFloat(this.state.total) === parseFloat(this.props.total) ? true : false
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: 'Refunded Paypal Transaction'
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, ({ response: { body: { error } } }) => {
          this.setState({
            paying: false,
            failed: (error || {}).message
          });
        });
    });
  }

  payOffline = () => {

    if (!this.txtTransactionID.value) {
      return;
    }

    this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      const { selectedPayment } = this.props;

      // CALL API      
      request.post(`${config.apiEndpoint}/api/v4/payments/refund/offline/${selectedPayment.IdTransaction}`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({
          amount: this.state.total,
          documentNumber: this.txtTransactionID.value,
          notes: this.txtNotes.value,
          idOrderItem: selectedPayment.IdOrderItem,
          idPaymentMethod: selectedPayment.IdPaymentMethod
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: 'Refunded with Offline Transaction'
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, ({ response: { body: { error } } }) => {
          this.setState({
            paying: false,
            failed: (error || {}).message
          });
        });
    });
  }

  render() {
    const { isOpen, toggle, selectedPayment } = this.props,
      { step, nope, total, mode, transactionId } = this.state;      

    return (<Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
      <ModalHeader toggle={toggle}>
        {step === 1 && <span>How much would you like to refund?</span>}
        {step === 2 && <span>{transactionId ? 'Refund complete' : 'How would you like to refund?'}</span>}
      </ModalHeader>
      <ModalBody>
        <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
          transitionEnter={true} transitionLeave={false}>
          {step === 1 && (
            <form>
              <div className="jumbotron no-margin text-center" key="step1">
                <span>Max refundable: {formatPrice((this.props.selectedPayment || { MaxRefund: 0 }).MaxRefund * 100)}</span>
                <div className="d-flex flex-row justify-content-center">
                  <i className="fa fa-dollar-sign align-self-center bigest-font margin-right-half" />
                  <input ref={(i) => this.txtAmount = i} onChange={this.handleChange} defaultValue={formatPriceWithoutCurrency((this.props.selectedPayment || { MaxRefund: 0 }).MaxRefund * 100)} type="number" className="form-control control-lg align-self-center text-center big-font margin-right"
                    style={{ height: 60, width: 200 }} />
                  <button type="submit" onClick={this.continue} className={`btn btn-${nope ? 'default' : 'success'} btn-lg idented`}>Continue</button>
                </div>
              </div>
            </form>)}
          {step === 2 && (
            <div className="font-12">
              <div className="tile-rainbow p-2 text-center" id="lblTotalWrapper">
                <span className="white bigest-font">Total: {formatPrice(total * 100)}</span>
              </div>
              <div className="container">
                <div className="row p-2">
                  <div className={`${(selectedPayment.IdGateway === 2 || selectedPayment.IdGateway === 5)  ? 'col-6' : 'col-12'} tile btn btn-${mode === 4 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(4)}>
                    <i className="fas fa-unlink bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Offline<br />Transaction</span>
                  </div>

                  {selectedPayment.IdGateway === 2 && <div className={`col-6 tile btn btn-${mode === 1 ? 'success' : 'default'} p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(1)}>
                    <i className="fab fa-cc-paypal bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Paypal</span>
                  </div>}

                  {selectedPayment.IdGateway === 5 && <div className={`col-6 tile btn btn-${mode === 1 ? 'success' : 'default'} p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(1)}>
                    <i className="fas fa-dot-circle bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Authorize.NET</span>
                  </div>}
                </div>
              </div>
              {mode === 4 && <BlueishHeader message="Offline Refund Information" />}
              {mode === 4 && <div className="d-flex flex-row idented-half margin-right-half margin-top-half  margin-bottom-half">
                <label className="black align-self-center">Transaction ID
                                          {' '}
                  {(!this.txtTransactionID || !this.txtTransactionID.value) && <i className="red fa fa-asterisk" />}
                </label>
                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 250 }} ref={(i) => this.txtTransactionID = i} />
              </div>}
              {mode > 1 && <div className="d-flex flex-row idented-half margin-right-half margin-top-half  margin-bottom-half">
                <label className="black align-self-center">Notes</label>
                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 250 }} ref={(i) => this.txtNotes = i} />
              </div>}
            </div>
          )}
        </ReactCSSTransitionGroup>
      </ModalBody>
      {nope && (
        <ModalBody className="bg-danger white p-2">
          {(!_.isNumber(this.state.total) || !this.state.total || this.state.total === 0) && <span>Invalid amount, please check.</span>}
          {this.state.total > this.props.max && <span>You cannot pay more than {formatPrice(this.props.max * 100)}</span>}
        </ModalBody>)}
      {this.state.paying && <div className="jumbotron no-margin text-center" key="step1">
        <i className="fa fa-credit-card bigest-font heart" />
        <h1 className="margin-top-half">Processing, please wait...</h1>
      </div>}
      {this.state.transactionId && <div className="jumbotron no-margin text-center bg-success" key="step1">
        <i className="fa fa-check white bigest-font" />
        <h1 className="white">Refund successful</h1>
        <h3 className="white">{formatPrice(total * 100)}</h3>
        <h3 className="white font-8">{this.state.transactionId}</h3>
      </div>}
      {!this.state.paying && this.state.failed && <div className="jumbotron no-margin text-center bg-danger" key="step1">
        <i className="fa fa-times white bigest-font" />
        <h1 className="white">Refund failed</h1>
        <h3 className="white">{this.state.failed}</h3>
      </div>}
      {step === 2 && mode !== 0 && !this.state.paying && !this.state.transactionId && (
        <ModalFooter className="no-padding no-margin">
          <button onClick={this.pay} className="btn btn-lg btn-success btn-block">Refund</button>
        </ModalFooter>)}
    </Modal>)
  }
}

export default Comp;