import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePicker2';
import { Animated } from 'react-animated-css';
import { getBase64 } from '../../../helpers';
import request from 'superagent';
import _ from 'lodash';
import config from '../../../config';

class CreateSeason extends React.Component {

    state = {
        bootstrapped: true,
        step: 1,        
        season: {
            DateStart: moment(),
            DateEnd: moment().add(1, 'day')
        }
    }

    componentWillMount(){
        const { seasons } = this.props;        
        let IdMiniLeague = (_.find(seasons, s => s.IsMiniLeague) || {}).IdLeague;
        this.setState({ IdMiniLeague })        
    }

    handleChangeStart = date => {
        const { season } = this.state;
        season.DateStart = moment(date);
        season.DateEnd = moment(date).isAfter(moment(season.DateEnd)) ? moment(date).add(1, 'day') : moment(season.DateEnd);
        this.setState({
            season
        });
    }
    handleChangeEnd = date => {
        const { season } = this.state;
        season.DateStart = moment(date).isBefore(moment(season.DateStart)) ? moment(date).add(-1, 'day') : moment(season.DateStart);
        season.DateEnd = moment(date);
        this.setState({ season });
    }

    handleAvatar = e => {
        getBase64(e.target.files[0]).then(data => {
            this.setState({ logo: data });
        });
    }

    create = () => {

        this.setState({ invalid: false })
        if (!this.txtName.value) {
            this.setState({ invalid: true });
            return;
        }
        const { team, teamActions } = this.props, { season } = this.state;
        this.setState({ step: 2 }, () => {
            request.post(`${config.apiEndpoint}/api/v4/teams/season/${team.IdTeam}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    Name: this.txtName.value,
                    DateStart: season.DateStart.format('YYYY-MM-DD'),
                    DateEnd: season.DateEnd.format('YYYY-MM-DD'),
                    IdLeague: this.state.IdMiniLeague,
                    Logo: this.state.logo && this.state.logo.indexOf('base64') !== -1 ? this.state.logo : null
                })
                .then((data) => {
                    if (data.body.success) {
                        // REFETCH SEASONS OF THE TEAM
                        teamActions && teamActions.fetchTeamSeasons(team.IdTeam);
                        this.props.toggle && this.props.toggle();
                    }
                }, () => {
                    this.setState({ step: 1 });
                });
        });
    }

    close = () => {
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
            setTimeout(() => { toggle && toggle(); }, 300);
        });
    }

    render() {
        const { bootstrapped, season, logo, step } = this.state;
        return (
            <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
                <div className="overlay" onClick={this.close} style={{ zIndex: -1 }} />
                <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
                    position: 'fixed', right: 0, top: 0, bottom: 0,
                    width: 450, borderLeft: '1px solid gray'
                }} className="bg-gray">
                    
                    <div className="d-flex flex-row p-3">
                        <i className="fas fa-arrow-left font-12 pointer black" onClick={this.close} />
                        <span className="black montserrat font-12 ml-auto">BASIC INFORMATION</span>
                        <i className=" font-16 pointer ml-auto" />
                    </div>
                    
                    <div className="d-flex flex-column">

                        <div className="card tile shadow-box p-4 ml-4 mr-4 d-flex flex-column" style={{ width: 'auto' }}>
                            <input ref={i => this.txtName = i} type="text" className="form-control" placeholder="Season's Name" />
                            <div className="d-flex flex-row">
                                <div className="align-self-center d-flex flex-row justify-content-end w-100">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                                        customInput={<CustomDatePicker />} selected={moment(season.DateStart).utc()} selectsStart
                                        startDate={moment(season.DateStart).utc()} endDate={moment(season.DateEnd).utc()} onChange={this.handleChangeStart} />
                                </div>
                                <span className="align-self-center m-2">to</span>
                                <div className="align-self-center w-100">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(season.DateEnd).utc()} selectsStart
                                        startDate={moment(season.DateStart).utc()} endDate={moment(season.DateEnd).utc()} onChange={this.handleChangeEnd} />
                                </div>
                            </div>
                        </div>

                        <label htmlFor="fi" className="card tile shadow-box p-4 ml-4 mr-4 mt-4 d-flex flex-column pointer" style={{ position: 'relative', width: 'auto', }}>
                            <span className="montserrat font-14">Season's Logo (optional)</span>
                            <div style={{ height: 150 }} className="w-100 d-flex flex-column justify-content-center">
                                {!logo && <i className="blue font-60 align-self-center icon-camera" />}
                                {!logo && <i className="blue fa fa-plus font-20" style={{ position: 'absolute', top: 80, left: 150 }} />}
                                {logo && <img alt="" style={{ height: 100 }} src={logo} className="align-self-center" />}
                            </div>
                        </label>

                        <button className="mt-3 btn mx-4 btn-success" id="btnSubmit" onClick={this.create}>
                            {step === 1 && <span>Save</span>}
                            {step === 2 && <i className="fa fa-spin fa-cog" />}
                        </button>

                        <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />

                    </div>

                    {this.state.invalid && <div className="text-center w-100 red mt-2">Please, complete the required fields.</div>}
                </Animated>
            </div>
        )
    }
}

export default CreateSeason;