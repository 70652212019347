import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import _ from 'lodash';
import BlueishHeader from '../things/BlueishHeader';
import config from '../../../config';
import request from 'superagent';
import fakeAuth from '../../../fakeAuth';
import LineLoader from '../LineLoader';
import Avatar from '../Avatar';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import "react-datepicker/dist/react-datepicker.css";
import { humanize_user } from '../../../helpers';


class NewMessage extends React.Component {

    state = {
        family: null,
        leagues: null,
        step: 1,
        season: null,
        microTransac: false,
        error: null,
        dirty: false,
        incompleteForm: false,
        from: fakeAuth.getUser(),
        to: [],
        drill: 1,
        subject: null,
        body: null,
        modes: {
            email: true,
            push: false
        },
        validations: {
            //name: this.props.season.Name ? true : false,
            //type: this.props.season.IdSeasonType ? true : false,
        }
    }

    // Lifecycle
    componentWillMount() {
        const { family, idLeague, seasonActions } = this.props;
        this.setState({ family });
        seasonActions && seasonActions.fetchSeasons(idLeague);
        this.setState({ editorState: EditorState.createEmpty() });

    }
    componentWillReceiveProps = nextProps => {
        const { family, seasons, league } = nextProps;
        this.setState({ family, seasons, league });
    }
    //    

    onEditorStateChange = (editorState) => this.setState({ editorState });

    send = () => {
        const { from, to, editorState } = this.state;
        let payload = {
            from: from.IdLeague ? { league: from } : { user: from },
            to,
            subject: this.state.subject,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            method: {
                email: this.state.modes.email,
                push: this.state.modes.push
            }
        }

        //
        if (!this.state.subject ||
            !payload.body ||
            !this.state.from || !this.state.to || !this.state.to.length) {
            this.setState({ incompleteForm: true, dirty: true });
            return;
        }
        //

        this.setState({ microTransac: true, incompleteForm: false, dirty: true }, () => {
            request.post(`${config.apiEndpoint}/api/v4/messages`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({ message: payload })
                .then(() => {
                    // Feedback
                    // Close this popup
                    const { messagesActions, idLeague } = this.props;
                    messagesActions && messagesActions.fetchReceived(idLeague);
                    messagesActions && messagesActions.fetchSent(idLeague);

                    this.setState({ microTransac: false });
                    this.props.toggle();
                });
        });
    }

    handleChangeSubject = e => this.setState({ subject: e.target.value });
    handleChangeBody = e => this.setState({ body: e.target.value });
    onStep = step => this.setState({ step });
    onSelectFromUser = user => this.setState({ from: user, step: 1 });
    onSelectFromLeague = league => this.setState({ from: league, step: 1 });
    onToggleEmailMode = () => {
        const { modes } = this.state;
        modes.email = !modes.email;
        this.setState({ modes });
    }
    onTogglePushMode = () => {
        const { modes } = this.state;
        modes.push = !modes.push;
        this.setState({ modes });
    }
    onSelectToUser = user => {
        let { to } = this.state;
        if (_.find(to, t => t.IdUser === user.IdUser)) {
            to = _.reject(to, t => t.IdUser === user.IdUser);
        } else {
            to.push({ ..._.pick(user, 'IdUser', 'NameFirst', 'NameLast'), entityType: 5 });
        }
        this.setState({ to });
    }
    onSelectSeason = season => {
        let { to } = this.state;
        if (_.find(to, t => t.IdSeason === season.IdSeason)) {
            to = _.reject(to, t => t.IdSeason === season.IdSeason);
        } else {
            to.push({ ..._.pick(season, 'IdSeason', 'SeasonName', 'Name'), entityType: 2 });
        }
        this.setState({ to });
    }
    onSelectDivision = division => {
        let { to } = this.state;
        if (_.find(to, t => t.IdDivision === division.IdDivision)) {
            to = _.reject(to, t => t.IdDivision === division.IdDivision);
        } else {
            to.push({ ..._.pick(division, 'IdDivision', 'DivisionName', 'Name'), entityType: 3 });
        }
        this.setState({ to });
    }
    onSelectTeam = team => {
        let { to } = this.state;
        if (_.find(to, t => t.IdTeam === team.IdTeam)) {
            to = _.reject(to, t => t.IdTeam === team.IdTeam);
        } else {
            to.push({ ..._.pick(team, 'IdTeam', 'IdTeamDivision', 'TeamName', 'Name'), entityType: 4 });
        }
        this.setState({ to });
    }
    onSelectUser = user => {
        let { to } = this.state;
        if (_.find(to, t => t.IdUser === user.IdUser)) {
            to = _.reject(to, t => t.IdUser === user.IdUser);
        } else {
            to.push({ ..._.pick(user, 'IdUser', 'NameFirst', 'NameLast', 'Name'), entityType: 5 });
        }
        this.setState({ to });
    }
    onDrillSeason = idSeason => {
        const { seasons } = this.state;
        let season = _.find(seasons, s => s.IdSeason === idSeason);
        if (season.divisions) {
            this.setState({ divisions: season.divisions, drill: 2, selectedSeason: season });
        } else {
            this.setState({ microTransac: true }, () => {
                // divisions
                request.get(`${config.apiEndpoint}/api/v4/divisions/${idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        season.divisions = data.body.divisions;
                        // scouts
                        request.get(`${config.apiEndpoint}/api/v6/scout/registration/${idSeason}`)
                            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                            .then((data) => {                                
                                season.scouts = data.body.registrations;
                                this.setState({ seasons, divisions: season.divisions, scouts: season.scouts, microTransac: false, drill: 2, selectedSeason: season });
                            });
                    });
            });
        }
    }
    onDrillDivision = idDivision => {
        const { divisions } = this.state;
        let division = _.find(divisions, d => d.IdDivision === idDivision);
        if (division.teams) {
            this.setState({ teams: division.teams, drill: 3, selectedDivision: division });
        } else {
            this.setState({ microTransac: true }, () => {
                request.get(`${config.apiEndpoint}/api/v4/teams/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        division.teams = data.body.teams;
                        this.setState({ divisions, teams: division.teams, microTransac: false, drill: 3, selectedDivision: division });
                    });
            });
        }
    }
    onDrillTeam = idTeamDivision => {
        const { teams } = this.state;
        let team = _.find(teams, t => t.IdTeamDivision === idTeamDivision);
        if (team.roster) {
            this.setState({ roster: team.roster, drill: 4, selectedTeam: team });
        } else {
            this.setState({ microTransac: true }, () => {
                request.get(`${config.apiEndpoint}/api/v6/roster/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(({ body: { roster } }) => {
                        // Decrypt
                        roster.forEach(u => humanize_user(u));
                        team.roster = roster;
                        this.setState({ teams, roster: team.roster, microTransac: false, drill: 4, selectedTeam: team });
                    });
            });
        }
    }
    drillBack = drill => this.setState({ drill })
    ok = () => this.setState({ step: 1 });
    onClose = () => {
        const { step } = this.state, { toggle } = this.props;
        if (step === 1) toggle();
        else this.setState({ step: 1 });
    }

    render() {

        const { isOpen, toggle, } = this.props,
            { microTransac, step, error, dirty, incompleteForm, family, from, to, modes, subject, body, editorState,
                seasons, selectedSeason, league, drill, divisions, scouts, selectedDivision, teams, selectedTeam, roster } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 650 }}>
                <ModalBody className="bg-warning d-flex flex-row w-100 p-2">
                    <div className="d-flex flex-row w-100 align-self-center">
                        <h5 className="align-self-center">New Message</h5>
                        <i className="fa fa-times black ml-auto align-self-center" onClick={this.onClose} />
                    </div>
                </ModalBody>
                <ModalBody>
                    {microTransac && <LineLoader />}
                    {step === 1 && <div className="no-padding no-margin d-flex flex-column">
                        <div className="d-flex flex-row">
                            <button className="btn btn-link align-self-center w-100" onClick={this.onToggleEmailMode}><i className={`far fa-${modes.email ? 'check-square green' : 'square black'}`} /> Email</button>
                            <button className="btn btn-link align-self-center w-100" onClick={this.onTogglePushMode}><i className={`far fa-${modes.push ? 'check-square green' : 'square black'}`} /> Push</button>
                        </div>
                        <div className="d-flex flex-row font-10">
                            <button className="btn btn-link align-self-center w-100 text-left d-flex flex-row" onClick={() => this.onStep(2)}><Label>From <i className={`font-8 +idented-half fa fa-asterisk ${(dirty && incompleteForm && !from) ? 'red' : ''}`} />:</Label> <span className="blue idented-half">{from ? (from.IdUser ? from.NameFirst : (from.LeagueName || from.Name)) : null}</span><i className="fa fa-caret-right ml-auto align-self-center" /></button>
                        </div>
                        <div className="d-flex flex-row font-10">
                            <button className="btn btn-link align-self-center w-100 text-left d-flex flex-row" onClick={() => this.onStep(3)}><Label>To <i className={`font-8 +idented-half fa fa-asterisk ${(dirty && incompleteForm && !to.length) ? 'red' : ''}`} /></Label> <span className="blue idented-half">{(to && to.length) ? (`${to[0].NameFirst || to[0].SeasonName || to[0].DivisionName || to[0].Name}${to.length > 1 ? `, +${to.length - 1} more...` : ''}`) : null}</span><i className="fa fa-caret-right ml-auto align-self-center" /></button>
                        </div>
                        <hr />
                        <div className="pl-2 pr-2">
                            <FormGroup>
                                <Label>Subject <i className={`font-8 +idented-half fa fa-asterisk ${(dirty && incompleteForm && !subject) ? 'red' : ''}`} /></Label>
                                <Input type="text" name="disclaimer" onChange={this.handleChangeSubject} />
                            </FormGroup>
                        </div>
                        <div className="pl-2 pr-2">
                            <FormGroup>
                                {editorState && <section>
                                    <Label>Message <i className={`font-8 +idented-half fa fa-asterisk ${(dirty && incompleteForm && !body) ? 'red' : ''}`} /></Label>
                                    <figure className="w3-card pt-2 pb-2 pl-4 pr-4" style={{ border: '1px solid #e5e5e5' }}>
                                        <Editor
                                            ref={(i) => this.txtNotes = i}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={this.onEditorStateChange}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                                                inline: { inDropdown: false },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: false },
                                                fontFamily: { options: ['Hind', 'Anton'] },
                                            }}
                                        />
                                    </figure>
                                </section>}
                            </FormGroup>
                        </div>
                    </div>}
                    {step === 2 && <div className="no-padding no-margin">
                        <BlueishHeader message="Family Members" />
                        {family && family.map((f, i) => <button onClick={() => this.onSelectFromUser(f)}
                            key={i} className="btn btn-link d-flex flex-row w-100">
                            <Avatar url={f.UserImage} defaultImage={`/images/defaults/${f.IdGender === 2 ? 'female' : 'male'}.png`} width={20} height={20} />
                            <span className="align-self-center idented-half margin-right-half">{f.NameFirst} {f.NameLast}</span>
                            <i className={`far fa-${from && from.IdUser && from.IdUser === f.IdUser ? 'check-square green' : 'square black'} ml-auto`} />
                        </button>)}
                        {league && <BlueishHeader message="League" />}
                        {league && <button onClick={() => this.onSelectFromLeague(league)}
                            className="btn btn-link d-flex flex-row w-100">{league.Name || league.LeagueName} <i className={`far fa-${from && from.IdLeague && from.IdLeague === league.IdLeague ? 'check-square green' : 'square black'} ml-auto`} /></button>}
                    </div>}
                    {step === 3 && <div className="no-padding no-margin">
                        <BlueishHeader message="Family Members" />
                        {family && family.map((f, i) => <button onClick={() => this.onSelectToUser(f)}
                            key={i} className="btn btn-link d-flex flex-row w-100">
                            <Avatar url={f.UserImage} defaultImage={`/images/defaults/${f.IdGender === 2 ? 'female' : 'male'}.png`} width={20} height={20} />
                            <span className="align-self-center idented-half margin-right-half">{f.NameFirst} {f.NameLast}</span>
                            <i className={`far fa-${_.find(to, t => t.IdUser === f.IdUser) ? 'check-square green' : 'square black'} ml-auto`} /></button>)}
                        <BlueishHeader message={`Seasons ${drill}`} />
                        <section style={{ maxHeight: 400, overflowY: 'auto' }}>
                            {/* Seasons */}
                            {drill === 1 && seasons && seasons.map((season, index) =>
                                <div className="d-flex flex-row" key={index}>
                                    <button onClick={() => this.onSelectSeason(season)} className="btn btn-link d-flex flex-row w-100" key={index}><i className={`far fa-${_.find(to, t => t.IdSeason === season.IdSeason) ? 'check-square green' : 'square black'} align-self-center`} /><span className="align-self-center font-8 idented">{season.Name || season.SeasonName}</span></button>
                                    <button className="btn btn-link" onClick={() => this.onDrillSeason(season.IdSeason)}><i className="fa fa-caret-right" /></button>
                                </div>
                            )}
                            {/* Division */}
                            {drill === 2 && <button onClick={() => this.drillBack(1)} className="btn bg-black w-100 white d-flex flex-row"><i className="fa fa-caret-left align-self-center margin-right-half" /><span>{selectedSeason.Name || selectedSeason.SeasonName}</span></button>}
                            {drill === 2 && divisions && divisions.map((division, index) =>
                                <div className="d-flex flex-row" key={index}>
                                    <button onClick={() => this.onSelectDivision(division)} className="btn btn-link d-flex flex-row w-100" key={index}><i className={`far fa-${_.find(to, t => t.IdUser === division.IdDivision) ? 'check-square green' : 'square black'} align-self-center`} /><span className="align-self-center font-8 idented">{division.Name || division.DivisionName}</span></button>
                                    <button className="btn btn-link" onClick={() => this.onDrillDivision(division.IdDivision)}><i className="fa fa-caret-right" /></button>
                                </div>
                            )}
                            {/* Scouts */}
                            {drill === 2 && scouts && scouts.length > 0 && <button className="btn bg-black w-100 white d-flex flex-row"><i className="fa fa-caret-left align-self-center margin-right-half" /><span>Scouts</span></button>}
                            {drill === 2 && scouts && scouts.map((scout, index) =>
                                <div className="d-flex flex-row" key={index}>
                                    <button onClick={() => this.onSelectUser(scout)} className="btn btn-link d-flex flex-row w-100" key={index}>
                                        <Avatar url={scout.UserImage} defaultImage={`/images/defaults/${scout.IdGender === 2 ? 'female' : 'male'}.png`} width={20} height={20} />
                                        <span className="align-self-center font-8 idented">{scout.Name || `${scout.NameFirst} ${scout.NameLast}`}</span>
                                        <i className={`far fa-${_.find(to, t => t.IdUser === scout.IdUser) ? 'check-square green' : 'square black'} align-self-center ml-auto`} />
                                    </button>
                                </div>
                            )}
                            {/* Teams */}
                            {drill === 3 && <button onClick={() => this.drillBack(2)} className="btn bg-black w-100 white d-flex flex-row"><i className="fa fa-caret-left align-self-center margin-right-half" /><span>{selectedDivision.Name || selectedDivision.DivisionName}</span></button>}
                            {drill === 3 && teams && teams.map((team, index) =>
                                <div className="d-flex flex-row">
                                    <button onClick={() => this.onSelectTeam(team)} className="btn btn-link d-flex flex-row w-100" key={index}><i className={`far fa-${_.find(to, t => t.IdTeam === team.IdTeam) ? 'check-square green' : 'square black'} align-self-center`} /><span className="align-self-center font-8 idented">{team.Name || team.TeamName}</span></button>
                                    <button className="btn btn-link" onClick={() => this.onDrillTeam(team.IdTeamDivision)}><i className="fa fa-caret-right" /></button>
                                </div>
                            )}
                            {/* Roster */}
                            {drill === 4 && <button onClick={() => this.drillBack(3)} className="btn bg-black w-100 white d-flex flex-row"><i className="fa fa-caret-left align-self-center margin-right-half" /><span>{selectedTeam.Name || selectedTeam.TeamName}</span></button>}
                            {drill === 4 && roster && roster.map((user, index) =>
                                <div className="d-flex flex-row">
                                    <button onClick={() => this.onSelectUser(user)} className="btn btn-link d-flex flex-row w-100" key={index}>
                                        <Avatar url={user.UserImage} defaultImage={`/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png`} width={20} height={20} />
                                        <span className="align-self-center font-8 idented">{user.Name || `${user.NameFirst} ${user.NameLast}`}</span>
                                        <i className={`far fa-${_.find(to, t => t.IdUser === user.IdUser) ? 'check-square green' : 'square black'} align-self-center ml-auto`} />
                                    </button>
                                </div>
                            )}
                        </section>
                    </div>}
                </ModalBody>
                {incompleteForm && dirty && <ModalBody className="bg-danger text-center white">Please complete the required fields <i className="fa fa-asterisk" /> and try again</ModalBody>}
                {this.state.updated && <ModalBody className="bg-success text-center white">Message Sent!</ModalBody>}
                {error && <ModalBody className="bg-danger text-center white">{error.message}</ModalBody>}
                {!microTransac && (
                    <ModalFooter>
                        {step === 1 && <button className="btn btn-sm btn-success" onClick={this.send}>Send Message</button>}
                        {step === 3 && <button className="btn btn-sm btn-success" onClick={this.ok}>Select</button>}
                    </ModalFooter>)}
                {microTransac && (
                    <ModalFooter>
                        <button className="btn btn-sm btn-success"><i className="fa fa-cog fa-spin fa-2x font-10" />
                            {' '}Please wait...</button>
                    </ModalFooter>
                )}
            </Modal>
        )
    }
}

export default NewMessage;
