import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';
import { humanize_user } from '../../helpers';
import fakeAuth from '../../fakeAuth';

export function* getFamilyTeams() {
    yield* takeEvery("TEAMS/FAMILY/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/family/team_divisions`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            });

            if (result && result.success) {
                // Decrypt
                humanize_user(result);
                result.teams.forEach(o => humanize_user(o));
                yield put({ type: 'TEAMS/SET', teams: result.teams });
                yield put({ type: 'FAMILY/LASTNAME/SET', FamilyLastName: result.FamilyLastName });
            } else {
                yield put({ type: 'TEAMS/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'TEAMS/FETCH_FAILED', e });
        }
    });
}

export function* getFamilyArchivedTeams() {
    yield* takeEvery("TEAMS/FAMILY/ARCHIVED/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v5/teams/archived`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            });

            if (result && result.success) {
                // Decrypt                
                //result.teams.forEach(o => humanize_user(o));                
                yield put({ type: 'TEAMS/ARCHIVED/SET', archived_teams: result.teams });
            } else {
                yield put({ type: 'TEAMS/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'TEAMS/FETCH_FAILED', e });
        }
    });
}

export function* getFamilySubscriptions() {
    yield* takeEvery("FAMILY/SUBSCRIPTIONS/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v4/video/subscriptions/by_family`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            });

            if (result && result.success) {
                yield put({
                    type: 'FAMILY/SUBSCRIPTIONS/SET',
                    // NEW: Family subscriptions are now by user
                    family_subscriptions: _.filter(result.subscriptions, s => s.Amount > 0 && s.IdUser)
                });
                yield put({
                    type: 'FAMILY/PLANS/SET',
                    afp: result.afp
                });
            } else {
                yield put({
                    type: 'FAMILY/SUBSCRIPTIONS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FAMILY/SUBSCRIPTIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getTeamSubscriptions() {
    yield* takeEvery("TEAM/SUBSCRIPTIONS/FETCH", function* (action) {
        try {
            const result = yield call(({ idTeam }) => {
                return request.get(`${config.apiEndpoint}/api/v4/video/subscriptions/by_team/${idTeam}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'FAMILY/SUBSCRIPTIONS/SET',
                    family_subscriptions: result.subscriptions
                });
                yield put({
                    type: 'FAMILY/PLANS/SET',
                    afp: result.afp
                });
            } else {
                yield put({
                    type: 'FAMILY/SUBSCRIPTIONS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FAMILY/SUBSCRIPTIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getTeamEvaluations() {
    yield* takeEvery("TEAM/EVALUATIONS/FETCH", function* (action) {
        try {
            const result = yield call(({ IdTeam }) => {
                return request.get(`${config.apiEndpoint}/api/v6/teams/evaluations/${IdTeam}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
            if (result && result.success) {
                yield put({
                    type: 'TEAM/EVALUATIONS/SET',
                    team_evaluations: result.evaluations
                });
            } else {
                yield put({
                    type: 'FAMILY/EVALUATIONS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FAMILY/EVALUATIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getFamilyTeamsWithAppPlans() {
    yield* takeEvery("TEAMS/APP_PLANS/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v4/appPlans/teams`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });

            if (result && result.success) {
                yield put({
                    type: 'TEAMS/SET',
                    teams: result.teams
                });
            } else {
                yield put({
                    type: 'TEAMS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TEAMS/FETCH_FAILED',
                e
            });
        }
    });
}

// /FAMILY/INFORMATION/FETCH
export function* getFamilyInformation() {
    yield* takeEvery("FAMILY/INFORMATION/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/family/information`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });
            if (result && result.success) {
                humanize_user(result.information);
                yield put({
                    type: 'FAMILY/INFORMATION/SET',
                    information: result.information
                });
            } else {
                yield put({
                    type: 'FAMILY/INFORMATION/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FAMILY/INFORMATION/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getFamilyMembers() {
    yield* takeEvery('MEMBERS/FAMILY/FETCH', function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/family/members`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });
            if (result && result.success) {
                result.users.forEach(u => humanize_user(u));
                yield put({ type: 'FAMILY/SET', family: result.users });
                const user = fakeAuth.getUser();

                // Select the user logged or the 1st user of the family
                yield put({
                    type: 'SELECTED_USER/SET',
                    user: user ? _.find(result.users, (u) => {
                        return u.IdUser === user.IdUser
                    }) : _.first(result.users)
                });
            } else {
                yield put({
                    type: 'FAMILY/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FAMILY/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getFamilyContactSaga() {
    yield* takeEvery("CONTACT/FAMILY/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/family/contact`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });
            if (result && result.success) {
                const { groupedPhones, emails } = result;

                // Decrypt
                emails.forEach(u => humanize_user(u));
                groupedPhones.forEach(group => {
                    humanize_user(group);
                    group.users.forEach(u => humanize_user(u));
                });

                yield put({
                    type: 'PHONES/SET',
                    phones: groupedPhones
                });
                yield put({
                    type: 'EMAILS/SET',
                    emails
                });
            } else {
                yield put({
                    type: 'FAMILY/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'CONTACT/FAMILY/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getCommunitiesSaga() {
    yield* takeEvery("COMMUNITIES/FETCH", function* (action) {
        try {
            const result = yield call(function () {
                return request.get(`${config.apiEndpoint}/api/v4/common/communities`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });
            if (result && result.success) {
                yield put({
                    type: 'COMMUNITIES/SET',
                    communities: result.communities
                });
            } else {
                yield put({
                    type: 'COMMUNITIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COMMUNITIES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getAgeGroupsSaga() {
    yield* takeEvery("AGE_GROUPS/FETCH", function* (action) {
        try {
            const result = yield call(function () {
                return request.get(`${config.apiEndpoint}/api/v4/common/age_groups`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });
            if (result && result.success) {
                yield put({
                    type: 'AGE_GROUPS/SET',
                    ageGroups: result.ageGroups
                });
            } else {
                yield put({
                    type: 'AGE_GROUPS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'AGE_GROUPS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getRankingAgeGroupsSaga() {
    yield* takeEvery("AGE_GROUPS/RANKINGS/FETCH", function* (action) {
        try {
            const result = yield call(function () {
                return request.get(`${config.apiEndpoint}/api/v4/common/rank_age_groups`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            });
            if (result && result.success) {
                yield put({ type: 'AGE_GROUPS/SET', ageGroups: result.ageGroups });
            } else {
                yield put({ type: 'AGE_GROUPS/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'AGE_GROUPS/FETCH_FAILED', e });
        }
    });
}

export function* addFamilyMemberSaga() {
    yield* takeEvery("MEMBERS/FAMILY/ADD", function* (action) {
        try {
            const result = yield call(({ user }) => {
                return request.post(`${config.apiEndpoint}/api/v6/family/member/full`)
                    .send(user)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
            if (result && result.success) {
                yield put({
                    type: 'MEMBERS/FAMILY/SET_ID',
                    user: { ...action.user, IdUser: result.IdUser }
                });
                yield put({ type: 'MEMBERS/FAMILY/FETCH' });
            } else {
                yield put({
                    type: 'MEMBERS/FAMILY/ADD_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'MEMBERS/FAMILY/ADD_FAILED',
                e
            });
        }
    });
}

//MEMBERS/FAMILY/UPDATE
export function* updateFamilyMemberSaga() {
    yield* takeEvery("MEMBERS/FAMILY/UPDATE", function* (action) {
        try {
            const result = yield call(({ user }) => {
                return request.patch(`${config.apiEndpoint}/api/v6/family/member/full/${user.IdUser}`)
                    .send(user)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            yield put({ type: 'MICRO/SET', micro: false });
            if (result && result.success) {
                // Optimistic
                if (action.user.IdTeamDivision) {
                    yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.user.IdTeamDivision })
                }
                yield put({ type: 'MEMBERS/FAMILY/FETCH' });
            } else {
                yield put({
                    type: 'MEMBERS/FAMILY/UPDATE_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'MEMBERS/FAMILY/UPDATE_FAILED',
                e
            });
        }
    });
}

//MEMBERS/FAMILY/REMOVE
export function* removeFamilyMemberSaga() {
    yield* takeEvery("MEMBERS/FAMILY/REMOVE", function* (action) {
        try {
            const result = yield call(({ user }) => {
                return request.del(`${config.apiEndpoint}/api/v4/family/member/${user.IdUser}`)
                    .send(user)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                // Optimistic
            } else {
                yield put({
                    type: 'MEMBERS/FAMILY/REMOVE_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'MEMBERS/FAMILY/REMOVE_FAILED',
                e
            });
        }
    });
}

//FAMILY/PHONE/ASSIGN
export function* assignPhoneNumberSaga() {
    yield* takeEvery("FAMILY/PHONE/ASSIGN", function* (action) {
        try {
            const result = yield call(({ idUser, idPhoneNumber, selected }) => {
                return request.post(`${config.apiEndpoint}/api/v4/family/assign/phone`)
                    .send({ idUser, idPhoneNumber, selected })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic
            } else {
                yield put({ type: 'FAMILY/PHONE/ASSIGN_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/PHONE/ASSIGN_FAILED', e });
        }
    });
}

export function* addPhoneSaga() {
    yield* takeEvery("PHONES/SAVE", function* (action) {
        try {
            const result = yield call(({ phone }) => {
                return request.post(`${config.apiEndpoint}/api/v4/family/phone`)
                    .send({ phone })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic cuz I'm already sending the ID..(crazy right ?)
                yield put({ type: 'CONTACT/FAMILY/FETCH' });
            } else {
                yield put({ type: 'FAMILY/PHONE/SAVE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/PHONE/SAVE_FAILED', e });
        }
    });
}

export function* updatePhoneSaga() {
    yield* takeEvery("PHONES/UPDATE", function* (action) {
        try {
            const result = yield call(({ phone, newId, oldId }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/family/phone/${oldId}`)
                    .send({ phone, newId })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic cuz I'm already sending the ID..(crazy right ?)
                yield put({ type: 'CONTACT/FAMILY/FETCH' });
            } else {
                yield put({ type: 'FAMILY/PHONE/UPDATE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/PHONE/UPDATE_FAILED', e });
        }
    });
}

export function* deletePhoneSaga() {
    yield* takeEvery("PHONES/DELETE", function* (action) {
        try {
            const result = yield call(({ idPhoneNumber }) => {
                return request.del(`${config.apiEndpoint}/api/v4/family/phone/${idPhoneNumber}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic cuz I'm already sending the ID..(crazy right ?)
            } else {
                yield put({ type: 'FAMILY/PHONE/DELETE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/PHONE/DELETE_FAILED', e });
        }
    });
}

export function* updateShirtSizeSaga() {
    yield* takeEvery("SHIRT_SIZE/UPDATE", function* (action) {
        try {
            const result = yield call(({ idUser, shirtSize }) => {
                return request.post(`${config.apiEndpoint}/api/v4/sizes/shirt/${idUser}`)
                    .send({ shirtSize })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic cuz I'm already sending the ID..(crazy right ?)
            } else {
                yield put({ type: 'SHIRT_SIZE/UPDATE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'SHIRT_SIZE/UPDATE_FAILED', e });
        }
    });
}

export function* assignEmailSaga() {
    yield* takeEvery("FAMILY/EMAIL/ASSIGN", function* (action) {
        try {
            const result = yield call(({ idUser, idEmailAddress, selected }) => {
                return request.post(`${config.apiEndpoint}/api/v4/family/assign/email`)
                    .send({ idUser, idEmailAddress, selected })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic
            } else {
                yield put({ type: 'FAMILY/EMAIL/ASSIGN_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/EMAIL/ASSIGN_FAILED', e });
        }
    });
}

export function* addEmailSaga() {
    yield* takeEvery("EMAILS/SAVE", function* (action) {
        try {
            const result = yield call(({ email }) => {
                return request.post(`${config.apiEndpoint}/api/v4/family/email`)
                    .send({ EmailAddress: email.Address })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic
                action.email.IdEmailAddress = result.IdEmailAddress;
                yield put({ type: 'EMAILS/COMPLETE', email: action.email });
            } else {
                yield put({ type: 'FAMILY/EMAIL/ASSIGN_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/EMAIL/ASSIGN_FAILED', e });
        }
    });
}

export function* updateEmailSaga() {
    yield* takeEvery("EMAILS/UPDATE", function* (action) {
        try {
            const result = yield call(({ email }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/family/email/${email.IdEmailAddress}`)
                    .send({ EmailAddress: email.Address })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic                
            } else {
                yield put({ type: 'FAMILY/EMAIL/UPDATE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/EMAIL/UPDATE_FAILED', e });
        }
    });
}

export function* deleteEmailSaga() {
    yield* takeEvery("EMAILS/DELETE", function* (action) {
        try {
            const result = yield call(({ idEmailAddress }) => {
                return request.del(`${config.apiEndpoint}/api/v4/family/email/${idEmailAddress}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic                
            } else {
                yield put({ type: 'FAMILY/EMAIL/DELETE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/EMAIL/DELETE_FAILED', e });
        }
    });
}

export function* getEmailsToUnsubscribe() {
    yield* takeEvery("FAMILY/UNSUBSCRIBE/EMAIL/FETCH", function* (action) {
        try {
            const result = yield call(({ idFamily, idMessage }) => {
                return request.get(`${config.apiEndpoint}/api/v4/messages/unsubscribe/${idFamily}/${idMessage}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                // Optimistic                
                yield put({ type: 'EMAILS/SET', emails: result.emails });
            } else {
                yield put({ type: 'FAMILY/EMAIL/DELETE_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/EMAIL/DELETE_FAILED', e });
        }
    });
}

export function* getCollegeProfile() {
    yield* takeEvery("COLLEGE_PROFILE/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/v6/college_profile/${idUser}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                const { profile } = result;
                // Decrypt
                if (profile) humanize_user(profile);
                //
                yield put({ type: 'COLLEGE_PROFILE/SET', profile });
            } else {
                yield put({ type: 'COLLEGE_PROFILE/FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'COLLEGE_PROFILE/FAILED', e });
        }
    });
}

export function* togglePersonalInfo() {
    yield* takeEvery("FAMILY/PERSONAL_INFO/TOGGLE", function* (action) {
        try {
            const result = yield call(({ IsHidden,
                IdTeamUserRole }) => {

                return request.post(`${config.apiEndpoint}/api/v4/membership/toggle_personal_info`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({
                        IsHidden,
                        IdTeamUserRole
                    })
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                //yield put({ type: 'COLLEGE_PROFILE/SET', profile });
            } else {
                yield put({ type: 'FAMILY/PERSONAL_INFO/TOGGLE/FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FAMILY/PERSONAL_INFO/TOGGLE/FAILED', e });
        }
    });
}

export function* fetchWaiversSaga() {
    yield* takeEvery("FAMILY/WAIVERS/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v4/waivers`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                const { waivers } = result;
                yield put({ type: 'WAIVERS/SET', waivers });
            } else {
                yield put({ type: 'WAIVERS/FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'WAIVERS/FAILED', e });
        }
    });
}