import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

// Updates the DOB of an user (optimistic)
export function* updateDOBSaga() {
  yield* takeEvery("USER/DOB/CHANGE", function* (action) {

    try {
      yield call(({ idUser, dob }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/users/dob`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({
            idUser, dob
          })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
    } catch (e) {
      yield put({
        type: 'USER/DOB/FETCH_FAILED',
        e
      });
    }
  });
}
