import React from 'react';
import _ from 'lodash';
import DivisionList from '../common/divisions/List';
import PaymentPlansList from '../common/payment_plans/List';
import SelectQuestion from '../common/questions/Select';
import RadioQuestion from '../common/questions/Radio';
import TextQuestion from '../common/questions/Text';
import CheckQuestion from '../common/questions/Check';
import CheckListQuestion from '../common/questions/CheckList';
import CheckoutActionsPopup from '../common/CheckoutActionsPopup';
import RegistrationPlayerSelection from '../common/RegistrationPlayerSelection';
import PersonSelection from './modals/PersonSelection';
import { generateRandomId } from '../../../helpers';
import CartPopup from '../common/CartPopup';
import config from '../../../config';
import request from 'superagent';
import Season from './partials/Season';
import Person from './partials/Person';
import Division from './partials/Division';


//const Soon = () => {
class ScoutRegistration extends React.Component {

    state = {
        questions: null,
        paymentPlans: null,
        divisions: null,
        selectedDivision: null,
        selectedPlan: null,
        selectedTeam: null,
        selectedUser: null,
        selectedRole: null,
        selectedSeason: null,
        selectedLeague: null,
        bootstrapped: false,
        step: 1,
        isCheckoutActionsOpen: false,
        isPlayerSelectionOpen: false,
        mode: 5,
        fetchingDataLocally: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, pouch, league } = this.props;

        setTitle && setTitle({
            main: 'Registration',
            sub: (pouch && pouch.selectedLeague) ? pouch.selectedLeague.LeagueName : null
        });

        this.setState(_.extend(this.state, this.props.pouch))
        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();

        // After fetching Payment plans and Questions
        var exit = _.after(2, () => {
            const { paymentPlans, questions } = this.state;
            let step = 2;
            if (paymentPlans.length) {
                this.setState({
                    fetchingDataLocally: false,
                    step
                });
            }
            else if (!paymentPlans.length && questions.length) {
                step = 3;
                this.setState({
                    fetchingDataLocally: false,
                    step
                });
            } else {
                // Here we save the item in the cart and go?
                this.addToCart();
                this.setState({ isCheckoutActionsOpen: true });
            }
        });

        // TODO: fetch divisions and stuffs
        if (pouch && pouch.selectedSeason) {
            /*this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfUsers(pouch.selectedSeason.IdSeason, pouch.selectedUser.IdUser, pouch.mode === 1 ? 3 : 4)*/


            // Get payment plans
            request.get(`${config.apiEndpoint}/api/v4/paymentPlans/scout_registration/${pouch.selectedSeason.IdSeason}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => {
                    this.setState({ paymentPlans: data.body.plans });
                    exit();
                }, () => {
                    this.setState({ paymentPlans: [] });
                    exit();
                });

            // Get questions            
            request.get(`${config.apiEndpoint}/api/v4/registration/questions/scout_registration/${pouch.selectedSeason.IdSeason}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => {
                    this.setState({ questions: data.body.questions });
                    exit();
                }, (reject) => {
                    this.setState({ questions: [] });
                    exit();
                });
        }

        if ((!league || !league.IdLeague) && pouch && pouch.selectedLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(pouch.selectedLeague.IdLeague)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState(_.extend(nextProps.pouch, { bootstrapped: true }));
    }
    //

    back = () => {
        const { step, divisions } = this.state,
            { league } = this.props;
        if (step === 1 || step === 3) { // In this case step = 3 when scouts have questions. Most likely scouts will not have payment plans
            this.props.router && this.props.router.push('/registration/' + league.IdLeague);
        } else {
            this.props.divisionsActions && this.props.divisionsActions.clearQuestions();
            this.props.divisionsActions && this.props.divisionsActions.clearPaymentPlans();

            divisions.Male && divisions.Male.forEach((d) => {
                d.selected = false;
            });
            divisions.Female && divisions.Female.forEach((d) => {
                d.selected = false;
            });
            divisions.Coed && divisions.Coed.forEach((d) => {
                d.selected = false;
            });

            this.setState({
                step: 1,
                divisions
            });
        }
    }

    selectDivision = (division) => {
        var { divisions } = this.state, { pouch } = this.props;

        divisions.Male && divisions.Male.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });
        divisions.Female && divisions.Female.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });
        divisions.Coed && divisions.Coed.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });

        // After fetching Payment plans and Questions
        var exit = _.after(2, () => {
            this.setState({
                fetchingDataLocally: false,
                selectedDivision: division
            });
        });

        // Spinner
        this.setState({
            fetchingDataLocally: true,
            divisions
        });

        // Get payment plans
        var url = [config.apiEndpoint, 'api/v4/paymentPlans',
        division.IdDivision, pouch.mode === 1 ? 3 : 4].join('/');
        request.get(url)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                this.setState({
                    paymentPlans: data.body.plans
                });
                exit();
            }, () => {
                this.setState({
                    paymentPlans: null
                });
                exit();
            });

        // Get questions
        url = [config.apiEndpoint, 'api/v4/registration/questions',
        division.IdDivision, pouch.mode === 1 ? 3 : 4].join('/');
        request.get(url)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                this.setState({
                    questions: data.body.questions
                });
                exit();
            }, (reject) => {
                this.setState({
                    questions: null
                });
                exit();
            });


    }

    next = () => {
        const { step } = this.state;
        if (step === 1) {
            this.setState({
                step: (this.state.paymentPlans && this.state.paymentPlans.length) ? 2 : (this.state.questions && this.state.questions.length ? 3 : 1)
            }, function () {
                // still 1 ? then add to cart and continue
                if (this.state.step === 1) {
                    this.addToCart(); //?
                    this.setState({
                        isCheckoutActionsOpen: true
                    });
                }
            });
        } else if (step === 2) {
            if (this.state.questions && this.state.questions.length) {
                this.setState({
                    step: 3
                }); // TODO: if there are questions
            } else {
                this.addToCart();
                this.setState({
                    isCheckoutActionsOpen: true
                });
            }
        }
    }

    backToDivisions = () => {
        const { divisions } = this.state;
        divisions.Male && divisions.Male.forEach((d) => {
            d.selected = false;
        });
        divisions.Female && divisions.Female.forEach((d) => {
            d.selected = false;
        });
        divisions.Coed && divisions.Coed.forEach((d) => {
            d.selected = false;
        });
        this.setState({
            selectedDivision: null,
            questions: null,
            paymentPlans: null,
            step: 1,
            divisions
        });
    }

    selectPaymentPlan = (pp) => {
        const { paymentPlans } = this.state;
        paymentPlans.forEach((plan) => {
            plan.selected = (plan.IdPlan === pp.IdPlan && plan.Name === pp.Name);
        });
        this.setState({
            selectedPlan: pp,
            paymentPlans
        });
    }

    toggleCheckoutActions = () => {
        this.setState({
            isCheckoutActionsOpen: !this.state.isCheckoutActionsOpen
        });
    }

    tooglePlayerSelection = () => {
        this.setState({
            isPlayerSelectionOpen: !this.state.isPlayerSelectionOpen,
            isCheckoutActionsOpen: false
        });
    }

    cancelRegisterAnother = () => {
        this.setState({
            isPlayerSelectionOpen: false,
            isCheckoutActionsOpen: true
        });
    }

    restart = (newPouch) => {

        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();
        this.setState(_.extend(this.state, newPouch, {
            step: 1,
            isCheckoutActionsOpen: false,
            isPlayerSelectionOpen: false,
            selectedDivision: null,
            selectedPlan: null,
            questions: null,
            paymentPlans: null,
            divisions: null
        }), function () {
            this.props.pouchActions &&
                this.props.pouchActions.set(newPouch);

            this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfUsers(newPouch.selectedSeason.IdSeason, newPouch.selectedUser.IdUser, newPouch.mode === 1 ? 3 : 4)

            // Scroll to the top !
            //document.getElementById('top-of').scrollIntoView()
        })

    }

    toggleCart = () => {
        this.props.cartActions &&
            this.props.cartActions.close();
    }

    addToCart = () => {

        var { cart } = this.props,
            { questions, selectedDivision, selectedUser, mode } = this.state;

        // 1st validate all the required questions
        if (_.find(questions, (q) => {
            return q.Required && !q.answer;
        })) {
            alert('Please answer all the required questions');
        } else {

            this.setState({
                isCheckoutActionsOpen: true
            }, function () {
                if (!cart)
                    cart = [];

                if (!_.find(cart, (item) => {
                    return (item.selectedUser.IdUser === selectedUser.IdUser &&                        
                        item.mode === mode);
                })) {

                    this.props.cartActions && this.props.cartActions.set([...cart, _.extend({
                        Date: new Date(),
                        Id: generateRandomId(10),
                        SCOUT: true,
                        questions: _.map(this.state.questions, (q) => {
                            return _.omit(q, 'Answers')
                        })
                    }, _.pick(this.state, 'selectedPlan', 'selectedUser', 'selectedLeague',
                        'selectedSeason', 'selectedRole', 'mode'))]);
                }
            });
        }
    }

    render() {
        const { fetchingDataLocally, isCheckoutActionsOpen, isPlayerSelectionOpen, selectedPlan, divisions, selectedRole,
            selectedDivision, selectedSeason, selectedUser, step, bootstrapped, questions, paymentPlans } = this.state;
        const { fetchingData, league, cart, family, familyRegistration, isCartOpen } = this.props;
        return (
            <section>
                {league && <div className="card shadow-box w-100 px-2 py-2 d-flex flex-row pointer" onClick={this.back}>
                    <i className="fa fa-arrow-left align-self-center" />
                    <span className="align-self-center ml-2">Back</span>
                </div>}

                <section className="w3-container">
                    <div className="w3-content">

                        <div className="tile card shadow-box d-flex flex-column mx-auto mt-4" style={{ maxWidth: 600 }}>
                            <Season season={selectedSeason} />
                            <Person selectedUser={selectedUser} selectedRole={{ Name: 'Scout ' }} />
                        </div>

                        { /* Divisions */}
                        {step === 1 && bootstrapped && (
                            <section key="step1" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your division</span>
                                {divisions && divisions.Male && <DivisionList title="Male Divisions" divisions={divisions.Male} fnSelect={this.selectDivision} />}
                                {divisions && divisions.Female && <DivisionList title="Female Divisions" divisions={divisions.Female} fnSelect={this.selectDivision} />}
                                {divisions && divisions.Coed && <DivisionList title="Coed Divisions" divisions={divisions.Coed} fnSelect={this.selectDivision} />}
                                {divisions && !divisions.Male && !divisions.Female && !divisions.Coed && <div style={{ height: 400 }} className="d-flex flex-column p-4 justify-content-center">
                                    <img alt="" src="/images/defaults/red_card.png" style={{ width: 100 }} className="align-self-center" />
                                    <span className="align-self-center mt-2">Sorry, there are no divisions available for registration right now</span>
                                </div>}
                            </section>
                        )}

                        { /* Payment Plans */}
                        {step === 2 && bootstrapped && paymentPlans.length > 0 && (
                            <section key="step2" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your Payment Plan</span>
                                <PaymentPlansList paymentPlans={paymentPlans} fnSelect={this.selectPaymentPlan} />
                            </section>)}

                        { /* Questions */}
                        {step === 3 && bootstrapped && (
                            <section key="step3" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Please answer some questions</span>
                                {questions && questions.map((q, i) => (
                                    <figure className="card tile shadow-box w-100 mt-2 mb-2 ml-auto mr-auto" key={i} style={{ maxWidth: 600 }}>
                                        {q.IdQuestionType === 5 && <SelectQuestion question={q} />}
                                        {q.IdQuestionType === 6 && <RadioQuestion question={q} />}
                                        {q.IdQuestionType === 2 && <TextQuestion question={q} />}
                                        {q.IdQuestionType === 3 && <CheckQuestion question={q} />}
                                        {q.IdQuestionType === 4 && <CheckListQuestion question={q} />}
                                    </figure>))}
                            </section>)}

                        <div style={{ height: 200 }} />
                    </div>
                </section>

                { /* Loader */}
                {bootstrapped && (fetchingData || fetchingDataLocally) && (
                    <div className="d-flex justify-content-center centered-message" key="laoder">
                        <h1 className="white align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1> </div>)}
                { /* Checkout actions */}
                {isCheckoutActionsOpen && <CheckoutActionsPopup router={this.props.router}
                    league={league} isOpen={isCheckoutActionsOpen} toggle={this.toggleCheckoutActions}
                    fnRegisterAnother={this.tooglePlayerSelection} fnOk={this.toggleCheckoutActions} label="Player"
                />}

                { /* Register Another */}

                {isPlayerSelectionOpen && <PersonSelection close={this.togglePlayerSelection} cart={cart}
                    league={league} season={selectedSeason} family={family} fnAddFamilyMember={() => { }}
                    fnOk={this.restart}
                    familyRegistration={familyRegistration} />}

                {isPlayerSelectionOpen && false && <RegistrationPlayerSelection cart={cart} league={league}
                    season={selectedSeason} family={family} isOpen={isPlayerSelectionOpen}
                    toggle={this.cancelRegisterAnother} fnOk={this.restart} familyRegistration={familyRegistration} />}

                {isCartOpen && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.isCartOpen} />}


                {((step === 1 && selectedDivision) || (step === 2 && selectedPlan)) && (
                    <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em', borderRadius: 30 }} onClick={this.next}>
                        Next
                        {' '}<i className="fa fa-arrow-right" /></button>)}

                {step === 3 && <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em' }} onClick={this.addToCart}><i className="fa fa-plus" />
                    {' '}Add to Cart</button>}
            </section>
        );
    }
}

export default ScoutRegistration;