import React from 'react';
import { Table } from 'reactstrap';
import _ from 'lodash';
import RosterPlayer from './RosterPlayer';
import SuspendedPlayer from './SuspendedPlayer';
import RosterCoach from './RosterCoach';
import { Popover, PopoverBody } from 'reactstrap';
import config from '../../../config';
import request from 'superagent';

class RosterTab extends React.Component {
    state = {
        roster: null,
        coaches: null,
        user: null,
        team: null,
        sortBy: 'NameLast'
    }
    componentWillMount() {
        const { user, team, selectedSeason } = this.props;
        this.setState({
            user, team, selectedSeason,
            roster: _.filter(this.props.roster, (u) => {
                return u.IdRole === 3
            }),
            coaches: _.filter(this.props.coaches, (u) => {
                return u.IdRole === 4
            })
        });
    }
    componentWillReceiveProps = (nextProps) => {
        const { user, team, selectedSeason } = nextProps;
        this.setState({
            user,
            team,
            selectedSeason
        });
    }
    //

    togglePopover = () => this.setState({ popoverOpen: !this.state.popoverOpen });

    assignStatus = (status) => {
        var { team, user, selectedSeason } = this.state;

        if (team) {
            team.IdRoleStatus = status.IdRoleStatus;
            team.RoleStatus = status.RoleStatusName;
            team.RegistrationStatus = status.RoleStatusName;
        }
        if (user) {
            selectedSeason.IdRoleStatus = status.IdRoleStatus;
            selectedSeason.RegistrationStatus = status.RoleStatusName;
        }

        this.setState({
            team,
            selectedSeason,
            popoverOpen: false
        });

        // Call API
        // TODO: Might need to refactor this for using on other places ?
        if (team) {
            request.patch([config.apiEndpoint, 'api/v4/teams/status',
            team.IdTeamDivision, status.IdRoleStatus].join('/'))
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => {
                    // Nothing to do here ?
                });
        } else {
            request.patch([config.apiEndpoint, 'api/v4/registration/status',
            user.IdUser, selectedSeason.IdDivision, status.IdRoleStatus].join('/'))
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => {
                    // Nothing to do here ?
                });
        }
    }
    //

    sortBy = x => this.setState({ sortBy: x })

    render() {
        const { roster, team, selectedSeason, popoverOpen, sortBy, coaches } = this.state;

        let actives = _.filter(roster || [], r => r.IdRoleStatus === 2 && !r.violations.length),
            suspended = _.filter(roster || [], r => r.violations && r.violations.length),
            inactives = _.filter(roster || [], r => r.IdRoleStatus !== 2 && !r.violations.length);            

        return <section>

            <h4 className="mt-3 ml-3">Roster</h4>

            <div className="d-flex flex-row p-3 montserrat">

                <div className="w-100">

                    { /* Division */}
                    <div className="align-self-center">
                        <span className="align-self-center block font-14  line1">{(team || selectedSeason || {}).DivisionName}</span>
                        <span className="font-8 block">Division</span>
                    </div>

                    { /* Team */}
                    {false && selectedSeason && selectedSeason.IdTeamDivision && !team && (
                        <div className="d-flex flex-row">
                            <i className="fas fa-shield-alt idented align-self-center" />
                            <span className="idented-half align-self-center">Team: </span>
                            <span className="red idented-half align-self-center">{selectedSeason.TeamName}</span>
                        </div>)}
                </div>

                <div className="w-100">
                    { /* Status */}
                    <button className="btn btn-link btn-block d-flex flex-row align-self-center" id="btnStatus" onClick={this.togglePopover}>
                        <i className={`fas fa-circle align-self-center`} style={{ color: (_.find(config.roleStatuses, (st) => { return st.IdRoleStatus === (team || selectedSeason).IdRoleStatus }) || { color: 'white' }).color }} />
                        <span className="idented-half align-self-center">Status: {(team || selectedSeason).RegistrationStatus || (team || selectedSeason).RoleStatus}</span>
                        <i className="fa fa-caret-down ml-auto align-self-center" />
                    </button>

                    { /* Status Popover */}
                    <Popover placement="bottom" isOpen={popoverOpen} target="btnStatus" toggle={this.toggle}>
                        <PopoverBody className="d-flex flex-column">
                            {this.props.statuses && this.props.statuses.map((s, i) => (
                                <button key={i} className="btn btn-link btn-sm btn-block d-flex flex-row" onClick={() => this.assignStatus(s)}>
                                    <i className={`fas fa-circle align-self-center margin-right`} style={{ color: _.find(config.roleStatuses, (st) => { return st.IdRoleStatus === s.IdRoleStatus }).color }} />
                                    {' '}
                                    {s.RegistrationStatus}
                                </button>))}
                        </PopoverBody>
                    </Popover>
                </div>
            </div>

            {coaches && coaches.length > 0 && <section>
                <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Coaches</h3>
                <Table size="sm" className="montserrat">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }} onClick={() => this.sortBy('IdUser')} className={sortBy === 'IdUser' ? 'blue' : 'black'}>Id</th>
                            <th style={{ width: 400 }} onClick={() => this.sortBy('NameLast')} className={`${sortBy === 'NameLast' ? 'blue' : 'black'}`}>Name</th>
                            <th style={{ width: 100 }} className={sortBy === 'JerseyNumber' ? 'blue' : 'black'}></th>
                            <th style={{ width: 100 }} onClick={() => this.sortBy('Gender')} className={`${sortBy === 'Gender' ? 'blue' : 'black'} text-center`}>Gender</th>
                            <th className="text-center" style={{ width: 'auto' }}>CONC</th>
                            <th className="text-center" style={{ width: 'auto' }}>C.CERT</th>
                            <th className="text-center" style={{ width: 'auto' }}>SAFE S.</th>
                            <th className="text-center" style={{ width: 'auto' }}>BGC.</th>
                            <th className="text-center" style={{ width: 'auto' }}>FP.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coaches && _.sortBy(coaches, sortBy).map((user, i) => (
                            <RosterCoach season={selectedSeason} user={user} key={user.IdUser} />))}
                    </tbody>
                </Table>
            </section>}

            {actives && actives.length > 0 && <section>
                <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Players</h3>
                <Table size="sm" className="montserrat">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }} onClick={() => this.sortBy('IdUser')} className={sortBy === 'IdUser' ? 'blue' : 'black'}>Id</th>
                            <th style={{ width: 400 }} onClick={() => this.sortBy('NameLast')} className={`${sortBy === 'NameLast' ? 'blue' : 'black'}`}>Name</th>
                            <th style={{ width: 100 }} onClick={() => this.sortBy('JerseyNumber')} className={sortBy === 'JerseyNumber' ? 'blue' : 'black'}>Jersey</th>
                            <th style={{ width: 100 }} onClick={() => this.sortBy('Gender')} className={`${sortBy === 'Gender' ? 'blue' : 'black'} text-center`}>Gender</th>
                            <th onClick={() => this.sortBy('DateBirth')} className={`${sortBy === 'DateBirth' ? 'blue' : 'black'} text-center`}>DOB</th>
                            <th className="text-center" style={{ width: 100 }}>Valid?</th>
                            {_.first(actives).IdLeague === 11826 && <th style={{ width: 50 }}>Waiver</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {actives && _.sortBy(actives, sortBy).map((user, i) => (
                            <RosterPlayer season={selectedSeason} user={user} key={user.IdUser} />))}
                    </tbody>
                </Table>
            </section>}

            {inactives && inactives.length > 0 && <section>
                <h3 className={`bg-danger white font-12 text-left division-title no-margin pl-3`}>Non-Active Users</h3>

                <Table size="sm" className="montserrat">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }} onClick={() => this.sortBy('IdUser')} className={sortBy === 'IdUser' ? 'blue' : 'black'}>Id</th>
                            <th style={{ width: 400 }} onClick={() => this.sortBy('NameLast')} className={`${sortBy === 'NameLast' ? 'blue' : 'black'}`}>Name</th>
                            <th style={{ width: 100 }} onClick={() => this.sortBy('JerseyNumber')} className={sortBy === 'JerseyNumber' ? 'blue' : 'black'}>Jersey</th>
                            <th style={{ width: 100 }} onClick={() => this.sortBy('Gender')} className={`${sortBy === 'Gender' ? 'blue' : 'black'} text-center`}>Gender</th>
                            <th onClick={() => this.sortBy('DateBirth')} className={`${sortBy === 'DateBirth' ? 'blue' : 'black'} text-center`}>DOB</th>
                            <th className="text-center" style={{ width: 100 }}>Valid?</th>
                            {_.first(inactives).IdLeague === 11826 && <th style={{ width: 50 }}>Waiver</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {inactives && _.sortBy(inactives, sortBy).map((user, i) => (
                            <RosterPlayer season={selectedSeason} user={user} key={user.IdUser} />))}
                    </tbody>
                </Table>
            </section>
            }

            {suspended && suspended.length > 0 && <section>
                <h3 className={`bg-danger white font-12 text-left division-title no-margin pl-3`}>Suspended Users</h3>

                <Table size="sm" className="montserrat">
                    <thead>
                        <tr>
                            <th style={{ width: 40 }} onClick={() => this.sortBy('IdUser')} className={sortBy === 'IdUser' ? 'blue' : 'black'}>Id</th>
                            <th style={{ width: 400 }} onClick={() => this.sortBy('NameLast')} className={`${sortBy === 'NameLast' ? 'blue' : 'black'}`}>Name</th>
                            <th style={{ width: 200 }} className={sortBy === 'JerseyNumber' ? 'blue' : 'black'}>Reason</th>
                            <th className={`${sortBy === 'DateBirth' ? 'blue' : 'black'} text-center`}>Until</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {suspended && _.sortBy(suspended, sortBy).map((user, i) => (
                            <SuspendedPlayer season={selectedSeason} user={user} key={user.IdUser} />))}
                    </tbody>
                </Table>
            </section>
            }


        </section>
    }
}

export default RosterTab;
