import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';

class TeamItem extends React.Component {

    state = {
        menu: false
    }

    componentWillMount() {
        this.setState({ menu: false, error: false, archiving: false });
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ menu: false, error: false, archiving: false });
    }

    fnMenu = () => {        
        this.setState({ menu: !this.state.menu });
    }

    onArchiveRole = () => {
        const { team } = this.props;
        const the_team = _.first(team);
        this.setState({ archiving: true, error: null }, () => {
            // CALL APIS
            request.post(`${config.apiEndpoint}/api/v5/teams/archive_role`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({ IdTeam: the_team.IdTeam })
                .then(({ body }) => {
                    this.props.familyActions &&
                        this.props.familyActions.fetchTeams();
                    this.props.familyActions &&
                        this.props.familyActions.fetchArchivedTeams();
                    this.setState({ menu: false, error: null });
                }, ({ status, response: { body } }) => {
                    // TODO: if status === 501
                    this.setState({ error: body.error });                    
                });
        });
    }

    onArchive = () => {
        const { team } = this.props;
        const the_team = _.first(team);
        if (this.state.archiving) return;
        this.setState({ archiving: true }, () => {
            // CALL APIS
            request.post(`${config.apiEndpoint}/api/v5/teams/archive`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({ IdTeam: the_team.IdTeam })
                .then(({ body }) => {
                    this.props.familyActions &&
                        this.props.familyActions.fetchTeams();
                    this.props.familyActions &&
                        this.props.familyActions.fetchArchivedTeams();
                    this.setState({ menu: false, error: null });
                }, ({ status, response: { body } }) => {
                    // TODO: if status === 501
                    this.setState({ error: body.error });                    
                });
        });
    }

    renderNormal = () => {
        const { team, index, teamActions } = this.props;
        const the_team = _.first(team);
        const style = !the_team.TeamImageUrl ? {} : {
            background: `url(${the_team.TeamImageUrl}) no-repeat center center`
        }

        return (
            <Animated animationIn="fadeInRight" animationInDelay={20 * index} className="league-square float-left card tile shadow-box">
                <Link to={`/roster/${the_team.IdTeam}`} onClick={() => { teamActions && teamActions.selectTeam(the_team) }}>
                    { /* Logo */}
                    <div className="league-logo text-center mt-4" style={style}>
                        {false && !the_team.TeamImageUrl && <i className="fa fa-shield-alt align-self-center" style={{ fontSize: '8em', color: '#e5e5e5' }} />}
                    </div>
                    { /* Name */}
                    <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center toUpper" style={{ lineHeight: 1 }}>{the_team.Name || the_team.TeamName} ({the_team.IdTeam})</span>
                </Link>
                {(the_team.IsTeamAdmin || the_team.IsTeamOwner) && <span style={{ position: 'absolute', top: -1, right: 0 }} className="icon-Diagonal-Ribbon-A_icon-1 jeff_blue font-40" />}

                <button onClick={this.fnMenu} className="btn" style={{ position: 'absolute', top: 0, left: 0 }}>
                    <i className="fas fa-ellipsis-v ml-auto align-self-center"></i>
                </button>
            </Animated>
        )
    }

    renderMenu = () => {
        const { index } = this.props, { error } = this.state;
        if (error) {
            return <Animated animationIn="fadeInRight" animationInDelay={20 * index} className="league-square float-left card tile shadow-box bg-dark-blue white">
                <div className="d-flex flex-column h-100 justify-content-center">
                    <span className="p-4 text-center">{error.message}</span>
                    <button onClick={this.onArchiveRole} className="btn-success">Archive anyways</button>
                    <button onClick={() => this.setState({ error: null, menu: false })} className="btn-link white">No, cancel</button>
                </div>
                <button onClick={this.fnMenu} className="btn" style={{ position: 'absolute', top: -1, left: 0 }}>
                    <i className="fas fa-ellipsis-v ml-auto align-self-center white"></i>
                </button>
            </Animated>
        } else
            return <Animated animationIn="fadeInRight" animationInDelay={20 * index} className="league-square float-left card tile shadow-box bg-dark-blue white">
                <div className="d-flex flex-column h-100 justify-content-center">
                    <div onClick={this.onArchive} style={{ height: 50 }} className="align-self-center d-flex flex-row pointer">
                        <i className={`fas fa-archive align-self-center mr-3 ${this.state.archiving ? 'fa-beat' : ''}`} />
                        <span className="align-self-center">{`${this.state.archiving ? 'Please wait...' : 'Archive'}`}</span>
                    </div>
                </div>
                <button onClick={this.fnMenu} className="btn" style={{ position: 'absolute', top: -1, left: 0 }}>
                    <i className="fas fa-ellipsis-v ml-auto align-self-center white"></i>
                </button>
            </Animated>
    }

    render() {
        const { menu } = this.state;
        if (menu) {
            return this.renderMenu();
        } else {
            return this.renderNormal();
        }
    }
}

export default TeamItem;
