function reducer(state = [], action) {

    switch (action.type) {
        case 'SCOUT/REGISTRATION/SET':            
            return action.registration;
        default:
            return state;
    }
}

export default reducer;