import React from 'react';
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';
import moment from 'moment';

class Snapshot extends React.Component {
    state = {
        tomorrow: moment().add(1, 'day').format('YYYY-MM-DD'),
        currentWeek: [moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], //getCurrentWeek(),
        currentMonth: [moment().add(-30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], //getCurrentWeek(),
        new_accounts: 0,
        new_referrals: 0,

        app_ios_new_accounts: 0,
        app_android_new_accounts: 0,

        rc_ios_new_accounts: 0,
        rc_android_new_accounts: 0,

        rf_ios_new_accounts: 0,
        rf_android_new_accounts: 0,

        ad_ios_new_accounts: 0,
        ad_android_new_accounts: 0,

    }

    componentWillMount() {
        const { snapshot_mode } = this.props;
        this.setState({ snapshot_mode }, () => {
            this.fetch();
        })
    }
    componentWillReceiveProps = nextProps => {
        const { snapshot_mode } = nextProps;
        if (snapshot_mode !== this.state.snapshot_mode) {
            this.setState({ snapshot_mode }, () => {
                this.fetch();
            })
        }
    }

    fetch = () => {
        const { currentMonth, snapshot_mode, currentWeek } = this.state;

        let start = null, end = null;
        switch (snapshot_mode) {
            case 1: start = _.last(currentWeek); end = this.state.tomorrow; break;
            case 2: start = _.first(currentWeek); end = this.state.tomorrow; break;
            case 3: start = _.first(currentMonth); end = this.state.tomorrow; break;
            default: start = _.last(currentWeek); end = this.state.tomorrow; break;
        }

        request.post(`${config.apiEndpoint}/api/v5/system/new_accounts/yearly`)
            .send({ start, end })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { monthly_totals, referrals_totals } }) => {
                let new_accounts = _.chain(monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                let new_referrals = _.chain(referrals_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                this.setState({ new_accounts, new_referrals });
            });

        request.post(`${config.apiEndpoint}/api/v5/system/app_installments/yearly`)
            .send({ start, end, include_daily: 1 })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { ad, app, rc, rf } }) => {               

                let app_ios_new_accounts = _.chain(app.ios.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                let app_android_new_accounts = _.chain(app.android.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();

                let rc_ios_new_accounts = _.chain(rc.ios.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                let rc_android_new_accounts = _.chain(rc.android.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();

                let rf_ios_new_accounts = _.chain(rf.ios.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                let rf_android_new_accounts = _.chain(rf.android.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();

                let ad_ios_new_accounts = _.chain(ad.ios.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();
                let ad_android_new_accounts = _.chain(ad.android.monthly_totals)
                    .map(a => a.value)
                    .reduce((a, b) => a + b, 0).value();

                this.setState({
                    app_android_new_accounts,
                    app_ios_new_accounts,
                    rc_android_new_accounts,
                    rc_ios_new_accounts,
                    rf_android_new_accounts,
                    rf_ios_new_accounts,
                    ad_android_new_accounts,
                    ad_ios_new_accounts
                })
                //
            });
    }

    render() {
        const { new_accounts,
            new_referrals,
            app_android_new_accounts,
            app_ios_new_accounts,
            rc_android_new_accounts,
            rc_ios_new_accounts,
            rf_android_new_accounts,
            rf_ios_new_accounts,
            ad_android_new_accounts,
            ad_ios_new_accounts,
        } = this.state;
        return <section>
            <div className="w3-content d-flex flex-row px-4 pt-4">
                <div className="card sheet w-100 p-4 shadow d-flex flex-column">
                    <span className="font-16">New Accounts</span>
                    <span className="font-60 align-self-center line1 mt-3 ">{new_accounts}</span>
                </div>
                <div className="p-2" />
                <div className="card sheet w-100 p-4 shadow d-flex flex-column">
                    <span className="font-16">Downloads</span>
                    <span className="font-60 align-self-center line1 mt-3 ">{
                        app_android_new_accounts +
                        app_ios_new_accounts +
                        rc_android_new_accounts +
                        rc_ios_new_accounts +
                        rf_android_new_accounts +
                        rf_ios_new_accounts +
                        ad_android_new_accounts +
                        ad_ios_new_accounts
                    }</span>
                </div>
                <div className="p-2" />
                <div className="card sheet w-100 p-4 shadow d-flex flex-column">
                    <span className="font-16">Referrals</span>
                    <span className="font-60 align-self-center line1 mt-3 ">{new_referrals}</span>
                </div>
            </div>

            <div className="w3-content d-flex flex-row p-4">
                {/* APP */}
                <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                    <div className="d-flex flex-row ">
                        <img className="align-self-center" src="/images/logos/app.png" style={{ width: 30, height: 30 }} alt="" />
                        <span className="font-14 ml-2 align-self-center">SQWAD APP</span></div>
                    <span className="font-50 align-self-center line1 mt-3 ">{app_ios_new_accounts + app_android_new_accounts}</span>
                    <div className="d-flex flex-row font-20">
                        <i className="fab gray fa-apple align-self-center" />
                        <span className="align-self-center ml-2 line1">{app_ios_new_accounts}</span>
                        <i className="fab green fa-android ml-auto align-self-center" />
                        <span className="align-self-center ml-2 line1">{app_android_new_accounts}</span>
                    </div>
                </div>
                <div className="p-2" />
                {/* RC */}
                <div className="card sheet w-100 p-4 shadow">
                    <div className="d-flex flex-row ">
                        <img className="align-self-center" src="/images/logos/rc.png" style={{ width: 30, height: 30 }} alt="" />
                        <span className="font-14 ml-2 align-self-center">SQWAD RC</span></div>
                    <span className="font-50 align-self-center line1 mt-3 ">{rc_android_new_accounts + rc_ios_new_accounts}</span>
                    <div className="d-flex flex-row font-20">
                        <i className="fab gray fa-apple align-self-center" />
                        <span className="align-self-center  ml-2 line1">{rc_ios_new_accounts}</span>
                        <i className="fab green fa-android ml-auto align-self-center" />
                        <span className="align-self-center  ml-2 line1">{rc_android_new_accounts}</span>
                    </div>
                </div>
                <div className="p-2" />
                {/* RF */}
                <div className="card sheet w-100 p-4 shadow">
                    <div className="d-flex flex-row ">
                        <img className="align-self-center" src="/images/logos/rf.png" style={{ width: 30, height: 30 }} alt="" />
                        <span className="font-14 ml-2 align-self-center">SQWAD RF</span></div>
                    <span className="font-50 align-self-center line1 mt-3 ">{rf_android_new_accounts + rf_ios_new_accounts}</span>
                    <div className="d-flex flex-row font-20">
                        <i className="fab gray fa-apple align-self-center" />
                        <span className="align-self-center  ml-2 line1">{rf_ios_new_accounts}</span>
                        <i className="fab green fa-android ml-auto align-self-center" />
                        <span className="align-self-center  ml-2 line1">{rf_android_new_accounts}</span>
                    </div>
                </div>
                <div className="p-2" />
                <div className="card sheet w-100 p-4 shadow">
                    <div className="d-flex flex-row ">
                        <img className="align-self-center" src="/images/logos/ad.png" style={{ width: 30, height: 30 }} alt="" />
                        <span className="font-14 ml-2 align-self-center">SQWAD AD</span></div>
                    <span className="font-50 align-self-center line1 mt-3 ">{ad_android_new_accounts + ad_ios_new_accounts}</span>
                    <div className="d-flex flex-row font-20">
                        <i className="fab gray fa-apple align-self-center" />
                        <span className="align-self-center  ml-2 line1">{ad_ios_new_accounts}</span>
                        <i className="fab green fa-android ml-auto align-self-center" />
                        <span className="align-self-center  ml-2 line1">{ad_android_new_accounts}</span>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default Snapshot;
