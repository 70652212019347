import React from 'react';
import Loader from '../../../common/Loader';
import _ from 'lodash';
import { getBase64, generateRandomId } from '../../../../helpers';
import request from 'superagent';
import config from '../../../../config';
import Field from './partials/field';

class FieldsManager extends React.Component {

    state = {
        bootstrapped: false,
        addingLayout: false,
        selectedLayout: null
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Fields Overlay',
            main: this.props.season.Name || null
        });
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
        this.props.seasonActions && this.props.seasonActions.fetchFieldLayouts(params.idSeason);
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Fields Overlay',
                main: nextProps.season.Name || null
            });
        }
        if (nextProps.fields_layout) {
            this.setState({
                fields_layout: nextProps.fields_layout,
                bootstrapped: true
            });
        }
    }
    componentWillUnmount() {

    }
    //    

    onAddLayout = () => this.setState({ addingLayout: true });
    handleAvatar = e => {
        const { fields_layout } = this.state, { params } = this.props;
        const temporalId = generateRandomId(10);
        getBase64(e.target.files[0]).then(data => {

            fields_layout.push({
                temporalId,
                ImageFile: data,
                ImageLabel: '-',
            });

            this.setState({
                fields_layout
            }, () => {
                // CALL API
                request.post(`${config.apiEndpoint}/api/v4/seasons/field_layout/${params.idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ ImageFile: data })
                    .then((data) => {
                        // TODO: Handle any kind of error here
                        if (data.body.success) {
                            const updateThing = _.find(fields_layout, f => f.temporalId === temporalId);
                            updateThing.Id = data.body.Id;
                            this.setState({ fields_layout });
                        }
                    });
            });
        });
    }
    removeImage = (index) => {
        let { fields_layout } = this.state, element = fields_layout[index];
        if (element) {
            fields_layout = [...fields_layout.slice(0, index),
            ...fields_layout.slice(index + 1)];
            this.setState({ fields_layout });
            // TODO: call api
            request.del(`${config.apiEndpoint}/api/v4/seasons/field_layout/${element.Id}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => { });
        }
    }

    render() {
        const { fields_layout } = this.state,
            { bootstrapped, } = this.state;

        return (
            <section>
                <div key="right-actions" className="right-actions d-flex flex-row">
                </div>
                {!bootstrapped && <Loader message={`Loading Fields, please wait`} />}
                {bootstrapped && (
                    <div className="clearfix fields-list white">
                        <figure className={`location-square float-left card hoverable`} style={{ height: 100 }}>
                            <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />
                            <label htmlFor="fi" className="d-flex flex-column justify-content-center h-100">
                                <i className='fas fa-plus font-60 align-self-center green' />
                                <span className="margin-top-half align-self-center black">Add Layout</span>
                            </label>
                        </figure>
                        {fields_layout.map((fl, index) =>
                            <Field key={fl.Id || fl.temporalId || index} field={fl} fnRemove={this.removeImage} index={index} />
                        )}
                    </div>
                )}
            </section>
        )
    }
}

export default FieldsManager;