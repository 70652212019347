import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getAutoPayReportSaga() {
  yield* takeEvery("REPORT/AUTO_PAY/FETCH", function* (action) {

    try {
      const result = yield call(function (idSeason) {

        var url = `${config.apiEndpoint}/api/v4/reports/auto_pay/${idSeason}`;
        return request.get(url)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action.idSeason);

      if (result && result.success) {

        yield put({
          type: 'REPORT/SET',
          report: result.records
        });

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getGamesReportSaga() {  
  yield* takeEvery("REPORT/GAMES/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason, mode }) {

        var url = `${config.apiEndpoint}/api/v4/reports/games/${idSeason}`;
        return request.get(url)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {

        yield put({
          type: 'REPORT/SET',
          report: result.games
        });

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getRegistrationReportSaga() {  
  yield* takeEvery("REPORT/REGISTRATION/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason, mode }) {

        var url = `${config.apiEndpoint}/api/v4/reports/registration/${idSeason}/${mode}`;
        return request.get(url)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {        
        yield put({
          type: 'REPORT/SET',
          report: result.players || result.teams
        });        
        yield put({
          type: 'QUESTIONS/SET',
          questions: result.questions
        });

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getRostersReportSaga() {
  //REPORT/REGISTRATION/FETCH
  yield* takeEvery("REPORT/ROSTERS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {
        
        return request.get(`${config.apiEndpoint}/api/v4/reports/rosters/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {

        yield put({
          type: 'REPORT/SET',
          report: result.report
        });        

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamsReportSaga() {  
  yield* takeEvery("REPORT/TEAMS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/teams/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {

        yield put({
          type: 'REPORT/SET',
          report: result.teams
        });
        yield put({
          type: 'QUESTIONS/SET',
          questions: result.questions
        });

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

//REPORT/TEAMS/ROSTERS/FETCH
export function* getTeamsRostersReportSaga() {  
  yield* takeEvery("REPORT/TEAMS/ROSTERS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/teams_rosters/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {        
        yield put({
          type: 'REPORT/SET',
          report: result.users
        });        

      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

//REPORT/TRANSACTIONS/FETCH
export function* getTransactionsReportSaga() {

  yield* takeEvery("REPORT/TRANSACTIONS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {
        return request.get(`${config.apiEndpoint}/api/v4/reports/transactions/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.transactions
        });
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getMasterTransactionsReportSaga() {

  yield* takeEvery("REPORT/MASTER_TRANSACTIONS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {
        return request.get(`${config.apiEndpoint}/api/v4/reports/master_transactions/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.report
        });
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

//REPORT/SHIRTS/FETCH
export function* getShirtsReportSaga() {

  yield* takeEvery("REPORT/SHIRTS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {
        return request.get(`${config.apiEndpoint}/api/v4/reports/shirts/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: { teams: result.teams, totals: result.totals }
        });
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

//REPORT/TEAM_ADMINS/FETCH
export function* getTeamAdminsReport() {
  
  yield* takeEvery("REPORT/TEAM_ADMINS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/admins/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.teams
        });        
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

//REPORT/REGISTRATION/SNAPSHOT/FETCH
export function* getRegistrationSnapshotReportSaga() {
  
  yield* takeEvery("REPORT/REGISTRATION/SNAPSHOT/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason, days }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/snapshot/${idSeason}/${days}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.users
        });        
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getDonationsReportSaga() {
  
  yield* takeEvery("REPORT/DONATIONS/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason, days }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/donations/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.donations
        });        
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

export function* fetchAccountingSaga() {
  
  yield* takeEvery("REPORT/ACCOUNTING/FETCH", function* (action) {

    try {
      const result = yield call(function ({ idSeason }) {        
        return request.get(`${config.apiEndpoint}/api/v4/reports/accounting/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action);

      if (result && result.success) {
        yield put({
          type: 'REPORT/SET',
          report: result.entries
        });        
      } else {
        yield put({
          type: 'REPORT/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'REPORT/FETCH_FAILED',
        e
      });
    }
  });
}

