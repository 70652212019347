import React from 'react';
import { Animated } from 'react-animated-css';
import request from 'superagent';
import config from '../../../config';
import { formatPriceWithoutCurrency } from '../../../helpers';
import moment from 'moment';
import _ from 'lodash';
import FamilySubscriptions from './family_subscriptions';
import PremierSubscriptions from './premier_subscriptions';
import StudioSubscriptions from './studio_subscriptions';

class InAppPurchase extends React.Component {

    state = {
        total: 0,
        family_subscriptions: [],
        mode: 1
    }

    componentWillMount() {
        const { yearFrom, yearTo, from, to, activeTab, monthTo } = this.props;
        this.setState({ yearFrom, yearTo, from, to, activeTab, monthTo }, () => {
            this.fetch()
        });
    }
    componentWillReceiveProps = () => {
        const { yearFrom, yearTo, from, to, activeTab, monthTo } = this.props;
        if (yearFrom !== this.state.yearFrom ||
            yearTo !== this.state.yearTo ||
            activeTab !== this.state.activeTab ||
            monthTo !== this.state.monthTo ||
            from !== this.state.from ||
            to !== this.state.to) {
            this.setState({ yearFrom, yearTo, from, to, activeTab, monthTo }, () => {
                this.fetch();
            });
        }
    }

    fetch = () => {
        const { yearFrom, yearTo, activeTab, from, to, monthTo } = this.state;
        request.post(`${config.apiEndpoint}/api/v5/system/earnings/yearly`)
            .send({
                start: (activeTab === '3' && from && to) ? `${from.format('YYYY-MM-DD')}` : ((activeTab === '2' && monthTo) ? `${moment(monthTo, 'MMM YYYY').format('YYYY-MM')}-01` : `${yearFrom}-01-01`),
                end: (activeTab === '3' && from && to) ? `${to.format('YYYY-MM-DD')}` : ((activeTab === '2' && monthTo) ? `${moment(`${monthTo} 01`, 'MMM YYYY DD').add(1, 'month').add(-1, 'day').format('YYYY-MM-DD')}` : `${yearTo}-12-31`),
                include_daily: activeTab === '3'
            })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { family_subscriptions, team_subscriptions, family_totals, team_totals } }) => {

                this.setState({
                    family_subscriptions,

                    total_family_subscriptions_active: _.chain(family_subscriptions)
                        .map(family => _.chain(family).filter(f => !f.EXPIRED).value().length)
                        .reduce((a, b) => a + b, 0).value(),

                    total_family_subscriptions_expired: _.chain(family_subscriptions)
                        .map(family => _.chain(family).filter(f => f.EXPIRED).value().length)
                        .reduce((a, b) => a + b, 0).value(),

                    team_subscriptions,

                    total_team_subscriptions_active: _.chain(team_subscriptions)
                        .map(family => _.chain(family).filter(f => !f.EXPIRED).value().length)
                        .reduce((a, b) => a + b, 0).value(),

                    total_team_subscriptions_expired: _.chain(team_subscriptions)
                        .map(family => _.chain(family).filter(f => f.EXPIRED).value().length)
                        .reduce((a, b) => a + b, 0).value(),

                    monthly_estimated: _.chain([..._.flatten(family_subscriptions), ..._.flatten(team_subscriptions)])
                        .map(s => s.Amount || s.Price)
                        .reduce((a, b) => a + b, 0).value(),

                    sqwad_family_totals: _.chain([..._.flatten(family_subscriptions)])
                        .filter(s => s.PlanName === 'SQWAD Studio Family')
                        .map(s => s.Amount || s.Price)
                        .reduce((a, b) => a + b, 0).value(),

                    sqwad_teams_totals: _.chain([..._.flatten(team_subscriptions)])
                        .filter(s => s.PlanName === 'SQWAD Studio Team')
                        .map(s => s.Amount || s.Price)
                        .reduce((a, b) => a + b, 0).value(),

                    premier_teams_totals: _.chain([..._.flatten(team_subscriptions)])
                        .filter(s => s.AppPlanName === 'PremierCoach' && !s.EXPIRED)
                        .map(s => s.Amount || s.Price)
                        .reduce((a, b) => a + b, 0).value(),

                    sqwad_studio_teams_total_active: _.chain([..._.flatten(team_subscriptions)])
                        .filter(s => s.PlanName === 'SQWAD Studio Team' && !s.EXPIRED)
                        .map(r => r.IdTeam)
                        .uniq().value().length,

                    premier_teams_total_active: _.chain([..._.flatten(team_subscriptions)])
                        .filter(s => s.AppPlanName === 'PremierCoach' && !s.EXPIRED)
                        .map(r => r.IdTeam)
                        .uniq().value().length
                });
            });
    }

    render() {

        const { family_subscriptions, team_subscriptions, total_family_subscriptions_active, monthly_estimated,
            sqwad_family_totals, sqwad_teams_totals, premier_teams_totals, sqwad_studio_teams_total_active,
            premier_teams_total_active, mode } = this.state;

        return <section style={{ position: 'relative' }}>
            <Animated isVisible={mode === 1} animateOnMount={false} animationIn="fadeInLeft" animationOut="fadeOutLeft" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <div className="w3-content d-flex flex-row p-4">
                    <button className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center zoom-less" onClick={() => this.setState({ mode: 2 })}>
                        <span className="font-16 text-center line1">Family Subscriptions (Active)</span>
                        <span className="font-40 align-self-center line1 mt-1 d-flex flex-row">{total_family_subscriptions_active}</span>
                    </button>
                    <div className="p-2" />
                    <button className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center zoom-less" onClick={() => this.setState({ mode: 3 })}>
                        <span className="font-16 text-center line1">Team Premier Subscriptions (Active)</span>
                        <span className="font-40 align-self-center line1 mt-1 d-flex flex-row">{premier_teams_total_active}</span>
                    </button>
                    <div className="p-2" />
                    <button className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center zoom-less" onClick={() => this.setState({ mode: 4 })}>
                        <span className="font-16 text-center line1">SQWAD Studio Team (Active)</span>
                        <span className="font-40 align-self-center line1 mt-1 d-flex flex-row">{sqwad_studio_teams_total_active}</span>
                    </button>
                </div>
                {/*<div className="w3-content d-flex flex-row px-4">
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span className="font-16 text-center">Family Subscriptions (Expired)</span>
                        <span className="font-40 align-self-center line1 mt-1 d-flex flex-row">{total_family_subscriptions_expired}</span>
                    </div>
                    <div className="p-2" />
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span className="font-16 text-center">Team Subscriptions (Expired)</span>
                        <span className="font-40 align-self-center line1 mt-1 d-flex flex-row">{total_team_subscriptions_expired}</span>
                    </div>
        </div>*/}
                <div className="w3-content d-flex flex-row px-4">
                    {/* APP */}
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span>Overall</span>
                        <span className="font-8 align-self-center line1 mt-3 d-flex flex-row ">{total_family_subscriptions_active + sqwad_studio_teams_total_active + premier_teams_total_active} Active Subscriptions</span>
                        <span className="font-30 align-self-center line1 mt-1 d-flex flex-row"><span className="font-12 line1 align-self-center">$</span> {formatPriceWithoutCurrency((monthly_estimated || 0) * 100)}</span>
                        <span className="font-12 align-self-center line1 d-flex flex-row ">Monthly | Projected</span>
                    </div>
                    <div className="p-2" />
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span>SQWAD Studio Family</span>
                        <span className="font-8 align-self-center line1 mt-3 d-flex flex-row ">{total_family_subscriptions_active} Active Subscriptions</span>
                        <span className="font-30 align-self-center line1 mt-1 d-flex flex-row"><span className="font-12 line1 align-self-center">$</span> {formatPriceWithoutCurrency((sqwad_family_totals || 0) * 100)}</span>
                        <span className="font-12 align-self-center line1 d-flex flex-row ">Monthly | Projected</span>
                    </div>
                    <div className="p-2" />
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span>SQWAD Studio Team</span>
                        <span className="font-8 align-self-center line1 mt-3 d-flex flex-row ">{sqwad_studio_teams_total_active} Active Subscriptions</span>
                        <span className="font-30 align-self-center line1 mt-1 d-flex flex-row"><span className="font-12 line1 align-self-center">$</span> {formatPriceWithoutCurrency((sqwad_teams_totals || 0) * 100)}</span>
                        <span className="font-12 align-self-center line1 d-flex flex-row ">Monthly | Projected</span>
                    </div>
                    <div className="p-2" />
                    <div className="card sheet w-100 p-4 shadow d-flex flex-column justify-content-center">
                        <span>Premier Coach</span>
                        <span className="font-8 align-self-center line1 mt-3 d-flex flex-row ">{premier_teams_total_active} Active Subscriptions</span>
                        <span className="font-30 align-self-center line1 mt-1 d-flex flex-row"><span className="font-12 line1 align-self-center">$</span> {formatPriceWithoutCurrency((premier_teams_totals || 0) * 100)}</span>
                        <span className="font-12 align-self-center line1 d-flex flex-row ">Monthly | Projected</span>
                    </div>
                </div>
            </Animated>

            <Animated isVisible={mode > 1} animateOnMount={false} animationIn="fadeInRight" animationOut="fadeOutRight" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <button className="w3-content d-flex flex-row p-4 w-100" onClick={() => this.setState({ mode: 1 })}>
                    <i className="fa fa-arrow-left align-self-center mr-2" /><span className="align-self-center">Back</span>
                </button>
                <div className="w3-content">
                    {mode === 2 && <FamilySubscriptions family_subscriptions={family_subscriptions} />}
                    {mode === 3 && <PremierSubscriptions premier_subscriptions={team_subscriptions} />}
                    {mode === 4 && <StudioSubscriptions studio_subscriptions={team_subscriptions} />}
                </div>
            </Animated>
        </section>
    }
}

export default InAppPurchase;
