import React from 'react';
import Loader from '../../common/Loader';
import request from 'superagent';
import config from '../../../config';
import { formatPrice } from '../../../helpers';
import Charge from './payment_types/Charge';
import Due from './payment_types/Due';
import _ from 'lodash';
import moment from 'moment';
import Dues from '../../family/payments/Dues';
import PaymentPlanItem from '../../family/common/payment_plans/Item';
import { Jumbotron } from 'reactstrap';

class AccountingTab extends React.Component {

  state = {
    selectedSeason: this.props.selectedSeason,
    payments: this.props.payments,
    balance: this.props.balance,
    pastDue: this.props.pastDue,
    futurePayments: this.props.futurePayments,
    isDuesOpen: false,
    showingPlans: false,
    IdDivision: null,
    fullBalance: null,
  }

  // Lifecycle
  componentWillMount() {
    this.getPaymentPlans();
    const { user, team, selectedSeason, fullBalance, payments } = this.props;    
    this.setState({
      user,
      team,
      selectedSeason,
      fullBalance,
      payments,
    });
  }
  componentWillReceiveProps(nextProps) {
    const { user, team, selectedSeason, fullBalance } = nextProps;    
    this.setState({
      user,
      team,
      selectedSeason,
      payments: nextProps.payments,
      balance: nextProps.balance,
      pastDue: nextProps.pastDue,
      futurePayments: nextProps.futurePayments,
      fullBalance
    }, () => {
      this.getPaymentPlans();
    })
  }
  //

  getPaymentPlans = () => {
    const { IdDivision } = this.state, { selectedSeason } = this.props;

    if (IdDivision !== selectedSeason.IdDivision) {

      this.setState({ IdDivision: selectedSeason.IdDivision }, () => {

        const { IdDivision } = this.state;

        request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${IdDivision}/3`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(({ body: { plans } }) => {

            const { fullBalance } = this.props;

            // The balance will tell us if the user selected a 
            // payment plan or full-payment at the moment of 
            // registration. This rule is effective from May 15th 2019
            if (fullBalance && fullBalance.IsPaymentPlan) {
              (_.last(plans) || {}).selected = true;
            } else {
              (_.first(plans) || {}).selected = true;
            }
            //

            this.setState({
              plans,
              fullBalance,
              somethingChanged: false,
              showingPlans: false,
              selectedPlan: (fullBalance && fullBalance.IsPaymentPlan) ? _.last(plans) : _.first(plans),
            });
          });
      });
    }
  }

  toggleDues = () => this.setState({ isDuesOpen: !this.state.isDuesOpen });
  toggleShowingPlans = () => this.setState({ showingPlans: !this.state.showingPlans });

  fnSelectPlan = (plan) => {
    const { plans } = this.state;
    plans.forEach(p => { p.selected = p.Name === plan.Name; });
    this.setState({ selectedPlan: _.find(plans, p => p.selected), plans, somethingChanged: true });
  }

  assignStatus = (status) => {
    var { team, user, selectedSeason } = this.state;

    if (team) {
      team.IdRoleStatus = status.IdRoleStatus;
      team.RoleStatus = status.RoleStatusName;
      team.RegistrationStatus = status.RoleStatusName;
    }
    if (user) {
      selectedSeason.IdRoleStatus = status.IdRoleStatus;
      selectedSeason.RegistrationStatus = status.RoleStatusName;
    }

    this.setState({
      team,
      user,
      selectedSeason,
      popoverOpen: false
    });

    // Call API
    // TODO: Might need to refactor this for using on other places ?
    if (team) {
      request.patch([config.apiEndpoint, 'api/v4/teams/status',
      team.IdTeamDivision, status.IdRoleStatus].join('/'))
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then((data) => {
          // Nothing to do here ?
        });
    } else {
      request.patch([config.apiEndpoint, 'api/v4/registration/status',
      user.IdUser, selectedSeason.IdDivision, status.IdRoleStatus].join('/'))
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then((data) => {
          // Nothing to do here ?
        });
    }
  }
  //

  savePlanChange = () => {
    const { selectedPlan, fullBalance } = this.state;
    const { IdOrderItem } = fullBalance;

    if (!IdOrderItem) {
      alert('There is no Order Item in this registration');
      return;
    }

    this.setState({ somethingChanged: false, showingPlans: false });
    this.props.fnBlockUI && this.props.fnBlockUI(true);
    request.post(`${config.apiEndpoint}/api/v4/registration/changePlan/${IdOrderItem}`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .send({ newPlan: selectedPlan })
      .then(() => {
        this.props.fnNotify && this.props.fnNotify();
      });
  }

  render() {
    const { fetchingPayments, fnAddCharge, fnAddCredit, fnMakePayment, fnChangePlan, dues } = this.props,
      { selectedSeason, payments, balance, fullBalance, futurePayments, showingPlans, plans = [], somethingChanged = false } = this.state;      

    // Re-calculate
    let totalDue = fullBalance ? fullBalance.TotalDue : 0;
    let currentDue = fullBalance ? (fullBalance.IsPaymentPastDue ? fullBalance.TodayBalance : fullBalance.NextPayment) : 0;
    if (currentDue > balance) currentDue = balance;    

    return <section style={{ position: 'relative', minHeight: 400 }}>

      <div className="d-flex flex-row">
        <div className="w-50">

          <h4 className="mt-3 ml-3">Accounting</h4>

          {balance > 0 && (
            <button onClick={this.toggleDues} className={`${dues && dues.length ? 'pointer' : ''} montserrat m-3 ${(balance && balance.IsPaymentPastDue) ? 'red' : 'black'}`}>
              <span className={`block line1 ${dues && dues.length ? 'underlined' : ''} text-left font-10 toBold`}>{formatPrice(currentDue * 100)}{' '}({(balance && balance.IsPaymentPastDue) ? moment(balance.LastDateDue).format('MMM Do YYYY') : moment(balance.NextPaymentDate).format('MMM Do YYYY')})</span>
              <span className="font-8 text-left block">{(balance && balance.IsPaymentPastDue) ? "Past Due" : "Next Due"}</span>
            </button>
          )}

        </div>

        <div className="w-50 d-flex flex-column justify-content-center pt-3 pb-3">
          {fullBalance && <div className="montserrat p-3 w-100 align-self-center white d-flex flex-column justify-content-center" style={{ backgroundColor: '#4469C7', borderRadius: 5 }}>
            <span className={`font-24 line1 align-self-center block`}>{formatPrice((fullBalance.TotalBalance || fullBalance.Balance || 0) * 100)}</span>
            <span className={`font-10 line1 align-self-center block`}>Balance</span>
          </div>}
        </div>

        {fullBalance && <div className="w-50 d-flex flex-row">
          <div className="w-50 d-flex flex-column pt-3 pb-3 pl-3 ml-auto">
            <span className="black montserrat font-10">Total Paid</span>
            <span className="black montserrat font-10">Total Due</span>
            <span className="black montserrat-bold font-12">Total Charge</span>
            {fullBalance.Refund > 0 && <span className="red montserrat font-10">Refunded</span>}
          </div>
        </div>}

        {fullBalance && <div className="w-50 d-flex flex-column pt-3 pb-3 pr-3">
          <span className="black montserrat font-10 w-100 text-right">{formatPrice(fullBalance.TotalPaid * 100)}</span>
          <span className="black montserrat font-10 w-100 text-right">{formatPrice(fullBalance.TotalDue * 100)}</span>
          <span className="black montserrat-bold font-12 w-100 text-right">{formatPrice((fullBalance.TotalAmount) * 100)}</span>
          {fullBalance.Refund > 0 && <span className="red montserrat font-10 text-right">{formatPrice(fullBalance.Refund * 100)}</span>}
        </div>}
      </div>

      <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Charges and Payments</h3>

      <div className="d-flex">
        <div className="w-100">
          <ul className="montserrat p-4">
            {payments && _.chain(payments).sortBy('DateDue').value().map((payment, i) => (
              <li key={i} className="d-flex flex-row p-2" style={{ borderBottom: '1px solid #ccc' }}>
                <Charge payment={payment} fnMakeRefund={this.props.fnMakeRefund}
                  fnDeletePayment={this.props.fnDeletePayment} index={i} />
              </li>))}
            {/* For some reason we decided that this should not be shown anymore, 
              tho I left the code here for the future (who knows...) */}
            {/*false && futurePayments.map((payment, i) => (
              <li key={i} className="d-flex flex-row p-2" style={{ borderBottom: '1px solid #ccc' }}>
                <Due payment={payment} i />
            </li>))*/}
          </ul>
        </div>
        <div className="w-50">
          <div className="mt-4 mb-4 mr-4 ml-2 d-flex flex-column pt-3 pb-3" style={{ borderRadius: 5, backgroundColor: '#f9f9f9' }}>
            <span className="toBold block text-center">What do you want to do?</span>
            <button onClick={fnAddCharge} style={{ width: 160, border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center mt-2"><i className="fas fa-plus mr-2" />Add charge</button>
            <button onClick={fnAddCredit} style={{ width: 160, border: '2px solid #dc3545', borderRadius: 20 }} className="red p-2 align-self-center mt-2"><i className="fas fa-minus mr-2" />Add credit</button>
            <button onClick={fnMakePayment} style={{ width: 160, border: '2px solid #008db5', borderRadius: 20 }} className="blue p-2 align-self-center mt-2"><i className="far fa-credit-card mr-2" />Make Payment</button>
            <button onClick={fnChangePlan} style={{ width: 160, border: '2px solid black', borderRadius: 20 }} className="black p-2 align-self-center mt-2">Change Plan</button>
          </div>
        </div>
      </div>

      {selectedSeason && false && (
        <div className="">
          {/* Show balance only if the status is different than Tryout or Waitlist */}
          {(selectedSeason.IdRoleStatus !== 6 && selectedSeason.IdRoleStatus !== 4) && <section>

            <div className="d-flex flex-row bg">
              {totalDue > 0 && (
                <button onClick={this.toggleDues} className={`pointer align-self-center idented-half font-10 underlined ${(balance && balance.IsPaymentPastDue) ? 'yellow' : 'green'}`}>
                  {(balance && balance.IsPaymentPastDue) ? "Past Due: " : "Next Due: "}
                  {formatPrice(currentDue * 100)}
                  {' '} (
                  {(balance && balance.IsPaymentPastDue) ? moment(balance.LastDateDue).format('MMM Do YYYY') : moment(balance.NextPaymentDate || balance.LastDateDue).format('MMM Do YYYY')})
                </button>)}
              <button className="btn btn-link ml-auto d-flex flex-row">
                {payments && <span className={`ml-auto idented-half big-font`}>Balance: {formatPrice(balance * 100)}</span>}
              </button>
            </div>

            <div className="d-flex flex-row bg-info p-2" >
              <span className="white align-self-center block text-center">Charges and Payments</span>
              {!somethingChanged && selectedSeason.IdRoleStatus !== 6 && <button onClick={this.toggleShowingPlans} className="btn btn-link underlined white btn-sm ml-auto">Change Plan</button>}
              {somethingChanged && selectedSeason.IdRoleStatus !== 6 && <button onClick={this.savePlanChange} className="btn btn-success btn-sm ml-auto">Save Changes</button>}
            </div>
            <div className="ease d-flex flex-row justify-content-center" style={{ height: showingPlans ? 250 : 0, backgroundColor: '#002549' }} >
              {showingPlans && plans.map((plan, i) => <li className="payment-plan-tile margin-top" key={i}>
                <button onClick={() => this.fnSelectPlan(plan)} className="white justify-content-center"
                  style={{ width: '100%', height: '100%' }}>
                  <PaymentPlanItem paymentPlan={plan} />
                </button>
              </li>)}
            </div>
            <ul>
              {payments && _.sortBy(payments, 'DateDue').map((payment, i) => (
                <li key={i} className="d-flex flex-row p-2" style={{ borderBottom: '1px solid #ccc' }}>
                  <Charge payment={payment} fnMakeRefund={this.props.fnMakeRefund}
                    fnDeletePayment={this.props.fnDeletePayment} index={i} />
                </li>))}
              {/* For some reason we decided that this should not be shown anymore, 
              tho I left the code here for the future (who knows...) */}
              {false && futurePayments.map((payment, i) => (
                <li key={i} className="d-flex flex-row p-2" style={{ borderBottom: '1px solid #ccc' }}>
                  <Due payment={payment} i />
                </li>))}
            </ul>
          </section>}

          {(selectedSeason.IdRoleStatus === 6 || selectedSeason.IdRoleStatus === 4) && <section className="p-2">
            <Jumbotron>
              <h1>{selectedSeason.RoleStatus} status</h1>
              <span>No current balance</span></Jumbotron>
          </section>}
        </div>)}
      {fetchingPayments &&
        <Loader message="" />}
      {this.state.isDuesOpen && dues && <Dues isOpen={this.state.isDuesOpen} toggle={this.toggleDues} dues={dues} />}
    </section>
  }
}

export default AccountingTab;
