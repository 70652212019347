import React from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import Hls from "hls.js";
//import { LightenDarkenColor } from '../../../../helpers';

class Video extends React.Component {

    state = {
        bootstrapped: false,
    }

    // Lifecycle
    componentWillMount() {
        const { player, roster_actions } = this.props;
        player && roster_actions && roster_actions.fetchVideosByUser(player.IdUser);
    }
    componentWillReceiveProps = nextProps => {
        const { videos } = nextProps;
        this.setState({ videos, boostrapped: videos ? true : false }, () => {
            if (videos.length) { this.onLoad(0); }
        });
    }
    //

    onLoad = (i) => {
        const { videos } = this.props, { index } = this.state;
        if (index === i) { return; }
        const video = this.player;
        const hls = new Hls();
        const url = `http://dispatcher.sportslogic.net:3001/${videos[i].description}/playlist.m3u8`; // `${config.wowzaEndpoint}/${videos[i].description}/playlist.m3u8`;

        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () { });
        this.setState({ index: i });
    }

    render() {
        const { isOpen, toggle, player } = this.props,
            { boostrapped, videos, index } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody className="container-fluid no-margin no-padding bg-black" style={{ height: 450 }}>
                    <Animated animationIn="fadeIn" animationOut="fadeOut" className={`bg-black d-flex flex-row justify-content-center w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }} isVisible={!boostrapped}>
                        <i className="heart white align-self-center icon-logo-header" style={{ fontSize: '50pt' }} />
                    </Animated>
                    {videos && videos.length > 0 && <video
                        poster={_.first(videos).thumbnail}
                        ref={player => (this.player = player)}
                        controls={true}
                        autoPlay={true}
                        width="100%"
                        height="100%"
                        style={{ backgroundColor: 'black' }}
                    />}
                    {videos && !videos.length && <Animated animationIn="fadeIn" animationOut="fadeOut" className={`bg-black d-flex flex-row justify-content-center w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }} isVisible={boostrapped}>
                        <span className="white align-self-center font-20">Seems like this player has not uploaded any highlights</span>
                    </Animated>}

                    <div style={{ position: 'absolute', top: 10, left: 0, height: 50, borderTopRightRadius: 5, borderBottomRightRadius: 5 }} className="bg-white d-flex flex-row w-auto">
                        <div className="ml-2 align-self-center" style={{ borderRadius: 20, width: 40, height: 40, background: `url(https://api.sportslogic.net/api/v4/users/image/decode/${player.IdUser}.png) no-repeat center center`, backgroundSize: 'cover' }} />
                        <span className="ml-2 mr-2 align-self-center black">{(player.JerseyNumber||player.PreferredJerseyNumber) !== null ? `#${player.JerseyNumber||player.PreferredJerseyNumber} - ` : ``}{`${player.NameFirst} ${player.NameLast}`}</span>
                    </div>

                </ModalBody>
                {videos && videos.length > 1 && <ModalBody className="container-fluid no-margin no-padding bg-black d-flex flex-row" style={{ border: '1px solid gray' }}>
                    <div className="d-flex flex-row" style={{ height: 150, overflowX: 'auto' }}>
                        {videos.map((video, i) => <div onClick={() => this.onLoad(i)} key={i} style={{ minWidth: 300, background: `url(${video.thumbnail}) no-repeat center center`, backgroundSize: 'contain', borderRight: index === i ? null : `1px solid gray`, border: index === i ? '1px solid orange' : null }} className="h-100 pointer d-flex flex-row justify-content-center">
                            <i className={`fas fa-${index === i ? '' : 'play'} white font-12 align-self-center`} />
                        </div>)}
                    </div>
                </ModalBody>}                
            </Modal>
        )
    }
}

export default Video;
