import React from 'react';
import { Modal, ModalHeader, ModalBody, Jumbotron, ModalFooter } from 'reactstrap';
import config from '../../../config';
import _ from 'lodash';
import request from 'superagent';
import LineLoader from '../LineLoader';
import PaymentPlanItem from '../../family/common/payment_plans/new_item';

class ChangePlanModal extends React.Component {
    state = {
        bootstrapped: false
    }

    componentWillMount() {
        const { season } = this.props;
        this.setState({
            season, microTransac: true
        }, () => {
            request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${season.IdDivision}/3`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(({ body: { plans } }) => {

                    const { fullBalance } = this.props;                    

                    // The balance will tell us if the user selected a 
                    // payment plan or full-payment at the moment of 
                    // registration. This rule is effective from May 15th 2019
                    if (fullBalance && fullBalance.IsPaymentPlan) {
                        (_.last(plans) || {}).current = (_.last(plans) || {}).selected = true;
                    } else {
                        (_.first(plans) || {}).current = (_.first(plans) || {}).selected = true;
                    }
                    //*/                    

                    this.setState({ plans, microTransac: false, bootstrapped: true });
                });
        })
    }

    fnSelectPlan = plan => {
        const { plans } = this.state;
        plans.forEach(p => { p.selected = (p.IdPlan === plan.IdPlan && p.Name === plan.Name) });
        this.setState({ plans });
    }

    onChangePlan = () => {
        const { plans } = this.state;
        this.props.fnChangePlan && this.props.fnChangePlan(_.find(plans, p => p.selected));
    }

    render() {
        const { isOpen, toggle } = this.props,
            { microTransac, plans, bootstrapped } = this.state;

        let other_plans = _.chain(plans).filter(p => !p.current).value()

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 650 }}>
                <ModalHeader toggle={toggle} className="bg-blue-dark white">
                    Change Plan
                </ModalHeader>
                <ModalBody className="container-fluid no-padding no-margin">
                    {microTransac && <LineLoader />}
                    {plans && <div className="p-4">
                        <h5 className="black montserrat">Actual Plan</h5>
                        <button onClick={() => this.fnSelectPlan(_.find(plans, p => p.current))} className="white justify-content-center"
                            style={{ width: '100%', height: '100%' }}>
                            <PaymentPlanItem paymentPlan={_.find(plans, p => p.current)} className="mt-2" />
                        </button>
                        <h5 className="black montserrat">New Plan</h5>
                        {other_plans.map((plan, index) =>
                            <button key={index} onClick={() => this.fnSelectPlan(plan)} className="white justify-content-center"
                                style={{ width: '100%', height: '100%' }}>
                                <PaymentPlanItem paymentPlan={plan} className="mt-2" />
                            </button>
                        )}
                        {bootstrapped && other_plans.length === 0 && <Jumbotron className="bg-white d-flex flex-column justify-content-center">
                            <i className="far fa-meh-blank font-30 black align-self-center" />
                            <span className="black font-20 align-self-center">There are no other plans available</span>
                        </Jumbotron>}
                    </div>}
                </ModalBody>
                {this.state.error && <ModalBody className="p-2 bg-danger white">
                    {this.state.error}
                </ModalBody>}
                <ModalFooter>
                    <button onClick={this.onChangePlan} style={{ width: 160, border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center mt-2">Save Changes</button>
                </ModalFooter>
            </Modal>)
    }
}

export default ChangePlanModal;
