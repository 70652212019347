import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import ZipCode from './zip_code';
import request from 'superagent';
import config from '../../../../../../config';


class ZipCodeAdjustmentModal extends React.Component {

  state = {
    saving: false,
    plus: this.props.generic.plus,
    selected: this.props.generic.selected
  }

  // Lifecycle
  
  //

  handleChangeBase = (e) => {
    const { generic } = this.props;
    generic.base = ((e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0);
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  save = () => {
    const { generic, zipCodes, season } = this.props,
      { saving, plus } = this.state;
    if (saving) return;

    this.setState({ saving: true }, () => {

      request[generic.id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/paymentPlans/zipCode${generic.id ? '/' + generic.id : ''}`)
        .send({
          base: generic.base * (plus ? 1 : -1),
          name: this.txtName.value,
          codes: zipCodes,
          idLeague: season.IdLeague,
          idSeason: season.IdSeason
        })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then((data) => {
          if (data.body.success) {
            this.props.paymentsActions &&
              this.props.paymentsActions.fetchPrograms(season.IdSeason);
            this.props.toggle && this.props.toggle();
          }
        }, reject => {
          this.setState({ saving: false });
        });
    });

  }

  addCode = () => {
    this.props.paymentsActions && this.props.paymentsActions.addZipCode({});
  }

  togglePlus = (flag) => {
    this.setState({
      plus: flag,
      selected: true
    });
  }

  render() {
    const { isOpen, toggle, generic, zipCodes } = this.props, { saving } = this.state;

    return <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
      <ModalHeader className="bg-info white">{generic.id ? 'Edit' : 'New'}{' '}Zip Code Adjustment</ModalHeader>
      <ModalBody className="container-fluid p-4">
        {generic && (
          <div className="row">
            { /* Name and Dates */}
            <div className="col-12 d-flex flex-row margin-bottom-half">
              <input className="form-control align-self-center" type="text" placeholder="Program Name" defaultValue={generic.name} ref={(i) => this.txtName = i} />
            </div>
            { /* Name and Dates */}
            {zipCodes && zipCodes.length > 0 && zipCodes.map((zip, i) => (
              <ZipCode key={i} code={zip} index={i} {...this.props} />))}
            <div className="col-12">
              <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3 w-100 block" onClick={this.addCode}>+ Add Zip Code</button>
            </div>
            <div className="col-12 d-flex flex-row justify-content-center mt-4">
              <span className="idented-half align-self-center margin-right-half ">Adjustment Type</span>
              <button onClick={() => this.togglePlus(false)} className={`margin-right-half btn btn-sm btn-${this.state.plus === false ? 'danger' : 'link bordered'}`}>Discount
                {' '}<i className={`fas fa-minus ${this.state.plus === false ? 'white' : 'red'}`} /></button>
              <button onClick={() => this.togglePlus(true)} className={`btn btn-sm btn-${this.state.plus === true ? 'success' : 'link bordered'}`}>Fee
                {' '}<i className={`fas fa-plus ${this.state.plus === true ? 'white' : 'green'}`} /></button>
            </div>
            {this.state.selected && (
              <div className="col-12 d-flex flex-row margin-top-half justify-content-center">
                <span className=" align-self-center idented-half">Amount</span>
                <div className="input-group idented-half" style={{ width: 180 }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <div className="input-group-prepend">
                    <span className={`input-group-text bg-${this.state.plus ? 'success' : 'danger'}`}><i className={`fas fa-${this.state.plus ? 'plus' : 'minus'}`} /></span>
                  </div>
                  <input onChange={this.handleChangeBase} defaultValue={generic.base} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                </div>
              </div>)}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {!saving && <button className="btn btn-link" onClick={toggle}>Cancel</button>}
        <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3" onClick={this.save}>{saving ? 'Saving, please wait...' : 'Save adjustment'}</button>
      </ModalFooter>
    </Modal>
  }
}

ZipCodeAdjustmentModal.defaultProps = {
  generic: {
    start: moment(),
    end: moment().add(1, 'month'),
    base: 0,
    fee: 0,
    initial: 0,
    late: 0,
    usePP: false
  },
}

export default ZipCodeAdjustmentModal;