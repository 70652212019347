import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Jumbotron } from 'reactstrap';
import TeamDraggable from '../../seasons/manage_schedule/brackets/TeamDraggable';
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';
import Header from '../things/BlackishHeader';
import CustomGroup from './partials/CustomGroup';
import { generateRandomId } from '../../../helpers';

class CustomGroups extends React.Component {

  state = {
    placements: null,
    groups: []
  }

  componentWillMount() {
    const { placements, teams, customGroups } = this.props;
    customGroups.forEach(group => {
      group.teams = _.filter(teams, t => t.IdCustomGroup === group.IdCustomGroup);
    })
    this.setState({ placements, teams, groups: customGroups });
  }

  toggleEditGroup = (index) => { }

  // done
  dropTeam = (indexGroup, team) => {
    const { groups, teams } = this.state, { customGroupsActions } = this.props;
    groups.forEach(group => {
      group.teams = _.reject(group.teams, t => t.IdTeamDivision === team.IdTeamDivision);
    });
    groups[indexGroup].teams.push(team);
    _.find(teams, t => t.IdTeamDivision === team.IdTeamDivision).IdCustomGroup = groups[indexGroup].IdCustomGroup;

    // Call API
    this.setState({ groups, teams }, () => {
      request.post(`${config.apiEndpoint}/api/v4/brackets/custom_group/team/${team.IdTeamDivision}/${groups[indexGroup].IdCustomGroup}`)
        .send({})
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(() => { });
    });
    customGroupsActions.set(groups);

  }

  // done
  addGroup = () => {
    const { groups } = this.state, { customGroupsActions, division } = this.props;

    let auxId = generateRandomId(4);
    const payload = { Name: `Group #${groups.length + 1}`, teams: [], IdCustomGroup: auxId, Sequence: groups.length, IdDivision: division.IdDivision };
    groups.push(payload);

    this.setState({ groups }, () => {
      request.post(`${config.apiEndpoint}/api/v4/brackets/custom_group/${division.IdDivision}`)
        .send(payload)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(data => {
          if (data.body.success) {
            _.find(groups, g => g.IdCustomGroup === auxId).IdCustomGroup = data.body.IdCustomGroup;
            this.setState({ groups });
            this.props.update && this.props.update();
          }
        });
    });

    customGroupsActions.set(groups);
  }

  notify = () => {
    const { customGroupsActions, division } = this.props;
    request.get(`${config.apiEndpoint}/api/v4/divisions/brackets/${division.IdDivision}`) //${idDivision}`)      
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .set('show', true) // This forces to show the brackets 
      .then(({ body: { customGroups } }) => {
        customGroupsActions.set(customGroups);
        this.props.update && this.props.update();
      });

  }

  removeGroup = index => {
    const { teams, groups } = this.state, { customGroupsActions } = this.props,
      group = groups[index];
    group && group.teams && group.teams.forEach(team => {
      _.filter(teams || [], t => t.IdTeamDivision === team.IdTeamDivision).forEach(team => {
        // Remove all teams 1st
        if (team && team.IdTeamDivision && team.IdCustomGroup) {
          request.del(`${config.apiEndpoint}/api/v4/brackets/custom_group/team/${team.IdTeamDivision}/${team.IdCustomGroup}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(() => { });
          team.IdCustomGroup = null;
        }
      });
    });

    // Remove the group
    request.del(`${config.apiEndpoint}/api/v4/brackets/custom_group/${group.IdCustomGroup}`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(() => { });

    this.setState({ teams, groups: [...groups.slice(0, index), ...groups.slice(index + 1)] }, () => {
      customGroupsActions.set(this.state.groups)
    });
  }

  removeTeam = (indexGroup, indexTeam) => {
    const { groups, teams } = this.state;
    const group = groups[indexGroup];
    const team = _.find(teams, t => t.IdTeamDivision === group.teams[indexTeam].IdTeamDivision);

    request.del(`${config.apiEndpoint}/api/v4/brackets/custom_group/team/${team.IdTeamDivision}/${team.IdCustomGroup}`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(() => {
        team.IdCustomGroup = null;
        group.teams = [...group.teams.slice(0, indexTeam), ...group.teams.slice(indexTeam + 1)];
        this.setState({ groups, teams }, () => { });
      });
  }

  render() {
    const { isOpen, toggle, division } = this.props, { teams, groups } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ width: 800 }}>
        <ModalHeader toggle={toggle}>
          Custom Groups for {division.Name || division.DivisionName}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row w-100" >
            <div className="w-50" style={{ borderRight: '1px solid #e5e5e5' }}>
              <Header message="Teams" />
              <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half p-2">
                {teams.length > 0 && teams.map((team, i) => (
                  <TeamDraggable key={i} team={team} selected={team.IdCustomGroup} hideDivision={false} />))}
                {!teams.length && <div className="jumbotron no-margin"><i className="fa fa-exclamation"></i> There are no Flighted Teams.</div>}
              </ul>
            </div>
            <div className="d-flex flex-column w-100">
              <Header message="Groups" />
              <div className="h-100" style={{ overflowX: 'auto', position: 'relative' }}>
                {groups.length === 0 && <Jumbotron className="w-100 h-100">
                  <h1 className="display-3">No groups..!</h1>
                  <p className="lead">You can start adding groups for this division using the Add Group option below.</p>
                </Jumbotron>}
                {groups.map((group, index) => <CustomGroup fnNotify={this.notify} key={group.IdCustomGroup} group={group}
                  index={index} division={division} dropTeam={this.dropTeam} removeTeam={this.removeTeam}
                  removeGroup={() => this.removeGroup(index)} left={200 * index} />)}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-sm btn-success" onClick={this.addGroup}><i className="fa fa-plus margin-right-half" />Add Group</button>
        </ModalFooter>
      </Modal>)
  }
}

export default CustomGroups;
