import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';

export function* getDivisionsSaga() {
    yield* takeEvery("DIVISIONS/FETCH", function* (action) {

        try {
            const result = yield call(({ idSeason, idUser, idRole, idTeam, module }) => {
                let url = '';
                switch (module) {
                    case 'Schedule_Manager':
                        url = _.compact([config.apiEndpoint, 'api/v4/divisions/schedule_manager', idSeason]).join('/');
                        break;
                    case 'Dashboard':
                        url = _.compact([config.apiEndpoint, 'api/v4/divisions/dashboard', idSeason]).join('/');
                        break;
                    case 'Flight_Manager':
                        url = _.compact([config.apiEndpoint, 'api/v4/divisions', idSeason]).join('/');
                        break;
                    case 'Registration':
                        url = idUser ? _.compact([config.apiEndpoint, 'api/v4/divisions/availableForRegistration', idSeason, idUser, idRole]).join('/') :
                            _.compact([config.apiEndpoint, 'api/v4/divisions/availableForTeamRegistration', idSeason, idTeam]).join('/');
                        break;
                    case 'Print':
                        url = _.compact([config.apiEndpoint, 'api/v4/divisions/printable', idSeason]).join('/');
                        break;
                    default:
                        url = _.compact([config.apiEndpoint, 'api/v4/divisions', idSeason]).join('/');
                        break;
                }

                return request.get(url)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));

            }, action);

            if (result && result.success) {
                switch (action.module) {
                    case 'Schedule_Manager':
                        yield put({
                            type: 'DIVISIONS/SET',
                            divisions: result.divisions
                        });

                        var flightedTeams = [];
                        result.divisions.forEach((d) => {
                            d.Flights.forEach((f) => {
                                flightedTeams = _.union(flightedTeams, f.FlightedTeams);
                            });
                        });
                        yield put({
                            type: 'FLIGHTED_TEAMS/SET',
                            teams: flightedTeams
                        });
                        
                        yield put({ type: 'TEAMS/SET', teams: result.teams });

                        yield put({
                            type: 'COACHES/SET',
                            coaches: result.coaches
                        });

                        break;
                    case 'Dashboard':
                        yield put({
                            type: 'GENDERS/SET',
                            genders: result.divisions
                        });
                        break;
                    default:
                        yield put({
                            type: 'DIVISIONS/SET',
                            divisions: result.divisions
                        });
                        break;

                }

            } else {
                yield put({
                    type: 'DIVISIONS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'DIVISIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getPaymentPlans() {
    yield* takeEvery("PAYMENT_PLANS/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision, mode }) => {
                return request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${idDivision}/${mode}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'PAYMENT_PLANS/SET',
                    plans: result.plans
                });

            } else {
                yield put({
                    type: 'PAYMENT_PLANS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PAYMENT_PLANS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getQuestionsSaga() {
    yield* takeEvery("QUESTIONS/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision, mode }) => {
                return request.get(`${config.apiEndpoint}/api/v4/registration/questions/${idDivision}/${mode}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'QUESTIONS/SET',
                    questions: result.questions
                });

            } else {
                yield put({
                    type: 'QUESTIONS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'QUESTIONS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getTeamsDivisionSaga() {
    yield* takeEvery("TEAMS/DIVISION/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v4/teams/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });

            }, action);

            if (result && result.success) {
                yield put({
                    type: 'TEAMS/SET',
                    teams: result.teams
                });

            } else {
                yield put({
                    type: 'TEAMS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TEAMS/FETCH_FAILED',
                e
            });
        }
    });
}

// This action does not require a feedback  for now
export function* updatePlayersDivisionSaga() {
    yield* takeEvery("PLAYERS/UPDATE", function* (action) {

        try {

            yield call(({ IdTeamUserRole, IdTeamDivision, IdRole }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/teams/move/${IdRole === 4 ? 'coach' : 'player'}`)
                    .send({
                        idTeamUserRole: IdTeamUserRole,
                        idTeamDivision: IdTeamDivision
                    })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action.player);
        } catch (e) { }
    });
}

export function* updateDivisionAgeGroupName() {
    yield* takeEvery("DIVISIONS/AGE_GROUP_NAME/SET", function* (action) {

        try {

            yield call(({ idDivision, DivisionAgeGroupName }) => {
                return request.post(`${config.apiEndpoint}/api/v4/divisions/age_group_name/${idDivision}`)
                    .send({ DivisionAgeGroupName })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            // RE CALL DIVISIONS
            yield put({ type: 'DIVISIONS/FETCH', idSeason: action.idSeason, module: 'Print' });
        } catch (e) { }


    });
}

export function* getPlayersDivisionSaga() {
    yield* takeEvery("PLAYERS/DIVISION/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision, partial }) => {
                return request.get(`${config.apiEndpoint}/api/v4/divisions/players/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .set('partial', partial)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });

            }, action);

            if (result && result.success) {
                yield put({
                    type: 'PLAYERS/SET',
                    players: result.players
                });

            } else {
                yield put({
                    type: 'PLAYERS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PLAYERS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getCoachesDivisionSaga() {
    yield* takeEvery("COACHES/DIVISION/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v4/divisions/coaches/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });

            }, action);

            if (result && result.success) {
                yield put({
                    type: 'COACHES/SET',
                    coaches: result.coaches
                });
            } else {
                yield put({
                    type: 'COACHES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COACHES/FETCH_FAILED',
                e
            });
        }
    });
}

// This action does not require a feedback  for now
export function* updateCoachesDivisionSaga() {
    yield* takeEvery("COACHES/UPDATE", function* (action) {
        try {
            yield call(({ IdTeamUserRole, IdTeamDivision }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/teams/move/coach`)
                    .send({
                        idTeamUserRole: IdTeamUserRole,
                        idTeamDivision: IdTeamDivision
                    })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action.player);
        } catch (e) { }
    });
}

//---------------------- Assign / Remove a program to a division (all optimistic)

export function* assignProgramSaga() {
    yield* takeEvery("DIVISION/ASSIGN_PROGRAM", function* (action) {
        try {
            yield call(({ idDivision, idCatalogItem }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_program/${idDivision}/${idCatalogItem}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* removeProgramSaga() {
    yield* takeEvery("DIVISION/REMOVE_PROGRAM", function* (action) {
        try {
            yield call(({ program, division }) => {
                return request.del(`${config.apiEndpoint}/api/v4/divisions/remove_program/${division.IdDivision}/${program.IdCatalogItem}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

//------------------------ Assign / Remove adjustment

export function* assignAdjustmentSaga() {
    yield* takeEvery("DIVISION/ASSIGN_ADJUSTMENT", function* (action) {
        try {
            yield call(({ idDivision, idCatalogItemPriceAdjustmentByDate }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_adjustment/${idDivision}/${idCatalogItemPriceAdjustmentByDate}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* removeAdjustmentSaga() {
    yield* takeEvery("DIVISION/REMOVE_ADJUSTMENT", function* (action) {
        try {
            yield call(({ program, division }) => {
                return request.del(`${config.apiEndpoint}/api/v4/divisions/remove_adjustment/${division.IdDivision}/${program.IdCatalogItemPriceAdjustmentByDate}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

//------------------------ Assign / Remove tryout programs to a division (all optimistic)

export function* assignTryoutProgramSaga() {
    yield* takeEvery("DIVISION/ASSIGN_TRYOUT", function* (action) {
        try {
            yield call(({ idDivision, id }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_tryout/${idDivision}/${id}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* removeTryoutSaga() {
    yield* takeEvery("DIVISION/REMOVE_TRYOUT", function* (action) {
        try {
            yield call(({ program, division }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/divisions/remove_tryout/${division.IdDivision}/${program.Id}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

//------------------------- Assign / Remove Zipcode programs to a division (all optimistic)

export function* assignZipCodeProgramSaga() {
    //
    yield* takeEvery("DIVISION/ASSIGN_ZIPCODE", function* (action) {
        try {
            yield call(({ idDivision, idZipCodeGroup }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_zipcode/${idDivision}/${idZipCodeGroup}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* removeZipCodeProgramSaga() {
    //
    yield* takeEvery("DIVISION/REMOVE_ZIPCODE", function* (action) {
        try {
            yield call(({ program, division }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/divisions/remove_zipcode/${division.IdDivision}/${program.IdZipCodeGroup}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}


//------------------------- Assign / Remove Family programs to a division (all optimistic)

export function* assignFamilyAdjustmentSaga() {
    //
    yield* takeEvery("DIVISION/ASSIGN_FAMILY_ADJUSTMENT", function* (action) {
        try {
            yield call(({ idDivision, idCatalogItemPriceAdjustmentByItemIndexGroup }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_family_adjustment/${idDivision}/${idCatalogItemPriceAdjustmentByItemIndexGroup}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* removeFamilyAdjustmentSaga() {
    //
    yield* takeEvery("DIVISION/REMOVE_FAMILY_ADJUSTMENT", function* (action) {
        try {
            yield call(({ program, division }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/divisions/remove_family_adjustment/${division.IdDivision}/${program.IdCatalogItemPriceAdjustmentByItemIndexGroup}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

//----------------------- Assign / Remove Question groups
export function* assignQuestionGroupSaga() {
    //
    yield* takeEvery("DIVISION/ASSIGN_QUESTION_GROUP", function* (action) {
        try {
            const result = yield call(({ idDivision, idQuestionGroup, idQuestionGroupLocation }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_question_group/${idDivision}/${idQuestionGroup}/${idQuestionGroupLocation}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                if (action.idSeason) {
                    yield put({
                        type: 'DIVISIONS/FETCH',
                        idSeason: action.idSeason
                    });
                }
            }
        } catch (e) { }
    });
}

export function* removeQuestionGroupSaga() {
    //
    yield* takeEvery("DIVISION/REMOVE_QUESTION_GROUP", function* (action) {
        try {
            yield call(({ IdQuestionGroupDivision }) => {
                return request.del(`${config.apiEndpoint}/api/v4/divisions/assign_question_group/${IdQuestionGroupDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

//----------------------- Assign / Remove scheduled locks for roster
export function* assignScheduledLockSaga() {
    //DIVISION/ASSIGN_ROSTER_LOCK
    //
    yield* takeEvery("DIVISION/ASSIGN_ROSTER_LOCK", function* (action) {
        try {
            const result = yield call(({ idDivision, IdTeamUserRoleLock }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/assign_lock_schedule/${idDivision}/${IdTeamUserRoleLock}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                if (action.idSeason) {
                    yield put({
                        type: 'DIVISIONS/FETCH',
                        idSeason: action.idSeason
                    });
                }
            }
        } catch (e) { }
    });
}

export function* lockDivisionSaga() {
    yield* takeEvery("DIVISION/LOCK", function* (action) {
        try {
            yield call(({ idDivision }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/lock/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* unlockDivisionSaga() {
    yield* takeEvery("DIVISION/UNLOCK", function* (action) {
        try {
            yield call(({ idDivision }) => {
                return request.del(`${config.apiEndpoint}/api/v4/divisions/lock/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) { }
    });
}

export function* setDivisionRosterLimitSaga() {
    yield* takeEvery("DIVISION/SET_ROSTER_LIMIT", function* (action) {
        try {
            const result = yield call(({ idDivision, limit }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/divisions/roster_limit/${idDivision}/${limit || 0}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            }, action);
            if (result && result.success) {
                yield put({ type: 'SEASON/DASHBOARD/FETCH', idSeason: action.idSeason });
            } else {
                yield put({ type: 'SEASON/SET_ROSTER_LIMIT/_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'SEASON/SET_ROSTER_LIMIT/_FAILED', e });
        }
    });
}
