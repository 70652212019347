
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';
import { generateRandomString } from '../../../helpers';
import LineLoader from '../LineLoader';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../CustomDatePicker';
import moment from 'moment';

class CouponModal extends React.Component {

    state = {
        bootstrapped: false,
        coupon: null,
        mode: 1,
        selectedDate: moment().add(7, 'days')
    }

    // Lifecycle
    componentWillMount() {
        const { coupon = {}, couponActions, leagueActions, idLeague } = this.props;
        this.setState({ coupon });
        couponActions && couponActions.fetchTypes();
        leagueActions && leagueActions.fetchSeasonsForRegistration(idLeague)
        // TODO: fetch coupon types
    }
    componentWillReceiveProps = nextProps => {
        const { coupon = {} } = nextProps;
        this.setState({ coupon }, () => {
            let mode = 1
            if (coupon && coupon.AmountPerCoupon) mode = 2;
            if (coupon && coupon.validUntil) this.setState({ selectedDate: moment(coupon.validUntil.split('T')[0], 'YYYY-MM-DD') });
            this.setState({ mode });
        });
    }
    //   

    save = () => {
        // TODO: validations        
        let { coupon = {}, mode } = this.state, { leagueActions, idLeague, toggle, non_expired_coupons } = this.props;
        if (!coupon) coupon = {}
        coupon.CouponName = this.txtCode.value;
        coupon.AmountPerCoupon = mode === 2 ? this.txtAmount.value : null;
        coupon.PercentPerCoupon = mode === 1 ? this.txtAmount.value : null;
        coupon.MaxNumberOfUsers = this.txtMax.value;
        coupon.EmailAddressString = this.txtEmails.value;
        coupon.validUntil = this.state.selectedDate.format();
        coupon.IdLeague = idLeague;
        coupon.IdCouponType = 9;
        coupon.PromoDays = this.txtPromoDays.value;
        this.setState({ error: false });

        // TODO: check if the coupon code is already
        if (_.find(non_expired_coupons, c => 
            (c.CouponName.toLowerCase() === coupon.CouponName.toLowerCase() && c.IdCoupon !== coupon.IdCoupon))) {
            this.setState({ error: true });
            return;
        } else {
            this.setState({ error: false });
        }

        this.setState({ microTransac: true }, () => {
            request[coupon.IdCoupon ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/coupons${coupon.IdCoupon ? `/${coupon.IdCoupon}` : ''}`)
                .send(coupon)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(() => {
                    this.setState({ microTransac: false });
                    leagueActions && leagueActions.fetchCoupons(idLeague);
                    toggle && toggle();
                });
        });
    }

    onSelectCouponType = e => {
        let { coupon } = this.state, { couponTypes } = this.props;
        if (!coupon) coupon = {}
        const couponType = _.find(couponTypes, c => c.IdCouponType === parseInt(e.target.value, 10));
        coupon.couponType = couponType;
        this.setState({ coupon });
    }

    onSelectSeason = season => {
        let { coupon } = this.state;
        if (!coupon) coupon = {}
        coupon.IdSeason = (season || {}).IdSeason;
        this.setState({ coupon, pickSeason: false });
    }

    onMode = mode => this.setState({ mode });

    handleChangeSelectedDate = (date) => this.setState({ selectedDate: moment(date) });

    autoGenerate = () => {
        let { coupon } = this.state;
        if (!coupon) coupon = {}
        coupon.CouponName = generateRandomString(6).toUpperCase();
        this.txtCode.value = coupon.CouponName;
        this.setState({ coupon });
    }

    render() {
        const { isOpen, toggle } = this.props,
            { coupon, mode, microTransac } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 400 }}>
                <ModalHeader toggle={toggle} className="bg-info">
                    {coupon && coupon.IdCoupon ? 'Edit Membership Coupon' : 'Create Membership Coupon'}
                </ModalHeader>
                <ModalBody className="container-fluid no-margin no-padding">
                    {microTransac && <LineLoader />}
                    
                    <div className="margin-bottom margin-top pl-4 pr-4 d-flex flex-row">
                        <div className="input-group w-100 align-self-center">
                            <input defaultValue={(coupon || {}).CouponName} type="text" className="form-control" placeholder="Coupon Code" ref={(i) => this.txtCode = i} />
                            <button onClick={this.autoGenerate} className="btn btn-info">Auto-Generate</button>
                        </div>
                    </div>
                    
                    <div className="d-flex flex-row pl-4 pr-4 margin-bottom">
                        <div className="input-group w-100 align-self-center">
                            <input defaultValue={(coupon || {}).AmountPerCoupon || (coupon || {}).PercentPerCoupon} type="number" className="form-control" placeholder="Amount" ref={(i) => this.txtAmount = i} />
                        </div>
                        <div style={{ width: 20 }} />
                        <div className="d-flex flex-row">
                            <button onClick={() => this.onMode(1)} className={`btn btn-${mode === 1 ? 'success' : 'link'}`}>%</button>
                            <button onClick={() => this.onMode(2)} className={`btn btn-${mode === 2 ? 'success' : 'link'}`}>$</button>
                        </div>
                    </div>
                    <div className="d-flex flex-row pl-4 pr-4 margin-bottom">
                        <div className="input-group w-100 align-self-center">
                            <input defaultValue={(coupon || {}).PromoDays} type="number" className="form-control" placeholder="Promo Days" ref={(i) => this.txtPromoDays = i} />
                        </div>                        
                    </div>
                    <div className="d-flex flex-row pl-4 pr-4 margin-bottom">
                        <div className="input-group w-100 align-self-center">
                            <input placeholder='Email Address' defaultValue={(coupon || {}).EmailAddressString} className="form-control" ref={(i) => this.txtEmails = i} />
                        </div>
                        <div style={{ width: 20 }} />
                        <div className="d-flex flex-row w-50 align-self-center">
                            <span className="font-8">Separated multiples by a comma (,)</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row pl-4 pr-4 margin-bottom">
                        <div className="input-group w-100 align-self-center">
                            <input defaultValue={(coupon || {}).MaxNumberOfUsers} type="number" className="form-control" ref={(i) => this.txtMax = i} />
                        </div>
                        <div style={{ width: 20 }} />
                        <div className="d-flex flex-row w-50 align-self-center">
                            <span className="font-8">Max. Number of Uses</span>
                        </div>
                    </div>

                    <div className="d-flex flex-row pl-4 pr-4 margin-bottom">
                        <span className="font-12 mr-2 align-self-center">Available until:</span>
                        <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.selectedDate}
                            startDate={this.state.selectedDate} onChange={this.handleChangeSelectedDate} />
                    </div>
                </ModalBody>
                {this.state.error && <ModalBody className="bg-danger p-3">
                    <span className="white">You already have an active Coupon with the same code {this.txtCode.value}</span>
                </ModalBody>}
                {
                    !microTransac && <ModalFooter className="d-flex flex-row">
                        <button className="btn btn-link align-self-center" onClick={toggle}>Cancel</button>
                        <button className="btn btn-success align-self-center ml-auto" onClick={this.save}>Save</button>
                    </ModalFooter>
                }
                {
                    microTransac && <ModalFooter className="d-flex flex-row">
                        <button className="btn btn-success align-self-center ml-auto" >Saving, please wait...</button>
                    </ModalFooter>
                }
            </Modal >
        )
    }
}

export default CouponModal;
