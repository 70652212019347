import React from 'react';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import config from '../../../config';
import _ from 'lodash';
import request from 'superagent';
import LineLoader from '../LineLoader';
import BlueishHeader from '../things/BlueishHeader';
import BlackishHader from '../things/BlackishHeader';
import { formatPrice, log } from '../../../helpers';
import PaymentPlanItem from '../../family/common/payment_plans/Item';

class MovePlayerModal extends React.Component {

    state = {
        divisions: null,
        selectedSeason: null,
        originalSeason: null,
        isSeasonsPaneOpened: false,
        microTransac: false,
        originalDivision: null,
        selectedDivision: null,
        selectedTeam: null,
        fetchingOriginalBalance: false,
        originalBalance: 0,
        estimatedFee: 0,
        fetchingFees: false,
        moving: false
    }

    // Lifecycle
    componentWillMount() {
        this.setState({
            selectedSeason: _.find(this.props.seasons, (s) => {
                return s.IdSeason === this.props.selectedSeason.IdSeason
            }) || _.first(this.props.seasons) || {},
            originalSeason: { ...this.props.selectedSeason },
            originalDivision: { ...this.props.user }
        }, () => { this.fetchDivisions(); });
    }
    //

    toggleSeasonsPane = () => this.setState(current => { return { isSeasonsPaneOpened: !current.isSeasonsPaneOpened } });

    selectSeason = selectedSeason => {
        this.setState((current) => {
            return {
                selectedSeason,
                selectedDivision: null,
                selectedTeam: null,
                divisions: null,
                isSeasonsPaneOpened: !current.isSeasonsPaneOpened
            }
        }, () => {
            this.fetchDivisions();
        });
    }

    selectTeam = (selectedTeam, division) => {
        this.setState({
            selectedTeam: _.extend(selectedTeam, { IdDivision: division.IdDivision }),
            selectedDivision: null,
            fetchingFees: true
        }, () => {
            this.fetchFees(division.IdDivision)
        });
    }

    selectDivision = selectedDivision => {
        this.setState({
            selectedDivision,
            selectedTeam: null,
            fetchingFees: true
        }, () => {
            this.fetchFees(selectedDivision.IdDivision);
        });
    }

    fetchFees = (idDivision) => {
        const { originalSeason } = this.state;
        request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${idDivision}/${originalSeason.IdRole}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { plans } }) => {
                const { fullBalance } = this.props;

                // The balance will tell us if the user selected a 
                // payment plan or full-payment at the moment of 
                // registration. This rule is effective from May 15th 2019
                if (fullBalance.IsPaymentPlan) {
                    (_.last(plans) || {}).selected = true;
                } else {
                    (_.first(plans) || {}).selected = true;
                }
                //

                this.setState({
                    fullBalance,
                    fetchingFees: false,
                    plans,
                    selectedPlan: fullBalance.IsPaymentPlan ? _.last(plans) : _.first(plans),
                }, () => {
                    this.calculateEstimateFee();
                });
            });
    }

    fetchDivisions = () => {
        const { selectedSeason, originalSeason } = this.state;

        this.setState({ microTransac: true }, () => {

            // TODO: Redux this
            request.get(`${config.apiEndpoint}/api/v4/divisions/${selectedSeason.IdSeason}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(({ body: { divisions } }) => {

                    let selectedDivision = null,
                        selectedTeam = null;

                    if (originalSeason.IdTeamDivision) {
                        divisions.forEach((d) => {
                            d.Teams.forEach((t) => {
                                if (parseInt(t.IdTeamDivision, 10) === parseInt(originalSeason.IdTeamDivision, 10)) {
                                    selectedTeam = t;
                                    d.open = true;
                                }
                            });
                        });
                    } else {
                        divisions.forEach((d) => {
                            if (d.IdDivision === this.props.selectedSeason.IdDivision) {
                                selectedDivision = d;
                                d.open = true;
                            }
                        });
                    }

                    this.setState({
                        microTransac: false,
                        selectedDivision,
                        selectedTeam,
                        divisions
                    });
                });
        });
    }

    calculateEstimateFee = () => {
        let { fullBalance, selectedPlan } = this.state;
        //estimatedFee: plan ? (plan.Total - (fullBalance.TotalAmount + (fullBalance.TotalAdjustmentCredits_Adm || 0))) : 0  
        let estimatedFee = selectedPlan ? (selectedPlan.Total - (fullBalance.TotalAmount + (fullBalance.TotalAdjustmentCredits_Adm || 0))) : 0;
        // To prevent decimals (pennys), we round to 0 if
        //if (estimatedFee < 1) estimatedFee = 0;
        if (parseInt(this.state.originalSeason.IdSeason, 10) === 11826) estimatedFee = 0;
        this.setState({ estimatedFee });
    }

    fnSelectPlan = (plan) => {
        const { plans } = this.state;
        plans.forEach(p => { p.selected = p.Name === plan.Name; });
        this.setState({ selectedPlan: _.find(plans, p => p.selected), plans }, () => { this.calculateEstimateFee(); });
    }

    move = () => {
        const { fnMoved, user } = this.props;

        if (!this.state.moving) {
            this.setState({ moving: true }, () => {

                const input = {
                    idUser: user.IdUser,
                    idDivision: (this.state.selectedDivision || this.state.selectedTeam).IdDivision,
                    idTeamDivision: this.state.selectedTeam ? this.state.selectedTeam.IdTeamDivision : (this.state.selectedDivision ? null : this.state.originalSeason.IdTeamDivision),
                    idOrderItem: this.props.idOrderItem,
                    adjustmentAmount: this.state.estimatedFee,
                    newPlan: this.state.selectedPlan,
                    idRole: this.state.originalSeason.IdRole,
                    idTeamUserRole: this.state.originalSeason.IdTeamUserRole,
                };

                // Call API with new IdDivision (and IdTeamDivision if team was selected), IdUser and the Calculated Fee Difference
                request.post(`${config.apiEndpoint}/api/v5/registration/move/player`)
                    .send(input)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => {
                        fnMoved && fnMoved(this.state.selectedSeason);
                    }, ({ response: { body: { error } } }) => {                        
                        this.setState({ moving: false, error: error.message });
                    });
            });
        }
    }

    moveFree = () => {
        const { fnMoved, user } = this.props;

        if (!this.state.moving) {
            this.setState({ moving: true }, () => {

                const input = {
                    idUser: user.IdUser,
                    idDivision: (this.state.selectedDivision || this.state.selectedTeam).IdDivision,
                    idTeamDivision: this.state.selectedTeam ? this.state.selectedTeam.IdTeamDivision : (this.state.selectedDivision ? null : this.state.originalSeason.IdTeamDivision),
                    idOrderItem: this.props.idOrderItem,
                    adjustmentAmount: 0,
                    newPlan: this.state.selectedPlan,
                    idRole: this.state.originalSeason.IdRole,
                    idTeamUserRole: this.state.originalSeason.IdTeamUserRole
                };

                // Call API with new IdDivision (and IdTeamDivision if team was selected), IdUser and the Calculated Fee Difference
                request.post(`${config.apiEndpoint}/api/v5/registration/move/player`)
                    .send(input)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => {
                        fnMoved && fnMoved(this.state.selectedSeason);
                    }, ({ response: { body: { error } } }) => {                        
                        this.setState({ moving: false, error: error.message || 'There was an error' });
                    });
            });
        }
    }

    toggleDivision = d => {
        const { divisions } = this.state;
        (_.find(divisions, division => d.IdDivision === division.IdDivision) || {}).open = !(_.find(divisions, division => d.IdDivision === division.IdDivision) || {}).open;
        this.setState({ divisions });
    }


    render() {
        const { user, isOpen, toggle, seasons } = this.props,
            { selectedSeason, isSeasonsPaneOpened, divisions, microTransac, error,
                selectedDivision, selectedTeam, selectedPlan, originalSeason, originalDivision, fetchingFees, moving, plans } = this.state;

        let not_same_shit = ((selectedDivision && originalDivision.IdDivision !== selectedDivision.IdDivision) ||
            (selectedTeam && parseInt(selectedTeam.IdTeamDivision, 10) !== parseInt(originalSeason.IdTeamDivision, 10)));
        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 650 }}>
                <ModalHeader toggle={toggle}>
                    Move
                    {' '}
                    {user.Name || user.UserName}
                </ModalHeader>
                <ModalBody className="container-fluid no-padding no-margin">
                    {microTransac && <LineLoader />}

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    <div className="row no-padding no-margin">
                        <div className="col-12 no-padding no-margin">
                            <button onClick={this.toggleSeasonsPane} className="btn btn-link btn-block d-flex flex-row bg-white">
                                <span className="align-self-center font-8">Season:</span>
                                <i className={`fas fa-circle align-self-center idented-half color-${selectedSeason ? selectedSeason.SeasonType : ''} font-8`} />
                                <span className="align-self-center idented-half font-8">{selectedSeason ? selectedSeason.SeasonName : ''}</span>
                                <i className="fa fa-caret-down ml-auto align-self-center" />
                            </button>
                            <div style={{ height: 3, backgroundColor: '#e5e5e5' }} />
                            {isSeasonsPaneOpened && selectedSeason && (
                                <ul className="bg-white">
                                    {seasons && seasons.map((s, i) => (
                                        <li key={i} style={{ borderTop: '1px solid #ccc' }} className={`${s.IdSeason === selectedSeason.IdSeason ? 'bg-warning' : 'bg-white'}`}>
                                            <button className="btn btn-link d-flex flex-row btn-block font-8" onClick={() => this.selectSeason(s)}>
                                                <i className={`fas fa-circle align-self-center color-${s.SeasonType}`} />
                                                <span className="idented-half align-self-center">{s.SeasonName}</span>
                                            </button>
                                        </li>))}
                                </ul>)}
                            {isSeasonsPaneOpened && <hr style={{ margin: 0 }} />}
                        </div>
                        { /* Left Side */}
                        <div className="col-6 no-padding no-margin" style={{ position: 'relative', maxHeight: 800, overflowY: 'auto' }}>
                            {divisions && divisions.length > 0 && divisions.map((d, i) => (
                                <div key={i}>
                                    <button key={originalSeason.IdDivision} className={`${(originalSeason.IdDivision === d.IdDivision && !originalSeason.IdTeamDivision) ? 'bg-info' : ''} btn btn-link btn-sm btn-block d-flex flex-row`} style={{ borderBottom: '1px solid #e5e5e5' }} >
                                        <i onClick={() => this.selectDivision(d)} className={`align-self-center ${(selectedDivision && selectedDivision.IdDivision === d.IdDivision) ? 'fas fa-check-square green' : 'far fa-square'}`} />
                                        <span className="align-self-center mx-2">{d.DivisionName || d.Name}</span>
                                        <i onClick={() => this.toggleDivision(d)} className={`pointer align-self-center ml-auto mr-2 fas fa-chevron-${d.open ? 'up' : 'down'}`} />
                                    </button>
                                    {d.open && d.Teams.map((t, i) => (
                                        <button key={t.IdTeamDivision} className={`${originalSeason.IdTeamDivision === t.IdTeamDivision ? 'bg-info' : ''} btn btn-link btn-sm btn-block no-margin d-flex flex-row`} style={{ borderBottom: '1px solid #e5e5e5' }} onClick={() => this.selectTeam(t, d)}>
                                            <i className={`idented align-self-center ${(selectedTeam && selectedTeam.IdTeamDivision === t.IdTeamDivision) ? 'fas fa-check-square green' : 'far fa-square'}`} />
                                            <span className="align-self-center idented-half">{t.Name || t.TeamName}</span>
                                        </button>))}
                                </div>
                            ))}
                        </div>
                        { /* Right Side */}
                        <div className="col-6 no-margin d-flex flex-column no-padding no-margin bg-gray" style={{ borderLeft: '1px solid #ccc' }}>

                            { /* From */}
                            <BlackishHader message="From" />
                            <section className="p-2 d-flex flex-column bg-white">
                                <span className="font-8 blue">Season</span>
                                <span className="margin-bottom">{originalSeason.SeasonName}</span>
                                <span className="font-8 blue">Division</span>
                                <span className="margin-bottom">{originalSeason.DivisionName}</span>
                                {originalSeason.IdTeamDivision && <span className="font-8 blue">Team</span>}
                                {originalSeason.IdTeamDivision && <span className="margin-bottom">{originalSeason.TeamName}</span>}
                                <span className="font-8 blue">Balance</span>
                                <span className="margin-bottom">{this.props.balance ? formatPrice(this.props.balance * 100) : '$0'}</span>
                            </section>
                            <hr className="no-margin" />
                            <i className="fas fa-arrow-down bigest-font" style={{ margin: '20px auto' }} />

                            { /* To */}
                            <BlueishHeader message="To" />
                            {selectedSeason && plans && selectedPlan && not_same_shit && <section className="p-2 d-flex flex-column" style={{ backgroundColor: '#002549' }}>
                                {plans.map((plan, i) => <li className="payment-plan-tile" key={i}>
                                    <button onClick={() => this.fnSelectPlan(plan)} className="white justify-content-center"
                                        style={{ width: '100%', height: '100%' }}>
                                        <PaymentPlanItem paymentPlan={plan} />
                                    </button>
                                </li>)}
                            </section>}

                            {selectedSeason && <section className="p-2 d-flex flex-column bg-white">
                                <span className="font-8 blue">Season</span>
                                <span className="margin-bottom">{selectedSeason.SeasonName}</span>
                                <span className="font-8 blue">Division</span>
                                <span className="margin-bottom">{selectedDivision ? selectedDivision.Name : (selectedTeam ? (_.find(divisions, (d) => {
                                    return parseInt(d.IdDivision, 10) === parseInt(selectedTeam.IdDivision, 10)
                                }) || {}).Name : 'N/A')}</span>
                                {selectedTeam && <span className="font-8 blue">Team</span>}
                                {selectedTeam && <span className="margin-bottom">{selectedTeam.Name || selectedTeam.TeamName}</span>}
                                {this.state.estimatedFee > 0 && <span className="font-8 blue">Additional Fees</span>}
                                {this.state.estimatedFee > 0 && <span className={`margin-bottom ${this.state.estimatedFee < 0 ? 'red' : 'green'}`}>{fetchingFees ? 'Calculating...' : formatPrice(this.state.estimatedFee * 100)}</span>}
                            </section>}

                            {not_same_shit &&
                                !fetchingFees &&
                                this.state.estimatedFee > 0 && (
                                    <button onClick={this.move} className={`m-2 btn btn-${(moving && this.state.movingFree) ? 'warning' : 'success'}`}><i className="fas fa-exchange-alt" />
                                        {' '}
                                        {(moving && this.state.movingFree) ? 'Moving...' : `Move player with ${formatPrice(this.state.estimatedFee * 100)} Fee`}
                                    </button>)}

                            {not_same_shit && !fetchingFees && (
                                <button onClick={this.moveFree} className={`m-2 btn btn-${(moving && this.state.movingFree) ? 'warning' : 'success'}`}><i className="fas fa-exchange-alt" />
                                    {' '}
                                    {(moving && this.state.movingFree) ? 'Moving with NO charges...' : 'Move with NO charges'}
                                </button>)}

                        </div>
                    </div>
                </ModalBody>
            </Modal>)
    }
}

export default MovePlayerModal;
