import React from 'react';
import request from 'superagent';
import config from '../../../../../../config';

class Comp extends React.Component {

  componentDidMount = () => {
    const { answer } = this.props;
    if (answer.editing) {
      this.txt.focus();
    }
  }
  handleChange = (e) => {
    const { answer } = this.props;
    if (!answer.editing) {
      answer.editing = true;
      answer.text = e.target.value;
      this.props.questionsActions &&
        this.props.questionsActions.updateAnswer(answer);
    }
  }
  handleChangeAmount = e => {
    const { answer } = this.props;
    if (!answer.editing) {
      answer.editing = true;
      answer.Amount = parseFloat(e.target.value);
      this.props.questionsActions &&
        this.props.questionsActions.updateAnswer(answer);
    }
  }

  delete = () => {
    const { answer } = this.props;
    this.props.questionsActions &&
      this.props.questionsActions.removeAnswer(answer.IdQuestionLibraryOption || answer.id);
  }

  select = () => {
    const { answer } = this.props;
    answer.selected = !answer.selected;
    this.props.questionsActions &&
      this.props.questionsActions.updateAnswer(answer);

    answer.selected && this.props.questionsActions.selectAnswer(answer);
    !answer.selected && this.props.questionsActions.unselectAnswer(answer);
  }

  save = (e) => {
    e.preventDefault && e.preventDefault();
    const { answer, season } = this.props;
    answer.editing = false;
    answer.text = this.txt.value;
    answer.Amount = answer.amount = this.txtAmount.value || 0;

    // call api to save the question
    // TODO: feedback of the api call ?
    request[answer.IdQuestionLibraryOption ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/questions/library/answer_text/${answer.IdQuestionLibraryOption || season.IdLeague}`)
      .send({
        text: answer.text,
        idQuestionType: answer.answer_type,
        amount: answer.amount,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(({ body }) => {
        if (body.success) {
          answer.IdQuestionLibraryOption = body.IdQuestionLibraryOption;
          this.props.questionsActions &&
            this.props.questionsActions.updateAnswer(answer);
        } else {
          alert('Something is wrong!')
        }
      }, reject => {
        // TODO: bubble the error!
        alert('Something is wrong!')
      });

    this.props.questionsActions &&
      this.props.questionsActions.updateAnswer(answer);
    this.txt.focus();
    this.txt.blur();
  }

  render() {
    const { answer, lock, selectable } = this.props;

    return <form className="input-group">
      {selectable && <div className="input-group-prepend">
        <button type="button" className="btn" onClick={this.select}><i className={`far fa-${answer.selected ? 'check-square' : 'square'}`}></i></button>
      </div>}
      <input disabled={lock ? true : false} style={{ width: selectable ? null : 180 }} defaultValue={(answer.text || answer.Text)} type="text" className="form-control" onChange={this.handleChange} ref={(i) => this.txt = i} />
      <div className="input-group-prepend input-group-append">
        <span className="input-group-text">$</span>
      </div>
      <input disabled={lock ? true : false} defaultValue={answer.Amount} type="number" onChange={this.handleChangeAmount} className="form-control" ref={(i) => this.txtAmount = i} />
      {answer.editing && !lock && <div className="input-group-append d-flex flex-row">
        <button type="submit" className="btn btn-sm ml-auto btn-success" onClick={this.save}><i className="fa fa-check"></i></button>
        <button type="button" className="btn btn-sm ml-auto btn-danger" onClick={this.delete}><i className="fa fa-times"></i></button>
      </div>}
      {!answer.editing && !lock && <div className="input-group-append d-flex flex-row">
        <button type="button" className="btn btn-sm ml-auto" onClick={this.delete}><i className="fa fa-times red"></i></button>
      </div>}
    </form>
  }
}

export default Comp;
