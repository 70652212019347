import React from 'react';
import './css.css';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import request from 'superagent';
import moment from 'moment';
import config from '../../../../config';
import Sheet from './sheet_say';
import Navigation from '../navigation';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePicker';
import DivisionGroupName from '../travel/partials/division_group_name';
import { Jumbotron } from 'reactstrap';
import { humanize_user } from '../../../../helpers';


class PrintRoster extends React.Component {

  state = {
    bootstrapped: false,
    filters: {},
    appliedFilters: {},
    ddDateOpen: false,
    ddTimeOpen: false,
    season: null,
    divisions: [],
    teams: [],
    fetchingGames: false,
    fetchedOnce: false,
    fullSheet: true,
    sheetMode: 1,
    downloading: false,
    title: null,
    filter: {}
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Print Player Cards',
      main: this.props.season.Name || null
    });

    // Fetch season
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.divisionsActions && this.props.divisionsActions.fetchPrintableDivisions(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Print Player Cards',
        main: nextProps.season.Name || null
      });
      this.setState({ season: nextProps.season });
    }
    if (nextProps.divisions && nextProps.season) {
      this.setState({ bootstrapped: true, divisions: nextProps.divisions, expDate: nextProps.season.PlayerCardExpDate ? moment(_.first(nextProps.season.PlayerCardExpDate.split('T')), 'YYYY-MM-DD') : null });
    }
  }

  preview = (e) => {

    e && e.preventDefault && e.preventDefault();
    const { divisions, fetchingGames } = this.state, { season } = this.props;
    if (fetchingGames) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    this.setState({ fetchingGames: true }, () => {
      request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
        .send({
          teams,
          title: this.txtSeason.value,
          league: this.txtLeagueTitle.value,
          expDate: moment(this.state.expDate).format('MM/DD/YYYY'),
          idSeason: (season.IdSeason || season.idSeason)
        })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(data => {
          this.setState({ teams: data.body.teams, fetchingGames: false, fetchedOnce: true });
        });
    });
  }

  reload_silently = () => {
    const { divisions } = this.state, { season } = this.props;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
      .send({
        teams,
        title: this.txtSeason.value,
        league: this.txtLeagueTitle.value,
        expDate: moment(this.state.expDate).format('MM/DD/YYYY'),
        idSeason: (season.IdSeason || season.idSeason)
      })
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(data => {
        this.setState({ teams: data.body.teams });
      });

  }

  download = () => {

    const { divisions, downloading } = this.state, { season } = this.props;
    if (downloading) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    this.setState({
      downloading: true
    }, () => {

      // Fetch games with filters applied
      request.post(`${config.apiEndpoint}/api/v4/roster/say/player_cards/download`)
        .send({
          teams, title: this.txtSeason.value,
          league: this.txtLeagueTitle.value,
          expDate: moment(this.state.expDate).format('MM/DD/YYYY'),
          idSeason: (season.IdSeason || season.idSeason)
        })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .responseType('arraybuffer')
        .then((data) => {
          let file = new Blob([data.body], { type: 'application/pdf' });
          require("downloadjs")(file, 'SAY-PC.pdf');
          this.setState({ downloading: false, });
        });
    });
  }

  toggleSeason = () => {
    const { season, divisions } = this.state;
    season.selected = !season.selected;
    divisions.forEach(division => {
      division.selected = season.selected;
      division.Teams.forEach(team => {
        team.selected = season.selected;
      });
    });
    this.setState({ season, divisions });
  }

  toggleDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].selected = !divisions[index].selected;
    divisions[index].Teams.forEach(team => {
      team.selected = divisions[index].selected;
    });
    this.setState({ divisions });
  }

  toggleTeam = (di, ti) => {
    const { divisions } = this.state;
    divisions[di].Teams[ti].selected = !divisions[di].Teams[ti].selected;
    divisions[di].selected = _.find(divisions[di].Teams, (team) => !team.selected) ? false : true;
    this.setState({ divisions });
  }

  toggleExpandDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].expanded = !divisions[index].expanded;
    this.setState({ divisions });
  }

  onRequestPictureChange = () => {
    const { season } = this.props, { picture_change } = this.state;
    this.setState({ picture_change: null }, () => {
      request.post(`${config.apiEndpoint}/api/v4/users/request/replacement/${picture_change.IdUser}/${season.IdLeague}`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({})
        .then((data) => {
          // Re-fetch roster
          this.reload_silently();
        });
    });

  }

  fetchRoster = (division_index, team_index) => {
    const { divisions } = this.state;
    let division = divisions[division_index], team = division.Teams[team_index];
    team.expanded = !team.expanded;
    this.setState({ divisions }, () => {
      if (!team.Roster) {
        request.get(`${config.apiEndpoint}/api/v6/roster/${team.IdTeamDivision}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(({ body: { roster } }) => {
            // Decrypt
            roster.forEach(u => humanize_user(u));
            //
            team.Roster = _.sortBy(roster, 'NameFirst');
            this.setState({ divisions });
          });
      }
    });
  }

  onPlayer = (division_index, team_index, IdUser) => {
    const { divisions } = this.state,
      division = divisions[division_index],
      team = division.Teams[team_index],
      player = _.find(team.Roster, r => r.IdUser === IdUser) || {};      

    player.selected = !player.selected;
    team.selected = true;

    this.setState({ divisions });
  }

  handleChangeSelectedDate = (date) => this.setState({ expDate: moment(date) });

  render() {

    const { fetchingGames, fetchedOnce, divisions, downloading, teams, bootstrapped } = this.state,
      { league, season } = this.props;

    let filtered_teams = _.chain(teams)
      .value();

    return <section id="registration-fees" style={{ overflow: 'hidden' }}>

      {season && season.Name && divisions && bootstrapped && <div className="w3-container mt-4 h-100" style={{ position: 'relative' }}>

        {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
          <div className="w-100 black">
            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
          </div>
        </div>}

        <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
          <h5>Administration</h5>
          {season && <Navigation season={season} />}
        </div>

        <div className="m-auto pt-4 w-100" style={{ maxWidth: 1285, position: 'absolute', top: 150, bottom: 0, left: 0, right: 0 }}>
          {divisions && bootstrapped && (
            <div key="with-data" className="d-flex flex-row h-100" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

              <Animated animationIn="slideInLeft" className="no-margin no-padding bg-white d-flex flex-column" style={{ width: 450 }}>

                <section className="h-100 " style={{ overflowY: 'auto', overflowX: 'hidden', borderBottom: '1px solid gray' }}>
                  {/* SEASON */}
                  {season && <button onClick={() => this.toggleSeason()} className="btn btn-link text-left d-flex flex-row w-100 mt-2 btn-sm px-3 py-2" style={{ whiteSpace: 'normal' }}>
                    <i className={`mr-2 align-self-center ${season.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                    <span className="align-self-center">{season.Name || season.SeasonName}</span>
                    <i onClick={(e) => { e.stopPropagation(); this.setState({ divisionsTool: true }); }} className="pointer fas fa-cog align-self-center ml-auto red" />
                  </button>}

                  {divisions && _.filter(divisions, d => d.Teams.length).map((division, i) => (

                    <section key={i}>

                      {/* DIVISION */}

                      <div onClick={(e) => { e.stopPropagation(); this.toggleDivision(i) }} className="btn btn-link text-left  btn-sm w-100" style={{ whiteSpace: 'normal' }}>
                        {division.Teams.length > 0 &&
                          <div className="btn btn-sm btn-link black" onClick={(e) => { e.stopPropagation(); this.toggleExpandDivision(i) }}>
                            <i className={`fa fa-caret-${division.expanded ? 'up' : 'down'}`} />
                          </div>
                        }
                        <i className={`${division.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'} mx-2`} />
                        <span>{`${division.Name || division.IdDivision}${division.DivisionAgeGroupName ? ` (${division.DivisionAgeGroupName})` : ''}`}</span>
                      </div>

                      {division.expanded && division.Teams.map((team, j) => (
                        <section className="" key={j}>

                          {/* TEAM */}
                          <div onClick={() => this.toggleTeam(i, j)} className="btn btn-link text-left btn-sm btn-block d-flex flex-row" style={{ whiteSpace: 'normal', marginLeft: '3em' }}>
                            <div className="black px-2 align-self-center" onClick={(e) => { e.stopPropagation(); this.fetchRoster(i, j); }}>
                              <i className={`fa fa-caret-${team.expanded ? 'up' : 'down'}`} />
                            </div>
                            <i className={` align-self-center mx-1 ${team.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                            {' '}
                            <span className="align-self-center">{team.Name || team.TeamName}</span>
                          </div>
                          {team.expanded && <section className="">
                            {/* ROSTER */}
                            {!team.Roster && <Jumbotron><h3>Fetching, please wait...</h3></Jumbotron>}
                            
                            {/* COACHES */}
                            {team.Roster && _.chain(team.Roster).filter(r => r.IdRole !== 3).sortBy('NameLast').value().map((user, k) =>
                              <div onClick={() => this.onPlayer(i, j, user.IdUser)} key={k} className=" btn btn-link text-left btn-sm btn-block d-flex flex-row"
                                style={{ whiteSpace: 'normal', marginLeft: '6em' }}>
                                <i className={`align-self-center ${user.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                                <span className="ml-2 align-self-center">{`${user.NameFirst} ${user.NameLast} ${user.IdRole !== 3 ? '(C)' : ''}`}</span>
                              </div>
                            )}
                            
                            {/* PLAYERS */}
                            {team.Roster && _.chain(team.Roster).filter(r => r.IdRole === 3).sortBy('NameLast').value().map((user, k) =>
                              <div onClick={() => this.onPlayer(i, j, user.IdUser)} key={k} className="btn btn-link text-left btn-sm btn-block d-flex flex-row"
                                style={{ whiteSpace: 'normal', marginLeft: '6em' }}>
                                <i className={`align-self-center ${user.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                                <span className="ml-2 align-self-center">{`${user.NameFirst} ${user.NameLast} ${user.IdRole !== 3 ? '(C)' : ''}`}</span>
                              </div>
                            )}

                          </section>}
                        </section>))}

                    </section>))}

                  <div style={{ height: 100 }} />
                </section>
              </Animated>

              <Animated animationIn="slideInRight" className="w-100 no-margin no-padding bg-white h-100  " style={{ borderLeft: '1px solid gray', position: 'relative' }}>

                {/* ACTIONS */}
                <div className="d-flex flex-row p-2 w-100" style={{ height: 60, position: 'absolute', top: 0, borderBottom: '1px solid gray' }}>
                  <span className="font-12 mr-2 align-self-center">Exp. Date:</span>
                  <div className="align-self-center">
                    <DatePicker className="align-self-center" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker className="align-self-center" />}
                      selected={this.state.expDate}
                      startDate={moment()} onChange={this.handleChangeSelectedDate} />
                  </div>
                  {/* Title */}
                  {season && <input className="ml-2 form-control align-self-center" style={{ width: 150 }} type="text" ref={i => this.txtSeason = i} defaultValue={season.PlayerCardTitle} placeholder="Type in the Season Title" />}
                  {season && <input className="ml-2 form-control align-self-center" style={{ width: 150 }} type="text" ref={i => this.txtLeagueTitle = i} defaultValue={season.PlayerCardLeagueTitle || season.LeagueTitle} placeholder="League Title" />}
                  <button type="submit" key="previewbtn" onClick={this.preview} className="ml-auto align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }}>
                    <i className="fa fa-eye mr-2" />
                    <span>{fetchingGames ? 'Fetching' : 'Preview'}</span>
                  </button>
                  {filtered_teams.length > 0 && <button type="submit" key="downloadbtn" onClick={this.download} className="ml-2 align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }}>
                    <i className="fa fa-download mr-2" />
                    <span>{downloading ? 'Downloading' : 'Download'}</span>
                  </button>}
                </div>

                <section className={`bg-gray-dark inner-shadow`} style={{ overflowY: 'auto', position: 'absolute', top: 60, bottom: 0, left: 0, right: 0 }}>
                  {filtered_teams.length > 0 && !fetchingGames && filtered_teams.map((team, i) => <Sheet exp={this.state.expDate}
                    title={this.txtSeason.value}
                    league={this.txtLeagueTitle.value}
                    fncp={(u) => this.setState({ picture_change: u })} key={team.IdTeamDivision} team={team} season={season} width={900} />)}
                  {filtered_teams.length > 0 && <div style={{ height: 100 }} />}
                  {fetchingGames && <Loader key="loader" className="white" message={`Fetching Games, please wait...`} />}
                  {!fetchingGames && fetchedOnce && !filtered_teams.length && <div className="d-flex justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">No roster were found with the applied filters!</h1>
                  </div>}
                </section>

              </Animated>
            </div>
          )}
          {!bootstrapped && <Loader className="black" key="loader" message={`Loading Assets for Schedule Building`} />}

          {(this.state.picture_change || this.state.divisionsTool) && <div className={`overlay ${(this.state.picture_change || this.state.divisionsTool) ? 'show' : ''}`} style={{ position: 'fixed', zIndex: 100 }} />}

          <Animated isVisible={this.state.picture_change ? true : false} animationInDuration={200} animationOutDuration={200} animationIn="slideInRight" animationOut='slideOutRight' style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 300, zIndex: 200 }} className="bg-white ">
            {this.state.picture_change && <div className="d-flex flex-column align-self-center justify-content-center  my-auto">
              <div className="d-flex flex-row">
                <button className="btn btn-link align-self-center" onClick={() => this.setState({ picture_change: null })}><i className="fas fa-times black font-20" /></button>
              </div>
              <div className="bordered cover align-self-center" style={{ width: 100, height: 100, borderRadius: 50, background: `url(${this.state.picture_change.UserImage}) no-repeat center center` }}></div>
              <h4 className="montserrat black align-self-center mt-1 mx-3 text-center">{`${this.state.picture_change.NameFirst} ${this.state.picture_change.NameLast}`}</h4>
              {!this.state.picture_change.replacements && <section className="d-flex flex-column">
                <span className="m-4 font-10 text-center montserrat black align-self-center">{`Request ${this.state.picture_change.NameFirst} to replace his profile picture?`}</span>
                <button className="btn btn-success mx-3" onClick={this.onRequestPictureChange}>Yes, request it</button>
                <button className="btn btn-link red" onClick={() => this.setState({ picture_change: null })}>No, cancel</button>
              </section>}

              {this.state.picture_change.replacements && <section className="d-flex flex-column">
                <span className="m-4 font-10 text-center montserrat black align-self-center">{`${this.state.picture_change.NameFirst} was requested to change its picture by ${this.state.picture_change.replacements.NameFirst} ${this.state.picture_change.replacements.NameLast} on ${moment(this.state.picture_change.replacements.DateCreated).format('MMM Do YYYY')}`}</span>
              </section>}
            </div>}
          </Animated>

          <Animated animateOnMount={false} isVisible={this.state.divisionsTool ? true : false} animationInDuration={200} animationOutDuration={200} animationIn="slideInRight" animationOut="slideOutRight" style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 600, zIndex: 200, borderLeft: '1px solid black' }} className="bg-white">

            <div className="d-flex flex-row">
              <button className="btn btn-link align-self-center" onClick={() => this.setState({ divisionsTool: false })}><i className="fas fa-times black font-20" /></button>
              <span className="ml-auto align-self-center black">Division Age Groups Setup</span>
              <i className="ml-auto" />
            </div>

            <div style={{ position: 'absolute', top: 50, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column w-100">
              {divisions.map((division, index) => <DivisionGroupName key={index} division={division} divisionsActions={this.props.divisionsActions} idSeason={this.props.params.idSeason} />)}
            </div>


          </Animated>
        </div>
      </div>}
    </section>
  }
}

export default PrintRoster;
