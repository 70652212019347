import React from 'react';
import { Link } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody, FormGroup, Label, Input } from 'reactstrap';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';

class TeamListItem extends React.Component {

    state = {
        team: null,
        addingPoints: false,
        filteredLeaguePoints: null,
        positions: null,
        saving: false, isGenderOpen: false
    }

    // Lifecycle    
    componentWillMount() {

        const { leaguePoints, team, season, positions } = this.props;
        const filteredLeaguePoints = _.filter(leaguePoints, lp => lp.IdTournamentType === season.IdTournamentType);
        this.setState({ team, filteredLeaguePoints, positions }, () => {

            // I don't like this routine.. might be refactored to improve code quality
            const { team, positions, filteredLeaguePoints } = this.state, { leaguePoints, season } = this.props;

            positions.forEach(position => {
                position.Points = (_.find(filteredLeaguePoints, lp => lp.IdTournamentDivisionResults === position.IdTournamentDivisionResults) || {}).Points;
            });

            team.LeaguePoints = _.find(leaguePoints, lp =>
                (lp.IdTournamentTypeDivisionPoints === team.IdTournamentTypeDivisionPoints &&
                    lp.IdTournamentType === season.IdTournamentType)) || { TournamentDivisionResults: 'Not Set' };
            this.setState({ team, positions });

            //
        });
    }
    componentDidMount() {
        const { team, division } = this.props;

        // Default to the division's gender
        if (!team.IdGender) {
            team.Gender = division.Gender;
            team.IdGender = division.IdGender;
        }

        this.setState({ team }, () => {
            if (team.editing) {
                this.txtName.focus();
                this.txtName.select();
            }
        });
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { team } = this.state;
        if (team.editing) {
            this.txtName.focus();
            this.txtName.select();
        }
    }

    //

    save = () => {
        const { index } = this.props, { team } = this.state;

        request[team.IdTeamDivision ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/teams/${team.IdTeamDivision || team.IdDivision}`)
            .send({
                teamName: this.txtName.value || `Team #${index + 1}`,
                idGender: team.IdGender,
            })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                if (!team.IdTeamDivision) {
                    team.IdTeamDivision = data.body.IdTeamDivision;
                    this.setState({ team });
                }
            });

        team.Name = this.txtName.value || `Team #${index + 1}`;
        team.editing = false;
        this.setState({ team });
    }

    edit = () => {
        const { team } = this.state;
        team.editing = true;
        this.setState({ team });
    }


    selectGender = (gender) => {
        const { team } = this.state;
        team.Gender = gender.Name;
        team.IdGender = gender.IdGender;
        this.setState({ team });
    }

    selectTournamentTypeDivisionPoints = ({ target: { value } }) => {
        const { team, filteredLeaguePoints } = this.state;

        //IdTournamentDivisionResults        
        const lp = _.find(filteredLeaguePoints, lp => lp.IdTournamentDivisionResults === value);
        if (lp || value === '0') {
            team.IdTournamentDivisionResults = value;
            team.LeaguePoints = lp;
            this.setState({ team });
        } else {
            alert('You have not set Points for this Placement at the league level');
        }


    }

    savePoints = (e) => {
        e.preventDefault && e.preventDefault();
        const { positions, teamActions, season } = this.props, { team } = this.state;
        team.Position = team.IdTournamentDivisionResults ?
            (_.find(positions, p => p.IdTournamentDivisionResults === team.IdTournamentDivisionResults) || { TournamentDivisionResults: 'Not Set' }).TournamentDivisionResults :
            'Not Set';
        team.Bonus = this.txtBonus.value;
        teamActions && teamActions.updateResults(team.IdTeamDivision,
            (team.LeaguePoints || {}).IdTournamentTypeDivisionPoints, team.Bonus, season.IdSeason);
        this.toggleAddingPoints();
    }

    toggleGender = () => this.setState({ isGenderOpen: !this.state.isGenderOpen });
    toggleAddingPoints = () => this.setState({ addingPoints: !this.state.addingPoints });

    render() {
        const { index, fnCancel, season } = this.props, { team, positions } = this.state;
        const genders = config.genders;

        return (
            <li className="container-fluid no-margin black py-2" style={{ borderTop: '1px solid gray'}} key={team.IdTeam}>
                {team && <form>
                    <div className="row">
                        <div className="col-3 align-self-center">
                            {!team.editing && (
                                <div className="d-flex flex-row">
                                    <Link to={`/season/${season.IdSeason}/team/${team.IdTeam}`} className={`btn btn-link align-self-center text-left blue font-${(team.TeamName || team.Name).length > 30 ? '8' : '10'}`}>{(index + 1)}. {(team.TeamName || team.Name).length > 35 ? ((team.TeamName || team.Name).substring(0, 34) + '...') : (team.TeamName || team.Name)}</Link>
                                    
                                    {team.IdTeamDivision && (
                                        <button onClick={this.edit} type="button" className="btn btn-sm btn-link ml-auto align-self-center"><i className="fa fa-pencil" /></button>)}
                                </div>)}
                            {team.editing && (
                                <input type="text" placeholder="Team Name" defaultValue={team.TeamName || team.Name} ref={(input) => this.txtName = input} className="form-control control-sm no-border-radius" />
                            )}
                        </div>

                        <div className="col-1 text-center align-self-center">
                            {!team.editing && <span>{team.Gender}</span>}
                            {team.editing && <Dropdown className="align-self-center" size="sm" isOpen={this.state.isGenderOpen} toggle={this.toggleGender}>
                                <DropdownToggle color="info" caret>
                                    {team.Gender || 'Select'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {genders.map((gender, i) =>
                                        <DropdownItem active={team.IdGender === gender.IdGender ? true : false} key={i} onClick={() => this.selectGender(gender)}>
                                            {gender.Name}
                                        </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>}
                        </div>

                        {/* Results */}
                        <div className="col-2 align-self-center">

                            <button type='button' onClick={this.toggleAddingPoints} id={`btnAddPoints-${team.IdTeam}`} className={`btn no-margin no-padding btn-link blue margin-left-half align-self-center underlined`}>{(team.LeaguePoints || { TournamentDivisionResults: 'Not Set' }).TournamentDivisionResults}</button>
                            {team.LeaguePoints && <div>{team.LeaguePoints.Points || 0} pts {team.Bonus ? ` (+ ${team.Bonus} bonus)` : ''}</div>}

                            <Popover placement="bottom" isOpen={this.state.addingPoints} target={`btnAddPoints-${team.IdTeam}`} toggle={this.toggleAddingPoints}>
                                <PopoverBody className="d-flex flex-column">
                                    <form>
                                        <div className="">
                                            <FormGroup className="no-padding margin-top-half">
                                                <Label>Placement</Label>
                                                <Input type="select" name="seasonType" value={(team.LeaguePoints || {}).IdTournamentDivisionResults} onChange={this.selectTournamentTypeDivisionPoints}>
                                                    <option value={0}>None</option>
                                                    {positions && positions.map((position, i) => <option value={position.IdTournamentDivisionResults} key={i}>
                                                        {position.TournamentDivisionResults} - {position.Points ? `${position.Points} pts` : '(*no points set*)'}
                                                    </option>)}
                                                </Input>
                                            </FormGroup>
                                            <FormGroup className="no-padding margin-top-half">
                                                <Label>Bonus Points</Label>
                                                <input defaultValue={team.Bonus} type="number" className="form-control" ref={i => this.txtBonus = i} />
                                            </FormGroup>
                                        </div>
                                        <div className="d-flex flex-row margin-top-half">
                                            <button type="submit" className="btn btn-success btn-sm ml-auto" onClick={this.savePoints}>Save</button>
                                        </div>
                                    </form>
                                </PopoverBody>
                            </Popover>
                        </div>

                        {!team.editing && <div className="col-1 text-center align-self-center">
                            {team.IdTeamDivision && <span className="align-self-center">{team.IdTeamDivision}</span>}
                            {!team.IdTeamDivision && !team.editing && <i className="fa fa-spin fa-2x fa-hourglass" />}
                        </div>}
                        {team.editing && <div className="col-1 text-center align-self-center">
                            <button onClick={this.save} type="submit" className="btn btn-sm btn-success ml-auto"><i className="fa fa-check" /> Save</button></div>}
                        <div className="col-3 align-self-center">{team.AdminName}{team.AdminRole ? ` - (${team.AdminRole})` : ''}</div>
                        <div className="col-2 d-flex flex-row">
                            <div className="d-flex flex-column align-self-center  font-9">
                                <span>{team.AdminPhoneNumber}</span>
                                <span>{team.AdminEmailAddress}</span>
                            </div>
                            {team.IdTeamDivision && <Link to={`/season/${season.IdSeason}/team/${team.IdTeam}`} className="btn btn-link ml-auto" ><i className="fa fa-arrow-right blue" /></Link>}
                            {!team.IdTeamDivision && team.editing && <button type="button" className="btn btn-link ml-auto" onClick={() => fnCancel(index)}><i className="fa fa-times red" /></button>}
                        </div>
                    </div>
                </form>}
            </li>
        )
    }
}

export default TeamListItem;
