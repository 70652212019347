import React from 'react';
import './css.css';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import request from 'superagent';
import config from '../../../../config';
import Sheet from './sheet';
import Sheetx3 from './Sheetx3';
import { Animated } from 'react-animated-css';

class PrintAwards extends React.Component {

  state = {
    bootstrapped: false,
    filters: {},
    appliedFilters: {},
    ddDateOpen: false,
    ddTimeOpen: false,
    season: null,
    divisions: [],
    teams: [],
    fetchingGames: false,
    fetchedOnce: false,
    fullSheet: true,
    sheetMode: 1,
    downloading: false
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Print Awards Cards',
      main: this.props.season.Name || null
    });

    // Fetch season
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.fetchBracketGroupTypes && this.props.fetchBracketGroupTypes();
    this.props.divisionsActions && this.props.divisionsActions.fetchPrintableDivisions(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Print Awards Cards',
        main: nextProps.season.Name || null
      });
      this.setState({ season: nextProps.season });
    }
    if (nextProps.divisions) {
      this.setState({ bootstrapped: true, divisions: nextProps.divisions });
    }
  }

  preview = (e) => {

    e.preventDefault();
    const { divisions, fetchingGames, cat } = this.state;
    if (fetchingGames) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => {
        if (cat) return (t.selected && t.IdGameBracketGroupType === cat);
        return t.selected;
      })
      .value();

    this.setState({ fetchingGames: true }, () => {
      request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
        .send({
          teams
        })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(data => {
          this.setState({ teams: data.body.teams, fetchingGames: false, fetchedOnce: true });
        });
    });
  }

  download = () => {

    const { divisions, downloading, sheetMode, cat } = this.state;
    if (downloading) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => {
        if (cat) return (t.selected && t.IdGameBracketGroupType === cat);
        return t.selected;
      })
      .value();

      if (!teams.length) {
        alert('No teams were selected'); return;
      }

    this.setState({
      downloading: true
    }, () => {

      // Fetch games with filters applied
      request.post(`${config.apiEndpoint}/api/v4/roster/awards/download`)
        .send({ teams, sheetMode })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .responseType('arraybuffer')
        .then((data) => {
          let file = new Blob([data.body], { type: 'application/pdf' });
          require("downloadjs")(file, 'Awards.pdf');
          this.setState({ downloading: false, });
        });
    });
  }

  toggleSeason = () => {
    const { season, divisions } = this.state;
    season.selected = !season.selected;
    divisions.forEach(division => {
      division.selected = season.selected;
      division.Teams.forEach(team => {
        team.selected = season.selected;
      });
    });
    this.setState({ season, divisions });
  }

  toggleDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].selected = !divisions[index].selected;
    divisions[index].Teams.forEach(team => {
      team.selected = divisions[index].selected;
    });
    this.setState({ divisions });
  }

  toggleTeam = (di, ti) => {
    const { divisions } = this.state;
    divisions[di].Teams[ti].selected = !divisions[di].Teams[ti].selected;
    divisions[di].selected = _.find(divisions[di].Teams, (team) => !team.selected) ? false : true;
    this.setState({ divisions });
  }

  toggleExpandDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].expanded = !divisions[index].expanded;
    this.setState({ divisions });
  }

  onCat = cat => this.setState({ cat: this.state.cat === cat ? null : cat });

  render() {

    const { season, fetchingGames, fetchedOnce, divisions, downloading, teams, cat } = this.state, { bracket_group_types } = this.props;

    const groups = _.chunk(teams, 3);

    return <section id="print-roster" className="canvas white" style={{ backgroundColor: '#121b32'}}>

      <Animated className="filters-wrapper d-flex flex-row idented margin-top-half" animationIn="fadeInDown">
        { /* PREVIEW BUTTON */}

        {bracket_group_types && bracket_group_types.map((t, i) => <button type="button" className={`btn btn-${cat === t.IdGameBracketGroupType ? 'info' : 'link'}`} onClick={() => this.onCat(t.IdGameBracketGroupType)}>
          <i className={`fas ${t.GameBracketGroupType} fa-medal mr-2`}></i>
          <span>{t.GameBracketGroupType}</span>
        </button>)}

        <button type="submit" key="previewbtn" onClick={this.preview} className="btn btn-success margin-right ml-auto">
          <i className="fa fa-eye" />
          {' '}
          <span>{fetchingGames ? 'Fetching' : 'Preview'}</span>
        </button>
      </Animated>

      <section className="tree-section" style={{ overflowY: 'auto' }}>

        { /* Season */}
        {season && <button onClick={() => this.toggleSeason()} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal' }}>
          <i className={`${season.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
          {' '}
          {season.Name || season.SeasonName}
        </button>}

        { /* Divisions | Teams */}
        {divisions && _.filter(divisions, d => d.Teams.length).map((division, i) => (
          <section key={i}>
            <button onClick={() => this.toggleDivision(i)} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal', marginLeft: '1em' }}>
              {division.Teams.length > 0 &&
                <button className="btn btn-sm btn-link white" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.toggleExpandDivision(i)}}>
                  <i className={`fa fa-caret-${division.expanded ? 'up' : 'down'}`} />
                </button>
              }
              <i className={`${division.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
              {' '}
              {division.Name || division.IdDivision}
              {' '}
            </button>
            {division.expanded && _.chain(division.Teams).filter(t => {
              if (cat) return t.IdGameBracketGroupType === cat;
              return true;
            }).value().map((team, j) => (
              <button onClick={() => this.toggleTeam(i, j)} key={j} className="btn btn-link text-left btn-sm block" style={{ whiteSpace: 'normal', marginLeft: '4em' }}>
                <i className={`${team.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
                {' '}
                {team.Name || team.TeamName}  <i className={`font-8 ml-2 fas ${team.GameBracketGroupType || 'hide'} fa-medal`} />
              </button>))}
          </section>))}
      </section>

      { /* Sheets */}
      <section className={`sheet-section ${true ? 'bg-gray-dark inner-shadow' : null}`} style={{ overflowY: 'auto', padding: '0 0 100px 0' }}>

        {/* Standard Letter */}
        {teams.length > 0 && !fetchingGames && this.state.sheetMode === 2 && teams.map((team, i) => <Sheet key={i} team={team} season={season} />)}

        {/* 3v3 - 3x Sheet */}
        {teams.length > 0 && !fetchingGames && this.state.sheetMode === 1 && groups.map((group, i) => <Sheetx3 key={i} teams={group} season={season} />)}


        {fetchingGames && <Loader key="loader" message={`Fetching Games, please wait...`} />}
        {!fetchingGames && fetchedOnce && !teams.length && <div className="d-flex justify-content-center centered-message">
          <h1 className="white align-self-center block text-center">No roster were found!</h1>
        </div>}
      </section>

      { /* Print controls */}
      {divisions.length > 0 && !fetchingGames && (
        <section style={{ position: 'fixed', bottom: 0, right: 0 }} className="d-flex flex-column p-2">
          <button className="btn btn-link btn-sm text-right" onClick={() => this.setState({ sheetMode: 1 })}>
            <i className={`${this.state.sheetMode === 1 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
            <span className="idented-half shadow">3x Sheet</span>
          </button>
          <button className="btn btn-link btn-sm text-right" onClick={() => this.setState({ sheetMode: 2 })}>
            <i className={`${this.state.sheetMode === 2 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
            <span className="idented-half shadow">1x Sheet</span>
          </button>
          <button className="btn btn-success btn-lg margin-top" type="button" onClick={this.download}>
            <i className="fas fa-download" />
            {' '}<span>{downloading ? 'Downlading...' : 'Download'}</span> sheets</button>
        </section>)}
    </section>
  }
}

export default PrintAwards;
