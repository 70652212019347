import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';
import _ from 'lodash';
import { parseMilliseconds } from '../../../helpers';

class VideoTile extends React.Component {
    state = {
        microTransac: false,
        seasons: []
    }

    hearthbeat = () => {
        const { video } = this.state;
        axios.get(`${config.apiEndpoint}/api/v4/video/${video.idVideo}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('sportslogic.authtoken')}` },
        }).then(({ data: { video } }) => {
            this.setState({ video }, () => {
                if (video.uploadComplete < 99 || !video.thumbnail) {
                    setTimeout(() => { this.hearthbeat(); }, 3000)
                }
            });
        });
    }

    // Lifecycle
    componentWillMount() {
        const { video, seasons, height = 'auto' } = this.props;

        this.setState({ video, seasons, height, user: fakeAuth.getUser() }, () => {            
            if ((video && video.uploadComplete < 99) || !video.thumbnail) {
                setTimeout(() => { this.hearthbeat(); }, 3000)
            }
        });
    }
    componentWillReceiveProps = ({ video }) => this.setState({ video }, () => {
        const { video } = this.state;
        if (video.uploadComplete < 99 || !video.thumbnail) {
            setTimeout(() => { this.hearthbeat(); }, 3000)
        }
    });
    componentDidMount = () => {

    }
    //

    onEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { video, user } = this.state;
        if (video.idUser === user.IdUser) {
            this.props.onEdit && this.props.onEdit(this.props.video);
        }
    }

    onShare = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { video } = this.state;
        this.props.onShare && this.props.onShare(video);
    }

    onClick = () => {
        const { video } = this.state;
        if (video.uploadComplete < 99) { return; }
        this.props.onClick && this.props.onClick(video);
    }

    selectSeason = e => { }
    render() {

        const { video = {} } = this.state, { idTeam, index, family } = this.props;

        let family_member = null;
        if (video.playerTaggedId) {
            family_member = _.find(family, f => f.IdUser === video.playerTaggedId);
        }

        let root_link = _.chain(this.props.route.path.split('/')).flatten().compact().first().value();

        return <Link onClick={this.onClick} to={idTeam ? `/${root_link}/team/${idTeam}/video/${video.idVideo}` : `/sqwad/family/video/${video.idVideo}`} className="mr-4 mb-4" style={{ maxWidth: 300 }}>
            <Animated animationIn="fadeIn" className="tile card shadow hoverable" animationInDelay={25 * index} style={{ borderRadius: 10, overflow: 'hidden' }}>
                <div className="w-100 h-100">

                    <div className='cover' style={{ height: 150, width: 300, background: `url(${video.thumbnail}) no-repeat center center`, backgroundColor: 'black', position: 'relative' }}>

                        <span className="white shadow font-10" style={{ position: 'absolute', bottom: 5, right: 10 }}>{parseMilliseconds(video.durationInSeconds * 1000)}</span>

                        {family_member !== null && family_member !== undefined && <div className="cover" style={{ position: 'absolute', bottom: 5, left: 10, width: 30, height: 30, borderRadius: 15, border: `1px solid white`, background: `url(${family_member.UserImage}?full=80) no-repeat center center black` }} />}
                    </div>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column p-2" onClick={this.onEdit}>
                            <span className="black font-12 trimed-text line1">{video.name || 'No Name'}</span>
                            <span className="gray font-8">{moment(video.createDate).format('MMM Do YYYY')}</span>
                            <span className={`${fakeAuth.getUser().IdUser === video.idUser ? 'blue' : 'white'} font-10`}>Manage Video</span>
                        </div>
                        <div className='ml-auto align-self-end btn btn-link' onClick={this.onShare}>
                            <i className="fas fa-ellipsis-v black font-12" />
                        </div>
                    </div>

                    {(video.uploadComplete < 99 || !video.thumbnail) && <div style={{ backgroundColor: 'rgba(157, 157, 157, 0.5)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} className="d-flex flex-row justify-content-center" onClick={this.onEdit}>
                        <div className="d-flex flex-column justify-content-center">
                            {video.uploadComplete < 99 && <span className="font-18 white align-self-center text-center line1">Your video is being processed</span>}
                            {video.uploadComplete >= 99 && !video.thumbnail && <span className="font-18 white align-self-center text-center line1">Thumbnails are being generated</span>}
                            {video.uploadComplete < 99 && <span className="big-font white align-self-center">{video.uploadComplete}%</span>}
                        </div>
                    </div>}

                </div>

                {((video.idTeam && fakeAuth.getUser().IdUser === video.idUser) || video.IS_FAMILY_VIDEO) && <span style={{ position: 'absolute', top: -1, right: -1 }} className="icon-Diagonal-Ribbon-A_icon-1 jeff_blue font-40" />}
            </Animated>
        </Link>
    }
}

export default VideoTile;
