import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';

class TeamSwap extends React.Component {

    state = {
        flighted_teams: null,
        all_teams: null,
        IdTeamDivision_Source: null,
        IdTeamDivision_Destination: null
    }

    componentWillMount() {
        const { teams, all } = this.props;        
        this.setState({ flighted_teams: teams, all_teams: all });
    }

    onChangeTeamSource = obj => {
        let id = obj ? obj.value : null;
        this.setState({ IdTeamDivision_Source: id });
    }
    onChangeTeamDestination = obj => {
        let id = obj ? obj.value : null;
        this.setState({ IdTeamDivision_Destination: id });
    }

    onSwap = () => {
        const { toggle } = this.props;
        const { team1, team2, swapping } = this.state;
        if (swapping) return;
        this.setState({ swapping: true }, () => {
            if (team1 && team2 && (team1.IdTeamDivision !== team2.IdTeamDivision)) {
                // Proceed                
                request.post(`${config.apiEndpoint}/api/v4/schedule/teams/swap`)
                    .send({ IdTeamDivision_Destination: team2.IdTeamDivision, IdTeamDivision_Source: team1.IdTeamDivision })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => {
                        if (data.body.success) {
                            toggle && toggle();
                            this.props.seasonActions &&
                                this.props.seasonActions.fetchGames(this.props.idSeason);
                        } else {
                            alert('There was a problem')
                            this.setState({ swapping: false })
                        }
                    });
            }
        });
    }

    render() {

        const { flighted_teams, team1, team2 } = this.state;

        let teams_dropdown = [];
        if (flighted_teams) {
            _.chain(flighted_teams)
                .filter(t => t.RegistrationDivision)
                .groupBy(d => d.IdDivision)
                .values()
                .value().forEach(g => {
                    teams_dropdown.push({ header: _.first(g).RegistrationDivision.Name });
                    _.sortBy(g, t => t.TeamName).forEach(team => {
                        teams_dropdown.push(team);
                    });
                });
        }

        return <section className="">
            <div className="d-flex flex-row">
                <div className="w-100 h-100  p-2 align-self-center">

                    <Dropdown className="align-self-center btn-link w-100" key="4" isOpen={this.state.isTeams1FilterOpen} toggle={() => this.setState({ isTeams1FilterOpen: !this.state.isTeams1FilterOpen })} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                        <DropdownToggle color="default" caret className="w-100 toUpper font-10">
                            {team1 ? `${(team1.Name || team1.TeamName)} (${team1.FlightName})` : 'Pick team #1'}
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {teams_dropdown.map((team, i) => {
                                if (team.header) return <DropdownItem className="blue" header key={i}>----------- {team.header}</DropdownItem>
                                else return <DropdownItem className="toUpper font-8" active={(team1 && team1.IdTeam === team.IdTeam)} key={i} onClick={() => this.setState({ team1: team })}>
                                    {team.Name || team.TeamName} ({team.FlightName})
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>

                </div>
                <div className="w-100 d-flex flex-column justify-content-center  p-4 text-center bg-whiteish">
                    <span className="font-10 line1 align-self-center">SWAP the spot of both teams in all their scheduled games</span>
                    <button onClick={this.onSwap} className={`btn align-self-center btn-lg mt-2 btn-${this.state.team1 && this.state.team2 && (this.state.team1.IdTeamDivision !== this.state.team2.IdTeamDivision) ? 'success' : 'default'}`}>{this.state.swapping ? 'Swapping, please wait...' : 'Proceed to swap teams'}</button>
                </div>
                <div className="w-100 h-100  p-2 align-self-center">

                    <Dropdown className="align-self-center btn-link w-100" key="4" isOpen={this.state.isTeams2FilterOpen} toggle={() => this.setState({ isTeams2FilterOpen: !this.state.isTeams2FilterOpen })} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                        <DropdownToggle color="default" caret className="w-100 toUpper font-10">
                            {team2 ? `${(team2.Name || team2.TeamName)} (${team2.FlightName})` : 'Pick team #2'}
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {teams_dropdown.map((team, i) => {
                                if (team.header) return <DropdownItem className="blue" header key={i}>----------- {team.header}</DropdownItem>
                                else return <DropdownItem className="toUpper font-8" active={(team2 && team2.IdTeam === team.IdTeam)} key={i} onClick={() => this.setState({ team2: team })}>
                                    {team.Name || team.TeamName} ({team.FlightName})
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>

                </div>
            </div>
        </section>
    }
}

export default TeamSwap;