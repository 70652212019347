import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import { humanize_user } from '../../helpers';

export function* getPaymentsSaga() {
    yield* takeEvery("PAYMENTS/FETCH", function* (action) {
        try {            
            const result = yield call(({ idUser, idSeason, mode }) => {
                return request.get(`${config.apiEndpoint}/api/v6/payments/balance/${idUser}/${idSeason}/${mode}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => { return (JSON.parse(data.text)) });
            }, action);            

            if (result && result.success) {
                
                

                // Decrypt
                [...result.payments, ...result.dues, ...result.pastDues].forEach(u => humanize_user(u));
                //

                yield put({
                    type: 'PAYMENTS/SET',
                    payments: result.payments
                });
                yield put({
                    type: 'BALANCE/SET',
                    balance: result.balance
                });
                yield put({
                    type: 'DUES/SET',
                    dues: result.dues
                });
                yield put({
                    type: 'PAST_DUES/SET',
                    pastDues: result.pastDues
                });
                yield put({
                    type: 'AUTO_PAY_SETTINGS/SET',
                    autoPaySettings: result.autoPaySettings
                });
            } else {
                yield put({
                    type: 'PAYMENTS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PAYMENTS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getProgramsSaga() {
    yield* takeEvery("REGISTRATION_PROGRAMS/FETCH", function* (action) {
        try {
            const result = yield call(({ idSeason }) => {
                return request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'PAYMENT_PLANS/SET',
                    plans: result.programs
                });
                yield put({
                    type: 'ADJUSTMENTS/ZIP/SET',
                    adjustments: result.zipcodeAdjustments
                });
                yield put({
                    type: 'ADJUSTMENTS/TRYOUTS/SET',
                    tryouts: result.tryouts
                });
                yield put({
                    type: 'ADJUSTMENTS/ADJUSTMENTS/SET',
                    adjustments: result.adjustments
                });
                yield put({
                    type: 'ADJUSTMENTS/FAMILY_ADJUSTMENT/SET',
                    familyAdjustments: result.familyAdjustments
                });
            } else {
                yield put({
                    type: 'PAYMENT_PLANS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PAYMENT_PLANS/FETCH_FAILED',
                e
            });
        }
    });
}

//
export function* deletePaymentDueSaga() {
    yield* takeEvery("DUES/DELETE", function* (action) {
        try {
            yield call(({ IdCatalogItemPaymentPlan }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/paymentPlans/due/${IdCatalogItemPaymentPlan}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);
        } catch (e) {
            yield put({
                type: 'DUES/DELETE_FAILED',
                e
            });
        }
    });
}

export function* deleteFamilyAdjustmentIndexSaga() {
    yield* takeEvery("FAMILY_ADJUSTMENT/REMOVE_INDEX", function* (action) {
        try {
            yield call(({ IdCatalogItemPriceAdjustmentByItemIndex }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/paymentPlans/family/index/${IdCatalogItemPriceAdjustmentByItemIndex}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);
        } catch (e) {
            yield put({
                type: 'DUES/DELETE_FAILED',
                e
            });
        }
    });
}


export function* deletePaymentSaga() {
    yield* takeEvery("PAYMENT/DELETE", function* (action) {
        try {
            yield call(({ payment }) => {
                return request.delete(`${config.apiEndpoint}/api/v4/payments/${payment.IdOrderItemCharge}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);
        } catch (e) {
            yield put({
                type: 'DUES/DELETE_FAILED',
                e
            });
        }
    });
}