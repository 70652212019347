import React from 'react';
import CouponModal from '../common/modals/Coupon';
import CouponMembershipModal from '../common/modals/MembershipCoupon';
import Menu from '../leagues/menu';
import { Alert } from 'reactstrap';
import _ from 'lodash';
import request from 'superagent';
import config from '../../config';
import moment from 'moment';
import { Jumbotron } from 'reactstrap';
import { Animated } from 'react-animated-css';

class Coupons extends React.Component {

    state = {
        creating: false,
        selectedCoupon: null,
        searchText: null
    }
    componentWillMount() {
        const { setTitle, leagueActions, params } = this.props;
        setTitle && setTitle({ main: 'Coupons' });
        leagueActions && leagueActions.fetchCoupons(params.idLeague)
    }

    componentWillReceiveProps = nextProps => {
        const { coupons } = nextProps;
        this.setState({ coupons })
    }

    toggleCreating = () => this.setState({ creating: !this.state.creating });

    doDelete = () => {
        const { deleting } = this.state, { params, leagueActions } = this.props;
        request.del(`${config.apiEndpoint}/api/v4/coupons/${deleting.IdCoupon}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(() => { leagueActions && leagueActions.fetchCoupons(params.idLeague); });
        this.setState({ deleting: false })
    }

    onChange = () => this.setState({ searchText: this.txtSearch.value });

    render() {
        const { creating, error, coupons, all_expired } = this.state,
            { couponActions, params } = this.props,
            { idLeague } = params;

        let expired_coupons = _.chain(coupons || [])
            .filter((c, index) => {
                if (!all_expired && index > 4) return false;
                return true
            })
            .filter(c => {
                if (this.state.searchText) return `${c.CouponName} ${c.EmailAddressString}`.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1;
                return true;
            })
            .filter(c => c.Archived || (c.validUntil && moment(c.validUntil).isBefore(moment())))
            .sortBy('dateCreated')
            .tap(arr => { arr.reverse(); })
            .value(),

            non_expired_coupons = _.chain(coupons || [])
                .filter(c => c.IdCouponType === 1)
                .filter(c => {
                    if (this.state.searchText) return `${c.CouponName} ${c.EmailAddressString}`.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1;
                    return true;
                })
                .reject(c => c.Archived || (c.validUntil && moment(c.validUntil).isBefore(moment())))
                .sortBy('dateCreated')
                .tap(arr => { arr.reverse(); })
                .value(),

            non_expired_membership_coupons = _.chain(coupons || [])
                .filter(c => c.IdCouponType === 9)
                .filter(c => {
                    if (this.state.searchText) return `${c.CouponName} ${c.EmailAddressString}`.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1;
                    return true;
                })
                .reject(c => c.Archived || (c.validUntil && moment(c.validUntil).isBefore(moment())))
                .sortBy('dateCreated')
                .tap(arr => { arr.reverse(); })
                .value();

        return (
            <section>

                <Menu idLeague={idLeague} option="coupons" />

                {coupons && <div className="w3-container mt-4" style={{ marginLeft: 90 }}>

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    {/* TITLE */}
                    <div className="w3-container mt-4">
                        <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                            <h1>Coupon Codes</h1>
                        </div>
                    </div>
                    <div className="pl-2 pr-2" >
                        <div className="w3-container mt-4">
                            <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                                <div className="w-100  d-flex flex-row">
                                    <input placeholder="Search by Code or Email" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />
                                    <button onClick={() => this.setState({ selectedCoupon: null }, this.toggleCreating)} style={{ width: 200, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                                        <i className="fas fa-plus margin-right-half" />Add Coupon</button>
                                    {this.props.params.idLeague === '1' && <button onClick={() => this.setState({ selectedCoupon: null, creating_membership: true })} style={{ width: 350, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                                        <i className="fas fa-plus margin-right-half" />Add Membership Coupon</button>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* REGISTRATION COUPONS */}
                    <div className="w3-content d-flex flex-column montserrat" style={{ maxWidth: 1285 }}>
                        <div className="clearfix">

                            {non_expired_coupons.length === 0 && <Jumbotron className="w-100 mt-4 bg-white d-flex flex-column justify-content-center">
                                <i className="far fa-meh-blank font-30 black align-self-center" />
                                <span className="black font-20 align-self-center">There are no active Coupon Codes created{(this.txtSearch && this.txtSearch.value) ? ` named like '${this.txtSearch.value}'` : ''}</span>
                                <button onClick={this.toggleCreating} style={{ width: 200, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                                    <i className="fas fa-plus margin-right-half" />Add Coupon</button>
                            </Jumbotron>}

                            {non_expired_coupons.map((coupon, index) => <figure key={index} style={{ width: 230, height: 215, position: 'relative' }} className="float-left d-flex flex-column justify-content-center m-2">
                                <i className="fas fa-ticket-alt green" style={{ fontSize: 200, position: 'absolute', top: 10 }} />
                                <span className="white align-self-center font-8 line1" style={{ zIndex: 1 }}>Registration</span>
                                <span className="white align-self-center font-12 line1 toBold mt-2" style={{ zIndex: 1 }}>{coupon.CouponName}</span>
                                <span className="white align-self-center font-20" style={{ zIndex: 1 }}>{coupon.AmountPerCoupon ? '$' : ''}{coupon.AmountPerCoupon || coupon.PercentPerCoupon}{coupon.AmountPerCoupon ? '' : '%'} OFF</span>
                                {coupon.IdSeason && <span className="white align-self-center font-8 text-center line1" style={{ zIndex: 1 }}>{coupon.SeasonName}</span>}
                                {coupon.validUntil && <span className="white align-self-center font-8 " style={{ zIndex: 1 }}>Available until {moment(_.first(coupon.validUntil.split('T')), 'YYYY-MM-DD').format('MMM Do YYYY')}</span>}
                                <span className="white align-self-center font-8 line1" style={{ zIndex: 1 }}>({`Used ${coupon.Usages.length} times${coupon.MaxNumberOfUsers ? ` out of ${coupon.MaxNumberOfUsers}` : ''}`})</span>
                                <button className='w-100' style={{ position: 'absolute', top: 0, bottom: 40, zIndex: 1 }} onClick={() => this.setState({ selectedCoupon: coupon, creating: true })} />
                                <div className="d-flex flex-row w-100" style={{ position: 'absolute', bottom: 0 }}>
                                    <button onClick={() => this.setState({ selectedCoupon: coupon, creating: true })} className="btn btn-sm btn-link ml-auto margin-right-half align-self-center"><i className="fas fa-pencil-alt black font-16" /></button>
                                    <button onClick={() => this.setState({ deleting: coupon })} className="btn btn-sm btn-link align-self-center margin-right-half"><i className="fa fa-times red font-16" /></button>
                                </div>
                            </figure>)}

                        </div>
                    </div>

                    {/* MEMBERSHIP COUPONS */}
                    {this.props.params.idLeague === '1' && <div className="w3-content  d-flex flex-column montserrat" style={{ maxWidth: 1285 }}>
                        <div className="clearfix">

                            {non_expired_membership_coupons.length === 0 && <Jumbotron className="w-100 mt-4 bg-white d-flex flex-column justify-content-center">
                                <i className="far fa-meh-blank font-30 black align-self-center" />
                                <span className="black font-20 align-self-center">There are no active Membership Coupon Codes created{(this.txtSearch && this.txtSearch.value) ? ` named like '${this.txtSearch.value}'` : ''}</span>
                                <button onClick={() => this.setState({ selectedCoupon: null, creating_membership: true })} style={{ width: 300, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                                    <i className="fas fa-plus margin-right-half" />Add Membership Coupon</button>
                            </Jumbotron>}

                            {non_expired_membership_coupons.map((coupon, index) => <figure key={index} style={{ width: 230, height: 215, position: 'relative' }} className="float-left d-flex flex-column justify-content-center m-2">
                                <i className="fas fa-ticket-alt blue" style={{ fontSize: 200, position: 'absolute', top: 10 }} />
                                <span className="white align-self-center font-8 line1" style={{ zIndex: 1 }}>Membership</span>
                                <span className="white align-self-center font-12 line1 toBold mt-2" style={{ zIndex: 1 }}>{coupon.CouponName}</span>
                                {(coupon.AmountPerCoupon || coupon.PercentPerCoupon) !== null && <span className="white align-self-center font-20 line1" style={{ zIndex: 1 }}>{coupon.AmountPerCoupon || coupon.PercentPerCoupon}{coupon.AmountPerCoupon ? '$' : '%'} OFF</span>}
                                {coupon.PromoDays && <span className="white align-self-center font-20 line1" style={{ zIndex: 1 }}>{coupon.PromoDays} Days</span>}
                                {coupon.IdSeason && <span className="white align-self-center font-10 " style={{ zIndex: 1 }}>{coupon.SeasonName}</span>}
                                {coupon.validUntil && <span className="white align-self-center font-8 " style={{ zIndex: 1 }}>Available until {moment(_.first(coupon.validUntil.split('T')), 'YYYY-MM-DD').format('MMM Do YYYY')}</span>}
                                <span className="white align-self-center font-8 line1" style={{ zIndex: 1 }}>({`Used ${coupon.Usages.length} times${coupon.MaxNumberOfUsers ? ` out of ${coupon.MaxNumberOfUsers}` : ''}`})</span>
                                <button className='w-100' style={{ position: 'absolute', top: 0, bottom: 40, zIndex: 1 }} onClick={() => this.setState({ selectedCoupon: coupon, creating_membership: true })} />
                                <div className="d-flex flex-row w-100" style={{ position: 'absolute', bottom: 0 }}>
                                    <button onClick={() => this.setState({ selectedCoupon: coupon, creating_membership: true })} className="btn btn-sm btn-link ml-auto margin-right-half align-self-center"><i className="fas fa-pencil-alt black font-16" /></button>
                                    <button onClick={() => this.setState({ deleting: coupon })} className="btn btn-sm btn-link align-self-center margin-right-half"><i className="fa fa-times red font-16" /></button>
                                </div>
                            </figure>)}

                        </div>
                    </div>}

                    {expired_coupons && expired_coupons.length > 0 && <section>

                        <hr />

                        <div className="pl-2 pr-2" >
                            <div className="w3-container mt-4">
                                <div className="w3-content d-flex flex-row montserrat">
                                    <div className="w-100 black">
                                        <h1>Expired and|or Cancelled Codes</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w3-content  d-flex flex-column montserrat" style={{ maxWidth: 1285 }}>
                            <div className="clearfix">

                                {expired_coupons.map((coupon, index) => <figure key={index} style={{ width: 230, height: 215, position: 'relative' }} className="float-left d-flex flex-column justify-content-center m-2 pointer">
                                    <i className="fas fa-ticket-alt red" style={{ fontSize: 200, position: 'absolute', top: 10 }} />
                                    <span className="white align-self-center font-12 line1" style={{ zIndex: 1 }}>{coupon.CouponName}</span>
                                    <span className="white align-self-center font-20" style={{ zIndex: 1 }}>{coupon.AmountPerCoupon ? '$' : ''}{coupon.AmountPerCoupon || coupon.PercentPerCoupon}{coupon.AmountPerCoupon ? '' : '%'} OFF</span>
                                    <span className="white align-self-center font-12 line1" style={{ zIndex: 1 }}>{coupon.Archived ? 'CANCELLED' : 'EXPIRED'}</span>
                                    <span className="white align-self-center font-8 line1" style={{ zIndex: 1 }}>Used {coupon.Usages.length} times</span>
                                </figure>)}

                            </div>
                            <button className="btn btn-link w-100 mb-4" onClick={() => this.setState({ all_expired: !this.state.all_expired })}>See {all_expired ? 'Less' : 'All'}</button>
                        </div>
                    </section>}

                </div>}

                {this.state.deleting && <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.5 }} />}
                <Animated animationInDuration={200} animationOutDuration={200} className="bg-white d-flex flex-row" animationIn='slideInRight' animationOut='slideOutRight' animateOnMount={false} isVisible={this.state.deleting ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, width: 300, right: 0 }}>
                    <div className="align-self-center d-flex flex-column">
                        <span className="align-self-center black font-12 px-4 text-center">Are you sure you want to cancel this coupon?</span>
                        <button onClick={this.doDelete} style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center mt-2">Yes, Cancel it</button>
                        <button onClick={() => this.setState({ deleting: false })} style={{ width: 150, borderRadius: 20 }} className="p-2 align-self-center">No, don't do it</button>
                    </div>
                </Animated>

                <CouponModal
                    toggle={this.toggleCreating}
                    coupon={this.state.selectedCoupon}
                    isOpen={creating}
                    couponActions={couponActions}
                    leagueActions={this.props.leagueActions}
                    couponTypes={this.props.couponTypes}
                    non_expired_coupons={non_expired_coupons}
                    idLeague={idLeague}
                    seasons={this.props.seasons} />

                <CouponMembershipModal
                    toggle={() => this.setState({ creating_membership: false })}
                    coupon={this.state.selectedCoupon}
                    isOpen={this.state.creating_membership}
                    couponActions={couponActions}
                    leagueActions={this.props.leagueActions}
                    couponTypes={this.props.couponTypes}
                    non_expired_coupons={non_expired_coupons}
                    idLeague={idLeague}
                    seasons={this.props.seasons} />

            </section >
        )
    }
}

export default Coupons;
