import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* creates() {
    yield* takeEvery("LOCKS/FETCH", function* (action) {
        try {
            const result = yield call(({ idSeason }) => {
                return request.get(`${config.apiEndpoint}/api/v4/roster/locks/${idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'LOCKS/SET',
                    locks: result.locks
                });
            } else {
                yield put({
                    type: 'LOCKS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'LOCKS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* createLockSaga() {
    yield* takeEvery("LOCKS/SAVE", function* (action) {
        try {
            const result = yield call(({ idSeason, dateStart }) => {
                return request.post(`${config.apiEndpoint}/api/v4/roster/locks/${idSeason}`)
                    .send({ dateStart })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action.lock);

            if (result && result.success) {
                const { lock } = action;
                lock.IdTeamUserRoleLock = result.IdTeamUserRoleLock;
                yield put({
                    type: 'LOCKS/COMPLETE',
                    lock
                });
            }
        } catch (e) {
            yield put({
                type: 'LOCKS/SAVE_FAILED',
                e
            });
        }
    });
}

export function* deleteLockSaga() {
    yield* takeEvery("LOCKS/DELETE", function* (action) {
        try {
            if (action.IdTeamUserRoleLock) {
                const result = yield call(({ IdTeamUserRoleLock }) => {
                    return request.del(`${config.apiEndpoint}/api/v4/roster/locks/${IdTeamUserRoleLock}`)
                        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                        .then((data) => {
                            return (JSON.parse(data.text))
                        });
                }, action);
                // optimistic
                if (result && result.success) {

                }
            }
        } catch (e) {
            yield put({
                type: 'LOCKS/DELETE_FAILED',
                e
            });
        }
    });
}

export function* fetchBracketGroupTypesSaga() {
    yield* takeEvery("BRACKET_GROUP_TYPES/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v4/common/bracket_group_types`)                    
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                const { groups } = result;                
                yield put({
                    type: 'BRACKET_GROUP_TYPES/SET',
                    groups
                });
            }
        } catch (e) {
            yield put({
                type: 'BRACKET_GROUP_TYPES/FETCH_FAILED',
                e
            });
        }
    });
}