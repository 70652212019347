import React from 'react';
import fakeAuth from '../../../fakeAuth';
import Loader from '../../common/Loader';
import { Animated } from 'react-animated-css';
import ReactDOM from 'react-dom';
import request from 'superagent';
import config from '../../../config';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';

class Profile extends React.Component {

  state = {
    bootstrapped: false,
    validations: {
      txtEmail: false,
      txtEmailConfirmation: false,
      txtPassword: false,
      txtPasswordConfirm: false,
      txtName: false,
      txtLastName: false,
      ddlistGender: false,
      ddlistCountry: false,
      ddlistState: false,
      txtDob: false,
      txtAddress: false,
      txtCity: false,
      txtZip: false,
      txtPhone: false
    },
    selected: {
      country: null,
      state: null,
    }
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, familyActions, geoActions } = this.props;
    setTitle && setTitle({ main: 'Profile', sub: 'Account Information' });
    this.setState({ credentials: fakeAuth.getCredentials() });
    familyActions && familyActions.getFamilyInformation();
    geoActions.fetchCountries();

  }
  componentWillReceiveProps = ({ information, countries, states }) => {
    this.setState({ bootstrapped: true, information }, () => {
      const { information, selected } = this.state;
      if (information && countries && countries.length && information.IdCountry && !selected.country) {
        this.selectCountry(_.find(countries, c => c.IdCountry === information.IdCountry));
      }
      if (information && states && states.length && information.IdState && !selected.state) {
        this.selectState(_.find(states, s => s.IdState === information.IdState));
      }
    });
  }
  //  

  selectCountry = (c) => {
    const { selected, validations } = this.state, { geoActions } = this.props;
    selected.country = c;
    validations.ddlistCountry = c ? true : false;
    geoActions.clearStates();
    geoActions.fetchStates(c.IdCountry);
    this.setState({
      selected,
      validations
    });
  }
  selectState = (s) => {
    const { selected, validations } = this.state;
    validations.ddlistState = s ? true : false;
    selected.state = s;
    this.setState({
      selected,
      validations
    });
  }

  toggleEdit = editing => this.setState({ editing });
  cancel = () => this.setState({ editing: null });
  saveEmail = e => {
    e.preventDefault && e.preventDefault();

    // Validations
    if (!this.txtEmail.value) {
      this.setState({ microtransact: false, error: 'Please provide your new Email' });
      return;
    }
    if (!this.txtConfirmEmail.value) {
      this.setState({ microtransact: false, error: 'Please confirm your new Email' });
      return;
    }
    if (this.txtConfirmEmail.value !== this.txtEmail.value) {
      this.setState({ microtransact: false, error: 'Your New Email and Confirmation must match' });
      return;
    }
    if (!this.txtPassword.value) {
      this.setState({ microtransact: false, error: 'Please provide your current password' });
      return;
    }
    //

    const { information } = this.state;
    // TODO: call API
    this.setState({ microtransact: true, error: null }, () => {
      request.post(`${config.apiEndpoint}/api/v4/family/main/email`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({
          IdEmailAddress: information.IdEmailAddress,
          EmailAddress: this.txtEmail.value,
          IsRelated: true
        })
        .then((data) => {
          if (data.body.success) {
            this.props.familyActions.getFamilyInformation();
            this.setState({ microtransact: false, editing: false });
          }
        }, ({ response }) => {
          this.setState({ microtransact: false, error: response.body.error.message });
        });
    });

  }
  savePassword = e => {
    e.preventDefault && e.preventDefault();

    // Validations
    if (!this.txtCurrentPasswrd.value) {
      this.setState({ microtransact: false, error: 'Please provide your Current Password' });
      return;
    }
    if (!this.txtPassword.value) {
      this.setState({ microtransact: false, error: 'Please provide your new Password' });
      return;
    }
    if (!this.txtConfirmPassword.value) {
      this.setState({ microtransact: false, error: 'Please confirm your new Password' });
      return;
    }
    if (this.txtConfirmPassword.value !== this.txtPassword.value) {
      this.setState({ microtransact: false, error: 'Your New Password and Confirmation must match' });
      return;
    }
    //

    // TODO: call API
    this.setState({ microtransact: true }, () => { });
    request.post(`${config.apiEndpoint}/api/v4/family/main/password`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .send({
        Password: this.txtCurrentPasswrd.value,
        PasswordNew: this.txtPassword.value,
        PasswordNewConfirm: this.txtConfirmPassword.value
      })
      .then((data) => {
        if (!data.body.success) {
          // TODO: there was a problem?
        }
        this.setState({ editing: false, microtransact: false });
      }, ({ response }) => {
        this.setState({ microtransact: false, error: response.body.Message });
      });
  }
  saveAddress = e => {
    e.preventDefault && e.preventDefault();
    const { information, selected } = this.state;
    this.setState({ microtransact: true }, () => {
      request.patch(`${config.apiEndpoint}/api/v4/family/main/info`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({
          IdTimeZone: information.IdTimeZone,
          Phone: this.txtPhone.inputElement.value,
          Line1: this.txtAddress.value,
          City: this.txtCity.value,
          ZipCode: this.txtZip.value,
          IdState: selected.state.IdState
        })
        .then((data) => {
          if (!data.body.success) {
            // TODO: there was a problem?            
          }
          this.props.familyActions.getFamilyInformation();
          this.setState({ editing: false, microtransact: false });
        }, ({ response }) => {
          // TODO: there was a problem?
          this.setState({ microtransact: false, error: response.body.Message });
        });
    });
  }

  validate = (key) => {
    const { validations, selected } = this.state;
    var valid = true;
    switch (key) {
      case 'ddlistCountry':
        validations.ddlistCountry = selected.country ? true : false;
        valid = !validations.ddlistCountry ? false : valid;
        break;
      case 'ddlistState':
        validations.ddlistState = selected.state ? true : false;
        valid = !validations.ddlistState ? false : valid;
        break;
      case 'txtAddress':
        validations.txtAddress = (this[key].value && this[key].value.length > 0) ? true : false;
        valid = !validations.txtAddress ? false : valid;
        break;
      case 'txtCity':
        validations.txtCity = (this[key].value && this[key].value.length > 0) ? true : false;
        valid = !validations.txtCity ? false : valid;
        break;
      case 'txtZip':
        validations.txtZip = (this[key].value && this[key].value.length > 0) ? true : false;
        valid = !validations.txtZip ? false : valid;
        break;
      case 'txtPhone':
        validations.txtPhone = (ReactDOM.findDOMNode(this[key]).value) ? true : false;
        valid = !validations.txtPhone ? false : valid;
        break;
      default:
        break;
    }
    this.setState({
      validations
    });

    return valid;
  }

  onDelete = () => {
    const { router } = this.props;
    request.delete(`${config.apiEndpoint}/api/v4/membership`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then((data) => { });

    fakeAuth.signout(() => {
      this.props.logout && this.props.logout();
      router.push('/login');
    });
  }

  toggleCountry = () => this.setState({ countryOpened: !this.state.countryOpened });
  toggleState = () => this.setState({ stateOpened: !this.state.stateOpened });

  render() {
    const { bootstrapped, editing, information, microtransact, validations, selected, error } = this.state,
      { countries, states } = this.props;

    // To set US and Canada first on the Countries selection
    const splitCountries = _.partition(countries, function (c) {
      return c.ISOCode === 'US' || c.ISOCode === 'CA';
    });

    return (
      <section id="credentials">
        {error && <div className="p-2 white bg-danger w-100 text-center">{error}</div>}
        {/*<div className="left-actions d-flex flex-column font-10" key="leftActions">
        </div>*/}
        <div className="right-actions d-flex flex-row-reverse" key="rightActions" />
        {
          bootstrapped && information && !editing && <Animated animationIn="fadeIn" className="margin-bottom d-flex flex-column justify-content-center w-100 m-auto p-4" style={{ maxWidth: 600 }}>
            {/* Email  */}
            <div className="p-3 tile card shadow-box d-flex flex-row">
              <div className="d-flex flex-column align-self-center">
                <span className="font-8 gray">Email</span>
                <span className="font-12 dark-blue">{information.EmailAddress}</span>
              </div>
              <span className="ml-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.toggleEdit(1)}>Edit</span>
            </div>

            {/* Password */}
            <div className="p-3 mt-3 tile card shadow-box d-flex flex-row">
              <div className="d-flex flex-column align-self-center">
                <span className="font-8 gray">Password</span>
                <span className="font-12 dark-blue">**********</span>
              </div>
              <span className="ml-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.toggleEdit(2)}>Edit</span>
            </div>

            {/* Address */}
            <div className="p-3 mt-3 tile card shadow-box d-flex flex-row">
              <div className="d-flex flex-column align-self-center">
                <span className="font-8 gray">Address</span>
                <span className="font-12 dark-blue line1">{information.Line1}<br />{information.City}, {information.State} {information.ZipCode}<br />{information.CountryName || information.Country}</span>
              </div>
              <span className="ml-auto align-self-center font-12 blue underlined pointer hoverable" onClick={() => this.toggleEdit(3)}>Edit</span>
            </div>

            {!this.state.deleting && <button onClick={() => this.setState({ deleting: true })} className="btn btn-danger mt-3">Delete Family Account</button>}

          </Animated>}

        {/* Edit Email */}
        {bootstrapped && editing === 1 && information && (
          <Animated animationIn="fadeInRight" style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
            <form className="align-self-center card tile shadow-box p-3 mt-4 w-100">
              <h3 className="margin-top margin-bottom-half">Family Email</h3>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="text" className="form-control" placeholder="New Email" ref={(i) => this.txtEmail = i} />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="text" className="form-control" placeholder="Confirm New Email" ref={(i) => this.txtConfirmEmail = i} />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><i className="fas fa-key" /></span>
                </div>
                <input type="password" className="form-control" placeholder="Current Password" ref={(i) => this.txtPassword = i} />
              </div>
              <div className="d-flex flex-row">
                <button type="button" className="btn btn-link btn-sm ml-auto" onClick={this.cancel}>Cancel</button>
                {!microtransact && <button type="submit" className="btn btn-success btn-sm" onClick={this.saveEmail}>Save</button>}
                {microtransact && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
              </div>
            </form>
          </Animated>)}

        {/* Edit Password */}
        {bootstrapped && editing === 2 && (
          <Animated animationIn="fadeInRight" style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
            <form className="align-self-center card tile shadow-box p-3 mt-4 w-100">
              <h3 className="margin-top margin-bottom-half">Change Account Password</h3>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><i className="fas fa-key" /></span>
                </div>
                <input type="password" className="form-control" placeholder="New Password" ref={(i) => this.txtPassword = i} />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><i className="fas fa-key" /></span>
                </div>
                <input type="password" className="form-control" placeholder="Confirm New Password" ref={(i) => this.txtConfirmPassword = i} />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><i className="fas fa-key" /></span>
                </div>
                <input type="password" className="form-control" placeholder="Current Password" ref={(i) => this.txtCurrentPasswrd = i} />
              </div>
              <div className="d-flex flex-row">
                <button type="button" className="btn btn-link  btn-sm ml-auto" onClick={this.cancel}>Cancel</button>
                {!microtransact && <button type="submit" className="btn btn-success btn-sm" onClick={this.savePassword}>Save</button>}
                {microtransact && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
              </div>
            </form>
          </Animated>)}

        {/* Edit Address and Phone */}
        {bootstrapped && editing === 3 && (
          <Animated animateIn="fadeInRight" style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
            <form className="align-self-center card tile shadow-box p-3 mt-4 w-100">
              <section key="address" className="row w-100 no-margin no-padding margin-top">
                { /* Address */}
                <div className={`p-2 col-12`}>
                  <div className={`d-flex flex-row ${(validations.txtAddress && validations.txtAddress === true) ? 'black' : ((validations.txtAddress === false) ? 'black' : null)}`}>
                    <span className="font-8 align-self-center">ADDRESS</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>
                  <input className="form-control" type="text" name="txtAddress" placeholder="Enter your Address "
                    ref={(input) => this.txtAddress = input} onBlur={() => this.validate('txtAddress')} defaultValue={information.Line1} />
                </div>
                { /* Country */}
                <div className={`p-2 col-6`}>

                  <div className={`d-flex flex-row ${(validations.ddlistCountry && validations.ddlistCountry === true) ? 'black' : ((validations.ddlistCountry === false) ? 'black' : null)}`}>
                    <span className="font-8  align-self-center">COUNTRY</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>

                  <Dropdown isOpen={this.state.countryOpened} toggle={this.toggleCountry} className="form-control no-padding w-100">
                    <DropdownToggle className="bg-white black text-left w-100 d-flex flex-row" style={{ border: 0 }}>
                      {selected.country ? selected.country.CountryName : 'Please select'}
                      <i className="fas fa-chevron-down ml-auto align-self-center" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {splitCountries.length >= 1 && splitCountries[0].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                        {c.CountryName || c.Name}
                      </DropdownItem>)}
                      <DropdownItem divider />
                      {splitCountries.length > 1 && splitCountries[1].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                        {c.CountryName || c.Name}
                      </DropdownItem>)}
                    </DropdownMenu>
                  </Dropdown>

                </div>
                { /* State */}
                <div className={`p-2 col-6 ${(selected && selected.country) ? null : 'bg-white'}`}>
                  <div className={`d-flex flex-row ${(validations.ddlistState && validations.ddlistState === true) ? 'black' : ((validations.ddlistState === false) ? 'black' : null)}`}>
                    <span className="font-8 align-self-center">STATE | PROVINCE {!selected.country && <span>(Select country first)</span>}</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>

                  <Dropdown isOpen={this.state.stateOpened} toggle={this.toggleState} className="form-control no-padding w-100">
                    <DropdownToggle className="bg-white black text-left w-100 d-flex flex-row" style={{ border: 0 }}>
                      {selected.state ? selected.state.StateName + ' (' + selected.state.StateCode + ')' : 'Please select'}
                      <i className="fas fa-chevron-down ml-auto align-self-center" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {states.length >= 1 && states.map((s) => <DropdownItem key={s.IdState} onClick={() => this.selectState(s)}>
                        {s.StateName}
                        {' ('}
                        {s.StateCode}
                        {') '}<i className="fa fa-marker" /></DropdownItem>)}
                    </DropdownMenu>
                  </Dropdown>

                </div>

                { /* City */}
                <div className={`p-2 col-6`}>
                  <div className={`d-flex flex-row ${(validations.txtCity && validations.txtCity === true) ? 'black' : ((validations.txtCity === false) ? 'black' : null)}`}>
                    <span className="font-8 align-self-center">CITY</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>
                  <input className="form-control" type="text" name="city" placeholder="Enter your City "
                    ref={(input) => this.txtCity = input} onBlur={() => this.validate('txtCity')} defaultValue={information.City} />
                </div>

                { /* Zip */}
                <div className={`p-2 col-6`}>
                  <div className={`d-flex flex-row ${(validations.txtZip && validations.txtZip === true) ? 'black' : ((validations.txtZip === false) ? 'black' : null)}`}>
                    <span className="font-8 align-self-center">ZIP | POSTAL</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>
                  <input className="form-control" type="text" name="zip" placeholder="Enter your Zip | Postal code "
                    ref={(input) => this.txtZip = input} onBlur={() => this.validate('txtZip')} defaultValue={information.ZipCode} />
                </div>

                { /* Phone */}
                <div className={`p-2 col-6`}>
                  <div className={`d-flex flex-row ${(validations.txtPhone && validations.txtPhone === true) ? 'black' : ((validations.txtPhone === false) ? 'black' : null)}`}>
                    <span className="font-8 align-self-center">PHONE NUMBER</span>
                    <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                  </div>
                  <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="form-control" placeholder="Enter your Phone Number"
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} onBlur={() => this.validate('txtPhone')} defaultValue={information.PhoneNumber_Format} />
                </div>
              </section>
              <div className="d-flex flex-row">
                <button type="button" className="btn btn-link btn-sm ml-auto" onClick={this.cancel}>Cancel</button>
                {!microtransact && <button type="submit" className="btn btn-success btn-sm" onClick={this.saveAddress}>Save</button>}
                {microtransact && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
              </div>
            </form>
          </Animated>)}

        <Animated animateOnMount={false} animationOut='fadeOutRight' animationOutDuration={200} animationInDuration={200} animationIn="fadeInRight" isVisible={this.state.deleting ? true : false}
          style={{ maxWidth: 600 }} className="container m-auto d-flex flex-column justify-content-center w-100">
          <div className="align-self-center card tile shadow-box p-3 mt-1 w-100 d-flex flex-column">
            <i className="fas fa-times red font-60 align-self-center"></i>
            <span className="text-center font-14 black montserrat align-self-center">Are you sure you want to delete your family's account?</span>
            <span className="text-center font-10 black montserrat">By doing so, you might lose important data</span>
            <button className="btn btn-danger mt-3" onClick={this.onDelete}>Yes, delete it</button>
            <button className="btn btn-link" onClick={() => this.setState({ deleting: false })}>No, cancel</button>
          </div>
        </Animated>

        {!bootstrapped && <Loader message="Please wait" />}
      </section>)
  }
}

export default Profile;
