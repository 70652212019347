import React from 'react';
import config from '../../../../../config';
import request from 'superagent';
import COLORS from '../../../../../colors';

class FlightTile extends React.Component {
    state = {
        dirty: false
    }
    componentWillMount() {
        const { flight } = this.props;
        this.setState({ flight });        
    }
    onChangeMinGames = () => {        
        this.setState({ dirty: true });
    }
    isOdd = (n) => {
        if (n % 2) return true;
        else return false
    }
    save = () => {
        const { flight } = this.state;
        flight.MinimumGamesPerTeam = parseInt(this.txtMinGames.value, 10);
        flight.Duration = 0;
        let error = this.isOdd(flight.MinimumGamesPerTeam) && this.isOdd(flight.FlightedTeams.length) ? 'You cannot have odd number of games for an odd number of teams' : (!flight.MinimumGamesPerTeam ? 'Please specify number of games' : null);

        if (!error) {
            this.setState({ flight, dirty: false, error: false }, () => {
                this.update();
            });
        } else {
            this.setState({ error });
        }
    }
    update = () => {
        const { flight } = this.state;
        request.patch(`${config.apiEndpoint}/api/v4/flights/${flight.IdFlight || flight.Id}`)
            .send({
                flightName: flight.FlightName,
                minimumGamesPerTeam: flight.MinimumGamesPerTeam,
                position: flight.Position,
                duration: flight.Duration
            })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(() => { });
    }


    render() {
        const { flight, dirty, error } = this.state, { fnSelectFlight } = this.props;

        return (
            <div className={`d-flex flex-column margin-top margin-left float-left card shadow`}
                style={{ width: 289, overflow: 'hidden', borderRadius: 5 }}>
                
                <section className={`header white p-2 ${flight.division.Gender.toLowerCase()}-header d-flex flex-row justify-content-center`} style={{ height: 100, position: 'relative' }}>
                    <span className="white align-self-center text-center" style={{ maxLines: 1 }}>{flight.division.Name.toUpperCase()}</span>                    
                    {error && <div className="bg-danger w-100 font-8 white text-center px-2 line1" style={{ position: 'absolute', bottom: 0 }}>
                            {error}
                        </div>}
                </section>
                
                <section>
                    <div className="d-flex flex-column p-2 w-100">
                        <span className="black">Flt: {flight.FlightName}</span>
                        <div className="d-flex flex-row mt-1" onClick={() => fnSelectFlight(flight)}>
                            <span className="black underlined pointer">Teams</span>
                            <span style={{ width: 40 }} className="ml-auto white bg-success card text-center" >{flight.FlightedTeams.length}</span>
                        </div>
                        <div className="d-flex flex-row mt-1">
                            <span className="black">Games X</span>
                            <input onChange={this.onChangeMinGames} type="number" defaultValue={flight.MinimumGamesPerTeam} ref={i => this.txtMinGames = i} style={{ width: 40 }} className="ml-auto black pl-2 pr-2 bg-white text-center card" />
                        </div>
                        <div className="d-flex flex-row mt-1">
                            <span className="black">Pairings =</span>
                            <span style={{ width: 40 }} className="ml-auto white bg-success card text-center">({flight.MinimumGamesPerTeam * flight.FlightedTeams.length / 2})</span>
                        </div>                        
                        <div className="d-flex flex-row justify-content-center mb-2">
                            <button onClick={this.save} style={{ color: dirty ? COLORS.white : COLORS.gray, border: `1px solid ${dirty ? COLORS.green : COLORS.gray}`, backgroundColor: dirty ? COLORS.green : COLORS.transparent, borderRadius: 20 }} className="ml-2 py-2 px-3 align-self-center block">Save Changes</button>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default FlightTile;