import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getLocationsSaga() {
  yield* takeEvery("LOCATIONS/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {

        return request.get(`${config.apiEndpoint}/api/v4/locations/${idLeague}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action.idLeague);

      if (result && result.success) {

        yield put({
          type: 'LOCATIONS/SET',
          locations: result.locations
        });

      } else {
        yield put({
          type: 'LOCATIONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LOCATIONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getFamilyLocationsSaga() {
  yield* takeEvery("LOCATIONS/FAMILY/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {

        return request.get(`${config.apiEndpoint}/api/v4/family/locations`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });

      }, action.idLeague);

      if (result && result.success) {

        yield put({
          type: 'LOCATIONS/SET',
          locations: result.locations
        });

      } else {
        yield put({
          type: 'LOCATIONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LOCATIONS/FETCH_FAILED',
        e
      });
    }
  });
}