import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import BlackOverlay from '../../partials/black_overlay';
import fakeAuth from '../../../fakeAuth';
import moment from 'moment';
import request from 'superagent';
import config from '../../../config';
import { generateRandomId } from '../../../helpers';
import InviteCollaborator from '../../family/roster/partials/InviteCollaborator';
import NetworkCollaborator from '../../family/roster/partials/NetworkCollaborator';
import ShareMatch from '../../family/roster/partials/ShareMatch';
import { Alert } from 'reactstrap';

class TeamEvaluations extends React.Component {

    state = {
        microTransac: false,
        bootstrapped: true,
        tab: 1,
        collaborators: [],
        collaborator_step: 0
    }

    componentWillMount() {
        const { roster, team_evaluations, customAttributesTemplates } = this.props;
        this.setState({ roster: [...roster] })
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }

    setup = (p = this.props) => {
        const { topics, customAttributesTemplates, collaborators } = p;
        const { match, template } = this.state;
        this.setState({ collaborators });
        if (topics) {
            this.setState({
                match: {
                    ...match,
                    Topics: _.chain(template ? template.topics : topics)
                        .map(t => {
                            return {
                                IdTopic: t.IdTopic,
                                Value: 0,
                                TopicName: t.TopicName,
                                SubTopics: _.map(t.subtopics, s => {
                                    return {
                                        IdTopic: s.IdTopic,
                                        Value: 0,
                                        TopicName: s.TopicName,
                                    }
                                })
                            }
                        }).value()
                }, bootstrapped: true
            });
        }
    }

    save = () => {
        this.setState({ trigger: true }, () => { this.close(); });
    }

    close = () => {
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
            setTimeout(() => { toggle && toggle(); }, 300);
        });
    }

    renderPlayer = (user, index) => {
        return <figure className="card mx-3 tile mt-2 mb-0 d-flex flex-row py-2 pl-3 pr-1 pointer" key={index} onClick={() => this.props.fnEvaluations(user)}>
            <div style={{ width: 80 }} className="d-flex flex-row">
                <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: '1px solid black', background: `url(${user.ImageUrl || user.Image}) no-repeat center center` }} />
            </div>
            <div className="d-flex flex-column align-self-center">
                <span className="montserrat black font-12 line1">{user.NameFirst}</span>
                <span className="montserrat-bold black font-14 line1">{user.NameLast}</span>
            </div>
            {_.find(this.props.team_evaluations, e => (e.IdUserTarget || e.IdAssignedUser) === user.IdUser && (this.props.tabs ? true : e.VISIBLE)) && <i className="icon-bar-chart blue font-16 ml-auto align-self-center" />}
        </figure>
    }
    renderTemplates = () => {
        const { customAttributesTemplates } = this.props;

        return <section className="pt-2">

            <Alert color="success" isOpen={this.state.success_import ? true : false} toggle={() => { this.setState({ success_import: null }) }}>
                Template imported successfully!
            </Alert>
            <h4><i className="icon-recreational black font-14 mx-3"></i> Attributes Template</h4>



            {customAttributesTemplates && customAttributesTemplates.map((c, i) =>
                <div className="card mx-3 tile mt-2 mb-0 d-flex flex-row py-2 pl-3 pr-1 pointer" key={i} style={{ height: 80 }}>
                    <i className="icon-recreational black font-20 align-self-center" />
                    <div className="d-flex flex-column montserrat ml-2 align-self-center">
                        <span className="black font-14 line1">{c.Title}</span>
                        <span className="gray font-10">{`Created${c.CreatorNameFirst ? ` by ${c.CreatorNameFirst} ` : ' '}on ${moment(c.EvaluationDate).format('MMM Do YYYY')}`}</span>
                    </div>
                    <button className="ml-auto pl-4 pr-3 align-self-start" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ edit_template: c });
                    }}><i className="fas black fa-ellipsis-v"></i></button>
                </div>)}





            <div onClick={() => this.setState({ new_template_menu: true })} style={{ width: 70, height: 70, borderRadius: '50%', border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" >
                <i className="font-30 fas fa-plus white align-self-center" />
            </div>
        </section>
    }

    onSave = () => {
        this.setState({ dirty: true }, () => {
            const { match, template } = this.state;
            if (!this.txtName.value) {
                alert('Please complete the missing fields');
                return;
            }
            this.props.scout_actions.save_match({
                ...match,
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
                Scale: template ? template.Scale : 10,
                Note: this.txtNotes.value
            });
            this.props.toggle();
        });
    }

    onToggleSubTopic = index => {
        const { match } = this.state;
        match.Topics[index].toggled = !match.Topics[index].toggled;
        if (match.Topics[index].toggled) {
            // Recalculate full topics value
            match.Topics[index].Value = _.chain(match.Topics[index].SubTopics)
                .map(i => i.Value)
                .reduce((a, b) => a + b, 0)
                .value() / match.Topics[index].SubTopics.length;
        }
        this.setState({ match });
    }

    onTopicChange = (index, value) => {
        const { match } = this.state;
        if (match.Topics[index].toggled) { return; }
        match.Topics[index].Value = value;
        this.setState({ match });
    }

    onSubTopicChange = (i, j, value) => {
        const { match } = this.state;
        match.Topics[i].SubTopics[j].Value = value;

        // Recalculate full topics value
        match.Topics[i].Value = _.chain(match.Topics[i].SubTopics)
            .map(i => i.Value)
            .reduce((a, b) => a + b, 0)
            .value() / match.Topics[i].SubTopics.length;

        this.setState({ match });
    }

    onImport = () => {
        const { teamActions } = this.props;
        if (this.state.importing) return;
        if (this.txtLinkImport.value &&
            (this.txtLinkImport.value.indexOf('://matchreports.thesqwad.com/scout/attributes_template') !== -1 ||
                this.txtLinkImport.value.indexOf('://matchreports.thesqwad.com/scout/match') !== -1
            )) {

            let [IdMatch] = this.txtLinkImport.value.split('/').reverse();
            this.setState({ importing: true, invalid_import_link: false }, () => {
                // TODO: call API
                // Call API
                request.post(`${config.apiEndpoint}/api/v6/scout/match_collaborator`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ IdMatch, IdUser: fakeAuth.getUser().IdUser })
                    .then((data) => {
                        if (data.body.success) {
                            teamActions && teamActions.fetch_custom_attributes_templates();
                            this.setState({ importing: false, importing_template: false, new_template_menu: false, success_import: true }, () => {
                                setTimeout(() => this.setState({ success_import: false }), 2000);
                            });
                        } else
                            this.setState({ importing: false });

                    });
            });

        } else this.setState({ invalid_import_link: true });

    }

    onSaveTemplate = () => {
        const { new_template } = this.state;
        // clear up DELETED topics;
        new_template.topics = _.reject(new_template.topics, t => (t.DELETED || !t.TopicName));
        new_template.topics.forEach(topic => {
            topic.subtopics = _.reject(topic.subtopics, s => (s.DELETED || !s.TopicName));
        });
        let payload = {
            Title: this.txtTemplateName.value,
            Scale: this.txtTemplateScale.value,
            ReportType: 86,
            EvaluationDate: moment().format(),
            IdUser: fakeAuth.getUser().IdUser,
            name: this.txtTemplateName.value.toUpperCase(),
            topics: new_template.topics
        }
        if (new_template.IdMatch) payload.IdMatch = new_template.IdMatch;
        this.props.scout_actions.save_match({ ...payload });
        this.setState({ adding_template: false });
    }

    onDeleteTemplate = () => {
        const { edit_template } = this.state;
        this.setState({ edit_template: null });
        if (edit_template.IdUser === fakeAuth.getUser().IdUser) this.props.scout_actions.delete_match(edit_template.IdMatch);
        else this.props.scout_actions.unfollow_match(edit_template.IdMatch);
        // TODO: call APIS
    }

    render() {
        const { bootstrapped, roster, tab, collaborator_step, template, new_template, adding_template, } = this.state;

        return (
            <section>

                <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <BlackOverlay />
                    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 500 }} onClick={this.close} />
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
                        position: 'fixed', right: 0, top: 0, bottom: 0,
                        width: 500, borderLeft: '1px solid gray', overflowY: 'auto'
                    }} className="bg-gray">
                        <div className="d-flex flex-row p-3">
                            <i className="fas fa-arrow-left font-12 pointer" onClick={this.close} />
                            <span className="montserrat font-12 ml-auto">Evaluations</span>
                            <i className=" font-16 pointer ml-auto" />
                        </div>
                        {this.props.tabs && <div className="d-flex flex-row">
                            <div onClick={() => this.setState({ tab: 1 })} className={`pointer w-50 d-flex flex-row justify-content-center ${tab !== 1 ? 'w3-gray' : ''}`} style={{ height: 40 }}>
                                <span className="align-self-center black">ROSTER</span>
                            </div>
                            <div onClick={() => this.setState({ tab: 2 })} className={`pointer w-50 d-flex flex-row justify-content-center ${tab !== 2 ? 'w3-gray' : ''}`} style={{ height: 40 }}>
                                <span className="align-self-center black">TEMPLATES ({(this.props.customAttributesTemplates).length})</span>
                            </div>
                        </div>}
                        {tab === 1 && roster && _.sortBy(roster, 'NameFirst').map((user, i) => this.renderPlayer(user, i))}
                        {tab === 2 && this.renderTemplates()}
                    </Animated>
                </div>

                {/* ADD TEMPLATE MEN?U */}
                {this.state.new_template_menu && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.new_template_menu ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ new_template_menu: false })} />
                    <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <div className="d-flex flex-row">
                            <button className="btn btn-link align-self-center" onClick={() => this.setState({ new_template_menu: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                            <h4 className="blue align-self-center ml-auto">CREATE TEMPLATE</h4>
                        </div>
                        <figure className="pointer mt-4 mx-4 card tile d-flex flex-column justify-content-center bg-whiteish p-3" onClick={() => this.setState({ adding_template: !this.state.adding_template, new_template: { topics: [] } })}>
                            <i className="icon-recreational font-40 black align-self-center" />
                            <span className="montserrat-bold mt-1 font-20 align-self-center line1">ATTRIBUTES</span>
                            <span className="montserrat font-12 align-self-center line1">Evaluation</span>
                        </figure>

                        <figure className="pointer mt-2 mx-4 card tile d-flex flex-column justify-content-center bg-whiteish p-3" onClick={() => this.setState({ importing_template: true })}>
                            <i className="icon-new-qr-code font-40 black align-self-center" />
                            <span className="montserrat-bold mt-1 font-20 align-self-center line1">IMPORT</span>
                            <span className="montserrat font-12 align-self-center line1">a shared template</span>
                        </figure>

                    </div>
                </Animated>}

                {/* IMPORTU */}
                {this.state.importing_template && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.importing_template ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ importing_template: false })} />
                    <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <div className="d-flex flex-row">
                            <button className="btn btn-link align-self-center" onClick={() => this.setState({ importing_template: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                            <h4 className="blue align-self-center ml-auto">CREATE TEMPLATE</h4>
                        </div>

                        <input type="text" ref={i => this.txtLinkImport = i} className={`w3-input bordered`} />
                        <span className="montserrat font-12 black">Paste here a shared link</span>
                        <button onClick={this.onImport} className="btn btn-success mt-2" style={{ borderRadius: 25 }}>
                            {this.state.importing && <i className="fas fa-cog fa-spin white font-14" />}
                            {!this.state.importing && <span>Import</span>}
                        </button>
                        {this.state.invalid_import_link && <span className="red font-14 align-self-center mt-2">Invalid Link</span>}
                    </div>
                </Animated>}

                {/* ADD TEMPLATE */}
                {new_template && adding_template && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.adding_template ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ adding_template: false })} />
                    <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <div className="d-flex flex-row">
                            <button className="btn btn-link align-self-center" onClick={() => this.setState({ adding_template: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                            <h4 className="blue align-self-center ml-auto">ADD CUSTOM FORM</h4>
                            <button onClick={this.onSaveTemplate} className="btn btn-success ml-auto align-self-center" style={{ borderRadius: 25 }}>{new_template.IdMatch ? 'Save Changes' : 'Save'}</button>
                        </div>
                        <hr />
                        <label>Title (Example: 'U10 Soccer Template')</label>
                        <input defaultValue={new_template.Title} type="text" ref={i => this.txtTemplateName = i} className={`w3-input bordered`} />
                        <div className="d-flex flex-row mt-2">
                            <label className="align-self-center line1">SCALE From Zero (0)</label>
                            <input defaultValue={new_template.Scale} type="text" placeholder='- TO -' ref={i => this.txtTemplateScale = i} className={`w3-input bordered ml-2`} />
                        </div>
                        <hr />
                        <div className="d-flex flex-column">
                            {new_template.topics.map((t, i) => <figure className={t.DELETED ? 'hide' : `card tile mx-2 mt-1 p-2`} key={i}>
                                <div className="d-flex flex-row">
                                    <input type="text" defaultValue={t.TopicName} onChange={(e) => {
                                        const { new_template } = this.state;
                                        new_template.topics[i].TopicName = e.target.value;
                                        this.setState({ new_template });
                                    }} className={`w3-input bordered`} />
                                    <button onClick={() => {
                                        const { new_template } = this.state;
                                        new_template.topics[i].DELETED = true;
                                        this.setState({ new_template });
                                    }} className="btn btn-link ml-auto"><i className="fas fa-times" /></button>
                                </div>
                                <div className="d-flex flex-column">
                                    {t.subtopics && t.subtopics.map((s, j) => <div className={s.DELETED ? 'hide' : `d-flex flex-row pl-4`} key={j}>
                                        <input type="text" defaultValue={s.Title} onChange={(e) => {
                                            const { new_template } = this.state;
                                            new_template.topics[i].subtopics[j].TopicName = e.target.value;
                                            this.setState({ new_template });
                                        }} className={`w3-input bordered ml-4`} />
                                        <button onClick={() => {
                                            const { new_template } = this.state;
                                            new_template.topics[i].subtopics[j].DELETED = true;
                                            this.setState({ new_template });
                                        }} className="btn btn-link ml-auto"><i className="fas fa-times" /></button>
                                    </div>)}
                                </div>
                                <div className="d-flex flex-row">
                                    <button onClick={() => {
                                        const { new_template } = this.state;
                                        new_template.topics[i].subtopics.push({});
                                        this.setState({ new_template });
                                    }} className="btn btn-link ml-auto">Click here to add sub-attributes</button>
                                </div>
                            </figure>)}
                        </div>
                        <button onClick={() => {
                            const { new_template } = this.state;
                            new_template.topics.push({ subtopics: [] });
                            this.setState({ new_template });
                        }} className="btn btn-info mt-2" style={{ borderRadius: 25 }}>Add Attribute</button>
                    </div>
                </Animated>}

                {/* EDIT TEMPLATE MENU */}
                {this.state.edit_template && <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ edit_template: false })} />
                    <div className="d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <BlackOverlay position='absolute' />
                        <div style={{ position: 'absolute', top: 0, bottom: 300, left: 0, right: 0 }} onClick={() => this.setState({ edit_template: false })} />
                        <Animated animationInDuration={200} animationIn='slideInUp' animationOut='slideOutDown'
                            className="w3-white d-flex flex-column" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 300, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                            <div className="d-flex flex-row mt-2">

                                <button className="btn btn-link align-self-center" onClick={() => this.setState({ edit_template: false })}><i className="fas fa-times black font-16 align-self-center"></i></button>
                                <button className="ml-auto btn btn-link">{this.state.edit_template.Title}</button>
                                <button className="ml-auto btn btn-link align-self-center" ><i className="fas fa-arrow-left white font-16 align-self-center"></i></button>
                            </div>

                            <hr />
                            {this.state.edit_template.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ new_template: this.state.edit_template, edit_template: false, adding_template: true })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-edit" /></div>Edit template</button>}
                            {this.state.edit_template.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ listing_collaborators: true, collaborators: null }, () => {
                                this.props.scout_actions && this.props.scout_actions.fetch_match_collaborators(this.state.edit_template.IdMatch);
                            })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-user-plus" /></div>Collaborators</button>}
                            <button onClick={() => this.setState({ share_match: this.state.edit_template })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-share" /></div>Share template</button>


                            {!this.state.deleting_template && <button onClick={() => this.setState({ deleting_template: true })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-x" /></div>{this.state.edit_template.IdUser === fakeAuth.getUser().IdUser ? 'Delete template' : 'Unfollow template'}</button>}
                            {this.state.deleting_template && <div className="d-flex flex-row">
                                <button className="ml-2 btn btn-link text-left">Are you sure?</button>
                                <button onClick={this.onDeleteTemplate} className="ml-auto btn btn-link"><i className="fas fa-check green" /></button>
                                <button onClick={() => this.setState({ deleting_template: false })} className="btn btn-link mr-2"><i className="fas fa-times red" /></button>
                            </div>}
                        </Animated>
                    </div>
                </div>}

                {/* COLLABORATORS */}
                {this.state.listing_collaborators && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.listing_collaborators ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ listing_collaborators: false })} />
                    <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <div className="d-flex flex-row">
                            <button className="btn btn-link align-self-center" onClick={() => this.setState({ listing_collaborators: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                            <h4 className="blue ml-auto align-self-center">COLLABORATORS</h4>
                        </div>
                        <figure className="card tile mt-2 p-3 d-flex flex-row">
                            <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: 15, background: `url(${fakeAuth.getUser().UserImage}?full=80) no-repeat center center black` }} />
                            <div className="d-flex flex-column align-self-center ml-2">
                                <span>{`${fakeAuth.getUser().NameFirst} ${fakeAuth.getUser().NameLast}`}</span>
                            </div>
                            <span className="ml-auto align-self-center">Owner</span>
                        </figure>
                        <h4 className="black align-self-center">VIEWERS</h4>

                        {this.state.collaborators && this.state.collaborators.map((collaborator, i) => <figure key={i} className="card tile my-1 p-3 d-flex flex-row">
                            <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: 15, background: `url(${collaborator.UserImage}?full=80) no-repeat center center black` }} />
                            <div className="d-flex flex-column align-self-center ml-2">
                                <span className="line1">{`${collaborator.NameFirst} ${collaborator.NameLast}`}</span>
                                <span className="font-8 line1 gray">{collaborator.EmailAddress}</span>
                            </div>
                            <button onClick={() => {
                                this.props.scout_actions.unfollow_generic_match({
                                    IdUser: collaborator.IdUser,
                                    IdMatch: this.state.edit_template.IdMatch
                                });
                            }} className="ml-auto align-self-center btn btn-link"><i className="fas fa-times gray" /></button>
                        </figure>)}

                        <div onClick={() => this.setState({ adding_collaborator: !this.state.adding_collaborator })} style={{ width: 70, height: 70, borderRadius: '50%', border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" >
                            <i className="font-30 fas fa-plus white align-self-center" />
                        </div>
                    </div>
                </Animated>}

                {/* ADD COLLABORATOR */}
                <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.adding_collaborator ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                    <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ adding_collaborator: false, collaborator_step: 0 })} />
                    <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                        <div className="d-flex flex-row p-3">
                            {collaborator_step === 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.setState({ adding_collaborator: false, collaborator_step: 0 })} />}
                            {collaborator_step !== 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.setState({ collaborator_step: 0 })} />}

                            <h4 className="blue ml-auto align-self-center">ADD COLLABORATOR</h4>
                        </div>
                        {collaborator_step === 0 && <div className="d-flex flex-column h-100 justify-content-center p-4">
                            <div onClick={() => this.setState({ collaborator_step: 1 })} style={{ height: 'auto', border: '1px solid gray' }} className="align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                                <span className="icon-mail font-24 black align-self-center" />
                                <span className="font-16 toBold black align-self-center">INVITE</span>
                                <span className="font-10 black line1 align-self-center">using their Email</span>
                            </div>
                            <div onClick={() => this.setState({ collaborator_step: 2 })} style={{ height: 'auto', border: '1px solid gray' }} className="mt-4 align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                                <span className="icon-coach_registration font-24 black align-self-center" />
                                <span className="font-16 toBold black align-self-center">INVITE</span>
                                <span className="font-10 black line1 align-self-center">from an Existing Roster</span>
                            </div>
                        </div>}

                        {collaborator_step === 1 && <InviteCollaborator {...this.props} match={this.state.edit_template}
                            onSave={(users) => {
                                users.forEach(user => {
                                    if (user.IdUser !== fakeAuth.getUser().IdUser)
                                        this.props.scout_actions.follow_match({
                                            IdUser: user.IdUser,
                                            IdMatch: this.state.edit_template.IdMatch
                                        });
                                });
                                this.setState({ adding_collaborator: false, collaborator_step: 0 });
                            }} />}
                        {collaborator_step === 2 && <NetworkCollaborator {...this.props} match={this.state.edit_template} onSave={(users) => {
                            users.forEach(user => {
                                if (user.IdUser !== fakeAuth.getUser().IdUser)
                                    this.props.scout_actions.follow_match({
                                        IdUser: user.IdUser,
                                        IdMatch: this.state.edit_template.IdMatch
                                    });
                            });
                            this.setState({ adding_collaborator: false, collaborator_step: 0 });
                        }} />}
                    </div>
                </Animated>

                {/* SHARE */}
                <ShareMatch {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ share_match: false })} />

            </section>)
    }
}

export default TeamEvaluations;
