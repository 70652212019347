import React from 'react';
import { Animated } from 'react-animated-css';
import AdminSelection from './AdminSelection';
import _ from 'lodash';
import RoleSelection from './RoleSelection';
import ScoutSelection from './ScoutSelection';

class ScoutOptions extends React.Component {
    state = {
        bootstrapped: true,
        searchTerm: null,
        stage: 1,
    }    
    componentWillReceiveProps = nextProps => {
        this.setState({ scoutRegistration: nextProps.scoutRegistration });
    }

    onClose = () => {
        this.setState({ bootstrapped: false }, () => {
            const { close } = this.props;
            setTimeout(() => { close && close() }, 500);
        });
    }

    onBack = () => {
        switch (this.state.stage) {
            case 1: this.onClose(); break;
            case 2: this.setState({ stage: 1 }); break;
            case 3: this.setState({ stage: 1 }); break;
            case 4: this.setState({ stage: 1 }); break;
            default: break;
        }
    }

    onOk = ({ selectedUser, selectedRole, mode }) => {
        if (selectedUser) { this.setState({ selectedUser, stage: 1 }); }
    }

    filter = e => this.setState({ searchTerm: e.target.value });

    render() {

        const { bootstrapped, stage, searchTerm } = this.state, { teamRegistration, teams, cart, season } = this.props;


        return <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
            <Animated animationIn='fadeIn' isVisible={bootstrapped ? true : false} animationInDuration={300} animationOutDuration={300} className="dark-overlay" />
            <div style={{ left: 0, right: 400, position: 'absolute', top: 0, bottom: 0 }} onClick={this.onClose} />
            <Animated style={{ width: 400, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
                isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>

                <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
                    <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onBack} />
                    {stage === 1 && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Select an option</span>}
                    {stage === 2 && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Who's Coaching | Admin</span>}
                    {stage === 3 && this.state.selectedUser
                        && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Select {this.state.selectedUser.NameFirst}'s Role</span>}
                    <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
                </div>

                {stage === 1 && <div className="d-flex flex-column dp-2 h-100 justify-content-center px-4">

                    {[3, 5].indexOf(season.IdSeasonType) === -1 && <div onClick={() => {
                        this.props.uno();
                        this.onClose();
                    }} className="pointer w-100 tile card shadow-box p-4 d-flex flex-column justify-content-center align-self-center" style={{ height: 160 }}>
                        <i className="icon-family_registration black font-40 align-self-center" />
                        <span className="black font-20 toBold align-self-center">PLAYER</span>
                        <span className="font-10 gray align-self-center">Registration</span>
                    </div>}

                    {[3, 5].indexOf(season.IdSeasonType) !== -1 && <div onClick={() => {
                        this.props.uno();
                        this.onClose();
                    }} className="pointer w-100 tile card shadow-box p-4 d-flex flex-column justify-content-center align-self-center" style={{ height: 160 }}>
                        <i className="icon-CrestOnly_icon-2 black font-40 align-self-center" />
                        <span className="black font-20 toBold align-self-center">TEAM</span>
                        <span className="font-10 gray align-self-center">Registration</span>
                    </div>}

                    <div onClick={() => this.setState({ stage: 4 })} className="pointer w-100 tile card shadow-box p-4 d-flex flex-column justify-content-center align-self-center mt-4" style={{ height: 160 }}>
                        <i className="icon-icon_explorer black font-40 align-self-center" />
                        <span className="black font-20 toBold align-self-center">SCOUT</span>
                        <span className="font-10 gray align-self-center">Registration</span>
                    </div>
                </div>}

                {stage === 4 && <ScoutSelection {...this.props} onOk={this.onOk} />}
            </Animated>
        </section>
    }
}

export default ScoutOptions;
