import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DivisionsList from '../../seasons/manage_schedule/schedule_manager/build/DivisionsList';
import config from '../../../config';
import COLORS from '../../../colors';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import request from 'superagent';

class BuildScheduleModal extends React.Component {
    state = {
        buildingSchedule: false,
        someWithPairings: false,
        results: null
    }

    // Lifecycle
    componentWillMount() {
        const { divisions } = this.props;
        let someWithPairings = false;
        divisions && divisions.forEach(d => {
            d.Flights.forEach(f => { if (f.HasPairings === 1) someWithPairings = true; });
        });
        this.setState({ someWithPairings });
    }
    componentWillReceiveProps = nextProps => {
        const { divisions } = nextProps;
        let someWithPairings = false;
        divisions && divisions.forEach(d => {
            d.Flights.forEach(f => { if (f.HasPairings === 1) someWithPairings = true; });
        });
        this.setState({ someWithPairings });
    }
    //

    build = () => {
        const { divisions } = this.props;

        // FIX?
        divisions.forEach(d => {
            d.Flights.forEach(f => f.DIVISION = d.Name || d.DivisionName);
        });

        const flightsChecked = _.chain(_.map(divisions, (division) => {
            return division.Flights
        })).flatten().filter((flight) => {
            return flight.checkedForSchedule;
        }).sortBy('TOTAL_SLOTS_FOR_SEASON').value();

        if (!flightsChecked.length) {
            this.props.nope && this.props.nope('Please select at least one (1) flight to be built')
            return;
        }

        this.setState({ buildingSchedule: true, results: null }, () => {

            // Clean up previous results
            flightsChecked.forEach(flight => { flight.result = null; flight.PROCESSING = false });

            this.setState({ flightsChecked }, () => {

                if (this.props.automatic) {

                    // ONE BY ONE
                    let go = (index) => {
                        flightsChecked[index].PROCESSING = true;
                        this.setState({ flightsChecked });
                        request.post(`${config.apiEndpoint}/api/v4/schedule/build`)
                            .send({ flights: [{ IdFlight: flightsChecked[index].IdFlight }] })
                            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                            .then(({ body: { flights } }) => {
                                flightsChecked[index].result = _.find(flights, r => r.IdFlight === flightsChecked[index].IdFlight);
                                flightsChecked[index].PROCESSING = false;
                                if (index < flightsChecked.length - 1) go(index + 1);
                                this.setState({ flightsChecked });
                            });
                    }
                    go(0);


                    /*
                    ALL AT ONCE
                    IF JEFF DON"T WANT THE ONE BY ONE

                    request.post(`${config.apiEndpoint}/api/v4/schedule/build`)
                            .send({ flights: _.map(flightsChecked, flight => { return { IdFlight: flight.IdFlight }; }) })
                            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                            .then(({ body: { flights } }) => {
                                flightsChecked.forEach(flight => {
                                    flight.result = _.find(flights, r => r.IdFlight === flight.IdFlight);                                    
                                });
                                this.setState({ flightsChecked });
                            });
                    
                    */

                } else {

                    // IDEA: for each flight, call the api and update its response
                    request.post(`${config.apiEndpoint}/api/v4/pairings/build`)
                        .send({ ids: _.map(flightsChecked, flight => flight.IdFlight) })
                        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                        .then(({ body: { results } }) => {
                            flightsChecked.forEach(flight => {
                                flight.result = _.find(results, r => r.IdFlight === flight.IdFlight);
                                if (flight.result) flight.result.MissingPairings = flight.result.TotalPairings - flight.result.TotalPairingsCreated;
                            })
                            this.setState({ flightsChecked });
                        });
                }
                /**/
            });
        });
    }

    goToCalendar = () => {
        const { season } = this.props;

        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();

        this.props.seasonActions &&
            this.props.seasonActions.clearGames &&
            this.props.seasonActions.clearGames();

        this.props.router.push('/season/' + season.IdSeason + '/games_calendar');
    }

    render() {
        const { isOpen, toggle, season, onToggleSeasonCheckedForSchedule, automatic } = this.props, { someWithPairings, flightsChecked } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={toggle}>

                <ModalHeader toggle={toggle} className="bg-blue-dark white">
                    Build the Schedule
                </ModalHeader>

                <ModalBody className="bg-info white p-2 font-10">
                    <div className="d-flex flex-row font-10">
                        <i className="fa fa-exclamation-circle half-idented align-self-center"></i>
                        <span className="align-self-center half-idented">Select the flights you consider are ready for Schedule</span>
                    </div>
                </ModalBody>

                {someWithPairings && <ModalBody className="bg-danger white p-2 font-10">Some of your flights have already been built for pairings schedule. If you rebuild, that information will be lost</ModalBody>}

                <ModalBody id="build-popup">

                    <ul className="p-0">
                        <li className="p-0">
                            <div className="d-flex flex-row p-2">
                                <input className="align-self-center ml-2" type="checkbox" defaultValue={season.checkedForSchedule}
                                    onChange={onToggleSeasonCheckedForSchedule} checked={season.checkedForSchedule} />
                                <span className="align-self-center ml-2">{season.Name}</span>
                            </div>
                            <DivisionsList {...this.props} results={this.state.results} />
                        </li>
                    </ul>

                    {(flightsChecked && flightsChecked.length > 0) && <div onClick={() => { this.setState({ flightsChecked: null, buildingSchedule: false }) }} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, opacity: 0.5 }} className="overlay" />}

                    <Animated animationInDuration={300} animationOutDuration={300} isVisible={this.state.buildingSchedule ? true : false} animationIn='slideInRight' animationOut='slideOutRight' animateOnMount={false} style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 100, overflowY: 'auto', zIndex: 1041 }} className="bg-white">

                        <div className="d-flex flex-column mt-4">
                            <i className={`fa fa-${_.find(flightsChecked, f => !f.result) ? 'cog fa-spin black' : 'check green'} font-30 ease align-self-center`} />
                            <span className="font-30 align-self-center">{_.find(flightsChecked, f => !f.result) ? 'Building, please wait' : 'Done!'}</span>
                            {_.find(flightsChecked, f => !f.result) && <span className="font-10 align-self-center">This process might take a while, depending on the number of flights selected for build</span>}
                        </div>

                        <table className="table bg-white font-10 mt-2">

                            <thead>
                                <tr>
                                    <th>Division / Flight</th>
                                    <th className="text-center">Min. Games x Team</th>
                                    <th className="text-center">Teams</th>
                                    <th className="text-center">Req. Pairings
                                        <br />
                                        <span className="font-8">((G x T) / 2)</span></th>

                                    {!automatic && <th className="text-center">Missing Pairings</th>}
                                    {automatic && <th className="text-center">Missing Games</th>}
                                    <th>Summary</th>
                                    <th className=""></th>
                                </tr>
                            </thead>

                            <tbody>
                                {flightsChecked && flightsChecked.map((item, j) => (
                                    <tr key={j} className={`${(item.result && item.result.MissingPairings !== 0) ? 'bg-danger' : ''}`}>
                                        <td style={{ whiteSpace: 'normal' }}>
                                            {`${item.DIVISION} - ${item.FlightName}`}
                                        </td>
                                        <td className="text-center">
                                            {item.MinimumGamesPerTeam}
                                        </td>
                                        <td className="text-center">
                                            {(item.FlightedTeams || []).length}
                                        </td>
                                        <td className="text-center">
                                            {item.MinimumGamesPerTeam * (item.FlightedTeams || []).length / 2}
                                        </td>
                                        {automatic && <td className="text-center font-20">
                                            {item.result ? (item.result.RequiredPairings - item.result.GAMES_CREATED) : (item.PROCESSING ? 'Calculating...' : 'Queued')}
                                        </td>}
                                        {!automatic && <td className="text-center font-20">
                                            {item.result ? (item.result.MissingPairings) : (item.PROCESSING ? 'Calculating...' : 'Queued')}
                                        </td>}
                                        <td>{item.result && item.result.MissingPairings !== 0 && <span>{`${(item.result.PAIRINGS_FORCED_AS_BYES || []).length} forced byes | ${(item.result.PAIRINGS_WITHOUT_AVAILABLE_SLOTS || []).length} out of rounds range`}</span>}
                                            {item.result && item.result.MissingPairings === 0 && <span>All good!</span>}
                                        </td>
                                        <td><i className={`fa fa-${(item.result && item.result.MissingPairings === 0) ? 'check green' : ((item.result && item.result.MissingPairings !== 0) ? 'times white' : 'cog fa-spin')} ml-2`} /></td>
                                    </tr>))}
                            </tbody>

                        </table>

                        {!_.find(flightsChecked, f => !f.result) &&
                            <div className="mt-4">
                                <h3 className="text-center mb-4">What now?</h3>
                                <ul className="white align-self-center text-center d-flex flex-row justify-content-center w-100">
                                    <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                            <button onClick={this.goToCalendar} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                                <i className="fas fa-calendar align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                                <span className=" align-self-center margin-top-half">Calendar</span>
                                            </button>
                                        </Animated>
                                    </li>
                                    <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                            <button onClick={this.props.onSummary} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                                <i className="fa fa-list fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                                <span className=" align-self-center margin-top-half">Summary</span>
                                            </button>
                                        </Animated>
                                    </li>
                                    <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                            <button onClick={() => { this.setState({ flightsChecked: null, buildingSchedule: false }) }} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                                <i className="fa fa-times fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                                <span className=" align-self-center margin-top-half">Close</span>
                                            </button>
                                        </Animated>
                                    </li>
                                </ul>
                                <div style={{ height: 200 }} />
                            </div>}

                    </Animated>
                </ModalBody>

                {!this.state.buildingSchedule && (<ModalFooter>
                    <button style={{ border: `1px solid ${COLORS.gray}`, borderRadius: 20, color: COLORS.gray }} className="ml-auto py-2 px-3 align-self-center block" onClick={toggle}>Cancel</button>
                    {' '}
                    <button style={{ border: `1px solid ${COLORS.green}`, borderRadius: 20, color: COLORS.green }} className="ml-2 py-2 px-3 align-self-center block" onClick={this.build}>Build it!</button>
                </ModalFooter>)}

                {this.state.buildingSchedule && (<ModalFooter>
                    <span className="font-8 black margin-right-half">This might take some time!</span>
                    <button className="btn btn-sm btn-success">Building, please wait...</button>
                </ModalFooter>)}

            </Modal>
        )
    }
}

export default BuildScheduleModal;
