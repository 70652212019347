import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getLeadsSaga() {
    yield* takeEvery("LEADS/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v5/leads`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, {});

            if (result && result.success) {
                yield put({ type: 'LEADS/SET', leads: result.leads || [] });
            } else {
                yield put({ type: 'LEADS/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'LEADS/FETCH_FAILED', e });
        }
    });
}