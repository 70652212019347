import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import request from 'superagent';
import config from '../../../config';
import moment from 'moment';

class Signature extends React.Component {

    state = {
        bootstrapped: false,
        signature: {}
    }

    componentWillMount() {
        const { team, awards, shirts } = this.props;
        let service = '';
        if (awards) service = 'awards';
        if (shirts) service = 'uniforms';

        request.get(`${config.apiEndpoint}/api/v4/${service}/signature/${team.IdTeamDivision}/${team.IdUser_CoachSigned}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(data => {
                this.setState({ signature: data.body.signature });
            });
    }

    render() {
        const { isOpen, toggle, team } = this.props,
            { signature } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody className="d-flex flex-column justify-content-center">
                    <img alt="" className="bg-white align-self-center" src={signature.Signature} style={{ height: 150 }} />
                    <span className="font-12 align-self-center">Signed by {team.UserName_CoachSigned}</span>
                    <span className="font-12 align-self-center margin-bottom">{signature.dateCreated ? `On ${moment(signature.dateCreated).format('MMM Do YYYY')}` : ''}</span>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-sm btn-link" onClick={toggle}>Close</button>
                </ModalFooter>
            </Modal>)
    }
}

export default Signature;
