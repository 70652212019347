import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../../config';
import request from 'superagent';

//v4
export function* getExceptionRulesSaga() {
    yield* takeEvery("SCHEDULE_MANAGER/EXCEPTION_RULES/FETCH", function* (action) {

        try {
            const result = yield call(({ idSeason }) => {
                return request.get(`${config.apiEndpoint}/api/v4/schedule/rules/exceptions/${idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            }, action);

            if (result && result.success) {
                yield put({ type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/SET', rules: result.rules });
            } else {
                yield put({ type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/FETCH_FAILED', e });
        }
    });
}

// v4
export function* associateDateExceptionRuleWithTeamSaga() {
    yield* takeEvery("SCHEDULE_MANAGER/EXCEPTION_RULES/DATE/ASSOCIATE_WITH_TEAM", function* (action) {
        const result = yield call(({ idTeam, idRule }) => {
            return request.post(`${config.apiEndpoint}/api/v4/flights/associate/exception/${idTeam}/${idRule}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => JSON.parse(data.text));
        }, action);

        if (result && result.success) {
            // TODO ?
            yield put({
                type: 'DIVISION/ASSOCIATE/DATE_EXCEPTION',
                IdScheduleFlightTeam: result.IdTeam,
                idRule: result.IdRule,
                idAssociation: result.idAssociation
            });
        }
    });
}

// v4
export function* removeDateExceptionRuleAssociationFromTeam() {
    yield* takeEvery("SCHEDULE_MANAGER/EXCEPTION_RULES/REMOVE_ASSOCIATION_FROM_TEAM", function* (action) {
        const result = yield call(({ idRuleAssociation }) => {
            return request.del(`${config.apiEndpoint}/api/v4/flights/remove/exception/${idRuleAssociation}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => JSON.parse(data.text));
        }, action);
        if (result && result.success) {
            yield put({ type: 'DIVISIONS/REMOVE_ASSOCIATION/DATE_EXCEPTION', idAssociation: action.idRuleAssociation });
        }
    });
}
