import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import fakeAuth from '../../fakeAuth';
import _ from 'lodash';
import { humanize_user } from '../../helpers';

export function* getRosterTeamsSaga() {
    yield* takeEvery("ROSTER/TEAMS/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/v4/roster/teams/${idUser}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => { return (JSON.parse(data.text)) });
            }, action);

            if (result && result.success) {

                yield put({
                    type: 'ROSTER/TEAMS/SET',
                    teams: result.teams
                });

                if (result.teams.length) {
                    yield put({
                        type: 'SELECTED_TEAM/SET',
                        team: _.chain(result.teams).sortBy('IdTeamDivision').last().value()
                    })
                }

            } else {
                yield put({
                    type: 'ROSTER/TEAMS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/TEAMS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getRoster() {
    yield* takeEvery("ROSTER/FETCH", function* (action) {

        try {
            const result = yield call(({ idUser, idTeamDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v6/roster/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => { return (JSON.parse(data.text)) });
            }, action);
            if (result && result.success) {

                // Decrypt
                [...result.roster, ...result.guests].forEach(u => humanize_user(u));
                //               

                yield put({
                    type: 'ROSTER/LIMITS/SET',
                    limits: result.limits
                });
                yield put({
                    type: 'PERMISSIONS/SET',
                    permissions: result.permissions
                });
                yield put({
                    type: 'ROLES/SET',
                    roles: result.roles
                });
                yield put({
                    type: 'ROSTER/SET',
                    roster: [...result.roster, ...result.guests]
                });
                yield put({
                    type: 'DIVISION/SET',
                    division: result.division
                });

            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getNetwork() {
    yield* takeEvery("ROSTER/NETWORK/FETCH", function* (action) {

        try {
            const result = yield call(({ idTeamDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v6/roster/related/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => { return (JSON.parse(data.text)) });
            }, action);
            if (result && result.success) {
                // Decrypt
                result.users.forEach(u => humanize_user(u));
                //
                yield put({
                    type: 'NETWORK/SET',
                    network: result.users
                });
            } else {
                yield put({
                    type: 'ROSTER/NETWORK/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/NETWORK/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getNetworkCoaches() {
    yield* takeEvery("ROSTER/NETWORK/COACHES/FETCH", function* (action) {

        try {
            const result = yield call(({ idTeamDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v6/roster/related_coaches/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => { return (JSON.parse(data.text)) });
            }, action);
            if (result && result.success) {
                // Decrypt
                result.users.forEach(u => humanize_user(u));
                //
                yield put({
                    type: 'NETWORK/COACHES/SET',
                    network_coaches: result.users
                });
            } else {
                yield put({
                    type: 'ROSTER/NETWORK/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/NETWORK/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getRosterPublic() {
    yield* takeEvery("ROSTER/PUBLIC/FETCH", function* (action) {
        try {
            const result = yield call(({ idTeamDivision }) => {
                return request.get(`${config.apiEndpoint}/api/external/teams/roster/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { roster, colors, guests } = result;
                yield put({ type: 'ROSTER/SET', roster: [...roster, ...guests] });
                yield put({ type: 'COLORS/SET', colors });
            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getVideosByUser() {
    yield* takeEvery("VIDEOS/USER/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/external/videos/by_user/${idUser}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { videos } = result;
                yield put({ type: 'VIDEOS/SET', videos });
            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

export function* updateJersey() {
    yield* takeEvery("ROSTER/JERSEY/UPDATE", function* (action) {
        try {
            const result = yield call(({ idTeamUserRole, position }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/position`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ idTeamUserRole, position })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/JERSEY/SUCCESS' });
            } else {
                yield put({ type: 'ROSTER/JERSEY/SUCCESS' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/JERSEY/SUCCESS' });
        }
    });
}

export function* updatePosition() {
    yield* takeEvery("ROSTER/JERSEY/UPDATE", function* (action) {
        try {
            const result = yield call(({ idTeamUserRole, jerseyNumber }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/jersey`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ idTeamUserRole, jerseyNumber })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/JERSEY/SUCCESS' });
            } else {
                yield put({ type: 'ROSTER/JERSEY/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/JERSEY/SUCCESS' });
        }
    });
}

export function* updateColors() {
    yield* takeEvery("ROSTER/COLORS/UPDATE", function* (action) {
        try {
            const result = yield call(({ idTeam, color1, color2 }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/colors/${idTeam}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ color1, color2 })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/COLORS/SUCCESS' });
            } else {
                yield put({ type: 'ROSTER/COLORS/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/COLORS/SUCCESS' });
        }
    });
}

export function* updateCollegeProfile() {
    yield* takeEvery("ROSTER/COLLEGE_PROFILE/UPDATE", function* (action) {
        try {
            const result = yield call((payload) => {
                return request.post(`${config.apiEndpoint}/api/v5/college_profile/${payload.IdUser}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(payload)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/COLORS/SUCCESS' });
                yield put({ type: 'MEMBERS/FAMILY/FETCH' });
                yield put({ type: 'COLLEGE_PROFILE/FETCH', idUser: action.IdUser })
            } else {
                yield put({ type: 'ROSTER/COLORS/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/COLORS/SUCCESS' });
        }
    });
}

export function* updateJerseyBatch() {
    yield* takeEvery("ROSTER/JERSEY/POSITION/UPDATE", function* (action) {
        try {
            const result = yield call(({
                IdTeamUserRole,
                JerseyNumber }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/registration/shirt_number`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ idTeamUserRole: IdTeamUserRole, jerseyNumber: JerseyNumber })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/JERSEY/POSITION/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/JERSEY/POSITION/UPDATE/FAIL' });
        }
    });
}

export function* updatePositionBatch() {
    yield* takeEvery("ROSTER/JERSEY/POSITION/UPDATE", function* (action) {
        try {
            const result = yield call(({
                IdTeamUserRole,
                Position }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/position`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ idTeamUserRole: IdTeamUserRole, position: Position })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/JERSEY/POSITION/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/JERSEY/POSITION/UPDATE/FAIL' });
        }
    });
}

export function* updateRole() {
    yield* takeEvery("ROSTER/ROLE/UPDATE", function* (action) {
        try {
            const result = yield call(({ idTeamUserRole, idRoleOverride }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roles/coach/${idTeamUserRole}/${idRoleOverride}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({})
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/ROLE/UPDATED' });
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.idTeamDivision });
            } else {
                yield put({ type: 'ROSTER/ROLE/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/ROLE/UPDATE/SUCCESS' });
        }
    });
}

export function* removeGuestFromGameSaga() {
    yield* takeEvery("ROSTER/GUEST/REMOVE", function* (action) {
        try {
            const result = yield call(({ IdUser, IdGame, IdTeamDivision }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/guest_players/${IdUser}/${IdTeamDivision}/${IdGame}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/ROLE/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/ROLE/UPDATE/SUCCESS' });
        }
    });
}

export function* addGuestToGameSaga() {
    yield* takeEvery("ROSTER/GUEST/ADD", function* (action) {
        try {
            const result = yield call(({ IdUser, IdGame, IdTeamDivision }) => {
                return request.post(`${config.apiEndpoint}/api/v5/guest_players`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ IdUser, IdGame, IdTeamDivision })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/ROLE/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/ROLE/UPDATE/SUCCESS' });
        }
    });
}

//ROSTER/PLAYER/STATUS/TOGGLE
export function* togglePlayerStatusSaga() {
    yield* takeEvery("ROSTER/PLAYER/STATUS/TOGGLE", function* (action) {
        try {
            const result = yield call(({ IdTeamUserRole }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/activate/${IdTeamUserRole}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send()
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/ROLE/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/ROLE/UPDATE/SUCCESS' });
        }
    });
}

// ROSTER/PLAYER/GAME/STATUS/TOGGLE
export function* togglePlayerStatusInGameSaga() {
    yield* takeEvery("ROSTER/PLAYER/GAME/STATUS/TOGGLE", function* (action) {
        try {
            const result = yield call(({ IdUser, IdTeamDivision, IdGame, IdInactivePlayer }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/roster/game/activate`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ IdUser, IdTeamDivision, IdGame, IdInactivePlayer })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                yield put({ type: 'ROSTER/FETCH', idUser: fakeAuth.getUser().IdUser, idTeamDivision: action.IdTeamDivision });
            } else {
                yield put({ type: 'ROSTER/ROLE/UPDATE/FAIL' });
            }
        } catch (e) {
            yield put({ type: 'ROSTER/ROLE/UPDATE/SUCCESS' });
        }
    });
}

export function* save_generic_match_saga() {
    yield* takeEvery("GENERIC_MATCH/SAVE", function* (action) {

        try {
            const result = yield call(({ match }) => {
                return request[match.IdMatch ? 'patch' : 'post'](`${config.apiEndpoint}/api/v6/scout/generic_match${match.IdMatch ? `/${match.IdMatch}` : ''}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(match)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                if (action.match && action.match.IdTeam) {
                    yield put({ type: 'TEAM/EVALUATIONS/FETCH', IdTeam: action.match.IdTeam });
                }
                if (action.match.ReportType === 86) {
                    yield put({ type: 'CUSTOM_ATTRIBUTES_TEMPLATES/FETCH' });
                }
                if (action.match.ReportType === 44 || action.match.ReportType === 4) {
                    // Depth Charts and Lineups
                    yield put({ type: 'TEAM/LINEUPS/FETCH', IdTeamDivision: action.match.IdTeamDivision });
                }
            } else {
                yield put({
                    type: 'GENERIC_MATCH/SAVE/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'GENERIC_MATCH/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* delete_match_saga() {
    yield* takeEvery("GENERIC_MATCH/DELETE", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.delete(`${config.apiEndpoint}/api/v6/scout/generic_match/${IdMatch}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                if (action.IdTeam) yield put({ type: 'TEAM/EVALUATIONS/FETCH', IdTeam: action.IdTeam });
                yield put({ type: 'CUSTOM_ATTRIBUTES_TEMPLATES/FETCH' });
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* unfollow_match_saga() {
    yield* takeEvery("GENERIC_MATCH/UNFOLLOW", function* (action) {

        try {
            const result = yield call(({ IdMatch, IdUser }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/follow/match/${IdMatch}${IdUser ? `/${IdUser}` : ''}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                yield put({ type: 'MATCH/COLLABORATORS/FETCH', IdMatch: action.IdMatch });
                yield put({ type: 'CUSTOM_ATTRIBUTES_TEMPLATES/FETCH' });
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* follow_match_saga() {
    yield* takeEvery("GENERIC_MATCH/FOLLOW", function* (action) {

        try {
            const result = yield call(({ IdMatch, IdUser }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/match`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ IdUser, IdMatch })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic                
                yield put({ type: 'CUSTOM_ATTRIBUTES_TEMPLATES/FETCH' });
                yield put({ type: 'MATCH/COLLABORATORS/FETCH', IdMatch: action.IdMatch });
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* match_collaborators_saga() {
    yield* takeEvery("MATCH/COLLABORATORS/FETCH", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/collaborators/personal_team/${IdMatch}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic                
                const { collaborators } = result;
                yield put({ type: 'COLLABORATORS/SET', collaborators });
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_questionaries() {
    yield* takeEvery("QUESTIONARIES/FETCH", function* (action) {
        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/questionaries`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { questionaries, custom_forms } = result;
                custom_forms && custom_forms.forEach(c => c.CUSTOM = true);
                yield put({ type: 'QUESTIONARIES/SET', questionaries: [...questionaries, ...custom_forms] });
            } else {
                yield put({
                    type: 'QUESTIONARIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'QUESTIONARIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_topics_saga() {
    yield* takeEvery("TOPICS/FETCH", function* (action) {

        try {
            const result = yield call(({ IdCommunity }) => {
                return request.get(`${config.apiEndpoint}/api/v5/evaluations/topics/${IdCommunity}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { topics } = result;
                yield put({ type: 'TOPICS/SET', topics });
            } else {
                yield put({
                    type: 'TOPICS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TOPICS/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_custom_attributes_templates_saga() {
    yield* takeEvery("CUSTOM_ATTRIBUTES_TEMPLATES/FETCH", function* (action) {

        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/custom_attributes_templates`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { custom_attributes_templates = [] } = result;
                yield put({ type: 'CUSTOM_ATTRIBUTES_TEMPLATES/SET', custom_attributes_templates });
            } else {
                yield put({
                    type: 'TOPICS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TOPICS/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* save_lineup_saga() {
    yield* takeEvery("TEAM/LINEUPS/SAVE", function* (action) {

        try {
            const result = yield call(({ match }) => {
                return request[match.IdMatch ? 'patch' : 'post'](`${config.apiEndpoint}/api/v6/scout/lineup${match.IdMatch ? `/${match.IdMatch}` : ''}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(match)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                // Depth Charts and Lineups
                yield put({ type: 'TEAM/LINEUPS/FETCH', IdTeamDivision: action.match.IdTeamDivision });
            } else {
                yield put({
                    type: 'TEAM/LINEUPS/SAVE/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TEAM/LINEUPS/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* delete_lineup_saga() {
    yield* takeEvery("LINEUP/DELETE", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.delete(`${config.apiEndpoint}/api/v6/scout/generic_match/${IdMatch}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'TEAM/LINEUPS/FETCH', IdTeamDivision: action.IdTeamDivision });                
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}
