//return { type: 'TEAM/UPDATE', idTeamDivision, name }a

import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';

// Updates a team (mostly the name) => Optimistic
export function* updateTeamSaga() {
  yield* takeEvery("TEAM/UPDATE", function* (action) {

    try {
      const result = yield call(({ idTeamDivision, name, idGender }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/teams/${idTeamDivision}`)
          .send({ teamName: name, idGender })
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        // TODO ?
        yield put({
          type: 'GENERIC/SET',
          generic: action.name
        });
      } else {
        yield put({
          type: 'TEAM/UPDATE_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/UPDATE_FAILED',
        e
      });
    }
  });
}

// Updates a team (mostly the name) => Optimistic
export function* updateResultsSaga() {
  yield* takeEvery("TEAM/UPDATE/RESULTS", function* (action) {
    try {
      const result = yield call(({ idTeamDivision, idTournamentTypeDivisionPoints, bonus }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/teams/results/${idTeamDivision}`)
          .send({ idTournamentTypeDivisionPoints, bonus })
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        // TODO ?
        yield put({
          type: 'DIVISIONS/FETCH',
          idSeason: action.idSeason,
          module: 'Flight_Manager'
        })
      } else {
        yield put({ type: 'TEAM/RESULTS/UPDATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TEAM/RESULTS//UPDATE_FAILED', e });
    }
  });
}

// Updates a team (mostly the name) => Optimistic
export function* updateAwards() {
  yield* takeEvery("AWARDS/UPDATE", function* (action) {
    try {
      const result = yield call(({ team }) => {
        return request.post(`${config.apiEndpoint}/api/v4/awards/${team.idTeamDivision}`)
          .send(team)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        // Refresh the whole list (cuz I'm lazy to pinpoint)
        const { idSeason, letter } = action.team;
        yield put({ type: 'AWARDS/FETCH', idSeason, letter });
      } else {
        yield put({ type: 'AWARDS/UPDATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'AWARDS/UPDATE_FAILED', e });
    }
  });
}

//SHIRTS/SIGNATURE/UPDATE
export function* updateShirtsSignatureSaga() {
  yield* takeEvery("SHIRTS/SIGNATURE/UPDATE", function* (action) {
    try {
      const result = yield call(({ team }) => {
        return request.post(`${config.apiEndpoint}/api/v4/uniforms/${team.idTeamDivision}`)
          .send(team)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        // TODO ?        
      } else {
        yield put({ type: 'SHIRTS/SIGNATURE/UPDATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'SHIRTS/SIGNATURE/UPDATE_FAILED', e });
    }
  });
}

//TEAM/RANKING_AGE_GROUP/SET
export function* updateRankingAgeGroup() {
  yield* takeEvery("TEAM/RANKING_AGE_GROUP/SET", function* (action) {
    try {
      const result = yield call(({ idTeam, idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank }) => {
        return request[idTournamentRank ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/rankings/${idTeam}`)
          .send({ idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank })
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        // TODO ? 
        if (!action.idTournamentRank) {
          yield put({ type: 'TEAM/RANKING_AGE_GROUP/COMPLETE', randomId: action.randomId, IdTournamentRank: result.IdTournamentRank })
        }
      } else {
        yield put({ type: 'TEAM/RANKING_AGE_GROUP/SET_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TEAM/RANKING_AGE_GROUP/SET_FAILED', e });
    }
  });
}

export function* getTeamSeasons() {
  yield* takeEvery("TEAMS/SEASONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v4/teams/seasons/${idTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'SEASONS/SET',
          seasons: result.seasons
        });
      } else {
        yield put({
          type: 'SEASONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeam() {
  yield* takeEvery("TEAM/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v4/teams/by_id/${idTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {

        yield put({
          type: 'SELECTED_TEAM/SET',
          team: result.team
        });
      } else {
        yield put({
          type: 'TEAM/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamVideos() {
  yield* takeEvery("TEAM/VIDEOS/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v4/video/by_team/${idTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VIDEOS/SET',
          videos: result.team_videos
        });
        yield put({
          type: 'FAMILY/VIDEOS/SET',
          videos: result.family_videos
        })
        yield put({
          type: 'CLIPS/SET',
          clips: result.clips
        });
        yield put({
          type: 'HIGHLIGHTS/SET',
          highlights: result.highlights
        });
      } else {
        yield put({
          type: 'VIDEOS/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'VIDEOS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamSubscriptions() {
  yield* takeEvery("TEAM/SUBSCRIPTIONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeam }) => {

        return request.get(`${config.apiEndpoint}/api/v4/video/subscriptions/by_team/${idTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TEAM/SUBSCRIPTIONS/SET',
          team_subscriptions: result.subscriptions
        });
        yield put({
          type: 'TEAM/PLANS/SET',
          atp: result.atp
        });

      } else {
        yield put({
          type: 'TEAM/SUBSCRIPTIONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/SUBSCRIPTIONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* subscribeToSQWADTrial() {
  yield* takeEvery("TEAM/SUBSCRIBE/SQWAD", function* (action) {
    try {
      const result = yield call(({ IdTeam, IdPlan, card }) => {
        return request.post(`${config.apiEndpoint}/api/v4/video/${IdTeam ? 'subscribe_team' : 'subscribe_family'}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdTeam, IdPlan, card })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'FAMILY/SUBSCRIPTIONS/FETCH'
        });
        if (action.IdTeam) {
          yield put({
            type: 'TEAM/SUBSCRIPTIONS/FETCH',
            idTeam: action.IdTeam
          });
        }
      } else {
        yield put({
          type: 'TEAM/SUBSCRIBE/FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/SUBSCRIBE/FAILED',
        e
      });
    }
  });
}

export function* getTeamVideo() {
  yield* takeEvery("TEAM/VIDEO/FETCH", function* (action) {
    try {
      const result = yield call(({ idVideo }) => {

        return request.get(`${config.apiEndpoint}/api/v4/video/${idVideo}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VIDEO/SET',
          video: result.video
        });
      } else {
        yield put({
          type: 'VIDEO/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'VIDEO/FETCH_FAILED',
        e
      });
    }
  });
}

export function* updateVideo() {
  yield* takeEvery("VIDEOS/UPDATE", function* (action) {
    try {
      yield call(({ video }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/video/${video.idVideo}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send(video)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'TEAM/VIDEO/FETCH', idVideo: action.video.idVideo });
      yield put({ type: 'TEAM/VIDEOS/FETCH', idTeam: action.video.idTeam });
    } catch (e) {
      yield put({
        type: 'VIDEO/FETCH_FAILED',
        e
      });
    }
  });
}

export function* deleteVideo() {
  yield* takeEvery("VIDEOS/DELETE", function* (action) {
    try {
      yield call(({ video }) => {
        return request.delete(`${config.apiEndpoint}/api/v4/video/${video.idVideo}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'TEAM/VIDEOS/FETCH', idTeam: action.idTeam || 1 });
    } catch (e) {
      yield put({
        type: 'VIDEO/FETCH_FAILED',
        e
      });
    }
  });
}

export function* archiveVideo() {
  yield* takeEvery("VIDEOS/ARCHIVE", function* (action) {
    try {
      yield call(({ idVideo }) => {
        return request.post(`${config.apiEndpoint}/api/v4/video/archive/${idVideo}`)
          .send({})
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'TEAM/VIDEOS/FETCH', idTeam: action.idTeam || 1 });
    } catch (e) {
      yield put({
        type: 'VIDEO/FETCH_FAILED',
        e
      });
    }
  });
}

export function* tagVideo() {
  yield* takeEvery("VIDEOS/UPDATE", function* (action) {
    try {
      yield call(({ video }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/video/tag/${video.idVideo}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send(_.pick(video, 'playerTaggedId', 'isSubmittedTop10'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'TEAM/VIDEO/FETCH', idVideo: action.video.idVideo });
      yield put({ type: 'TEAM/VIDEOS/FETCH', idTeam: action.video.idTeam });
    } catch (e) {
      yield put({
        type: 'VIDEO/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamSchedule() {
  yield* takeEvery("TEAM/SCHEDULE/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeamDivision }) => {

        return request.get(`${config.apiEndpoint}/api/v6/teams/games/${idTeamDivision}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {

        yield put({
          type: 'GAMES/SET',
          games: result.games
        });
      } else {
        yield put({
          type: 'GAMES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAMES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamOtherEvents() {
  yield* takeEvery("TEAM/OTHER_EVENTS/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeamDivision }) => {

        return request.get(`${config.apiEndpoint}/api/v6/teams/next_events/${idTeamDivision}/100`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'EVENTS/SET',
          events: _.reject(result.events, r => r.IdEventType === 2)
        });
      } else {
        yield put({
          type: 'GAMES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAMES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* fetchOpponentsSaga() {
  yield* takeEvery("OPPONENTS/FETCH", function* (action) {
    try {
      const result = yield call(({ }) => {
        return request.get(`${config.apiEndpoint}/api/v4/family/opponents`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'OPPONENTS/SET',
          opponents: result.opponents
        });
      } else {
        yield put({
          type: 'GAMES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAMES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* fetchTeamRights() {
  yield* takeEvery("TEAM/RIGHTS/FETCH", function* (action) {
    try {
      const result = yield call(({ IdTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v5/teams/admin_rights/${IdTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'RIGHTS/SET',
          rights: result.rights
        });
      } else {
        yield put({
          type: 'TEAM/RIGHTS/FETCH/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/RIGHTS/FETCH/FETCH_FAILED',
        e
      });
    }
  });
}


// ATTENDANCE

export function* fetchFamilyAttendaceToGameSaga() {
  yield* takeEvery("TEAM/FAMILY/ATTENDANCE/FETCH", function* (action) {
    try {
      const result = yield call(({ IdGame }) => {
        return request.get(`${config.apiEndpoint}/api/v4/games/attendance/${IdGame}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'FAMILY_ATTENDANCE/SET',
          family_attendance: result.attendance
        });
      } else {
        yield put({
          type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
        e
      });
    }
  });
}

export function* setFamilyAttendaceToGameSaga() {
  yield* takeEvery("TEAM/FAMILY/ATTENDANCE/SET", function* (action) {
    try {
      const result = yield call(({ idEvent, idUser, idEventAttendanceReply }) => {
        return request.post(`${config.apiEndpoint}/api/v4/games/attendance`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ idEvent, idUser, idEventAttendanceReply })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TEAM/FAMILY/ATTENDANCE/FETCH',
          IdGame: action.idEvent
        });
      } else {
        yield put({
          type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
        e
      });
    }
  });
}

export function* fetchTeamAttendaceToGameSaga() {
  yield* takeEvery("TEAM/TEAM/ATTENDANCE/FETCH", function* (action) {
    try {
      const result = yield call(({ IdGame }) => {
        return request.get(`${config.apiEndpoint}/api/v4/games/attendance/${IdGame}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'FAMILY_ATTENDANCE/SET',
          family_attendance: result.attendance
        });
      } else {
        yield put({
          type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'FAMILY_ATTENDANCE/FETCH_FAILED',
        e
      });
    }
  });
}

// UNIFORMS

export function* fetchUniformByGameSaga() {
  yield* takeEvery("TEAM/UNIFORM/GAME/FETCH", function* (action) {
    try {
      const result = yield call(({ IdGame, IdTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v5/uniforms/byGame/${IdTeam}/${IdGame}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'UNIFORM_BAG/SET',
          uniform_bag: result.bag
        });
      } else {
        yield put({
          type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
        e
      });
    }
  });
}

export function* fetchUniformByTeamSaga() {
  yield* takeEvery("TEAM/UNIFORMS/FETCH", function* (action) {
    try {
      const result = yield call(({ IdTeam }) => {
        return request.get(`${config.apiEndpoint}/api/v5/uniforms/bags/${IdTeam}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'UNIFORM_BAGS/SET',
          uniform_bags: result.bags
        });
      } else {
        yield put({
          type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
        e
      });
    }
  });
}

export function* setUniformToGameSaga() {
  yield* takeEvery("TEAM/UNIFORM/GAME/SET", function* (action) {
    try {
      const result = yield call(({ IdTeam, IdGame, items }) => {
        return request.post(`${config.apiEndpoint}/api/v5/uniforms/toGame/${IdTeam}/${IdGame}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ items })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TEAM/UNIFORM/GAME/FETCH',
          IdGame: action.IdGame,
          IdTeam: action.IdTeam
        });
        // TODO: here I should refretch games right?
      } else {
        yield put({
          type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/UNIFORM/GAME/FETCH_FAILED',
        e
      });
    }
  });
}

// LINEUPS

export function* fetchLineupsSaga() {
  yield* takeEvery("TEAM/LINEUPS/FETCH", function* (action) {
    try {
      const result = yield call(({ IdTeamDivision }) => {
        return request.get(`${config.apiEndpoint}/api/v6/scout/lineups/${IdTeamDivision}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LINEUPS/SET',
          lineups: _.filter(result.lineups, r => r.ReportType === 4)
        });
        yield put({
          type: 'DEPTH_CHARTS/SET',
          depth_charts: _.chain(result.lineups)
            .filter(r => r.ReportType === 44)
            .sortBy('LastUpdate').tap(arr => arr.reverse()).value()
        });
      } else {
        yield put({
          type: 'TEAM/LINEUPS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/LINEUPS/FETCH_FAILED',
        e
      });
    }
  });
}