import React from 'react';
import './flight_manager.css';
import { Link } from 'react-router';
import DivisionListItem from './DivisionListItem';
import _ from 'lodash';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import request from 'superagent';
import config from '../../../../config';
import Loader from '../../../common/Loader';
import CrossFlightsManager from '../../../common/modals/CrossFlightsManager';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import Flights from './Flights';
import { Animated } from 'react-animated-css';
import Navigation from '../navigation';

class FlightManager extends React.Component {

    state = {
        bootstrapped: false,
        idDivision: 0,
        selectedDivision: null,
        searching: false,
        isCrossFlightManagerOpen: false,
        isDivisionFilterOpen: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle } = this.props;

        this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
        this.props.flightsActions && this.props.flightsActions.fetchFlights(params.idSeason);
        this.props.crossFlightRulesActions && this.props.crossFlightRulesActions.fetchCrossFlightRules && this.props.crossFlightRulesActions.fetchCrossFlightRules(params.idSeason);
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);

        //
        let idDivision = parseInt(localStorage.getItem("GLOBAL.SELECTED_DIVISION"), 10);
        if (idDivision) { this.props.fetchFlights && this.props.fetchFlights(params.idSeason, idDivision); }
        //

        setTitle && setTitle({
            sub: 'Flight/Group Manager',
            main: this.props.season.Name || null
        });
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Flight/Group Manager',
                main: nextProps.season.Name || null
            });
        }

        if (nextProps.divisions && !this.state.bootstrapped) {
            const idDivision = parseInt(localStorage.getItem("GLOBAL.SELECTED_DIVISION"), 10);
            this.setState({
                bootstrapped: true,
                idDivision
            }, () => {
                if (idDivision) {
                    this.selectDivision(_.find(nextProps.divisions, (division) => { return division.IdDivision === idDivision; }));
                }
            });
        }
    }

    componentWillUnmount = () => {
        this.props.clearFlights &&
            this.props.clearFlights();
        this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();
        this.props.flightsActions && this.props.flightsActions.clearFlights();
        this.props.crossFlightRulesActions && this.props.crossFlightRulesActions.clearCrossFlightRules &&
            this.props.crossFlightRulesActions.clearCrossFlightRules();
    }
    //

    toggleDivisionPicker = () => this.setState({ isDivisionFilterOpen: !this.state.isDivisionFilterOpen });


    addFlight = () => {
        // Update the local copy with the reducer.        
        const { flights } = this.props, { selectedDivision } = this.state;

        if (selectedDivision) flights.push({
            FlightName: `Flight ${flights.length + 1}`,
            MinimumGamesPerTeam: 0,
            teams: [],
            IdDivision: selectedDivision.IdDivision
        });
        this.props.setFlights([...flights]);
        //                                                ^ This needs to be mutated, otherwise the reducer can't tell the difference
    }

    removeFlight = (flight, index) => {

        // Call API
        request.del(`${config.apiEndpoint}/api/v4/flights/${flight.IdFlight}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                // TODO: Handle any kind of error here
            });

        const { flights } = this.props;

        // Update redux
        this.props.setFlights && this.props.setFlights([
            ...flights.slice(0, index),
            ...flights.slice(index + 1)
        ]);

        // Update the flighted teams, removing any team that was on this flight
        flight.teams.forEach(team => {
            this.props.removeFlightedTeam && this.props.removeFlightedTeam(team);
            this.fnUpdateTeam(team);
        });
    }

    selectDivision = (division) => {
        const { params } = this.props;
        this.setState({
            selectedDivision: division,
            idDivision: (division || {}).IdDivision
        });

        localStorage.setItem("GLOBAL.SELECTED_DIVISION", (division || {}).IdDivision);
        if (division) this.props.fetchFlights && this.props.fetchFlights(params.idSeason, division.IdDivision);
    }

    toggleCrossFlightManager = () => this.setState({ isCrossFlightManagerOpen: !this.state.isCrossFlightManagerOpen });

    fnUpdateTeam = (team) => {
        // TODO: Change this routine to the proper react/redux scheme        
        const { divisions } = this.props;
        divisions.forEach(division => {
            division.Teams && division.Teams.forEach(t => {
                if (parseInt(team.IdTeam, 10) === parseInt(t.IdTeam, 10)) { t.Color = null }
            });
        })

        this.forceUpdate();
    }

    notify = () => this.forceUpdate();

    render() {

        const { idDivision, selectedDivision, bootstrapped, isCrossFlightManagerOpen, error, updated, } = this.state,
            { divisions = [], flightedTeams, flights, crossFlightRules, flightsActions, season, league } = this.props;

        //let teams_not_filghted = 0
        if (divisions && divisions.length) {
            /*_.filter(divisions, d => d.Teams && d.Teams.length).forEach(division => {
                teams_not_filghted += _.filter(division.Teams, t => !t.Color).length;
            });*/
            divisions.forEach(d => {
                if (_.find(flights, f => parseInt(f.IdDivision, 10) === parseInt(d.IdDivision, 10))) { d.HasFlights = true; }
            })
        }

        return (

            <section id="registration-fees" style={{ overflow: 'hidden' }}>

                <div className="w3-container mt-4 h-100" style={{ position: 'relative' }}>

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                        <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
                            Season updated!
                        </Alert>
                    </div>

                    {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
                            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                        </div>
                    </div>}

                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <h5>Schedule Management</h5>
                        {season && <Navigation season={season} />}
                    </div>

                    <Animated animationIn="slideInUp" className="m-auto card shadow pt-4 w-100" style={{ maxWidth: 1285, position: 'absolute', top: 150, bottom: 0, left: 0, right: 0, overflow: 'hidden' }}>

                        <div key="with-data" className="d-flex flex-row h-100" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

                            <div className="no-margin no-padding bg-white" style={{ width: 450, overflowY: 'auto' }}>

                                <div className="d-flex flex-row justify-content-center" style={{ backgroundColor: '#c8c8c8', height: 48 }}>
                                    <h3 className="align-self-center black">Teams</h3>
                                </div>

                                {bootstrapped && divisions && divisions.map((division, index) => (
                                    <DivisionListItem key={division.IdDivision} index={index} division={division} flightedTeams={flightedTeams} onDivision={this.selectDivision} />
                                ))}

                                <div style={{ height: 100 }} />
                            </div>

                            <div className="w-100 no-margin no-padding bg-white h-100" style={{ borderLeft: '1px solid gray', position: 'relative', bottom: 0 }}>

                                
                                <div className="d-flex flex-row p-2 w-100" style={{ height: 60, position: 'absolute', top: 0, borderBottom: '1px solid gray' }}>                                    

                                    <div className="d-flex flex-row align-self-center" key="divisionSelector">
                                        <span className="align-self-center margin-left margin-right">Flights for</span>
                                        {divisions && <Dropdown className="align-self-center btn-link" size="sm" isOpen={this.state.isDivisionFilterOpen} toggle={this.toggleDivisionPicker} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                                            <DropdownToggle color="default" className="w-100" caret>
                                                {selectedDivision ? (selectedDivision.Name) : 'Select a division'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem header>------- With Flight</DropdownItem>
                                                {_.filter(divisions, d => d.HasFlights).map((division, i) =>
                                                    <DropdownItem active={idDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                                        <i className={`fas fa-plane margin-right-half`} />{division.Name}
                                                    </DropdownItem>)}
                                                <DropdownItem header>------- Without Flight</DropdownItem>
                                                {_.filter(divisions, d => !d.HasFlights).map((division, i) =>
                                                    <DropdownItem active={idDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                                        {division.Name}
                                                    </DropdownItem>)}
                                            </DropdownMenu>
                                        </Dropdown>}
                                    </div>
                                    {selectedDivision &&
                                        <div className="d-flex ml-auto flex-row-reverse align-self-center" key="topActions">
                                            <button className="ml-auto align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} onClick={this.addFlight}><i className="fa fa-plus"></i> Add Flight</button>
                                            
                                            {flights && flights.length > 1 && <button className="align-self-center green pl-3 pr-3 mr-2" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} type="button" onClick={this.toggleCrossFlightManager}>
                                                <i className="fa fa-random"></i> Cross Flights</button>}
                                        </div>}
                                </div>

                                <section className={`bg-whiteish flights-wrapper`} style={{ overflowY: 'auto', position: 'absolute', top: 60, bottom: 10, left: 0, right: 0, backgroundColor: '#f9f9f9' }}>
                                    
                                    {bootstrapped && flights && selectedDivision && (
                                        <Flights flightsActions={flightsActions} selectedDivision={selectedDivision} flights={_.filter(flights, f => f.IdDivision === selectedDivision.IdDivision)}
                                            divisions={divisions}
                                            removeFlight={this.removeFlight} flightedTeams={flightedTeams} fnNotify={this.notify} fnUpdateTeam={this.fnUpdateTeam} />
                                    )}
                                    {!bootstrapped && <Loader key="loader" message={`Loading Flights`} />}
                                </section>

                            </div>
                        </div>                        
                    </Animated>
                </div>

                { /* Cross-Flight manager */}
                {isCrossFlightManagerOpen && selectedDivision && <CrossFlightsManager selectedDivision={selectedDivision} flights={flights}
                    isOpen={isCrossFlightManagerOpen} toggle={this.toggleCrossFlightManager} rules={crossFlightRules}
                    crossFlightRulesActions={this.props.crossFlightRulesActions} />}
                
            </section>
        )
    }
}

export default DragDropContext(HTML5Backend)(FlightManager);
