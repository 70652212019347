import React from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { formatPrice, detectCreditCardType } from '../../../helpers';
import BlueishHeader from '../../common/things/BlueishHeader';
import request from 'superagent';
import config from '../../../config';
import '../../externals/signature-pad.css';
import _ from 'lodash';
import moment from 'moment';

var SignaturePad = require('react-signature-pad');

class PremiumModal extends React.Component {

    state = {
        target: null,
        user: null,
        mode: 1,
        signed: false,
        invalidCC: false,
        paying: false,
        paid: false,
        error: false,
        family: null,
        discounts: [],
    }

    // Lifecycle
    componentWillMount() {
        const { target, user, family } = this.props;
        this.setState({ target, user, family: [...family] });
    }
    //   

    validateNewCard = () => {
        var valid = true;
        if (!this.txtCardNumber) return valid;
        if (!this.txtCardNumber.value ||
            !this.txtNameOnCard.value ||
            !this.txtExpDate.value ||
            !this.txtCVV.value ||
            !this.txtZipCode.value) {
            valid = false;
        } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
            valid = false;
        }
        return valid;
    }

    onMode = mode => this.setState({ mode });

    onPay = () => {
        const { target } = this.state;
        if (this.validateNewCard()) {
            let selectedCard = {
                number: (this.txtCardNumber || {}).value,
                name: (this.txtNameOnCard || {}).value,
                cvv: (this.txtCVV || {}).value,
                expdate: (this.txtExpDate || {}).value,
                zip: (this.txtZipCode || {}).value
            }
            this.setState({ invalidCC: false, mode: 4, paying: true, paid: false, error: false }, () => {

                request.post(`${config.apiEndpoint}/api/v6/subscriptions/subscribe_users`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({
                        discounts: this.state.discounts,
                        users: [..._.chain(this.state.family)
                            .filter(f => f.selected).map(f => f.IdUser).value(),
                        this.state.user.IdUser],
                        IdPlan: target.IdPlan,
                        card: selectedCard
                    })
                    .then((data) => {
                        if (data.body.success) {
                            this.setState({
                                step: 4,
                                message: data.body.transactionId,
                                paying: false,
                                paid: true
                            }, () => {
                                this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
                                this.props.success && this.props.success(target);                                
                            });

                            // Remove that cart
                        } else {
                            this.setState({
                                step: 4,
                                message: data.body.message,
                                paying: false, error: true
                            })
                        }
                    }, ({ response: { body } }) => {
                        this.setState({
                            step: 4,
                            message: body.error.message,
                            paying: false,
                            error: true
                        });
                    });
            });

        } else {
            this.setState({ invalidCC: true })
        }
    }

    continue = () => {
        var signature = this.refs.mySignature;
        if (signature && signature.isEmpty()) {
            this.setState({
                signatureError: true
            });
            document.getElementById('terms-and-conditions').scrollIntoView();

        } else {
            this.setState({
                signatureDataURL: signature.toDataURL()
            }, function () {
                document.getElementById('btnPay').scrollIntoView();
            });
        }
    }

    clearSignature = () => {
        var signature = this.refs.mySignature;
        signature.clear();
        this.setState({
            signatureDataURL: null,
            signed: false
        })
    }

    signatureEnd = () => this.setState({ signed: true });

    cancel = () => {
        const { family_subscriptions, user } = this.props;
        let current = _.find(family_subscriptions, s => s.IdUser === user.IdUser && !s.Expired);
        request.del(`${config.apiEndpoint}/api/v4/video/subscription/${current.IdSubscription}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                // Call api to bring family plans again
                this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
                this.props.toggle && this.props.toggle();
                /*target.Canceled = true;
                this.setState({ target, cancelling: true });*/
            });
    }

    onToggleMember = id => {
        const { family } = this.state;
        _.find(family, f => f.IdUser === id).selected = !_.find(family, f => f.IdUser === id).selected;
        this.setState({ family });
    }

    onApplyCouponCode = () => {
        const { discounts } = this.state;
        this.setState({ invalidCoupon: false }, () => {
            request.post(`${config.apiEndpoint}/api/v5/coupons/apply`)
                .send({ IdLeague: 1, CouponName: this.txtCouponCode.value })
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(({ body: { coupon } }) => {
                    if (_.find(discounts, c => c.IdCoupon === coupon.IdCoupon)) {
                        this.setState({ invalidCoupon: 'Already applied' });
                        return;
                    }
                    discounts.push(_.pick(coupon, 'IdCoupon', 'AmountPerCoupon', 'PercentPerCoupon', 'PromoDays', 'CouponName'));
                    this.setState({ discounts });
                }, () => {
                    this.setState({ invalidCoupon: 'Invalid Coupon' });
                });
        });
    }

    render() {
        const { isOpen, toggle, user, family_subscriptions, } = this.props,
            { target, mode, signatureError, signed, invalidCC, paying, paid, error, family, discounts } = this.state;

        let current = _.find(family_subscriptions, s => s.IdUser === user.IdUser && !s.Expired);
        let other_family_members = _.chain(family || [])
            .reject(u => u.IdUser === user.IdUser)
            .reject(u => _.find(family_subscriptions, s => s.IdUser === u.IdUser && !s.Expired))
            .value();

        // Calculate totals        
        let amount_coupons = (_.chain(discounts).filter(d => d.AmountPerCoupon).map(d => d.AmountPerCoupon).reduce((a, b) => a + b, 0).value()) || 0;
        let total = target.Amount - amount_coupons;
        _.chain(discounts).filter(d => d.PercentPerCoupon).value().forEach(coupon => {
            total = total - (total * coupon.PercentPerCoupon / 100);
        });

        let coupon_days_restriction = (_.find(discounts, d => d.PromoDays) || { PromoDays: target.Duration }).PromoDays;        

        return (
            <Modal isOpen={isOpen} style={{ width: 600 }}>
                <ModalBody className="container-fluid no-margin no-padding" style={{ background: `url(/images/backgrounds/grass.png) no-repeat center center`, backgroundSize: 'cover', border: '1px solid white', borderRadius: 5 }}>

                    {mode === 1 && (
                        <div className="d-flex flex-row justify-content-center" style={{ position: 'relative' }}>
                            <div className="h-auto w-100 align-self-center montserrat">
                                <span className='sqwad-font white font-40 text-center block my-4 pt-4'>SQWAD</span>

                                {/* VALUE */}
                                <div style={{ height: 150, position: 'relative' }} className="w-100">
                                    <div style={{ backgroundColor: '#FFFFFF', opacity: 0.2, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
                                    <div className="h-100 w-100 d-flex flex-column justify-content-center">
                                        <span className="white toUpper sqwad-font font-16 align-self-center">{target.Name}</span>
                                        <span className="white font-20 align-self-center">{formatPrice(target.Amount * 100)} per month</span>
                                        <span className="white font-8 align-self-center">Auto-renewed every {target.Duration} days</span>
                                    </div>
                                </div>

                                <h3 className="white montserrat toBold text-center block mt-4">{user.NameFirst}</h3>
                                {/* PERKS */}
                                <div className="d-flex flex-column">
                                    <ul className="white mt-4 align-self-center">
                                        <li className="toBold font-16"><i className="icon-recreational_icon mr-2" />Get Recruited!</li>

                                        <li className="font-14"><i className="icon-arrow-up-circle mr-2" />Create and attach highlights to your profile</li>
                                        <li className="font-14"><i className="icon-film-reel-1 mr-2" />Store, Manage and View videos</li>
                                        <li className="font-14"><i className="icon-video-player-1 mr-2" />Use the power of SQWAD Studio</li>
                                        <li className="font-14 mt-4 text-center"><i className="icon-database mr-2" />{target.StorageLabel} Storage space</li>
                                    </ul></div>
                                <div style={{ height: 40 }} />

                                {/* If Current */}
                                {!current && <button onClick={() => this.onMode(2)} style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 20, width: 200 }} className="block mx-auto p-2 text-center pointer">
                                    <span className="white">PURCHASE</span>
                                </button>}

                                {/* Else */}
                                {current && <div>
                                    {!current.Canceled && !this.state.cancelling && <div>
                                        <button onClick={() => this.setState({ cancelling: true })} style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 20, width: 300 }} className="m-auto block p-2 text-center pointer"><span className="white">CANCEL SUBSCRIPTION</span></button>
                                        <span className="block font-8 mt-2 text-center white p-2">Next billing on {moment(current.StartDate.split('T')[0], 'YYYY-MM-DD').add(current.Duration, 'days').format('MMM Do YYYY')}</span>
                                    </div>}
                                    {!current.Canceled && this.state.cancelling && <div>
                                        <button onClick={this.cancel} style={{ border: '3px solid #dc3545', borderRadius: 20, width: 300 }} className="btn-danger m-auto block p-2 text-center pointer" ><span className="white">CONFIRM CANCELATION</span></button>
                                        <div onClick={() => this.setState({ cancelling: false })} style={{ width: 250 }} className="m-auto p-2 text-center pointer" ><span className="white font-8">Nevermind</span></div>
                                    </div>}
                                    {current.Canceled && <div>
                                        <button style={{ border: '3px solid #dc3545', borderRadius: 20, width: 300 }} className="btn-danger m-auto block p-2 text-center pointer" ><span className="white">SUBSCRIPTION CANCELLED</span></button>
                                        <span className="block font-8 mt-2 text-center white">It will be active until {moment(current.StartDate.split('T')[0], 'YYYY-MM-DD').add(current.Duration, 'days').format('MMM Do YYYY')}</span>
                                    </div>}
                                </div>}
                                <span className="my-4 block font-10 text-center white">Terms of Service and Privacy Policy</span>
                            </div>
                            <i className="fa fa-times font-20 white" style={{ position: 'absolute', top: 10, right: 10 }} onClick={toggle} />
                        </div>
                    )}

                    {/* TERMS AND CONDITIONS */}
                    {mode === 2 && <div className="h-100" style={{ position: 'relative' }}>
                        <span className="bg-info p-2 text-center w-100 block white">Terms and Payment</span>
                        <div id="terms-and-conditions" style={{ height: 359, overflowY: 'scroll' }} className="white m-auto font-8">
                            {this.props.disclaimer.sportslogic && (
                                <section className="bg-white black">
                                    <div className="p-2" dangerouslySetInnerHTML={{ __html: this.props.disclaimer.sportslogic }} />
                                </section>
                            )}
                        </div>
                        { /* Signature */}
                        {!signatureError && <BlueishHeader message="Please Sign Here" />}
                        {signatureError && <div className="bg-warning p-2 big-font text-center">Please Sign!</div>}
                        <div className="m-auto w-100" style={{ position: 'relative' }}>
                            <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                            <button className="btn btn-link float-right" onClick={this.clearSignature} style={{ position: 'absolute', top: 0, right: 0 }}>Clear</button>
                        </div>
                        <button id="" className={`btn btn-lg btn-block btn-${signed ? 'success' : 'info'} bigest-font`} onClick={() => this.onMode(3)}>Continue</button>

                        <i className="fa fa-arrow-left font-18 white" style={{ position: 'absolute', top: 6, left: 8 }} onClick={() => this.onMode(1)} />

                    </div>}


                    {/* PAYMENT */}
                    {mode === 3 && <div className="h-100" style={{ position: 'relative' }}>

                        <div style={{ height: 150, position: 'relative' }} className="w-100">
                            <div style={{ backgroundColor: '#FFFFFF', opacity: 0.2, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
                            <div className="h-100 w-100 d-flex flex-column justify-content-center montserrat">
                                <span className="white font-30 align-self-center">TOTAL: {formatPrice(total * 100 * (_.filter(other_family_members, o => o.selected).length + 1))}</span>
                                {total < target.Amount && <span className="white font-12 align-self-center">{`-${formatPrice((target.Amount - total) * 100 * (_.filter(other_family_members, o => o.selected).length + 1))} for ${coupon_days_restriction} days`}</span>}
                            </div>
                        </div>

                        <div className="p-3">

                            {/* Coupon Codes */}
                            <section className={`m-auto font-12 align-self-center bg-white p-3`} style={{ position: 'relative', borderRadius: 4 }}>
                                <div className="d-flex flex-row" style={{ position: 'relative' }}>
                                    <input className="form-control align-self-center p-4 w-100" placeholder="Coupon Code" type="text" ref={(i) => this.txtCouponCode = i} />
                                    <button onClick={this.onApplyCouponCode} className="btn btn-sm btn-default align-self-end w-25 align-self-center ml-2">APPLY</button>
                                    {this.state.invalidCoupon && <span className="red" style={{ position: 'absolute', top: 10, right: 130 }}>{this.state.invalidCoupon}</span>}
                                </div>
                            </section>

                            {/* Add family members */}
                            {other_family_members.length && <section>
                                <div className="py-2">
                                    <span className='white font-16 montserrat'>Add family members</span>
                                </div>
                                {other_family_members && other_family_members.map((user, key) => <div onClick={() => this.onToggleMember(user.IdUser)} key={user.IdUser} className={`font-12 align-self-center bg-white p-2 mb-2 d-flex flex-row`} style={{ position: 'relative', borderRadius: 4 }}>
                                    <div className="cover" style={{ width: 50, height: 50, borderRadius: 25, background: `url(${user.UserImage}) no-repeat center center black` }} />
                                    <div className="d-flex flex-column ml-3 align-self-center">
                                        <span className="montserrat line1 font-14">{`${user.NameFirst} ${user.NameLast}`}</span>
                                        <span className="montserrat gray line1 font-12">+{formatPrice(target.Amount * 100)}</span>
                                    </div>
                                    {user.selected && <i className="fas fa-circle-check mr-3 ml-auto align-self-center green" />}
                                </div>)}
                            </section>}

                            {total > 0 && <div className="py-2">
                                <span className='white font-16 montserrat'>Credit Card Information</span>
                            </div>}
                            {total > 0 && invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                            {total > 0 && <section className={`m-auto font-12 align-self-center bg-white p-3`} style={{ position: 'relative', borderRadius: 4 }}>
                                <div className="d-flex flex-row">
                                    <input className="form-control align-self-center p-4 w-100" placeholder="Card Number" maxLength="16" type="text" ref={(i) => this.txtCardNumber = i} />
                                </div>
                                <div className="d-flex flex-row pt-2">
                                    <input className="form-control align-self-center p-4 w-100" placeholder="Name on Card" type="text" ref={(i) => this.txtNameOnCard = i} />
                                </div>
                                <div className="d-flex flex-row pt-2">
                                    <input className="form-control align-self-center p-4 w-100" placeholder="Exp. Date (MMYY)" maxLength="4" type="text" ref={(i) => this.txtExpDate = i} />
                                    <div style={{ width: 20 }} />
                                    <input className="form-control align-self-center p-4 w-100" placeholder="CVV" type="text" maxLength="4" ref={(i) => this.txtCVV = i} />
                                </div>
                                <div className="d-flex flex-row pt-2">
                                    <input className="form-control align-self-center p-4 w-50" placeholder="Zip | Postal" type="text" ref={(i) => this.txtZipCode = i} />
                                </div>
                            </section>}
                            <button id="btnPay" className="btn btn-lg btn-block btn-success bigest-font align-self-end mt-2" onClick={this.onPay}>
                                {total > 0 ? `Pay ${formatPrice(total * 100 * (_.filter(other_family_members, o => o.selected).length + 1))}` : 'Purchase'}
                            </button>
                        </div>
                        <i className="fa fa-arrow-left font-20 white" style={{ position: 'absolute', top: 10, left: 10 }} onClick={() => this.onMode(2)} />
                    </div>}

                    {/* PLACEBO */}
                    {mode === 4 && <div className="bg-dark-blue d-flex flex-row justify-content-center" style={{ height: 400 }}>
                        {paying && <div className="h-auto d-flex flex-column justify-content-center no-margin text-center" key="step1">
                            <i className="fa fa-credit-card bigest-font heart align-self-center white" />
                            <h1 className="margin-top-half align-self-center white">Processing, please wait...</h1>
                        </div>}
                        {paid && <div className="h-auto w-100 d-flex flex-column justify-content-center no-margin text-center bg-success" key="step2" onClick={toggle}>
                            <i className="fa fa-check white bigest-font" />
                            <h1 className="white">Registration successful</h1>
                            <h3 className="white">{formatPrice(target.Amount * 100)}</h3>
                            <h3 className="white font-8">{this.state.message}</h3>
                        </div>}
                        {error && <div className="h-auto w-100 d-flex flex-column justify-content-center no-margin text-center bg-danger"
                            onClick={() => this.onMode(3)} key="step3" style={{ position: 'relative' }}>
                            <i className="fa fa-times white bigest-font" />
                            <h1 className="white">Registration failed</h1>
                            <h3 className="white pl-2 pr-2">{this.state.message}</h3>
                            <i className="fa fa-arrow-left font-20 white" style={{ position: 'absolute', top: 10, left: 10 }} />
                        </div>}
                    </div>}
                </ModalBody>
            </Modal>
        )
    }
}

export default PremiumModal;
