import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../CustomDatePicker';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';
import TeamSwap from './TeamSwap';
import TeamReplace from './TeamReplace';


class AdjustMultipleGames extends React.Component {

    state = {
        saved: false,
        division: {
            value: 0,
            label: 'All divisions'
        },
        location: {
            value: 0,
            label: 'All locations'
        },
        newIdLocation: null,
        selectedDate: moment(),
        selectedTime: null,
        newSelectedDate: moment(),
        gamesDuration: 90,
        minutesToGameDuration: null,
        minutesToGameStart: null,
        games: [],
        allSelected: false,
        actions: {
            moveDate: false,
            moveLocation: false,
            changeDuration: false,
            changeMinutesToGameDuration: false,
            changeMinutesToGameStart: false,
            keepGameSpacing: true
        },
        locationOptions: [],
        filteredLocations: [],
        activeTab: 1,
        IdTeamDivision_Source: null,
        IdTeamDivision_Destination: null,
        swapping: false
    }

    // Lifecycle
    componentWillMount() {
        const { games, divisions, locations, selectedDate, teams } = this.props;        
        games.forEach(game => {
            game.DivisionName = (_.find(divisions, d => {
                return parseInt(d.value, 10) === parseInt(game.IdDivision, 10);
            }) || { label: 'N/A' }).label;            
        });
        this.setState({
            selectedDate: selectedDate || games.length ? moment(_.chain(games).sortBy('GameDate').first().value().GameDate) : moment(),
            games: [...games],
            filteredLocations: [{
                label: 'All Locations',
                value: 0
            }, ..._.filter(locations, l => _.find(games, g => parseInt(g.IdLocation, 10) === parseInt(l.value, 10)))],
            locationOptions: [{
                label: 'All Locations',
                value: 0
            }, ...locations],
            teams
        });
    }
    //

    divisionChange = (obj) => {
        const { games } = this.state;
        games.forEach(g => {
            g.markedForAction = false;
        });

        const { divisions } = this.props;
        let idDivision = obj ? obj.value : 0;
        this.setState({
            division: _.find(divisions, d => parseInt(d.value, 10) === parseInt(idDivision, 10)),
            games,
            allSelected: false
        });
    }

    locationChange = (obj) => {
        const { games } = this.state;
        games.forEach(g => {
            g.markedForAction = false;
        });
        const { locationOptions } = this.state;
        let idLocation = obj ? obj.value : 0;
        this.setState({
            location: _.find(locationOptions, l => parseInt(l.value, 10) === parseInt(idLocation, 10)),
            games,
            allSelected: false
        });
    }

    newLocationChange = (obj) => {
        const { actions } = this.state;
        actions.moveLocation = true;
        let idLocation = obj ? obj.value : 0;
        this.setState({
            newIdLocation: idLocation,
            actions
        });
    }

    handleChangeSelectedDate = (date) => {
        const { games } = this.state;
        games.forEach(function (g) {
            g.markedForAction = false;
        });
        this.setState({
            selectedDate: moment(date),
            games,
            allSelected: false
        });
    }

    handlerSelectNewDate = (date) => {
        const { actions } = this.state;
        actions.moveDate = true;
        this.setState({ newSelectedDate: moment(date), actions });
    }

    getFilteredGames = () => {
        const { games } = this.props,
            { selectedDate, location, division } = this.state;

        return _.chain(games)
            .filter((game) => {
                return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
            })
            .filter((game) => {
                if (location.value) {
                    return parseInt(game.Location.IdLocation, 10) === parseInt(location.value, 10);
                }
                return true;
            })
            .filter((game) => {
                if (division.value) {
                    return parseInt(game.IdDivision, 10) === parseInt(division.value, 10);
                }
                return true;
            })
            .value();
    }

    toggleSelectAll = () => {
        this.setState({
            allSelected: !this.state.allSelected
        }, function () {
            const { allSelected, games, selectedDate, division, location } = this.state;

            _.chain(games)
                .filter(game => {
                    return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
                })
                .filter(game => {
                    if (location.value) {
                        return parseInt(game.Location.IdLocation, 10) === parseInt(location.value, 10);
                    } else {
                        return true;
                    }
                })
                .filter(game => {
                    if (division.value) {
                        return game.IdDivision === division.value;
                    }
                    return true;
                })
                .value().forEach(g => {
                    g.markedForAction = allSelected;
                });
            this.setState({ games });
        })
    }

    markGameForAction = (index, idGame) => {
        const { games, } = this.state;
        _.find(games, g => g.IdGame === idGame).markedForAction = !_.find(games, g => g.IdGame === idGame).markedForAction;
        this.setState({
            games,
            allSelected: _.find(this.getFilteredGames(), function (g) {
                return !g.markedForAction;
            }) ? false : true
        });
    }

    toggleAction = (i) => {
        const { actions } = this.state;
        switch (i) {
            case 1:
                actions.moveDate = !actions.moveDate;
                break;
            case 2:
                actions.moveLocation = !actions.moveLocation;
                break;
            case 3:
                actions.changeDuration = !actions.changeDuration;
                break;
            case 4:
                actions.changeMinutesToGameDuration = !actions.changeMinutesToGameDuration;
                break;
            case 5:
                actions.changeMinutesToGameStart = !actions.changeMinutesToGameStart;
                break;
            case 6:
                actions.keepGameSpacing = !actions.keepGameSpacing;
                break;
            default: break
        }
        this.setState({ actions });
    }

    save = () => {
        const { games, actions } = this.state,
            { toggle } = this.props;

        if (this.state.saving) return;

        this.setState({ saved: false, saving: true });

        const payload = {
            Games: _.chain(games).filter(g => {
                return g.markedForAction
            }).map(g => {
                return {
                    IdGame: g.IdGame
                };
            }).value(),
            GameDate: actions.moveDate ? this.state.newSelectedDate : undefined,
            MinutesToGameDuration: actions.changeMinutesToGameDuration ? parseInt(this.txtMinutesToGameDuration.value, 10) : undefined,
            MinutesToGameStart: actions.changeMinutesToGameStart ? parseInt(this.txtMinutesToGameStart.value, 10) : undefined,
            GameSpacing: actions.keepGameSpacing || undefined,
            IdLocation: actions.moveLocation ? this.state.newIdLocation : undefined,
        }

        // Smart Call aPI        
        request.patch(`${config.apiEndpoint}/api/v4/games/adjust/multiple/v2`)
            .send(payload)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(data => {
                if (data.body.success) {
                    this.setState({ saved: true, saving: false });
                    toggle && toggle();
                    this.props.seasonActions &&
                        this.props.seasonActions.fetchGames(this.props.idSeason);
                }
            });
    }
    toggleTimes = () => this.setState({ isTimesOpen: !this.state.isTimesOpen });
    selectTime = (selectedTime) => this.setState({ selectedTime });
    changeTab = i => this.setState({ activeTab: i });
    onChangeTeamSource = obj => {
        let id = obj ? obj.value : null;
        this.setState({ IdTeamDivision_Source: id });
    }
    onChangeTeamDestination = obj => {
        let id = obj ? obj.value : null;
        this.setState({ IdTeamDivision_Destination: id });
    }

    onChange = () => {
        const { toggle } = this.props;
        const { IdTeamDivision_Destination, IdTeamDivision_Source, swapping } = this.state;
        if (swapping) return;
        this.setState({ swapping: true }, () => {
            if (IdTeamDivision_Destination && IdTeamDivision_Source) {
                // Proceed
                // Smart Call aPI        
                request.post(`${config.apiEndpoint}/api/v4/schedule/teams/replace`)
                    .send({ IdTeamDivision_Destination, IdTeamDivision_Source })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then(data => {
                        if (data.body.success) {
                            toggle && toggle();
                            this.props.seasonActions &&
                                this.props.seasonActions.fetchGames(this.props.idSeason);
                        } else {
                            alert('There was a problem')
                            this.setState({ swapping: false })
                        }
                    });
            }
        });
    }

    render() {
        const { isOpen, toggle, divisions, locations } = this.props,
            { saved, division, location, newIdLocation, selectedDate, selectedTime,
                games, allSelected, actions, filteredLocations, minutesToGameDuration, minutesToGameStart, activeTab } = this.state;

        const highlightWithRanges = [{ "highlighted-date": _.map(games, 'GameDate') }];
        const filteredGames = this.getFilteredGames();

        return <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader className="no-padding">
                <ul className="nav nav-tabs font-16">
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                            <i className="fas fa-cog blue"></i> {true ? <span className="half-idented">Adjust Games</span> : ''}</button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}>
                            <i className="fas fa-retweet green"></i> {true ? <span className="half-idented">Swap Teams</span> : ''}</button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}>
                            <i className="fas fa-exchange-alt red"></i> {true ? <span className="half-idented">Replace Teams</span> : ''}</button>
                    </li>
                </ul>
            </ModalHeader>

            {activeTab === 1 && <ModalBody>
                <div className="d-flex flex-row padding-top-half padding-bottom-half under-ish white-back bg-white">
                    <span className="align-self-center margin-left margin-right-half">Division:</span>

                    <Select clearable={false} style={{ width: '180px' }} className={`align-self-center`} options={divisions} value={division.value} name="selectDivision"
                        onChange={this.divisionChange} placeholder="Select a division" />
                    <span className="align-self-center margin-left margin-right-half">Location:</span>

                    <Select clearable={false} style={{ width: '180px' }} className={`align-self-center`} options={filteredLocations} value={location.value} name="selectLocation"
                        onChange={this.locationChange} placeholder="Select a location" />

                    <div className="align-self-center idented">
                        <DatePicker highlightDates={highlightWithRanges} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.selectedDate}
                            startDate={this.state.selectedDate} onChange={this.handleChangeSelectedDate} />
                    </div>

                    <div className="align-self-center idented">
                        {/* Time */}
                        <button id="btn-times-filters" onClick={this.toggleTimes} className="btn btn-info white idented font-10">
                            <i className="fa fa-clock" />
                            <span className="idented-half">{selectedTime ? selectedTime.Time : 'Time'}</span>
                            <i className="fa fa-caret-down idented-half" />
                        </button>
                        <Popover placement="bottom" isOpen={this.state.isTimesOpen} target="btn-times-filters" toggle={this.toggleTimes}>
                            <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                {/* All */}
                                <button onClick={() => this.selectTime()} className={`btn btn-link btn-sm btn-block text-left`}>All</button>
                            </PopoverBody>
                        </Popover>
                    </div>
                </div>
                <div style={{ height: '300px', overflowY: 'auto' }}>
                    {filteredGames.length > 0 && <table className="table table-sm font-8 centered">
                        <thead>
                            <tr className="bg-info">
                                <th>
                                    <button className="btn btn-sm btn-info"
                                        onClick={this.toggleSelectAll}><i className={`fa-${allSelected ? 'check-square fas' : 'square far'}`} /></button>
                                </th>
                                <th>ID</th>
                                <th>When</th>
                                <th>Division</th>
                                <th>Flight</th>
                                <th>Teams</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredGames.map((game, i) => (
                                <tr key={i}>
                                    <td>
                                        <button className="btn btn-sm" onClick={() => this.markGameForAction(i, game.IdGame)}>
                                            <i className={`fa-${game.markedForAction ? 'check-square fas' : 'square far'}`} /></button>
                                    </td>
                                    <td>
                                        {game.IdGame}
                                    </td>
                                    <td>
                                        <div>
                                            {game.GameDate.format('MMM Do YYYY @ h:mmA')}
                                        </div>
                                        <div>({game.GameDurationMinutes} minutes)</div>
                                    </td>
                                    <td>
                                        {game.DivisionName}
                                    </td>
                                    <td>
                                        {game.FlightName}
                                    </td>
                                    <td>
                                        {[game.HomeTeam.Name, game.AwayTeam.Name].join(' VS ')}
                                    </td>
                                    <td>
                                        {game.Location.LocationName || game.Location.Name || game.LocationName}
                                    </td>
                                </tr>))}
                        </tbody>
                    </table>}
                    {filteredGames.length === 0 && (
                        <div className="jumbotron text-center margin-top margin-left margin-right">
                            <h1 className="display-3">No games found!</h1>
                            <p>
                                {['on', selectedDate.format('MMM Do YYYY'), 'for', division.label, 'at', location.label].join(' ')}
                            </p>
                        </div>)}
                </div>
            </ModalBody>}

            {activeTab === 1 && <ModalBody>
                <div className="bg-info"><span className="idented">Actions</span></div>
                <div className="container-fluid padding-bottom-half">
                    <div className="row">
                        <div className="col-12 d-flex flex-row margin-top-half">
                            <button className="btn btn-sm" onClick={() => this.toggleAction(1)}>
                                <i className={`fa-${actions.moveDate ? 'check-square fas' : 'square far'}`} /></button>
                            <span className={`align-self-center`}>Move {_.filter(filteredGames, function (g) {
                                return g.markedForAction
                            }).length || ''} game(s) to another day</span>
                            <div className="align-self-center idented">
                                <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.newSelectedDate}
                                    startDate={this.state.newSelectedDate} onChange={this.handlerSelectNewDate} />
                            </div>
                        </div>
                        <div className="col-12 d-flex flex-row margin-top-half">
                            <button className="btn btn-sm" onClick={() => this.toggleAction(2)}>
                                <i className={`fa-${actions.moveLocation ? 'check-square fas' : 'square far'}`} /></button>
                            <span className={`align-self-center`}>Move {_.filter(filteredGames, function (g) {
                                return g.markedForAction
                            }).length || ''} game(s) to another location</span>
                            <Select style={{ width: '200px' }} className={`align-self-center idented`} options={locations} value={newIdLocation} onChange={this.newLocationChange} placeholder="Select new location"
                            />
                        </div>

                        <div className="w-100 margin-top-half" style={{ height: 1, backgroundColor: 'black' }} />

                        <div className="col-12 d-flex flex-row margin-top-half">
                            <button className="btn btn-sm" onClick={() => this.toggleAction(4)}>
                                <i className={`fa-${actions.changeMinutesToGameDuration ? 'check-square fas' : 'square far'}`} /></button>
                            <span className={`align-self-center margin-right`}>+/- minutes to game(s) duration</span>
                            <input className="form-control form-control-sm" type="number" min="0" style={{ width: '80px' }} defaultValue={minutesToGameDuration} ref={(input) => {
                                this.txtMinutesToGameDuration = input;
                            }} />
                            <span className={`align-self-center margin-left`}>minutes</span>
                        </div>

                        <div className="col-12 d-flex flex-row margin-top-half">
                            <button className="btn btn-sm" onClick={() => this.toggleAction(5)}>
                                <i className={`fa-${actions.changeMinutesToGameStart ? 'check-square fas' : 'square far'}`} /></button>
                            <span className={`align-self-center margin-right`}>+/- minutes to game(s) start time</span>
                            <input className="form-control form-control-sm" type="number" min="0" style={{ width: '80px' }} defaultValue={minutesToGameStart} ref={(input) => {
                                this.txtMinutesToGameStart = input;
                            }} />
                            <span className={`align-self-center margin-left`}>minutes</span>
                        </div>

                        {(actions.changeMinutesToGameDuration || actions.changeMinutesToGameStart) && <div className="col-12 d-flex flex-row margin-top-half">
                            <button className="btn btn-sm" onClick={() => this.toggleAction(6)}>
                                <i className={`fa-${actions.keepGameSpacing ? 'check-square fas' : 'square far'}`} /></button>
                            <span className={`align-self-center margin-right`}>Keep game spacing</span>
                        </div>}

                    </div>
                </div>
            </ModalBody>}

            {activeTab === 2 && <ModalBody>
                <TeamSwap {...this.props} />
            </ModalBody>}

            {activeTab === 3 && <ModalBody>
                <TeamReplace {...this.props} />
            </ModalBody>}

            {saved && <ModalBody className="bg-success text-center white">Adjusted!</ModalBody>}

            {activeTab === 1 && <ModalFooter>
                {!this.state.saving && <button className="btn btn-sm btn-success" onClick={this.save}>Save changes on
                    {' '}
                    {_.filter(filteredGames, function (g) {
                        return g.markedForAction
                    }).length || 'zero'} game(s) </button>}
                {this.state.saving && <button className="btn btn-sm btn-success">Saving, please wait...</button>}
            </ModalFooter>}
        </Modal>
    }
}

export default AdjustMultipleGames;
