import React from 'react';
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';
import { Jumbotron } from 'reactstrap';

class Team extends React.Component {

  componentWillMount() {
    const { team } = this.props;    
    if (!team.roster) {
      // TODO: move this to encrypted version?      
      const url = _.compact([config.apiEndpoint, 'api/v4/roster', team.IdTeamDivision]).join('/');
      request.get(url)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(({ body }) => {          
          team.roster = {
            coaches: _.filter(body.roster, r => r.IdRole === 4),
            players: _.filter(body.roster, r => r.IdRole !== 4),
          };
          this.forceUpdate();
        });
    }
  }

  render() {

    const { team } = this.props;

    return (<div className="container-fluid margin-top-half">
      {team.roster && <div className="row">
        <div className="col-5">
          {team.TeamImage && <img style={{ height: 100 }} src={team.TeamImage} alt="" />}
          <h2>Id: {team.IdTeamDivision}</h2>
          <hr />
          <h3>Contact Information</h3>
          {team.roster && team.roster.coaches.length > 0 && (
            <div className="mb-4">
              <div>{`${_.first(team.roster.coaches).NameFirst} ${_.first(team.roster.coaches).NameLast}`}</div>
              {_.first(team.roster.coaches).PhoneNumber_Format && <div>{_.first(team.roster.coaches).PhoneNumber_Format}</div>}
              {_.first(team.roster.coaches).EmailAddress && <div>{_.first(team.roster.coaches).EmailAddress}</div>}
            </div>)}
        </div>
        <div className="col-7">
          <h3>Roster</h3>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Admins</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {team.roster && team.roster.coaches.map((coach, i) => (
                <tr key={i}>
                  <td>
                    {`${coach.NameFirst} ${coach.NameLast}`}
                  </td>
                  <td>
                    {coach.RoleOverrideName}
                  </td>
                </tr>))}
            </tbody>
          </table>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Players</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {team.roster && team.roster.players.map((player, i) => (
                <tr key={i}>
                  <td>
                    {`${player.NameFirst} ${player.NameLast}`}
                  </td>
                  <td>
                    {player.Number}
                  </td>
                </tr>))}
            </tbody>
          </table>
        </div>
      </div>}
      {!team.roster && <Jumbotron>
        <h1>Fetching roster</h1>
        <h3>Please wait...</h3>
      </Jumbotron>}
    </div>)
  }
}

export default Team;
