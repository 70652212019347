import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import AddOpponent from './AddOpponent';
import AddLocation from './AddLocation';
import request from 'superagent';
import config from '../../../../config';

class AddGame extends React.Component {
    state = {
        game: {
            IsHome: true,
            DateStart: moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').add(24 + 14, 'hours'),
            DateEnd: moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').add(24 + 15, 'hours'),
            MinutesEarly: 0
        },
        arrive_options: [
            { title: 'On Time', value: 0 },
            { title: '15 Minutes earlier', value: 15 },
            { title: '20 Minutes earlier', value: 20 },
            { title: '30 Minutes earlier', value: 30 },
            { title: '1 Hour earlier', value: 60 }
        ]
    }
    componentWillMount() {
        // TODO: api call to bring opponents and locations
        const { opponents } = this.props;
        this.setState({ opponents });
        setTimeout(() => {
            this.setState({ bootstrapped: true });
        }, 200);
    }

    onSave = () => {
        const { game, microTransac } = this.state;
        if (microTransac) return;

        // TODO: validate and call API
        if (!game.Opponent) {
            alert('Please select your opponent');
            return;
        }
        if (!game.IdLocation) {
            alert('Please select a location');
            return;
        }
        this.setState({ microTransac: true }, () => {
            /*
            */

            request.post(`${config.apiEndpoint}/api/v4/family/game`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    idTeamDivision: this.props.idTeamDivision,
                    idTeamOther: parseInt(game.Opponent.IdTeamOther, 10),
                    idLocation: game.IdLocation,
                    dateTimeStart: game.DateStart.format(),
                    dateTimeEnd: game.DateEnd.format(),
                    minutesEarly: game.MinutesEarly,
                    notes: this.txtNotes.value,
                    notify: false,
                    isHome: game.IsHome
                })
                .then(({ body: { users } }) => {
                    // Decrypt                   
                    this.setState({ bootstrapped: false }, () => {
                        setTimeout(this.props.fnAdded, 200);
                    });
                }, reject => {
                    alert('An error has occurred');
                    this.setState({ microTransac: false });
                });

        });


    }
    render() {
        const { game } = this.state;
        return <section>

            <section className="fullscreen" style={{ zIndex: 10001 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10002, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">CREATE GAME</span>
                </div>
                {/* HOME / AWAY */}
                <div className="d-flex flex-row mt-4">
                    <div className="w-100 justify-content-center">
                        <div className="align-self-center d-flex flex-row justify-content-center">
                            <i className={`${this.state.game.IsHome ? 'fas fa-circle green' : 'far fa-circle black'} font-30 align-self-center pointer`} onClick={() => {
                                const { game } = this.state;
                                game.IsHome = true;
                                this.setState({ game });
                            }} />
                            <span className="font-16 black montserrat ml-2 align-self-center">Home</span>
                        </div>
                    </div>
                    <div className="w-100 justify-content-center">
                        <div className="align-self-center d-flex flex-row justify-content-center">
                            <i className={`${!this.state.game.IsHome ? 'fas fa-circle green' : 'far fa-circle black'} font-30 align-self-center pointer`} onClick={() => {
                                const { game } = this.state;
                                game.IsHome = false;
                                this.setState({ game })
                            }} />
                            <span className="font-16 black montserrat ml-2 align-self-center">Away</span>
                        </div>
                    </div>
                </div>

                {/* OPPONENT */}
                <div className="montserrat font-10 black mt-3 mx-4">Opponent</div>
                {!game.Opponent && <div onClick={() => this.setState({ adding_opponent: true })} className="pointer tile card p-3 mx-4 bg-white black d-flex flex-row justify-content-center"><i className="fas fa-circle-plus mr-2 align-self-center" /><span className="align-self-center">Select your opponent</span></div>}
                {game.Opponent && <div onClick={() => this.setState({ adding_opponent: true })} className="pointer tile card p-3 mx-4 white d-flex flex-row justify-content-center" style={{ backgroundColor: '#617790' }}><span className="align-self-center">{game.Opponent.TeamOtherName}</span></div>}


                <div className="mx-4 mt-3 react-datepicker-full">
                    <div className="montserrat font-10 black">Start</div>
                    <DatePicker
                        className="w-100  d-flex flex-row"
                        customInput={<CustomDatePicker format={`ddd, MMM Do YYYY @ hh:mmA`} />}
                        selected={moment(this.state.game.DateStart)}
                        selectsStart
                        dateFormat="ddd, MMM Do YYYY @ hh:mmA"
                        showTimeSelect
                        startDate={this.state.game.DateStart}
                        endDate={this.state.game.DateEnd}
                        onChange={(e) => {
                            const { game } = this.state;
                            game.DateStart = e;
                            if (game.DateStart.isAfter(game.DateEnd)) game.DateEnd = moment(e).add(1, 'hour');
                            this.setState({ game });
                        }} />
                </div>
                <div className="mx-4 mt-3 react-datepicker-full">
                    <div className="montserrat font-10 black">End</div>
                    <DatePicker
                        className="w-100  d-flex flex-row"
                        customInput={<CustomDatePicker format={`ddd, MMM Do YYYY @ hh:mmA`} />}
                        selected={moment(this.state.game.DateEnd)}
                        selectsEnd
                        dateFormat="ddd, MMM Do YYYY @ hh:mmA"
                        showTimeSelect
                        startDate={this.state.game.DateStart}
                        endDate={this.state.game.DateEnd}
                        onChange={(e) => {
                            const { game } = this.state;
                            game.DateEnd = e;
                            if (game.DateEnd.isBefore(game.DateStart)) game.DateStart = moment(e).add(-1, 'hours');
                            this.setState({ game });
                        }} />
                </div>

                <div className="montserrat font-10 black mt-3 mx-4">Arrive time</div>
                <Dropdown isOpen={this.state.pick_arrival} toggle={() => this.setState({ pick_arrival: !this.state.pick_arrival })} className="tile card p-3 mx-4 font-16 bg-white black">
                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                        <span className="black align-self-center">{game.MinutesEarly !== null ? _.find(this.state.arrive_options, c => c.value === game.MinutesEarly).title : 'Select'}</span>
                        <i className="ml-auto fas fa-caret-down align-self-center black" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                        {this.state.arrive_options.map((c) => <DropdownItem key={c.value} onClick={() => {
                            const { game } = this.state;
                            game.MinutesEarly = c.value;
                            this.setState({ game });
                        }}>
                            {c.title}
                        </DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>

                {/* LOCATION */}
                <div className="montserrat font-10 black mt-3 mx-4">Location</div>
                {!game.IdLocation && <div onClick={() => this.setState({ adding_location: true })} className="tile card p-3 mx-4 bg-white black d-flex flex-row justify-content-center"><i className="fas fa-map-marker mr-2 align-self-center" /><span className="align-self-center">Select the location</span></div>}
                {game.IdLocation && <div onClick={() => this.setState({ adding_location: true })} className="pointer tile card p-3 mx-4 white d-flex flex-row justify-content-center" style={{ backgroundColor: '#617790' }}><span className="align-self-center">{game.LocationName}</span></div>}

                {/* NOTES */}
                <div className="montserrat font-10 black mt-3 mx-4">Notes</div>
                <div className="mx-4">
                    <textarea defaultValue={game.Notes} className={`form-control`} rows="4" name="notes" placeholder="Notes" ref={(input) => this.txtNotes = input} /></div>

                {/* SAVE BUTTON */}
                <button onClick={this.onSave} className="btn btn-success mt-4 mb-4 mx-4" style={{ borderRadius: 30 }}>SAVE</button>
            </Animated>

            {this.state.adding_opponent && <AddOpponent toggle={() => this.setState({ adding_opponent: false })}
                opponents={this.props.opponents}
                teamActions={this.props.teamActions}
                fn={o => {
                    const { game } = this.state;
                    game.Opponent = o;
                    this.setState({ adding_opponent: false });
                }} />
            }

            {this.state.adding_location && <AddLocation toggle={() => this.setState({ adding_location: false })}
                locations={this.props.locations}
                teamActions={this.props.teamActions}
                fn={o => {
                    const { game } = this.state;
                    game.LocationName = o.LocationName;
                    game.IdLocation = parseInt(o.IdLocation, 10);
                    this.setState({ adding_location: false });
                }} />
            }
        </section>
    }
}

export default AddGame;