import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';
import moment from 'moment';
import { humanize_user } from '../../helpers';

// Get all the seasons of a league (as well as the league itself)
export function* getSeasonsSaga() {
  yield* takeEvery("SEASONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/all/${idLeague}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(data => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'SEASONS/SET',
          seasons: result.seasons
        });
        yield put({
          type: 'LEAGUE/SET',
          league: result.league
        });
      } else {
        yield put({
          type: 'SEASONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASONS/FETCH_FAILED',
        e
      });
    }
  });
}

// Get all the seasons of a league (as well as the league itself)
export function* getSeasonSaga() {
  yield* takeEvery("SEASON/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/season/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(data => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'SEASON/SET',
          season: result.season
        });
      } else {
        yield put({
          type: 'SEASON/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/FETCH_FAILED',
        e
      });
    }
  });
}

// Get dashboard information of a season
export function* getSeasonDashboardSaga() {
  yield* takeEvery("SEASON/DASHBOARD/FETCH", function* (action) {
    try {
      const result = yield call(idSeason => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/dashboard/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(data => {
            return (JSON.parse(data.text))
          });
      }, action.idSeason);
      if (result && result.success) {
        const { refunds, pendings, received, charges } = result;
        yield put({
          type: 'SEASON/SET',
          season: result.season
        });
        yield put({
          type: 'LEAGUE/SET',
          league: result.league
        });
        yield put({
          type: 'DETAILS/SET',
          details: {
            refunds, pendings, received, charges
          }
        });
      } else {
        yield put({
          type: 'SEASON/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/FETCH_FAILED',
        e
      });
    }
  });
}

// Get the games of the season
export function* getSeasonGamesSaga() {
  yield* takeEvery("SEASON/FETCH_GAMES", function* (action) {
    try {
      const result = yield call(({ idSeason, date }) => {
        if (date) {

          return request.get(`${config.apiEndpoint}/api/v4/games/${idSeason}/${date}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
              return (JSON.parse(data.text))
            });
        } else {

          return request.get(`${config.apiEndpoint}/api/v4/games/calendar/${idSeason}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
              return (JSON.parse(data.text))
            });
        }
      }, action);

      if (result && result.success) {
        yield put({
          type: 'GAMES/SET',
          games: result.games
        });
      } else {
        yield put({
          type: 'GAMES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAMES/FETCH_FAILED',
        e
      });
    }
  });
}

// Get the games of the season
export function* getScoreEntryGamesSaga() {
  yield* takeEvery("SEASON/SCORE_ENTRY/FETCH_GAMES", function* (action) {
    try {
      const result = yield call(({ idSeason, date }) => {
        if (date) {

          return request.get(`${config.apiEndpoint}/api/v4/games/score_entry/${idSeason}/${date}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
              return (JSON.parse(data.text))
            });
        } else {

          return request.get(`${config.apiEndpoint}/api/v4/games/calendar/${idSeason}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => JSON.parse(data.text));
        }
      }, action);

      if (result && result.success) {
        yield put({
          type: 'GAMES/SET',
          games: result.games
        });
      } else {
        yield put({
          type: 'GAMES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAMES/FETCH_FAILED',
        e
      });
    }
  });
}

// Get all the coaches of a season
export function* getSeasonCoachesSaga() {
  yield* takeEvery("SEASON/FETCH_COACHES", function* (action) {
    try {
      const result = yield call(idSeason => {
        return request.get(`${config.apiEndpoint}/api/v3/seasons/coaches/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idSeason);
      if (result && result.success) {
        yield put({
          type: 'COACHES/SET',
          coaches: result.coaches
        });
      } else {
        yield put({
          type: 'COACHES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'COACHES/FETCH_FAILED',
        e
      });
    }
  });
}

// Get seasons available for registration
export function* getSeasonsForRegistrationSaga() {
  yield* takeEvery("SEASONS/REGISTRATION/FETCH", function* (action) {
    try {
      const result = yield call(idLeague => {
        return request.get(`${config.apiEndpoint}/api/v6/seasons/${idLeague}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        // Decrypt
        result.seasons.forEach(o => humanize_user(o));
        //
        // Only the seasons that match:
        yield put({
          type: 'SEASONS/SET',
          seasons: _.chain(result.seasons)
            .reject(function (season) {
              return moment(season.RegistrationEnd || season.DateEnd).diff(moment()) <= 0;
            })
            .uniqBy((s) => {
              return s.IdSeason;
            })
            .sortBy('DateEnd')
            .value().reverse()
        });
      } else {
        yield put({
          type: 'SEASONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASONS/FETCH_FAILED',
        e
      });
    }
  });
}

// Get all the seasons in where the user has a Payment in action
export function* getSeasonsForPaymentsSaga() {
  yield* takeEvery("SEASONS/PAYMENTS/FETCH", function* (action) {
    try {
      const result = yield call(idLeague => {
        return request.get(`${config.apiEndpoint}/api/v6/seasons/${idLeague}/1`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        result.seasons.forEach(o => humanize_user(o));
        // Only the seasons that match:
        yield put({
          type: 'SEASONS/SET',
          seasons: _.chain(result.seasons)
            .uniqBy((s) => {
              return s.IdSeason;
            })
            .sortBy('DateEnd')
            .value().reverse()
        });
      } else {
        yield put({
          type: 'SEASONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASONS/FETCH_FAILED',
        e
      });
    }
  });
}

// Get the disclaimer of a season
export function* getSeasonDisclaimerSaga() {
  yield* takeEvery("DISCLAIMER/FETCH", function* (action) {
    try {
      const result = yield call(idSeason => {
        return request.get(`${config.apiEndpoint}/api/v4/registration/disclaimer/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idSeason);
      if (result && result.success) {
        yield put({
          type: 'DISCLAIMER/SET',
          disclaimer: result
        });
      } else {
        yield put({
          type: 'DISCLAIMER/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'DISCLAIMER/FETCH_FAILED',
        e
      });
    }
  });
}

// A second version of a saga that gets coaches of a season ? 
// TODO: check for deprecation
export function* getSeasonCoachesSaga2() {
  yield* takeEvery("SEASON/COACHES/FETCH", function* (action) {
    try {
      const result = yield call(idSeason => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/coaches/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idSeason);
      if (result && result.success) {
        yield put({
          type: 'COACHES/SET',
          coaches: result.coaches
        });
      } else {
        yield put({
          type: 'COACHES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'COACHES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* assignQuestionLibraryGroupSaga() {
  yield* takeEvery("SEASON/QUESTION_LIBRARY_GROUP/ASSIGN", function* (action) {
    try {
      const result = yield call(({ idSeason, idQuestionLibraryGroup, idQuestionGroupLocation }) => {
        return request.post(`${config.apiEndpoint}/api/v4/questions/link/season/${idSeason}/${idQuestionLibraryGroup}/${idQuestionGroupLocation}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      if (result && result.success) {
        const { idSeason } = action;
        yield put({
          type: 'APPLIED_QUESTIONS/FETCH',
          idSeason
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/QUESTION_LIBRARY_GROUP/ASSIGN_FAILED',
        e
      });
    }
  });
}

export function* archiveQuestionLibraryGroupSaga() {
  yield* takeEvery("SEASON/QUESTION_LIBRARY_GROUP/ARCHIVE", function* (action) {
    try {
      const result = yield call(({ IdQuestionGroup }) => {
        return request.delete(`${config.apiEndpoint}/api/v4/questions/link/season/${IdQuestionGroup}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      if (result && result.success) {
        const { idSeason } = action;
        yield put({
          type: 'APPLIED_QUESTIONS/FETCH',
          idSeason
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/QUESTION_LIBRARY_GROUP/ARCHIVE_FAILED',
        e
      });
    }
  });
}

// A second version of a saga that gets coaches of a season ? 
// TODO: check for deprecation
export function* getSeasonFiltersSaga() {
  yield* takeEvery("SEASON/FILTERS/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/filters2/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      if (result && result.success) {
        yield put({
          type: 'FILTERS/SET',
          filters: result.filters
        });
      } else {
        yield put({
          type: 'SEASON/FILTERS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/FILTERS/FETCH_FAILED',
        e
      });
    }
  });
}

// A second version of a saga that gets coaches of a season ? 
// TODO: check for deprecation
export function* getTournamentPointTypes() {
  yield* takeEvery("TORUNAMENT/POINT_TYPES/FETCH", function* (action) {
    try {
      const result = yield call(({ idCommunity }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/point_types/${idCommunity}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      if (result && result.success && result.types.length) {
        yield put({
          type: 'TORUNAMENT/POINT_TYPES/SET',
          types: result.types
        });
      } else {
        yield put({
          type: 'TORUNAMENT/POINT_TYPES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TORUNAMENT/POINT_TYPES/FETCH_FAILED',
        e
      });
    }
  });
}

// AWARDS/FETCH
export function* getAwardsSaga() {
  yield* takeEvery("AWARDS/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason, letter }) => {
        return request.get(`${config.apiEndpoint}/api/v4/awards/${idSeason}${letter ? `/${letter}` : ''}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        yield put({
          type: 'TEAMS/SET',
          teams: result.teams
        });
      } else {
        yield put({ type: 'AWARDS/FETCH_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'AWARDS/FETCH_FAILED', e });
    }
  });
}

//SHIRTS/SIGNATURE/FETCH
export function* getShirtsSignagureSaga() {
  yield* takeEvery("SHIRTS/SIGNATURE/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason, letter }) => {
        return request.get(`${config.apiEndpoint}/api/v4/uniforms/${idSeason}${letter ? `/${letter}` : ''}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        yield put({
          type: 'TEAMS/SET',
          teams: result.teams
        });
      } else {
        yield put({ type: 'SHIRTS/SIGNATURE/FETCH_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'SHIRTS/SIGNATURE/FETCH_FAILED', e });
    }
  });
}

export function* setSeasonRosterLimitSaga() {
  yield* takeEvery("SEASON/SET_ROSTER_LIMIT", function* (action) {
    try {
      const result = yield call(({ idSeason, limit }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/seasons/roster_limit/${idSeason}/${limit || 0}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        // optimistic
      } else {
        yield put({ type: 'SEASON/SET_ROSTER_LIMIT/_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'SEASON/SET_ROSTER_LIMIT/_FAILED', e });
    }
  });
}

// FIELDS LAYOUT
export function* getFieldsLayoutSaga() {
  yield* takeEvery("SEASON/FIELD_LAYOUT/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/fields_layout/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        const { fields } = result;
        yield put({ type: 'FIELD_LAYOUT/SET', fields });
      } else {
        yield put({ type: 'FIELD_LAYOUT/_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'FIELD_LAYOUT/_FAILED', e });
    }
  });
}

export function* fetchPointsSystemSaga() {
  yield* takeEvery("SEASON/POINT_SYSTEM/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/point_system/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        const { points, contest_type, settings } = result;
        yield put({ type: 'POINTS/SET', points });
        yield put({ type: 'CONTEST_TYPE/SET', contest_type });
        yield put({ type: 'SETTINGS/SET', settings });
      } else {
        yield put({ type: 'POINTS/_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'POINTS/_FAILED', e });
    }
  });
}

export function* fetchSlotsSaga() {
  yield* takeEvery("SLOTS/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/slots/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => JSON.parse(data.text));
      }, action);
      if (result && result.success) {
        const { slots } = result;
        yield put({ type: 'SLOTS/SET', slots });
      } else {
        yield put({ type: 'SLOTS/FETCH_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'SLOTS/FETCH_FAILED', e });
    }
  });
}

//LEAGUE/PCV/FETCH
export function* getPCVsBySeasonSaga() {
  yield* takeEvery("SEASON/PCV/FETCH", function* (action) {

    try {
      const result = yield call(({ idSeason, letter }) => {
        return request.get(`${config.apiEndpoint}/api/v4/seasons/pcv/${idSeason}/${letter}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { users } = result;
        yield put({ type: 'USERS/SET', users });
      } else {
        yield put({
          type: 'USERS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'USERS/FETCH_FAILED',
        e
      });
    }
  });
}

// LEAGUE/PCV/VALIDATE
// Optimistic
export function* validatePCVSeasonSaga() {
  yield* takeEvery("SEASON/PCV/VALIDATE", function* (action) {
    try {
      yield call(({ idUser, idSeason }) => {
        return request.post(`${config.apiEndpoint}/api/v4/seasons/pcv`)
          .send({ idUser, idSeason })
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}


export function* unvalidatePCVSeasonSaga() {
  yield* takeEvery("SEASON/PCV/UNVALIDATE", function* (action) {
    try {
      yield call(({ idUser, idSeason }) => {
        return request.del(`${config.apiEndpoint}/api/v4/seasons/pcv/${idUser}/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getMedals() {
  yield* takeEvery("MEDALS/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(idSeason ? `${config.apiEndpoint}/api/v5/medals/by_season/${idSeason}` : `${config.apiEndpoint}/api/v5/medals`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'MEDALS/SET',
          medals: result.medals
        });
      } else {
        yield put({ type: 'MEDALS/FETCH_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'MEDALS/FETCH_FAILED', e });
    }
  });
}

//LEAGUE/VALIDATIONS/FETCH
export function* getValidationsBySeasonSaga() {
  yield* takeEvery("SEASON/VALIDATIONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/by_season/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VALIDATIONS/SET',
          validations: result.validations
        });
        yield put({
          type: 'PROCESSES/SET', processes: result.processes
        })

      } else {
        yield put({
          type: 'SEASON/VALIDATIONS/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'SEASON/VALIDATIONS/FETCH_FAILED',
        e
      });
    }
  });
}

// VIOLATIONS
export function* getSeasonViolationsByUserSaga() {
  yield* takeEvery("SEASON/VIOLATIONS/USER/FETCH", function* (action) {
    try {
      const result = yield call(({ idTeamUserRole }) => {
        return request.get(`${config.apiEndpoint}/api/v5/violations/by_user/${idTeamUserRole}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VIOLATIONS/SET',
          violations: result.violations
        });
      } else {
        yield put({
          type: 'SEASON/VIOLATIONS/USER/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'SEASON/VIOLATIONS/USER/FETCH_FAILED',
        e
      });
    }
  });
}

export function* addUserViolationSaga() {
  yield* takeEvery("USER/VIOLATION/ADD", function* (action) {
    try {
      const result = yield call(({ violation }) => {
        return request.post(`${config.apiEndpoint}/api/v5/violations/${violation.IdTeamUserRole}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send(violation)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'SEASON/VIOLATIONS/USER/FETCH',
          idTeamUserRole: action.violation.IdTeamUserRole
        });
      } else {
        yield put({
          type: 'SEASON/VIOLATIONS/USER/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'SEASON/VIOLATIONS/USER/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getViolationTypesSaga() {
  yield* takeEvery("SEASON/VIOLATIONS/TYPES/FETCH", function* (action) {
    try {
      const result = yield call(({ idCommunity }) => {
        return request.get(`${config.apiEndpoint}/api/v5/violations/by_community/${idCommunity
          }`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VIOLATION/TYPES/SET',
          violation_types: result.violation_types
        });
      }

    } catch (e) {
      yield put({
        type: 'SEASON/VIOLATIONS/TYPES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* removeUserViolationSaga() {
  yield* takeEvery("USER/VIOLATION/REMOVE", function* (action) {
    try {
      yield call(({ IdViolation }) => {
        return request.del(`${config.apiEndpoint}/api/v5/violations/${IdViolation}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      yield put({
        type: 'SEASON/VIOLATIONS/USER/FETCH',
        idTeamUserRole: action.IdTeamUserRole
      });

    } catch (e) {
      yield put({
        type: 'SEASON/VIOLATIONS/USER/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getRolesBySeasonSaga() {
  yield* takeEvery("SEASON/ROLES/FETCH", function* (action) {

    try {
      const result = yield call(({ idSeason }) => {

        const url = `${config.apiEndpoint}/api/v4/roles/${idSeason}`;

        return request.get(url)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {

        yield put({
          type: 'ROLES/SET',
          roles: result.roles
        });

      } else {
        yield put({
          type: 'ROLES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

//SEASON/VALIDATIONS/SUMMARY/FETCH
export function* validationsSummaryBySeasonFetchSaga() {
  yield* takeEvery("SEASON/VALIDATIONS/SUMMARY/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason, idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/summary/by_season/${idLeague}/${idSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { totals, validations, spread, league_summary } = result;
        let validations_summary = { totals, validations, spread, league_summary }
        yield put({ type: 'VALIDATIONS/SUMMARY/SET', validations_summary });
      } else {
        yield put({
          type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
        e
      });
    }
  });
}
//










//SEASON/SCOUT_REGISTRATIONS/FETCH
export function* fetchSeasonScoutRegistrationsSaga() {
  yield* takeEvery("SEASON/SCOUT_REGISTRATIONS/FETCH", function* (action) {
    try {
      const result = yield call(({ IdSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v6/scout/registration/${IdSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)          
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { registrations } = result;
        yield put({ type: 'SCOUT_REGISTRATIONS/SET', scout_registrations: registrations });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}


//SEASON/SCOUT_PROGRAM/ASSIGN
export function* assignSeasonScoutRegistrationProgramSaga() {
  yield* takeEvery("SEASON/SCOUT_PROGRAM/ASSIGN", function* (action) {
    try {
      const result = yield call(({ IdSeason, IdCatalogItem }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/seasons/assign_scout_program`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdSeason, IdCatalogItem })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}

//SEASON/SCOUT_PROGRAM/REMOVE
export function* removeSeasonScoutRegistrationProgramSaga() {
  yield* takeEvery("SEASON/SCOUT_PROGRAM/REMOVE", function* (action) {
    try {
      const result = yield call(({ IdSeason }) => {
        return request.delete(`${config.apiEndpoint}/api/v4/seasons/remove_scout_program/${IdSeason}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}

//SEASON/SCOUT_ADJUSTMENT/ASSIGN
export function* assignSeasonScoutRegistrationAdjustmentSaga() {
  yield* takeEvery("SEASON/SCOUT_ADJUSTMENT/ASSIGN", function* (action) {
    try {
      const result = yield call(({ IdSeason, IdCatalogItemPriceAdjustmentByDate }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/seasons/scout_registration/adjustment/add`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdSeason, IdCatalogItemPriceAdjustmentByDate })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}

//SEASON/SCOUT_ADJUSTMENT/REMOVE
export function* removeSeasonScoutRegistrationAdjustmentSaga() {
  yield* takeEvery("SEASON/SCOUT_ADJUSTMENT/REMOVE", function* (action) {
    try {
      const result = yield call(({ IdSeason, IdCatalogItemPriceAdjustmentByDate }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/seasons/scout_registration/adjustment/remove`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdSeason, IdCatalogItemPriceAdjustmentByDate })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}


// SEASON/QUESTION_GROUP/ASSIGN
export function* assignQuestionGroupToSeasonSaga() {
  yield* takeEvery("SEASON/QUESTION_GROUP/ASSIGN", function* (action) {
    try {
      const result = yield call(({ IdSeason, IdQuestionGroup }) => {
        return request.patch(`${config.apiEndpoint}/api/v6/seasons/assign_question_group`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdSeason, IdQuestionGroup })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}

// SEASON/QUESTION_GROUP/ASSIGN
export function* removeQuestionGroupFromSeasonSaga() {
  yield* takeEvery("SEASON/QUESTION_GROUP/REMOVE", function* (action) {
    try {
      const result = yield call(({ IdSeason, IdQuestionGroup }) => {
        return request.patch(`${config.apiEndpoint}/api/v6/seasons/remove_question_group`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .send({ IdSeason, IdQuestionGroup })
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { } = result;
        yield put({ type: 'SEASON/FETCH', idSeason: action.IdSeason });
        // HERE I think I should retrieve the season object again?
        // or perhaps it is updated optimistically 
      } else {
        yield put({
          type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'SEASON/SCOUT_PROGRAM/ASSIGN_FAILED',
        e
      });
    }
  });
}