import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import AddOpponent from './AddOpponent';
import AddLocation from './AddLocation';
import request from 'superagent';
import config from '../../../../config';

class AddPractice extends React.Component {
    state = {
        game: this.props.entity ? {
            ...this.props.entity,
            DateStart: moment(this.props.entity.DateTimeStart).utc(),
            DateEnd: moment(this.props.entity.DateTimeEnd).utc()
        } : {
            DateStart: moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').add(24 + 14, 'hours'),
            DateEnd: moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').add(24 + 15, 'hours'),
            MinutesEarly: 0,
            IdEventRecurrenceRule: 0,
            IdEventRecurrence: 0,
            IdEventType: 4
        },
        arrive_options: [
            { title: 'On Time', value: 0 },
            { title: '15 Minutes earlier', value: 15 },
            { title: '20 Minutes earlier', value: 20 },
            { title: '30 Minutes earlier', value: 30 },
            { title: '1 Hour earlier', value: 60 }
        ],
        repeat_options: [
            { value: 0, title: 'Never' },
            { value: 1, title: 'Daily' },
            { value: 2, title: 'Weekly' },
            { value: 3, title: 'Monthly' },
        ],
        max_days: 120
    }
    componentWillMount() {
        setTimeout(() => {
            this.setState({ bootstrapped: true });
        }, 200);
    }

    onSave = () => {
        const { game, microTransac } = this.state;
        if (microTransac) return;

        // TODO: validate and call API        
        if (!game.IdLocation) {
            alert('Please select a location');
            return;
        }
        this.setState({ microTransac: true }, () => {
            /*
            IdEventType, IdLocation, EventText, EventTypeOther, Notify, DateTimeStart, DateTimeEnd, MinutesEarly,
            IdEventRecurrenceRule, IdEventRecurrent, EventRepeatEndDate, recipients, IsVisibleToRecruiters
            */

            request[game.IdEvent ? 'patch' : 'post'](`${config.apiEndpoint}/api/v5/family/event${game.IdEvent ? `/${game.IdEvent}` : ''}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    IdEvent: game.IdEvent,
                    IdEventType: game.IdEventType,
                    IdLocation: game.IdLocation,
                    EventText: this.txtNotes.value,
                    EventTypeOther: 'Practice',
                    Notify: false,
                    DateTimeStart: game.DateStart.format(),
                    DateTimeEnd: game.DateEnd.format(),
                    MinutesEarly: game.MinutesEarly,
                    recipients: [{ IdTeamDivision: this.props.idTeamDivision }],
                    IdEventRecurrenceRule: game.IdEventRecurrenceRule,
                    EventRepeatEndDate: game.DateRepeatEnd ? moment(game.DateRepeatEnd).format('YYYY-MM-DD') : null,
                    ApplyChangesToRecurrent: true, // TODO: check this
                    IsVisibleToRecruiters: false,
                    IdEventRecurrent: game.IdEventRecurrenceRule ? game.IdEventRecurrence : null
                })
                .then(({ }) => {
                    this.setState({ bootstrapped: false }, () => {
                        setTimeout(this.props.fnAdded, 200);
                    });
                }, reject => {
                    alert('An error has occurred');
                    this.setState({ microTransac: false });
                });

        });


    }

    render() {
        const { game } = this.state;        
        return <section>

            <section className="fullscreen" style={{ zIndex: 10001 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10002, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">

                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">CREATE TRAINING</span>
                </div>

                <div className="mx-4 mt-3 react-datepicker-full">
                    <div className="montserrat font-10 black">Start</div>
                    <DatePicker
                        className="w-100  d-flex flex-row"
                        customInput={<CustomDatePicker format={`ddd, MMM Do YYYY @ hh:mmA`} />}
                        selected={moment(this.state.game.DateStart)}
                        selectsStart
                        dateFormat="ddd, MMM Do YYYY @ hh:mmA"
                        showTimeSelect
                        startDate={this.state.game.DateStart}
                        endDate={this.state.game.DateEnd}
                        onChange={(e) => {
                            const { game } = this.state;
                            game.DateStart = e;
                            if (game.DateStart.isAfter(game.DateEnd)) game.DateEnd = moment(e).add(1, 'hour');
                            this.setState({ game });
                        }} />
                </div>
                <div className="mx-4 mt-3 react-datepicker-full">
                    <div className="montserrat font-10 black">End</div>
                    <DatePicker
                        className="w-100  d-flex flex-row"
                        customInput={<CustomDatePicker format={`ddd, MMM Do YYYY @ hh:mmA`} />}
                        selected={moment(this.state.game.DateEnd)}
                        selectsEnd
                        dateFormat="ddd, MMM Do YYYY @ hh:mmA"
                        showTimeSelect
                        startDate={this.state.game.DateStart}
                        endDate={this.state.game.DateEnd}
                        onChange={(e) => {
                            const { game } = this.state;
                            game.DateEnd = e;
                            if (game.DateEnd.isBefore(game.DateStart)) game.DateStart = moment(e).add(-1, 'hours');
                            this.setState({ game });
                        }} />
                </div>

                {/* REPEAT */}
                {!game.IdEvent && <div className="montserrat font-10 black mt-3 mx-4">Repeat</div>}
                {!game.IdEvent && <Dropdown isOpen={this.state.pick_repeat} toggle={() => this.setState({ pick_repeat: !this.state.pick_repeat })} className="tile card p-3 mx-4 font-16 bg-white black">
                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                        <span className="black align-self-center">{game.IdEventRecurrenceRule !== null ? _.find(this.state.repeat_options, c => c.value === game.IdEventRecurrenceRule).title : 'Select'}</span>
                        <i className="ml-auto fas fa-caret-down align-self-center black" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                        {this.state.repeat_options.map((c) => <DropdownItem key={c.value} onClick={() => {
                            const { game } = this.state;
                            game.IdEventRecurrenceRule = c.value;
                            game.DateRepeatEnd = game.DateStart;
                            let max_days = 365;
                            switch (game.IdEventRecurrenceRule) {
                                case 1: max_days = 120; break;
                                case 2: max_days = 31 * 6; break;
                                case 3: max_days = 365; break;
                                case 4: max_days = 365 * 3; break;
                                default: break;
                            }
                            /*
                            
        $.txtRepeatEnd.applyMinDate && $.txtRepeatEnd.applyMinDate(moment($.txtStart.getValue().value));
        $.txtRepeatEnd.applyMaxDate && $.txtRepeatEnd.applyMaxDate(moment($.txtStart.getValue().value).add(max_days, 'days'));
        $.repeat_wrapper.applyProperties({ height: Ti.UI.SIZE, visible: true });
        $.sv_week_days.applyProperties({
            top: $.txtRepeat.getValue().value === 2 ? 10 : 0,
            visible: $.txtRepeat.getValue().value === 2 ? true : false,
            height: $.txtRepeat.getValue().value === 2 ? 60 : 0,
        });*/

                            this.setState({ game, max_days });
                        }}>
                            {c.title}
                        </DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>}

                {/* REPEAT PATTERN */}
                {game.IdEventRecurrenceRule === 2 && !game.IdEvent && <div className="mx-4 mt-3 d-flex flex-row tile card bg-white justify-content-center p-2">

                    {/* SUNDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 1) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 1) game.IdEventRecurrence -= 1;
                        else game.IdEventRecurrence += 1;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">S</span></div>

                    {/* MONDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 2) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 2) game.IdEventRecurrence -= 2;
                        else game.IdEventRecurrence += 2;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">M</span></div>

                    {/* TUESDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 4) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 4) game.IdEventRecurrence -= 4;
                        else game.IdEventRecurrence += 4;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">T</span></div>

                    {/* WEDNESDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 8) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 8) game.IdEventRecurrence -= 8;
                        else game.IdEventRecurrence += 8;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">W</span></div>

                    {/* THURSDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 16) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 16) game.IdEventRecurrence -= 16;
                        else game.IdEventRecurrence += 16;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">T</span></div>

                    {/* FRIDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 32) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 32) game.IdEventRecurrence -= 32;
                        else game.IdEventRecurrence += 32;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">F</span></div>

                    {/* SATURDAY */}
                    <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: (game.IdEventRecurrence & 64) ? 'green' : 'transparent' }} onClick={() => {
                        const { game } = this.state;
                        if (game.IdEventRecurrence & 64) game.IdEventRecurrence -= 64;
                        else game.IdEventRecurrence += 64;
                        this.setState({ game });
                    }} className="mx-1 pointer bordered align-self-center d-flex flex-row justify-content-center"><span className="align-self-center black font-12">S</span></div>


                </div>}

                {game.IdEventRecurrenceRule > 0 && !game.IdEvent && <div className="mx-4 mt-3 react-datepicker-full">
                    <div className="montserrat font-10 black">End Repeat</div>
                    <DatePicker
                        className="w-100 d-flex flex-row"
                        customInput={<CustomDatePicker format={`ddd, MMM Do YYYY`} />}
                        selected={moment(this.state.game.DateRepeatEnd)}
                        maxDate={moment().add(this.state.max_days, 'days')}
                        dateFormat="ddd, MMM Do YYYY"
                        onChange={(e) => {
                            const { game } = this.state;
                            game.DateRepeatEnd = e;
                            this.setState({ game });
                        }} />
                </div>}

                {/* ARRIVE TIME */}
                <div className="montserrat font-10 black mt-3 mx-4">Arrive time</div>
                <Dropdown isOpen={this.state.pick_arrival} toggle={() => this.setState({ pick_arrival: !this.state.pick_arrival })} className="tile card p-3 mx-4 font-16 bg-white black">
                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                        <span className="black align-self-center">{game.MinutesEarly !== null ? _.find(this.state.arrive_options, c => c.value === game.MinutesEarly).title : 'Select'}</span>
                        <i className="ml-auto fas fa-caret-down align-self-center black" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                        {this.state.arrive_options.map((c) => <DropdownItem key={c.value} onClick={() => {
                            const { game } = this.state;
                            game.MinutesEarly = c.value;
                            this.setState({ game });
                        }}>
                            {c.title}
                        </DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>

                {/* LOCATION */}
                <div className="montserrat font-10 black mt-3 mx-4">Location</div>
                {!game.IdLocation && <div onClick={() => this.setState({ adding_location: true })} className="tile card p-3 mx-4 bg-white black d-flex flex-row justify-content-center"><i className="fas fa-map-marker mr-2 align-self-center" /><span className="align-self-center">Select the location</span></div>}
                {game.IdLocation && <div onClick={() => this.setState({ adding_location: true })} className="pointer tile card p-3 mx-4 white d-flex flex-row justify-content-center" style={{ backgroundColor: '#617790' }}><span className="align-self-center">{game.LocationName}</span></div>}

                {/* NOTES */}
                <div className="montserrat font-10 black mt-3 mx-4">Notes</div>
                <div className="mx-4">
                    <textarea defaultValue={game.Notes} className={`form-control`} rows="4" name="notes" placeholder="Notes" ref={(input) => this.txtNotes = input} /></div>

                {/* SAVE BUTTON */}
                <button onClick={this.onSave} className="btn btn-success mt-4 mb-4 mx-4" style={{ borderRadius: 30 }}>SAVE</button>
            </Animated>

            {this.state.adding_location && <AddLocation toggle={() => this.setState({ adding_location: false })}
                locations={this.props.locations}
                teamActions={this.props.teamActions}
                fn={o => {
                    const { game } = this.state;
                    game.LocationName = o.LocationName;
                    game.IdLocation = parseInt(o.IdLocation, 10);
                    this.setState({ adding_location: false });
                }} />
            }
        </section>
    }
}

export default AddPractice;