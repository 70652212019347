import React from 'react';
import request from 'superagent';
import config from '../../../config';
import { Link } from 'react-router';
import _ from 'lodash';

class PlayerTeams extends React.Component {
    state = {}
    componentWillMount() {
        const { idUser, idSeason, idTeamUserRole } = this.props;
        request.get(`${config.apiEndpoint}/api/v5/teams/by_user/${idUser}/${idSeason}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body }) => {
                let { teams } = body;                
                this.setState({ team: idTeamUserRole ? _.find(teams, t => t.IdTeamUserRole === parseInt(idTeamUserRole, 10)) : _.first(teams || []) });
            });
    }
    componentWillReceiveProps = nextProps => {
        const { idUser, idSeason, idTeamUserRole } = nextProps;
        if (idUser === this.props.idUser && idSeason === this.props.idSeason && idTeamUserRole === this.props.idTeamUserRole) return;
        request.get(`${config.apiEndpoint}/api/v5/teams/by_user/${idUser}/${idSeason}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body }) => {
                let { teams } = body;                
                this.setState({ team: idTeamUserRole ? _.find(teams, t => t.IdTeamUserRole === parseInt(idTeamUserRole, 10)) : _.first(teams || []) });
            });
    }
    render() {
        const { team } = this.state, { idSeason } = this.props;
        return <section>
            {team && <Link to={`/season/${idSeason}/team/${team.IdTeam}?tab=3`} className="d-flex flex-column justify-contente-center">
                <div className="contain contains align-self-center" style={{ width: 100, height: 100, background: `url(${team.TeamImageUrl}) no-repeat center center` }} />
                <span className="white align-self-center">{team.TeamName}</span>
            </Link>}

        </section>
    }
}

export default PlayerTeams;