import React from 'react';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';

class GamesTable extends React.Component {

  state = {
    up: true,
    by: 'GameDate'
  }

  sort = (newBy) => {
    const { up, by } = this.state;
    if (by === newBy) {
      this.setState({ up: !up });
    } else {
      this.setState({ up: false, by: newBy });
    }
  }

  edit = (game) => {
    this.props.fnEdit && this.props.fnEdit(game);
  }
  game_card = game => {
    this.props.fnGameCard && this.props.fnGameCard(game);
  }

  swapTeams = (game) => {
    game = _.extend(game, {
      HomeTeam: {
        ...game.AwayTeam
      },
      AwayTeam: {
        ...game.HomeTeam
      }
    });
    this.forceUpdate();
    // Smart Call aPI
    const url = _.compact([config.apiEndpoint, 'api/v3/games/swap', game.Id]).join('/');
    request.patch(url)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then((data) => {

      });
  }

  render() {

    const { mode, flight, games, selectedTeam, homeAway } = this.props,
      { up, by } = this.state;
    let flightGames = _.filter(games, g => {
      return g.IdFlight === flight.IdFlight
    });
    if (selectedTeam) {
      flightGames = _.filter(flightGames, g => {
        return parseInt(g.HomeTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10) ||
          parseInt(g.AwayTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10);
      });     

      switch (homeAway) {
        case 1:
          flightGames = _.filter(flightGames, g => parseInt(g.HomeTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10));
          break;
        case 2:
          flightGames = _.filter(flightGames, g => parseInt(g.AwayTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10));
          break;
        default: break;
      }
    }

    // sorting
    flightGames = this.state.up ? _.sortBy(flightGames, this.state.by) : _.sortBy(flightGames, this.state.by).reverse();    

    return (
      <ul className={`${mode === 1 ? 'list' : 'tiles'} mb-4`}>
        <li className="container-fluid no-margin no-padding d-flex flex-row mt-3" style={{ borderBottom: '1px solid black' }}>
          <span className="idented align-self-center black font-20 montserrat">{flight.Name || flight.FlightName}</span>
        </li>
        {flightGames.length > 0 &&
          <li className="container-fluid no-margin no-padding blue pointer">
            <div className="row font-9">
              <div className="col-1 d-flex flex-row justify-content-center">
                <button className={`btn btn-link btn-sm ${by === 'IdGame' ? 'green' : ''}`} onClick={() => this.sort('IdGame')}>ID
                  {by === 'IdGame' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                </button>
              </div>
              <div className="col-2 d-flex flex-row  justify-content-center">
                <button className={`btn btn-link btn-sm ${by === 'GameDate' ? 'green' : ''}`} onClick={() => this.sort('GameDate')}>DATE
                  {by === 'GameDate' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                </button>
              </div>
              {/*<div className="col-1 d-flex flex-row  justify-contcylent-center">
                <button className={`btn btn-link btn-sm ${by === 'FlightName' ? 'green' : ''}`} onClick={() => this.sort('FlightName')}>FLIGHT
                  {by === 'FlightName' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                </button>
        </div>*/}
              <div className="col-4 text-center d-flex flex-row justify-content-center">
                <button className="align-self-center btn btn-link btn-sm black">HOME VS AWAY</button>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <button className={`btn btn-link btn-sm ${by === 'LocationName' ? 'green' : ''}`} onClick={() => this.sort('LocationName')}>LOCATION
                  {by === 'LocationName' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                </button>
              </div>              
              <div className="col-1 d-flex flex-row  justify-content-center">
                <button className="align-self-center btn btn-link btn-sm black">DURATION</button>
              </div>
            </div>
          </li>}
        {flightGames.map((game, i) => (
          <li className="container-fluid no-margin no-padding black font-9 pointer" style={{ backgroundColor: i % 2 ? 'transparent' : '#e5e5e5' }} key={game.IdGame}>
            <div className="row">
              <div className="col-1 d-flex  justify-content-center">
                <span className="font-9 align-self-center">{(i + 1)}. ({game.IdGame})</span>
              </div>
              <div className="col-2 d-flex flex-row font-9 justify-content-center">
                <span className="align-self-center">{game.GameDate.format('ddd, MMM Do YYYY')}{game.GameStartHour && <span>{' @ '} {game.GameStartHour.format('h:mmA')}</span>}</span>
              </div>
              {/*<div className="col-1 d-flex flex-row font-9 justify-content-center">
                <span className="align-self-center">{game.FlightName}</span>
        </div>*/}
              <div className="col-4 text-center  d-flex flex-row justify-content-center">
                <span className="align-self-center">{game.HomeTeam.Name || game.HomeTeam.TeamName}<span className="blue font-8">{' - '}VS{' - '}</span>
                  {game.AwayTeam.Name || game.AwayTeam.TeamName}
                </span> <i className="fa fa-random align-self-center idented blue hide" onClick={() => this.swapTeams(game)}></i>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <span className="align-self-center idented">{game.Location.LocationName || game.Location.Name}</span>
              </div>
              <div className="col-1 d-flex flex-row  justify-content-center font-9 ">
                <span className="align-self-center">{game.GameDurationMinutes} min (Round {game.Round || game.RoundNumber})</span>
              </div>
              <div className="col-1 d-flex flex-row-reverse ">
                <button className="btn btn-link align-self-center"><i className="fas fa-pen black" onClick={() => this.edit(game)}></i></button>
                <button className="btn btn-link align-self-center"><i className="far fa-newspaper black" onClick={() => this.game_card(game)}></i></button>
              </div>
            </div>
          </li>
        )
        )}
        {(flightGames.length === 0) && (
          <li className="container-fluid jumbotron no-margin black text-center no-games-found">
            No games found for this Flight
          </li>)}
      </ul>
    )
  }
}

export default GamesTable;