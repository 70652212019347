import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import request from 'superagent';
import config from '../../../config';

class Comp extends React.Component {

    state = {
        total: null,
        nope: true,
        dueDate: moment(),
        isDueDateDatePickerOpen: false,
        description: null,
        saving: false
    }

    continue = () => {
        if (!this.state.nope && !this.state.saving) {
            this.setState({ saving: true },
                function () {
                    // TODO: add the fee, then return

                    request.post([config.apiEndpoint, 'api/v4/payments/addCharge'].join('/'))
                        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                        .send({
                            idOrderItem: this.props.idOrderItem,
                            amount: this.txtAmount.value,
                            dueDate: this.state.dueDate,
                            description: this.state.description
                        })
                        .then((data) => {
                            if (data.body.success) {
                                // SEND THE REFRESH SIGNAL TO THE PARENT
                                this.props.fnRefreshPayments && this.props.fnRefreshPayments();
                            }
                            this.props.toggle && this.props.toggle();
                        });
                });
        }
    }

    handleChange = (e) => {
        this.setState({
            description: this.txtDescription.value,
            total: parseFloat(this.txtAmount.value),
            nope: !_.isNumber(parseFloat(this.txtAmount.value))
                || parseFloat(this.txtAmount.value) === 0
                || !this.txtAmount.value
                || !this.txtDescription.value
        });
    }

    handleChangeDueDate = (date) => {
        this.setState({
            dueDate: moment(date)
        }, function () {
            this.toggleCalendarDueDate();
        });
    }

    toggleCalendarDueDate = (e) => {
        e && e.preventDefault()
        this.setState({
            isOpenStartDatepicker: !this.state.isOpenStartDatepicker
        })
    }

    render() {
        const { isOpen, toggle } = this.props,
            { nope } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
                <ModalHeader toggle={toggle} className="bg-blue-dark white montserrat">
                    <i className="fa fa-plus" />
                    {' '}Add Charge / Fee
                </ModalHeader>
                <ModalBody>
                    <div className="jumbotron no-margin text-center" key="step1">
                        <div className="d-flex flex-row justify-content-center">
                            <i className="fa fa-dollar-sign align-self-center bigest-font margin-right-half" />
                            <input ref={(i) => this.txtAmount = i} onChange={this.handleChange} type="number" className="form-control control-lg align-self-center text-center big-font margin-right" placeholder="Amount"
                                style={{ height: 60, width: 200 }} />
                        </div>
                        <div className="margin-top d-flex flex-row justify-content-center">
                            <input type="text" className="form-control form-control-lg w-100 align-self-center" placeholder="Description and Due Date" ref={(input) => this.txtDescription = input} onChange={this.handleChange} />

                            <div className="align-self-center" style={{ width: 250 }}>
                                <DatePicker className="w-100 btn-lg" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.dueDate}
                                    onChange={this.handleChangeDueDate} />
                            </div>

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={this.continue} style={{ width: 120, border: `2px solid ${nope ? 'grey' : '#2cad3c'}`, borderRadius: 20 }} className={`${nope ? 'gray' : 'green'} p-2 align-self-center mt-2`}>{this.state.saving ? 'Saving...' : 'Save'}</button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default Comp;