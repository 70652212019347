import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';
import { getBase64 } from '../../../helpers';
import request from 'superagent';

const baseStyle = {
  flex: 1,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#FFFFFF',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function StyledDropzone(props) {

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      props.onDrop && props.onDrop(file);
      props.drop && props.drop(file);
    });

  }, [props])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: 'image/*', onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>

      <div {...getRootProps({ style })} className="pointer">
        <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          className=" d-flex flex-row justify-content-center w-100 h-100">
          <div style={{ width: 300, height: 300, borderRadius: 150, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
        </div>
        <input {...getInputProps()} />
        <div style={{ height: 50 }} />
        <p className="line1 text-center font-20">Drag 'n' drop some files here, or click to select files</p>
        <div style={{ width: 200, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center text-center">SELECT PHOTOS</div>
        <div style={{ height: 50 }} />
      </div>

    </div>
  );
}

class PendingValidations extends React.Component {

  state = {
    entity: null,
    microTransac: false,
    bootstrapped: true,
    step: 1,
    files: []
  }

  componentWillMount() {
    const { user, validations } = this.props;
    this.setState({ user, validations });
  }

  errorImage = () => {
    const { entity } = this.state;
    entity.Image = entity.ImageUrl = (entity.IdGender === 2 ? '/images/defaults/female.png' : '/images/defaults/male.png');
    this.setState({ entity });
  }

  save = () => {


  }

  close = () => {
    const { step, user } = this.state;
    if (!user.validations.length) {
      const { toggle } = this.props;
      this.setState({ bootstrapped: false }, () => {
        setTimeout(() => { toggle && toggle(); }, 300);
      });
      return;
    }

    switch (step) {
      case 1:
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
          setTimeout(() => { toggle && toggle(); }, 300);
        });
        break;
      case 2:
        this.setState({ step: 1, validation: null })
        break;
      case 3:
        if (!this.state.uploading) this.setState({ step: 1, validation: null })
        break;
      default:
        break;
    }
  }

  onDrop = (file) => {
    const { files } = this.state;
    getBase64(file).then(data => {
      files.push(data);
      this.setState({ files });
    });
  }

  onDeleteFile = index => {
    const { files } = this.state;
    this.setState({ files: [...files.slice(0, index), ...files.slice(index + 1)] });
  }

  onDoSend = () => {
    const { files } = this.state;
    if (!files.length) {
      alert('Please, add at least one picture of the Documents requested');
      return;
    }

    // TODO: call API to upload the documents
    // TODO: upload feedback
    // TODO: remove the validation from the list of pending validations
    const { user, validation } = this.state, { rosterActions, team } = this.props;

    let means = [];
    files.forEach(file => means.push(file));
    this.setState({ user, step: 3, uploading: true }, () => {
      request.post(`${config.wowzaEndpoint}/api/v5/validations/receive`)
        .send({ validation, means })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(({ body }) => {
          if (body.success) {
            user.validations = _.reject(user.validations, v => v.IdUserValidation === validation.IdUserValidation);
          } else {
            rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, team.IdTeamDivision);
          }
        }).finally(() => {

          this.setState({ uploading: false, files: [] })
        })
    });
    // TODO: if there are no more validations, close the back button closes this screen
    // TODO: call saga to update the
  }

  render() {
    const { user, validations } = this.state,
      { step, validation, bootstrapped } = this.state;      

    return (
      <section>

        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>

          <div className="overlay" style={{ zIndex: -1 }} onClick={this.close} />

          <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
            position: 'fixed', right: 0, top: 0, bottom: 0, overflowY: 'auto',
            width: 450, borderLeft: '1px solid gray'
          }} className="bg-gray montserrat">

            {/* Top Actions */}
            <div className="d-flex flex-row p-3 bg-gray">
              <i className="fas fa-arrow-left font-12 pointer black" onClick={this.close} />
              <span className="black montserrat font-12 ml-auto">Pending Validations</span>
              <i className=" font-16 ml-auto " />
            </div>

            {/* Basic info (name, photo, id) */}
            {step === 1 && <div className="d-flex flex-column justify-content-center bg-gray px-4">
              {validations && validations.map((validation, i) => <button onClick={() => this.setState({ validation, step: 2 }, () => {
                request.get(`${config.apiEndpoint}/api/v5/validations/${validation.IdValidationProcess}`)
                  .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                  .then(data => {
                    this.setState({ means: data.body.validation.means });
                  });
              })} className="card shadow-box p-2 w-100 mb-2 d-flex flex-row" key={i}>
                <div className="cover align-self-center" style={{ width: 50, height: 50, borderRadius: 25, background: `url(${user.UserImage || user.Image}?full=80) no-repeat center center` }} />
                <div className="d-flex flex-column align-self-center ml-2">
                  <span className="black font-12 line1 text-left">Validation: {validation.what}</span>
                  <span className="black toBold font-14 line1 text-left">{`${user.NameFirst} ${user.NameLast}`}</span>
                  <span className="black font-12 line1 text-left">{validation.league.LeagueName}</span>
                </div>
              </button>)}
            </div>}

            {step === 2 && validation && <div className="d-flex flex-column justify-content-center bg-gray px-4">
              <button className="card shadow-box p-2 w-100 mb-2 d-flex flex-row">
                <div className="cover align-self-center" style={{ width: 50, height: 50, borderRadius: 25, background: `url(${user.Image}?full=80) no-repeat center center` }} />
                <div className="d-flex flex-column align-self-center ml-2">
                  <span className="black font-12 line1 text-left">Validation: {validation.what}</span>
                  <span className="black toBold font-14 line1 text-left">{`${user.NameFirst} ${user.NameLast}`}</span>
                  <span className="black font-12 line1 text-left">{validation.league.LeagueName}</span>
                </div>
              </button>
              {this.state.means && <div className="card shadow-box p-4 w-100 mb-2">
                <span className="font-12 black">Instructions: Proceed to upload a legible photo of any of the following:</span>
                <ul>
                  {this.state.means.map((mean, i) => <li className="pl-4" key={i}><i className="fas fa-circle font-8 black mr-2" />{mean.ValidationInstrumentName || 'Mean'}</li>)}
                </ul>
              </div>}

              <StyledDropzone onDrop={this.onDrop} />

              {this.state.files.map((file, i) => <div key={i} className="mt-2 p-4 card shadow-box d-flex flex-column">
                <i className="fas fa-times align-self-end mb-1" onClick={() => this.onDeleteFile(i)} />
                <img alt="" src={file} />
              </div>)}

              <div onClick={this.onDoSend} style={{ width: 200, border: '1px solid #2cad3c', borderRadius: 20 }} className="green mt-4 p-2 align-self-center text-center">Send Documents</div>
              <div onClick={this.close} style={{ width: 200 }} className="black mt-4 p-2 align-self-center text-center">Will do later</div>
              <div style={{ height: 100 }} />

            </div>}

            {step === 3 && <div className="d-flex flex-column justify-content-center bg-gray px-4" style={{ height: 500 }}>
              {this.state.uploading && <div className="align-self-center d-flex flex-column">
                <i className="font-60 black fas fa-file-upload align-self-center" />
                <span className="black font-20 align-self-center heart">Processing, please wait...</span>
              </div>}
              {!this.state.uploading && <div className="align-self-center d-flex flex-column">
                <i className="font-60 green fas fa-circle-check align-self-center" />
                <span className="black font-20 align-self-center text-center">Documents received.</span>
                <span className="black font-10 align-self-center text-center">One of our representatives will review your document and validate everything for you!</span>
              </div>}
            </div>}

          </Animated>

        </div>

      </section>)
  }
}

export default PendingValidations;







