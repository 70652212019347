import React from 'react';
import moment from 'moment';


const TournamentList = ({ questions, report }) => {

  /*

  "DOBV": "NO",
      "CONC": "NO",
      "COACH": "NO",
      "SAFE": "YES - Until Jan 13th 2023",
      "BGC": "NO",
      "FINGER": "NO"


      */

  return (
    <table id="table-to-xls" className="table table-sm font-10">
      <thead>
        <tr>
          <th>Id Team</th>
          <th>Team Name</th>
          <th>Id Div.</th>
          <th>Division</th>
          <th>Reg. Date</th>
          <th>Admin ID</th>
          <th>Admin First</th>
          <th>Admin Last</th>
          <th>Role</th>
          <th>Date of Birth</th>
          <th>Admin Email</th>
          <th>Admin Phone</th>
          <th>Address</th>
          <th>State</th>
          <th>Zip Code</th>
          <th>COACH</th>
          <th>SAFE</th>
          <th>BGC</th>
          <th>FINGER</th>
          <th>CONC</th>
        </tr>
      </thead>
      <tbody>
        {report && report.map((record, i) => (
          <tr key={i}>
            <td className="font-8">
              {record.IdTeam}
            </td>
            <td>
              {record.TeamName}
            </td>
            <td className="font-8">
              {record.IdTeamDivision}
            </td>
            <td>
              {record.DivisionName}
            </td>
            <td>
              {moment(record.RegistrationDate).format('MM/DD/YYYY')}
            </td>
            <td>{record.IdUser}</td>
            <td>{record.NameFirst}</td>
            <td>{record.NameLast}</td>
            <td>{record.RoleOverride}</td>
            <td>{moment(record.DateBirth).utc().format('MM/DD/YYYY')}</td>
            <td>{record.EmailAddress}</td>
            <td>{record.CountryCode} {record.PhoneNumber}</td>
            <td>{record.StreetAddress}</td>
            <td>{record.StateName}</td>
            <td>{record.ZipCode}</td>
            <td>{record.COACH}</td>
            <td>{record.SAFE}</td>
            <td>{record.BGC}</td>
            <td>{record.FINGER}</td>
            <td>{record.CONC}</td>
          </tr>)
        )}
      </tbody>
    </table>
  );
}

export default TournamentList;
