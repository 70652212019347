import React from 'react';
import { Input, Popover, PopoverBody } from 'reactstrap';
import request from 'superagent';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePicker';
import moment from 'moment';
import config from '../../../../config';
import _ from 'lodash';

class DivisionListItem extends React.Component {

    state = {
        saving: false,
        deleting: false,
        microTransac: false,
        touched: false,
        addingCap: false,
        capDDOpen: false,
        addingDateRange: false,
        dateRangeOpen: false,
        changingStatus: false,
    }

    // Lifecycle
    componentDidMount() {
        const { division } = this.props;
        if (division.editing) {
            this.txtName.focus();
            this.txtName.select();
        }
    }
    //

    save = (e) => {

        e.preventDefault && e.preventDefault();
        const { division, season, index } = this.props;

        division.Name = this.txtName.value || division.Name || ('Division #' + index);
        division.editing = false;
        division.IdSeason = season.IdSeason;

        // Update the store
        this.props.divisionsAction &&
            this.props.divisionsActions.updateDivision(division, index);

        this.setState({
            microTransac: true
        }, () => {
            request[division.IdDivision ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/divisions/${division.IdDivision || season.IdSeason}`)
                .send(division)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then((data) => {
                    if (!division.IdDivision) {
                        division.IdDivision = data.body.IdDivision;
                        division.Color = data.body.Color;
                    }
                    this.setState({ microTransac: false });
                });
        });
    }

    delete = () => {

        const { division, index } = this.props;
        // Only call the API if the division has an Id (and if its deletable)      
        if (division.IdDivision) {

            this.setState({ microTransac: true, deleting: false });

            request.delete(`${config.apiEndpoint}/api/v4/divisions/${division.IdDivision}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(({ body }) => {                    
                    if (body.success) {
                        this.props.divisionsActions &&
                            this.props.divisionsActions.removeDivision(index);
                    } else {
                        this.props.onError && this.props.onError(`Can't delete this division: ${body.messsage}`);
                    }
                    this.setState({ microTransac: false });
                }, error => {
                    this.setState({ microTransac: false });
                });
        } else {
            this.props.divisionsActions &&
                this.props.divisionsActions.removeDivision(index);
        }

    }

    handleChangeGender = (e) => {
        const { division, index } = this.props
        division.IdGender = parseInt(e.target.value, 10);
        division.Gender = _.find(config.genders, (g) => { return g.IdGender === division.IdGender; }).Name
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
    }

    toggleEditMode = () => {
        const { division, index } = this.props;
        division.editing = !division.editing;
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);

    }
    toggleDeleteConfirm = () => {
        const { division } = this.props;
        if (division.CanDelete) {
            this.setState({ deleting: !this.state.deleting });
        } else {
            this.props.onError && this.props.onError(`Can't delete this division: ${division.Reason}`);
        }
    }

    // Cap handle
    toggleAddCap = () => {
        this.setState({ addingCap: !this.state.addingCap }, () => {
            if (this.state.addingCap) {
                this.txtCap && this.txtCap.select();
            }
        });
    }
    handleChangeCapType = (e) => {
        const { division, index } = this.props;
        division.capType = e.target.value;
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
    }
    saveCap = () => {
        const { division, index } = this.props;
        division.cap = parseInt(this.txtCap.value || 0, 10);
        division.isCapped = division.cap ? true : false;
        division.capType = division.capType || 'Closed';
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingCap: false });
    }
    deleteCap = () => {
        const { division, index } = this.props;
        division.cap = 0;
        division.capType = null;
        division.isCapped = false;
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingCap: false });
    }
    //

    // Date Range handle
    toggleAddDateRange = () => {
        this.setState({ addingDateRange: !this.state.addingDateRange });
    }
    handleChangeDateStart = (date) => {
        const { division, index } = this.props;
        division.DateBirthMin = moment(date).utc();
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
    }
    handleChangeDateEnd = (date) => {
        const { division, index } = this.props;
        division.DateBirthMax = moment(date).utc();
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
    }
    saveDateRange = () => {
        const { division, index } = this.props;
        division.DateBirthMax = division.DateBirthMax || moment().add(-1, 'years');
        division.DateBirthMin = division.DateBirthMin || moment().add(-5, 'years');
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingDateRange: false });
    }
    deleteDateRange = () => {
        const { division, index } = this.props;
        division.DateBirthMax = null;
        division.DateBirthMin = null;
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingDateRange: false });
    }
    //

    // status handle
    toggleStatusChange = () => {
        this.setState({ changingStatus: !this.state.changingStatus });
    }
    setStatus = (id) => {
        const { division, index } = this.props, { divisionStatuses } = config;
        const newStatus = _.find(divisionStatuses, s => { return s.IdRoleStatus === id; });
        division.DivisionStatus = newStatus.RoleStatusName;
        division.IdDivisionStatus = newStatus.IdRoleStatus;

        // Update the store
        this.props.divisionsAction &&
            this.props.divisionsActions.updateDivision(division, index);
        this.toggleStatusChange();
    }
    //

    renderEdit = () => {
        const { division, index } = this.props,
            { microTransac } = this.state;

        const genders = config.genders,
            divisionStatuses = config.divisionStatuses;

        return <tr key={index}>
            <td>
                <input placeholder="Division Name" className="form-control" type="text" defaultValue={division.Name} ref={(input) => this.txtName = input} />                
            </td>
            <td className="text-center">
                <Input type="select" name="seasonType" value={division.IdGender} onChange={this.handleChangeGender}>
                    {genders.map((gender, i) => (
                        <option value={gender.IdGender} key={i}>
                            {gender.Name}
                        </option>))}
                </Input>
            </td>

            <td className="text-center">
                <button type='button' onClick={this.toggleAddCap} id={`btnAddCap-${division.IdDivision}-${index}`} className={`btn btn-link blue margin-left-half align-self-center underlined font-${division.isCapped ? '8' : '12'}`}>
                    {division.isCapped ? `${division.capType} when reaches ${division.cap} players` : 'Add Cap'}
                </button>
                <Popover placement="bottom" isOpen={this.state.addingCap} target={`btnAddCap-${division.IdDivision}-${index}`} toggle={this.toggleAddCap}>
                    <PopoverBody className="d-flex flex-column justify-content-center">
                        <div className="d-flex flex-row font-8">
                            <span># of Players</span>
                            <span className="ml-auto">Status once reached</span>
                        </div>
                        <div className="d-flex flex-row">
                            <input defaultValue={division.cap} style={{ width: 100 }} type="number" className="form-control" ref={(i) => this.txtCap = i} />
                            <Input style={{ width: 120 }} type="select" value={division.capType || ''} onChange={this.handleChangeCapType} className="margin-left">
                                {_.tail(divisionStatuses).map((status, i) => (
                                    <option value={status.RoleStatusName} key={i}>
                                        {status.RoleStatusName}
                                    </option>))}
                            </Input>
                        </div>
                        <div className="d-flex flex-row margin-top-half">
                            <button style={{ border: '1px solid red', borderRadius: 20, width: 80 }} className="red p-2 align-self-center block" onClick={this.deleteCap}>Delete</button>
                            <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 80 }} className="green ml-auto p-2 align-self-center block" onClick={this.saveCap}>Save</button>
                        </div>
                    </PopoverBody>
                </Popover>
            </td>
            {/* Date Range */}
            <td className="text-center">
                <button type='button' onClick={this.toggleAddDateRange} id={`btnAddDateRange-${division.IdDivision}-${index}`} className={`btn btn-link blue margin-left-half align-self-center underlined font-${division.DateBirthMin ? '8' : '12'}`}>{division.DateBirthMin ? `${moment(division.DateBirthMin).utc().format('MMM Do YYYY')} to ${moment(division.DateBirthMax).utc().format('MMM Do YYYY')}` : `Add Date Range`}</button>
                <Popover placement="bottom" isOpen={this.state.addingDateRange} target={`btnAddDateRange-${division.IdDivision}-${index}`} toggle={this.toggleAddDateRange}>
                    <PopoverBody className="d-flex flex-column no-padding">
                        {/*<div className="p-3 bg-blue-dark white" style={{ width: 300 }}>Setup</div>*/}
                        <div className="d-flex bg-warning" style={{ width: 300 }}>
                            <span className="w-100 text-center font-10">Example: Jan 1st 1998 to Dec 31st 2000</span>
                        </div>
                        <div className="d-flex flex-row justify-content-center pt-2">
                            <span className="text-center w-100">Oldest</span><span>TO</span><span className="text-center w-100">Youngest</span>
                        </div>
                        <div className="d-flex flex-row justify-content-center">
                            <DatePicker className="align-self-center w-100" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={70} customInput={<CustomDatePicker className="w-100 align-self-center" />} selected={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} maxDate={moment()} selectsStart
                                startDate={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} endDate={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} onChange={this.handleChangeDateStart} />
                            <div style={{ width: 20 }} />
                            <DatePicker className="align-self-center w-100" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={70} customInput={<CustomDatePicker className="w-100 align-self-center" />} selected={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} maxDate={moment()} selectsEnd
                                startDate={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} endDate={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} onChange={this.handleChangeDateEnd} />
                        </div>
                        <div className="d-flex flex-row p-3">
                            <button style={{ border: '1px solid red', borderRadius: 20, width: 80 }} className="red p-2 align-self-center block" onClick={this.deleteDateRange}>Delete</button>
                            <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 80 }} className="green ml-auto p-2 align-self-center block" onClick={this.saveDateRange}>Save</button>
                        </div>
                    </PopoverBody>
                </Popover>
            </td>
            {/* Status */}
            <td>
                <button type='button' onClick={this.toggleStatusChange} id={`btnChangeStatus-${division.IdDivision}-${index}`}
                    className={`btn btn-link blue margin-left-half align-self-center underlined font-12`}>{division.DivisionStatus}</button>

                <Popover placement="bottom" isOpen={this.state.changingStatus} target={`btnChangeStatus-${division.IdDivision}-${index}`}
                    toggle={this.toggleStatusChange}>
                    <PopoverBody className="d-flex flex-column">
                        <button className="btn btn-link btn-sm " onClick={() => this.setStatus(1)}>Open</button>
                        <button className="btn btn-link btn-sm " onClick={() => this.setStatus(2)}>Closed</button>
                        <button className="btn btn-link btn-sm " onClick={() => this.setStatus(3)}>Waitlisted</button>
                        <button className="btn btn-link btn-sm " onClick={() => this.setStatus(4)}>Tryout</button>
                    </PopoverBody>
                </Popover>
            </td>
            {/*<td className="white">{division.IsTryout ? 'Yes' : 'No'}</td>*/}
            <td className="text-right">
                {/* Edit */}
                {!microTransac && <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 80 }} onClick={this.save} className="green ml-2 p-2">
                    Save</button>}

                {microTransac && <i className="fa fa-spin fa-hourglass fa-2x white" />}
                {/* Delete */}
                {!division.IdDivision && <button type="button" className="btn btn-link " id={`btnDeleteDivision-${division.IdDivision}-${index}`}>
                    <i className="fa fa-times red" onClick={this.toggleDeleteConfirm} /></button>}
                {/* Delete confirmation */}
                {!division.IdDivision && <Popover placement="left" isOpen={this.state.deleting} target={`btnDeleteDivision-${division.IdDivision}-${index}`} toggle={this.toggleDeleteConfirm}>
                    <PopoverBody className="d-flex flex-column justify-content-center">
                        <span className="align-self-center">Are you sure?</span>
                        <button style={{ border: '1px solid red', borderRadius: 20, width: 150 }} className="red p-2 align-self-center block" onClick={this.toggleDeleteConfirm}>No, cancel</button>
                        <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 150 }} className="green mt-2 p-2 align-self-center block" onClick={this.delete}>Yes, delete it!</button>
                    </PopoverBody>
                </Popover>
                }
            </td>
        </tr>
    }

    renderNonEdit = () => {
        const { division, index } = this.props;
        return <tr className="black" key={index}>
            <td>
                {division.Color && <i className="ml-2 fas fa-circle mr-3" style={{ color: division.Color }} />}
                {`${index + 1}. ${division.Name}`}
            </td>
            <td className="text-center">{division.Gender}</td>
            {/*<td className="text-center">{division.Status}</td>*/}
            <td className="text-center font-10">{division.isCapped ? `${division.capType} when reaches ${division.cap} players` : ''}</td>
            <td className="text-center font-10">{division.DateBirthMin ? `${moment(division.DateBirthMin).utc().format('MMM Do YYYY')} to ${moment(division.DateBirthMax).utc().format('MMM Do YYYY')}` : ''}</td>
            <td className="text-center">{division.DivisionStatus}</td>
            {/*<td className="text-center">{division.IsTryout ? 'Yes' : 'No'}</td>*/}
            {division.IdDivision && <td className="text-right">
                {/* Delete */}
                <button type="button" className="btn btn-link" onClick={this.toggleEditMode}>
                    <i className="fas fa-pen jeff_blue" /></button>
                {/* Delete */}
                <button type="button" className="btn btn-link" onClick={this.toggleDeleteConfirm} id={`btnDeleteDivision-${division.IdDivision}-${index}`}>
                    <i className="far fa-trash-alt jeff_blue" /></button>
                {/* Delete confirmation */}
                <Popover placement="left" isOpen={this.state.deleting} target={`btnDeleteDivision-${division.IdDivision}-${index}`} toggle={this.toggleDeleteConfirm}>
                    <PopoverBody className="d-flex flex-column justify-content-center">
                        <span className="align-self-center">Are you sure?</span>
                        <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
                        <button className="btn btn-sm btn-success" onClick={this.delete}>Yes, delete it!</button>
                    </PopoverBody>
                </Popover>
            </td>}

            {!division.IdDivision && <td className="text-right">{true && <i className="fa fa-spin fa-hourglass fa-2x white" />}</td>}
        </tr>
    }

    render() {
        const { division } = this.props;
        if (division.editing) return this.renderEdit();
        else return this.renderNonEdit();
    }
}

export default DivisionListItem;
