import React from 'react';
import { ModalBody } from 'reactstrap';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import Switch from "react-switch";
import config from '../../../../config';
import request from 'superagent';
import LineLoader from '../../LineLoader';

class Fees extends React.Component {
    state = { fees: null };
    componentWillMount() {
        const { fees, feeTypes } = this.props;
        this.setup(fees, feeTypes);
    }
    componentWillReceiveProps = nextProps => {
        const { fees, feeTypes } = nextProps;
        this.setup(fees, feeTypes);
    }
    componentWillUnmount() {
        const { leagueActions } = this.props;
        leagueActions && leagueActions.clearLeagueFees();
    }
    setup = (fees, feeTypes) => {
        let recreational = _.find(fees, f => f.IdSeasonType === 1) || {},
            competitive = _.find(fees, f => f.IdSeasonType === 2) || {},
            tournament = _.find(fees, f => f.IdSeasonType === 3) || {},
            camp = _.find(fees, f => f.IdSeasonType === 4) || {},
            hybrid = _.find(fees, f => f.IdSeasonType === 5) || {};
        this.setState({ fees, recreational, competitive, tournament, camp, hybrid, feeTypes });
    }
    toggle = (i, IdFeeType) => {
        const { competitive, recreational, tournament, camp, hybrid } = this.state;
        switch (i) {
            case 1: recreational.IdFeeType = recreational.IdFeeType === IdFeeType ? 0 : IdFeeType; break;
            case 2: competitive.IdFeeType = competitive.IdFeeType === IdFeeType ? 0 : IdFeeType; break;
            case 3: tournament.IdFeeType = tournament.IdFeeType === IdFeeType ? 0 : IdFeeType; break;
            case 4: camp.IdFeeType = camp.IdFeeType === IdFeeType ? 0 : IdFeeType; break;
            case 5: hybrid.IdFeeType = hybrid.IdFeeType === IdFeeType ? 0 : IdFeeType; break;
            default: break;
        }
        this.setState({ competitive, recreational, tournament, camp, hybrid });
    }
    onChange = () => { }
    save = () => {
        const { competitive, recreational, tournament, camp, hybrid } = this.state, { league, leagueActions } = this.props;
        const payload = {
            competitive: { ...competitive, PlayerRegistrationFee: this.txtCompetitive.value, ScoutFee: this.txtCompetitiveScout.value },
            recreational: { ...recreational, PlayerRegistrationFee: this.txtRecreational.value, ScoutFee: this.txtRecreationalScout.value },
            tournament: { ...tournament, TeamRegistrationFee: this.txtTournament.value, ScoutFee: this.txtTournamentScout.value },
            camp: { ...camp, PlayerRegistrationFee: this.txtCamp.value, ScoutFee: this.txtCampScout.value },
            hybrid: { ...hybrid, PlayerRegistrationFee: this.txtHybrid.value, ScoutFee: this.txtHybridScout.value }
        }

        this.setState({ microTransact: true }, () => {
            request.post(`${config.apiEndpoint}/api/v4/leagues/fees/${league.IdLeague}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send(payload)
                .then(data => {
                    leagueActions.fetchLeagueFees(league.IdLeague);
                    this.setState({ microTransact: false });
                });
        });
    }

    render() {
        const { recreational, competitive, tournament, camp, hybrid, microTransact } = this.state;

        return <ModalBody style={{ position: 'relative' }}>
            {microTransact && <LineLoader />}
            <div className="p-4 mt-4">
                <h4>Administrative Settings</h4>
                <div className="d-flex flex-row">

                    {/* Recreational */}
                    <div className="d-flex flex-row margin-top-half w-100">
                        <Animated animationIn="fadeIn" isVisible={true} animationInDelay={300}
                            className={`card w-100 bg-gray`}>
                            <span className={`header white bg-Recreational w-100 block text-left p-2`}>Recreational Fees</span>
                            <div className="p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                    </div>
                                    <input type="number" className="form-control p-4" defaultValue={recreational.PlayerRegistrationFee} placeholder="Player" ref={(i) => this.txtRecreational = i} />
                                    <input type="number" className="form-control p-4" defaultValue={recreational.ScoutFee} placeholder="Scout" ref={(i) => this.txtRecreationalScout = i} />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(1, 1)} checked={recreational.IdFeeType === 1} />
                                        <span className="align-self-center margin-left-half">Bundled</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(1, 2)} checked={recreational.IdFeeType === 2} />
                                        <span className="align-self-center margin-left-half">Added</span>
                                    </label>
                                </div>
                            </div>
                        </Animated>
                    </div>

                    <div style={{ width: 50 }} />
                    {/* Competitive */}

                    <div className="d-flex flex-row margin-top-half w-100">
                        <Animated animationIn="fadeIn" isVisible={true} animationInDelay={600}
                            className={`card w-100 bg-gray`}>
                            <span className={`header white bg-Competitive w-100 block text-left p-2`}>Competitive Fees</span>
                            <div className="p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                    </div>
                                    <input type="number" className="form-control p-4" placeholder="Player" defaultValue={competitive.PlayerRegistrationFee} ref={(i) => this.txtCompetitive = i} />
                                    <input type="number" className="form-control p-4" defaultValue={competitive.ScoutFee} placeholder="Scout" ref={(i) => this.txtCompetitiveScout = i} />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(2, 1)} checked={competitive.IdFeeType === 1} />
                                        <span className="align-self-center margin-left-half">Bundled</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(2, 2)} checked={competitive.IdFeeType === 2} />
                                        <span className="align-self-center margin-left-half">Added</span>
                                    </label>
                                </div>
                            </div>
                        </Animated>
                    </div>

                    <div style={{ width: 50 }} />
                    {/* Tournament */}

                    <div className="d-flex flex-row margin-top-half w-100">
                        <Animated animationIn="fadeIn" isVisible={true} animationInDelay={900}
                            className={`card w-100 bg-gray`}>
                            <span className={`header white bg-Tournament w-100 block text-left p-2`}>Tournament Fees</span>
                            <div className="p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                    </div>
                                    <input type="number" className="form-control p-4" placeholder="Team" defaultValue={tournament.TeamRegistrationFee} ref={(i) => this.txtTournament = i} />
                                    <input type="number" className="form-control p-4" defaultValue={tournament.ScoutFee} placeholder="Scout" ref={(i) => this.txtTournamentScout = i} />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(3, 1)} checked={tournament.IdFeeType === 1} />
                                        <span className="align-self-center margin-left-half">Bundled</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(3, 2)} checked={tournament.IdFeeType === 2} />
                                        <span className="align-self-center margin-left-half">Added</span>
                                    </label>
                                </div>
                            </div>
                        </Animated>
                    </div>

                </div>

                <div className="d-flex flex-row mt-3">
                    {/* Camp */}
                    <div className="d-flex flex-row margin-top-half w-100">
                        <Animated animationIn="fadeIn" isVisible={true} animationInDelay={1200}
                            className={`card w-100 bg-gray`}>
                            <span className={`header white bg-Camp w-100 block text-left p-2`}>Camp Fees</span>
                            <div className="p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                    </div>
                                    <input type="number" className="form-control p-4" defaultValue={camp.PlayerRegistrationFee} placeholder="Player" ref={(i) => this.txtCamp = i} />
                                    <input type="number" className="form-control p-4" defaultValue={camp.ScoutFee} placeholder="Scout" ref={(i) => this.txtCampScout = i} />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(4, 1)} checked={camp.IdFeeType === 1} />
                                        <span className="align-self-center margin-left-half">Bundled</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(4, 2)} checked={camp.IdFeeType === 2} />
                                        <span className="align-self-center margin-left-half">Added</span>
                                    </label>
                                </div>
                            </div>

                        </Animated>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* Hybrid */}
                    <div className="d-flex flex-row margin-top-half w-100">
                        <Animated animationIn="fadeIn" isVisible={true} animationInDelay={1500}
                            className={`card w-100 bg-gray`}>
                            <span className={`header white bg-Hybrid w-100 block text-left p-2`}>Hybrid Fees</span>
                            <div className="p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                    </div>
                                    <input type="number" className="form-control p-4" placeholder="Player" defaultValue={hybrid.PlayerRegistrationFee} ref={(i) => this.txtHybrid = i} />
                                    <input type="number" className="form-control p-4" defaultValue={hybrid.ScoutFee} placeholder="Scout" ref={(i) => this.txtHybridScout = i} />
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(5, 1)} checked={hybrid.IdFeeType === 1} />
                                        <span className="align-self-center margin-left-half">Bundled</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch onChange={() => this.toggle(5, 2)} checked={hybrid.IdFeeType === 2} />
                                        <span className="align-self-center margin-left-half">Added</span>
                                    </label>
                                </div>
                            </div>
                        </Animated>
                    </div>

                    <div style={{ width: 50 }} />

                    <div className="d-flex flex-row margin-top-half w-100">

                    </div>
                </div>

                <div className="d-flex flex-row margin-top-half mt-4">
                    {!microTransact && <button style={{ width: 230, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2" onClick={this.save}>Save Fee Settings</button>}
                    {microTransact && <button style={{ width: 230, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2" >Saving, please wait...</button>}
                </div>
            </div>
        </ModalBody>
    }
}

export default Fees;
