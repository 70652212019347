import React from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import Hls from "hls.js";
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import axios from 'axios';
import Confirm from './partials/StitchModal';
import config from '../../config';
import Drop from './partials/Drop';
import Drag from './partials/Drag';
import { generateRandomId } from '../../helpers';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 80,
}

const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 10,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: '#8B9CB6',
}

const Handle = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps
}) => (
    <div
        style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: -15,
            marginTop: 25,
            zIndex: 2,
            width: 30,
            height: 30,
            border: 0,
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: '#2C4870',
            color: '#333',
        }}
        {...getHandleProps(id)}
    >
        <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -18 }}>
            {value}
        </div>
    </div>
);

const Track = ({ source, target, getTrackProps }) => {
    return (
        <div
            style={{
                position: 'absolute',
                height: 10,
                zIndex: 1,
                marginTop: 35,
                backgroundColor: 'red',
                borderRadius: 5,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
        />
    )
};

const Tick = ({ tick, count }) => {
    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    marginTop: 52,
                    marginLeft: -0.5,
                    width: 1,
                    height: 8,
                    backgroundColor: 'silver',
                    left: `${tick.percent}%`,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    marginTop: 60,
                    fontSize: 10,
                    textAlign: 'center',
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {tick.value}
            </div>
        </div>
    )
}

class Studio extends React.Component {
    state = {
        left: 0,
        initialLeft: 100,
        pantone: [],
        current: 0,
        currentTime: 0,
        values: [0],
        modal: false,
        factor: 16,
        addingMarker: false,
        colorPicker: false,
        sizePicker: false,
        selectedColor: 'black',
        selectedSize: { text: 'Medium', value: 24 },
        sizes: [{ text: 'Small', value: 16 }, { text: 'Medium', value: 24 }, { text: 'Large', value: 32 }],
        newMarker: null,
        markers: []
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam, family_lastname } = this.props;

        setTitle && setTitle({
            main: 'SQWAD Studio',
            sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
        });

        const { params } = this.props;
        this.props.teamActions && this.props.teamActions.fetchTeamSeasons && this.props.teamActions.fetchTeamSeasons(params.idTeam);
        this.props.teamActions && this.props.teamActions.fetchVideos(params.idTeam);
    }
    componentWillReceiveProps = nextProps => {
        const { clips } = nextProps;
        if (clips) { this.setState({ pantone: [], clips }); }
    }
    //    

    loadPortion = (index) => {
        const hls = new Hls();
        const player = this[`player${index}`];
        const { pantone, currentTime } = this.state;
        let url = `${config.wowzaEndpoint}/dispatcher/${pantone[index].url}/playlist.m3u8`;
        hls.loadSource(url);
        hls.attachMedia(player);

        // This is for loading the next portion if the video is playing and ends
        player.onended = () => {
            let { current, pantone } = this.state;
            if (current < pantone.length - 1) {
                current++;
                this.setState({ current, currentTime: 0 }, () => {
                    this[`player${current}`] && this[`player${current}`].play();
                });
            }
        }

        player.ontimeupdate = ({ target }) => {
            const { current, pantone } = this.state;
            // We need to add to the seeker, left of all the videos that have already passed.
            // That's why is crucial to know the length of videos in seconds.            
            this.setState({
                values: [parseInt(this[`player${index}`].currentTime, 10) + pantone[current].starts],
                left: (parseInt(this[`player${index}`].currentTime, 10) + pantone[current].starts) * this.state.factor,
                currentTime: this[`player${index}`].currentTime,
                milliseconds: parseInt(this[`player${index}`].currentTime * 1000, 10)
            });
        }

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
            player.currentTime = currentTime;
            if (this.state.playing) player.play();
        });
    }

    onUpdate = values => {
        this.onPause();
        this.setState({ left: _.first(values) * this.state.factor, values }, () => {
            let { pantone, current } = this.state;
            let changeTo = _.findIndex(pantone, p => values[0] <= (p.seconds + p.starts));
            if (changeTo !== current && changeTo !== -1) {
                current = changeTo;
                this.setState({ current, currentTime: values[0] - pantone[current].starts });
            } else {
                if (this[`player${current}`]) this[`player${current}`].currentTime = values[0] - pantone[current].starts;
            }
        });
    }

    onSlideStart = () => { this.onPause(); }

    onPlay = () => {
        const video = this[`player${this.state.current}`];
        video.play && video.play();
        this.setState({ playing: true });
    }
    onPause = () => {
        const { current } = this.state;
        const video = this[`player${current}`];
        video.pause && video.pause();
        this.setState({ playing: false });
    }

    onAddToPantone = (i) => {
        const { pantone, clips } = this.state;
        let hl = clips[i], last = (_.last(pantone) || { starts: 0, seconds: 0 })
        pantone.push({
            IdVideo: _.first(hl.description.split('/')),
            seconds: hl.durationInSeconds,
            starts: last.starts + last.seconds,
            url: hl.description,
            thumbnail: hl.thumbnail,
            IdClip: hl.idVideo
        });

        this.setState({ pantone }, () => {
            this.loadPortion(pantone.length - 1);
            if (pantone.length === 1 && false) this.loadPortion(pantone.length - 1);
        })
    }

    onSave = () => {
        const { pantone, markers } = this.state;
        // Update the formData object 

        markers.forEach(marker => {
            marker.absolute_time = marker.playback +
                (_.chain(pantone)
                    .reject((p, i) => i >= marker.currentIndex)
                    .map(p => p.seconds * 1000)
                    .reduce((a, b) => a + b, 0).value()
                )
            marker.capture_area = { width: 1600 * 0.5, height: 900 * 0.5 };
        });

        axios.post(`${config.wowzaEndpoint}/api/v4/video/project`, {
            pantone, 
            fileName: `${generateRandomId(20)}.mp4`, 
            markers, 
            name: this.txtName.value || 'No Name'
        }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('sportslogic.authtoken')}` },
        }).then(() => {
            this.setState({ modal: true });
        });
    }

    toggleModal = () => this.setState({ modal: !this.state.modal });

    onRemoveFromPantone = (index) => {
        const { pantone } = this.state;
        let newPantones = [...pantone.slice(0, index), ...pantone.slice(index + 1)];
        // Rebuild the 'start' of each element of the pantone
        newPantones.forEach((item, index) => {
            if (index) {
                item.starts = newPantones[index - 1].starts + newPantones[index - 1].seconds
            }
        })
        this.setState({ pantone: newPantones })
    }

    onMoveClipLeft = (index) => {
        let { pantone } = this.state;
        if (pantone.length < 2) return;
        if (index !== 0) {
            const item1 = { ...pantone[index] },
                item2 = { ...pantone[index - 1] }
            pantone = [...pantone.slice(0, index - 1), item1, item2, ...pantone.slice(index + 1)];
        }
        this.setState({ pantone });
    }

    onMoveClipRight = (index) => {

        let { pantone } = this.state;
        if (pantone.length < 2) return;
        if (index !== pantone.length - 1) {
            const item1 = { ...pantone[index] },
                item2 = { ...pantone[index + 1] }
            pantone = [...pantone.slice(0, index), item2, item1, ...pantone.slice(index + 2)];
        }
        this.setState({ pantone });
    }

    onClickVideo = ({ nativeEvent = {} }) => {
        const { selectedSize, selectedColor, addingMarker, values } = this.state;
        if (!addingMarker) return;
        this.setState({
            colorPicker: false,
            sizePicker: false,
            newMarker: {
                x: nativeEvent.offsetX - (selectedSize.value / 2),
                y: nativeEvent.offsetY - (selectedSize.value / 2),
                size: selectedSize.value,
                size_string: selectedSize.text,
                color: selectedColor,
                current_value: _.first(values)
            }
        })
    }

    onAddingMarker = () => {
        this.onPause();
        this.setState({ addingMarker: !this.state.addingMarker });
    }

    onChangeColor = (color) => {
        const { newMarker } = this.state;
        if (newMarker) {
            newMarker.color = color;
        }
        this.setState({ selectedColor: color, newMarker });
    }

    onChangeSize = (size) => {
        const { newMarker } = this.state;
        if (newMarker) {
            newMarker.size = size.value;
            newMarker.size_string = size.text;
        }
        this.setState({ selectedSize: size, newMarker });
    }

    onSaveMarker = () => {
        const { newMarker, markers, current, pantone, values, factor, milliseconds } = this.state;
        let second = values[0] - pantone[current].starts;
        //let second = milliseconds - pantone[current].starts;        

        if (newMarker) markers.push({
            ...newMarker,
            id: generateRandomId(5),
            currentIndex: current,
            playback: milliseconds+300, // There's some trailing error here that I can't figure out
            offset: { x: second * factor },
        });
        this.setState({ addingMarker: false, newMarker: null, markers, colorPicker: false, sizePicker: false });
    }

    onClickMarker = (marker) => {        
        const { factor } = this.state;
        this.onPause();
        this.setState({ editingMarker: true, newMarker: marker, current: marker.currentIndex, currentTime: marker.offset.x / factor, values: [marker.current_value] }, () => {
            this.onUpdate(this.state.values);
        });
    }

    onRemoveMarker = () => {
        const { newMarker } = this.state;
        this.setState({
            newMarker: null,
            editingMarker: false,
            markers: _.reject(this.state.markers, m => m.id === newMarker.id)
        });
    }

    render() {
        const { left, initialLeft, pantone = [], values, clips, modal, current, addingMarker, editingMarker, markers = [], newMarker } = this.state,
            { params } = this.props;

        let totalSeconds = _.chain(pantone)
            .map(p => p.seconds)
            .reduce((num, memo) => num + memo, 0)
            .value();

        const domain = [0, totalSeconds];        

        return (<section className={`d-flex justify-content-center h-100 flex-row ${(addingMarker || editingMarker) ? 'bg-black' : ''}`}>

            <div style={{ width: 1600 * 0.5, height: 'auto' }} className="align-self-start mt-2 d-flex flex-column">
            <input type="text" className="form-control control-sm mt-2" placeholder="Project Name" ref={i => this.txtName = i} />
                <div className="bg-black mb-2 mt-2" style={{ height: 900 * 0.5, position: 'relative' }}>

                    <div onClick={(e) => this.onClickVideo(e)} style={{ width: 1600 * 0.5, height: 900 * 0.5, position: 'relative' }}>
                        {pantone.map((vid, index) => <video
                            key={index}
                            style={{ position: 'absolute', display: current === index ? 'block' : 'none' }}
                            ref={player => (this[`player${index}`] = player)}
                            controls={false}
                            width={1600 * 0.5}
                            height={900 * 0.5}
                        />)}
                        <i className={`icon-VideoCirclePointer marker-${newMarker ? newMarker.size_string.toLowerCase() : ''}`} style={{ color: newMarker ? newMarker.color : 'transparent', pointerEvents: 'none', position: 'absolute', top: newMarker ? newMarker.y : 0, left: newMarker ? newMarker.x : 0 }} />
                    </div>

                    {pantone.length > 0 && !addingMarker && !editingMarker && <div className="d-flex flex-row w-100" style={{ position: 'absolute', bottom: 20 }}>
                        {!this.state.playing && <span className="white font-40 fa fa-play mr-4 ml-4" onClick={this.onPlay} />}
                        {this.state.playing && <span className="white font-40 fa fa-pause mr-4 ml-4" onClick={this.onPause} />}

                        <button onClick={this.onAddingMarker} style={{ height: 40 }} className="btn-sm btn-link debu ml-auto pl-2 pr-2 white text-shadow shadow"><i className="icon-VideoCirclePointer font-16" /> Add Marker</button>

                        <button style={{ height: 40 }} className="btn-sm btn-success mr-4 pl-2 pr-2" onClick={this.onSave}><i className="fa fa-save" /> Save</button>
                    </div>}
                </div>

                {/* DROP AREA */}
                {!addingMarker && !editingMarker && <Drop left={left} initialLeft={initialLeft} pantone={pantone} markers={markers} onDrop={this.onAddToPantone} factor={this.state.factor} onRemove={this.onRemoveFromPantone} onMoveLeft={this.onMoveClipLeft} onMoveRight={this.onMoveClipRight} onMarker={this.onClickMarker} />}

                {addingMarker === true && <div className=" d-flex flex-column">
                    <div style={{ height: 50 }} className=" d-flex flex-row justify-content-center">
                        <span className="white font-10 align-self-center text-center">Click where you want to place your<br />marker in the video</span>
                    </div>
                    <div style={{ height: 50 }} className=" d-flex flex-row">
                        <div className=" w-100 h-100 d-flex flex-row justify-content-center">
                            <div className="d-flex flex-row justify-content-center  align-self-center" onClick={() => this.setState({ colorPicker: true, sizePicker: false })}>
                                <span className="white font-12 align-self-center mr-2">Color:</span>
                                <div style={{ width: 30, height: 30, borderRadius: 15, border: `4px solid white`, backgroundColor: this.state.selectedColor }} />
                            </div>
                        </div>
                        <div className=" w-100 h-100 d-flex flex-row justify-content-center" onClick={() => this.setState({ sizePicker: true, colorPicker: false })}>
                            <div className="d-flex flex-row justify-content-center  align-self-center">
                                <span className="white font-12 align-self-center mr-2">Size:</span>
                                <i className={`icon-VideoCirclePointer font-${this.state.selectedSize.value} white align-self-center`} />
                                <span className="white font-12 align-self-center">{this.state.selectedSize.text}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 80 }} className=" d-flex flex-column justify-content-center">
                        <button onClick={this.onSaveMarker} className={`btn btn-${newMarker ? 'success' : 'default'} h-100 align-self-center white`}>Save Marker</button>
                        <button className="btn btn-link h-100 align-self-center white" onClick={() => this.setState({ addingMarker: false })}>Cancel</button>
                    </div>
                </div>}

                { editingMarker === true && <div className="d-flex flex-column">
                        <div style={{ height: 50 }} className="d-flex flex-row-justify-content-center">
                            <span className="white align-self-center text-center">Are you sure you want to remove this marker?</span>
                        </div>
                        <button className="btn btn-danger" onClick={this.onRemoveMarker}>Yes, remove it</button>
                        <button className="btn btn-link white" onClick={() => this.setState({ newMarker: null, editingMarker: false })}>No, cancel</button>
                    </div>}

                {!addingMarker && !editingMarker && <div className="h-100">
                    {clips && clips.length > 0 && pantone && pantone.length > 0 && (
                        <Slider
                            mode={1}
                            step={1}
                            domain={domain}
                            rootStyle={sliderStyle}
                            onUpdate={this.onUpdate}
                            onSlideStart={this.onSlideStart}
                            values={values}
                        >
                            <Rail>
                                {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div>
                                        {handles.map(handle => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                domain={domain}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                            <Tracks right={false} left={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                        {
                                            tracks.map(({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                />
                                            ))}
                                    </div>
                                )}
                            </Tracks>
                            <Ticks count={10 /* generate approximately 15 ticks within the domain */}>
                                {({ ticks }) => (
                                    <div className="slider-ticks">
                                        {ticks.map(tick => (
                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                        ))}
                                    </div>
                                )}
                            </Ticks>
                        </Slider>)}
                </div>}
            </div>

            { !addingMarker && !editingMarker && <div style={{ width: 250, overflowY: 'auto' }} className="ml-2  h-100">
                {clips && clips.map((highlight, i) =>
                    <Drag onClick={this.onAddToPantone} key={i} index={i} highlight={highlight} pantone={pantone} />
                )}
            </div>}

            { modal && <Confirm {...this.props} isOpen={modal} toggle={this.toggleModal} />}

            { clips && clips.length === 0 && <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} className="p-2 bg-danger">
                <Link to={`/sqwad/team/${params.idTeam}/videos`} className="white">Seems like you don't have video clips to work with yet. First you need to generate some Clips out of your <i className="white">Videos</i></Link>
            </div>}

            {/* COLOR PICKER */}
            <div style={{ width: 300, position: 'fixed', right: this.state.colorPicker ? 0 : -300, top: 0, bottom: 0 }} className="bg-gray  ease d-flex flex-column">
                <div className="d-flex flex-row">
                    <i className="fa fa-times font-20 black ml-2 align-self-center" onClick={() => this.setState({ colorPicker: false })} />
                    <span className="font-20 black ml-auto mr-2 align-self-center">Pick a Color</span>
                    <i className="ml-auto" />
                </div>
                <div className="align-self-center d-flex flex-row justify-content-center mt-4" style={{ width: 100, height: 100, backgroundColor: 'gray', borderRadius: 4 }}>
                    <div style={{ width: 70, height: 70, borderRadius: 35, border: `6px solid white`, backgroundColor: this.state.selectedColor }} className="align-self-center" />
                </div>
                <div className="mt-4 d-flex flex-column">
                    <div className="d-flex flex-row ml-2 mr-2">
                        <button className="w-100 pointer" style={{ height: 50, backgroundColor: 'black' }} onClick={() => this.onChangeColor('black')}></button>
                        <div style={{ width: 20 }} />
                        <button className="w-100 pointer" style={{ height: 50, backgroundColor: 'white' }} onClick={() => this.onChangeColor('white')}></button>
                        <div style={{ width: 20 }} />
                        <button className="w-100 pointer" style={{ height: 50, backgroundColor: 'red' }} onClick={() => this.onChangeColor('red')}></button>
                    </div>
                    <div className="d-flex flex-row ml-2 mr-2 mt-2">
                        <button className="w-100 pointer" style={{ height: 50, backgroundColor: 'yellow' }} onClick={() => this.onChangeColor('yellow')}></button>
                        <div style={{ width: 20 }} />
                        <div className="w-100" style={{ height: 50 }}></div>
                        <div style={{ width: 20 }} />
                        <div className="w-100" style={{ height: 50 }}></div>
                    </div>
                </div>
            </div>

            {/* SIZE PICKER */}
            <div style={{ width: 300, position: 'fixed', right: this.state.sizePicker ? 0 : -300, top: 0, bottom: 0 }} className="bg-gray  ease d-flex flex-column">

                <div className="d-flex flex-row">
                    <i className="fa fa-times font-20 black ml-2 align-self-center" onClick={() => this.setState({ sizePicker: false })} />
                    <span className="font-20 black ml-auto mr-2 align-self-center">Pick a Size</span>
                    <i className="ml-auto" />
                </div>

                {this.state.sizes.map((size, i) => <div onClick={() => this.onChangeSize(size)} key={i} className="align-self-center d-flex flex-column justify-content-center mt-4" style={{ width: 100, height: 100, backgroundColor: 'gray', borderRadius: 4, color: (this.state.selectedSize && this.state.selectedSize.value === size.value) ? 'white' : 'black' }}>
                    <i className="icon-VideoCirclePointer align-self-center" style={{ fontSize: size.value }} />
                    <span className="font-12 align-self-center">{size.text}</span>
                </div>)}

            </div>
        </section >)
    }
}

export default DragDropContext(HTML5Backend)(Studio);