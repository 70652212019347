import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';

class CreateOpponent extends React.Component {
    state = {
        opponent: {}
    }
    componentWillMount() {
        setTimeout(() => {
            this.setState({ bootstrapped: true });
        }, 200);
    }
    onSave = () => {
        if (!this.txtName.value){
            alert('Please complete the missing fields');
            return;
        }

        const { opponent, microTransac } = this.state;
        if (microTransac) return;

        // TODO: validate and call API                
        this.setState({ microTransac: true }, () => {            

            request.post(`${config.apiEndpoint}/api/v4/family/opponent`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    Name: this.txtName.value
                })
                .then(({ body }) => {
                    let { IdTeamOther } = body;
                    this.setState({ bootstrapped: false }, () => {
                        setTimeout(() => this.props.fnAdded({
                            IdTeamOther, TeamOtherName: this.txtName.value
                        }), 200);
                    });
                }, reject => {
                    alert('An error has occurred');
                    this.setState({ microTransac: false });
                });

        });

    }
    render() {
        const { opponent } = this.state;
        return <section>

            <section className="fullscreen" style={{ zIndex: 10003 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10004, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">CREATE OPPONENT</span>
                </div>
                
                {/* DESCRIPTION */}
                <div className="montserrat font-10 black mt-3 mx-4">Name</div>
                <div className="mx-4">
                    <input type="text" defaultValue={opponent.Name} className={`form-control`} rows="4" name="name" placeholder="Name" ref={(input) => this.txtName = input} />
                    </div>

                {/* SAVE BUTTON */}
                <button onClick={this.onSave} className="btn btn-success mt-4 mb-4 mx-4" style={{ borderRadius: 30 }}>SAVE</button>
            </Animated>
        </section>
    }
}

export default CreateOpponent;