import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../common/CustomDatePicker2';
import Due from './due';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../../../config';

class AddRegistrationProgram extends React.Component {

  state = {
    saving: false,
    plansEnabled: (this.props.dues && this.props.dues.length > 0),
    isOpenFrom: false,
    isOpenTo: false,
    from: moment(),
    to: moment().add(1, 'day')
  }

  // Lifecycle
  componentWillMount() {
    if (this.props.generic) {
      const { generic } = this.props;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.generic) {
      const { generic } = nextProps;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  //

  toggleCalendarFrom = () => {
    this.setState({
      isOpenFrom: !this.state.isOpenFrom
    })
  }
  toggleCalendarTo = () => {
    this.setState({
      isOpenTo: !this.state.isOpenTo
    });
  }
  togglePlans = () => {
    this.setState({
      plansEnabled: !this.state.plansEnabled
    }, () => {
      if (this.state.plansEnabled) {
        this.props.paymentsActions &&
          this.props.paymentsActions.setDues([{
            DateDue: moment().add(1, 'month')
          }]);
      } else {
        this.props.paymentsActions &&
          this.props.paymentsActions.clearDues();
      }
    })
  }

  addDue = () => {
    const { dues } = this.props;
    this.props.paymentsActions &&
      this.props.paymentsActions.addDue({
        DateDue: moment(_.last(dues).DateDue).add(1, 'month') // TODO: calculate next due based on the number of dues that there are, and the configuration
      });
  }

  handleChangeFrom = (date) => {
    const { to } = this.state;
    this.setState({
      from: moment(date),
      to: moment(date).isAfter(moment(to)) ? moment(date).add(1, 'day') : moment(to) // range validation
    });
  }

  handleChangeTo = (date) => {
    const { from } = this.state;
    this.setState({
      to: moment(date),
      from: moment(date).isBefore(moment(from)) ? moment(date).add(-1, 'day') : moment(from) // range validation
    });
  }

  handleChangeBase = (e) => {
    const { generic } = this.props;
    generic.base = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  handleChangeFee = (e) => {
    const { generic } = this.props;
    generic.fee = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  handleChangeLate = (e) => {
    const { generic } = this.props;
    generic.late = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }
  handleChangeInitial = (e) => {
    const { generic } = this.props;
    generic.initial = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  save = () => {
    const { generic, dues, season } = this.props, { saving, from, to } = this.state;
    if (saving) return;

    this.setState({ saving: true }, () => {
      // TODO: do this with Redux ?
      request[generic.id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/paymentPlans${generic.id ? '/' + generic.id : ''}`)
        .send({
          ...generic,
          name: this.txtName.value,
          from,
          to,
          dues,
          idLeague: season.IdLeague,
          idSeason: season.IdSeason
        })
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then((data) => {
          if (data.body.success) {
            this.props.paymentsActions &&
              this.props.paymentsActions.fetchPrograms(season.IdSeason);
            this.props.toggle && this.props.toggle();
          }

        }, reject => {
          this.setState({ saving: false });
        });
    });
  }

  render() {
    const { isOpen, toggle, dues, generic } = this.props,
      { from, to, saving } = this.state;

    let total = 0;
    if (generic) {
      total = generic.base + generic.fee;
    }

    return <Modal isOpen={isOpen} toggle={toggle} className="w-500">

      <ModalHeader className="bg-dark-blue white"><i className="fas fa-address-card margin-right-half" />{generic.id ? 'Edit' : 'New'} Registration Program</ModalHeader>

      <ModalBody className="container-fluid p-4">
        {generic && <div className="row">
          { /* Name and Dates */}
          <div className="col-12 d-flex flex-row margin-bottom-half">
            <input className="form-control align-self-center" type="text" placeholder="Program Name" defaultValue={generic.name} ref={(i) => this.txtName = i} />
          </div>
          <div className="col-12 d-flex flex-row justify-content-center">
            <div className="align-self-center">
              <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={from} selectsStart
                startDate={from} endDate={to} onChange={this.handleChangeFrom} />
            </div>
            <span className=" idented-half align-self-center margin-right-half ">to</span>
            <div className="align-self-center">
              <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={to}
                selectsEnd startDate={from} endDate={to} onChange={this.handleChangeTo} />
            </div>
          </div>

          <div className="col-12">

            <div className="d-flex flex-row">
              <div className="d-flex flex-column p-2  w-100">
                <span className="">Base Registration</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input onChange={this.handleChangeBase} defaultValue={generic.base} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                </div>
              </div>
              <div className="d-flex flex-column p-2  w-100">
                <span className="">Payment Plan Fee</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input value={generic.fee} disabled="disabled" type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row">

              <div className="d-flex flex-column p-2 w-100">
                <span className="">Initial Fee</span>
                <div className="input-group" >
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input defaultValue={generic.initial} disabled={this.state.plansEnabled ? false : true} type="text" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={this.handleChangeInitial} />
                </div>
              </div>

              <div className="d-flex flex-column p-2 w-100">
                <span className="">Total</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input value={total} disabled="disabled" type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                </div>
              </div>

            </div>

            {this.state.plansEnabled && false && <hr style={{ background: 'white', color: 'white' }} className="no-margin" />}

          </div>

          <div className="col-12">

            {/* THE CHECK BOX */}
            <div className="form-check margin-top-half w-100">
              <input id="checkPlans" onChange={this.togglePlans} className="" type="checkbox"
                checked={this.state.plansEnabled} />
              <label className="form-check-label idented-half" htmlFor="checkPlans">Use Payment Plan</label>
            </div>


            {this.state.plansEnabled && <section className="container no-padding no-margin">

              { /* PP Fees */}
              <div className="no-padding no-margin">
                <div className="d-flex flex-row">

                  <div className="d-flex flex-column p-2 w-100">
                    <span className="">Fee</span>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input defaultValue={generic.fee} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={this.handleChangeFee} />
                    </div>
                  </div>

                  <div className="d-flex flex-column p-2 w-100">
                    <span className="">Late</span>
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input defaultValue={generic.late} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                    </div>
                  </div>

                </div>

              </div>
              { /* Dues */}
              <div>
                {dues && dues.length && dues.map((due, i) => (
                  <Due due={due} {...this.props} key={i} index={i} />
                ))}
                {this.state.plansEnabled && <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className=" mt-2 green align-self-center btn-block" onClick={this.addDue}><i className="fa fa-plus" />
                  {' '}Add Due</button>}
              </div>
            </section>}
          </div>
        </div>}
      </ModalBody>

      <ModalFooter>
        {!saving && <button className="btn btn-link" onClick={toggle}>Cancel</button>}
        <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3" onClick={this.save}>{saving ? 'Saving, please wait...' : 'Save program'}</button>
      </ModalFooter>

    </Modal>
  }
}

export default AddRegistrationProgram;
