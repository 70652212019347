import React from 'react';
import Loader from '../../../common/Loader';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import BuildPreview from '../../../common/modals/BuildPreview';
import BuildSchedule from '../../../common/modals/BuildSchedule';
import FlightedTeams from '../../../common/modals/FlightedTeams';
import { Link } from 'react-router';
import _ from 'lodash';
import BlueishHeader from '../../../common/things/BlueishHeader';
import BlackishHeader from '../../../common/things/BlackishHeader';
import DivisionTile from './divisions/DivisionTile';
import FlightTile from './divisions/flightTile';
import config from '../../../../config';
import request from 'superagent';

class Pairings extends React.Component {

    state = {
        divisions: null,
        bootstrapped: false,
        isBuildPreviewOpen: false,
        modal: false,
        buildingSchedule: false,
        isFlightedTeamsOpen: false,
        selectedFlight: null
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Schedule Manager | Pairings',
            main: this.props.season.Name || null
        });

        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason)

        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Schedule Manager | Pairings',
                main: nextProps.season.Name || null
            });
        }
        if (nextProps.divisions) {
            this.setState({
                divisionsWithFlights: _.filter(nextProps.divisions, d => d.Flights.length > 0),
                divisionsWithoutFlights: _.filter(nextProps.divisions, d => d.Flights.length === 0)
                , bootstrapped: true
            });
        }
    }
    //

    toggleModal = () => this.setState({ modal: !this.state.modal });
    toggleBuildPreview = () => this.setState({ isBuildPreviewOpen: !this.state.isBuildPreviewOpen });
    toggleFlightedTeams = () => this.setState({ isFlightedTeamsOpen: !this.state.isFlightedTeamsOpen });
    toggleSeasonCheckedForSchedule = () => {
        const { season, divisions } = this.props;
        season.checkedForSchedule = !season.checkedForSchedule;

        divisions.forEach(d => {
            d.checkedForSchedule = season.checkedForSchedule;
            d.Flights.forEach(f => {
                f.checkedForSchedule = season.checkedForSchedule;
            });
        });
        this.forceUpdate();
    }
    buildSchedule = () => {
        const { season, divisions } = this.props;
        this.setState({ buildingSchedule: true }, () => {

            const flightsChecked = _.chain(_.map(divisions, division => {
                return division.Flights
            })).flatten().filter((flight) => {
                return flight.checkedForSchedule;
            }).value();

            request.post(`${config.apiEndpoint}/api/v4/pairings/build`)
                .send({ ids: _.map(flightsChecked, flight => flight.IdFlight) })
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(data => {
                    this.setState({
                        buildingSchedule: false,
                        modal: false
                    }, function () {
                        //this.toggleBuildPreview();
                        // TODO: go to calendar
                        this.props.router.push('/season/' + season.IdSeason + '/games_calendar'); //from.pathname);
                    });
                }, reject => {                    
                    alert('There was an error at building. Please check the console!')
                    this.setState({ buildingSchedule: false });
                });
        });
    }
    goToCalendar = () => { }
    selectFlight = selectedFlight => this.setState({ selectedFlight, isFlightedTeamsOpen: true });

    render() {
        const { bootstrapped, isBuildPreviewOpen, divisionsWithFlights = [], divisionsWithoutFlights = [], isFlightedTeamsOpen, selectedFlight } = this.state,
            { season } = this.props;
        const individualFlights = _.chain(divisionsWithFlights).map('Flights').flatten().value();
        individualFlights.forEach(f => {
            f.division = _.pick(_.find(divisionsWithFlights, d => d.IdDivision === f.IdDivision), 'Name', 'IdDivision', 'IdGender', 'Gender');
        });

        return <section id="schedule-manager" className="bg-gray-dark">
            <div className="filters-wrapper d-flex flex-row">
                <ReactCSSTransitionGroup className="w-100" transitionName="example" transitionAppear={true} transitionAppearTimeout={200}
                    transitionEnterTimeout={200} transitionEnter={true} transitionLeave={true} transitionLeaveTimeout={200}>
                    {bootstrapped &&
                        <div className="d-flex flex-column w-100 align-self-center">
                            <div className="d-flex flex-row ml-auto " key="topActions">
                                <button className="btn-link white btn btn-sm" onClick={this.toggleBuildPreview}><i className="fa fa-list"></i> Schedule Summary</button>
                                <button className="btn-success btn btn-sm" onClick={this.toggleModal}><i className="fa fa-cog"></i> Build Schedule</button>
                                <Link to={`/season/${season.IdSeason}/schedule_manager/auto`} className="btn btn-sm tile-rainbow margin-left-half white" ><i className="fa fa-cogs" /></Link>
                                <button className="btn btn-sm tile-rainbow margin-left-half white" style={{ border: '1px solid white' }}><i className="fas fa-hand-paper" /></button>
                            </div>
                        </div>}
                </ReactCSSTransitionGroup>
            </div>

            <ReactCSSTransitionGroup className="work-area container-fluid" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
                transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
                {bootstrapped && (
                    <div key="with-data" className="row d-flex align-items-stretch full-height">
                        <div className="col-12 no-margin no-padding">
                            <BlueishHeader message="Divisions with Flights" />                            
                            <section className="clearfix margin-bottom">
                                {individualFlights.map((flight, index) => <FlightTile flight={flight} key={index} division={flight} fnSelectFlight={this.selectFlight} />)}
                            </section>

                            <BlackishHeader message="Divisions without Flights" />
                            <section className="clearfix" >
                                {divisionsWithoutFlights.map((division, i) =>
                                    <DivisionTile division={division} key={i} fnSelectFlight={this.selectFlight} />
                                )}
                            </section>
                        </div>

                    </div>
                )}
            </ReactCSSTransitionGroup>

            {!bootstrapped && <Loader message="Please wait..." />}
            
            {(this.state.modal || this.state.buildingSchedule) && (
                <BuildSchedule isOpen={this.state.modal || this.state.buildingSchedule} toggle={this.toggleModal} season={season}
                    onToggleSeasonCheckedForSchedule={this.toggleSeasonCheckedForSchedule} onBuildSchedule={this.buildSchedule}
                    {...this.props} />)}
            { /* Build Preview / Summary */}
            
            {isBuildPreviewOpen && <BuildPreview isOpen={isBuildPreviewOpen} toggleBuildPreview={this.toggleBuildPreview}
                fnOk={this.goToCalendar} season={season} params={this.props.params} />}
            
            {isFlightedTeamsOpen && <FlightedTeams flight={selectedFlight} isOpen={isFlightedTeamsOpen} toggle={this.toggleFlightedTeams} />}
        </section>
    }
}

export default Pairings;
