import React from 'react';
import { Animated } from 'react-animated-css';
import VideoPlayerFull from './partials/VideoPlayerFull';
import Loader from '../common/Loader';
import axios from 'axios';
import { generateRandomId } from '../../helpers';
import fakeAuth from '../../fakeAuth';
import config from '../../config';
import ClipModal from './partials/ClipModal';
import { Link } from 'react-router';
import EditModal from '../common/modals/EditVideo';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,

} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalBody, } from 'reactstrap';
import './css.css';

class Video extends React.Component {

    state = { bootstrapped: true, video: null, side: false, quick: false, clipTitle: false, clipLength: null, middle: null, copySuccess: false }

    // Lifecycle
    componentWillMount() {
        const { setTitle, video, teamActions, params } = this.props, { idVideo } = params;
        this.setState({ video });

        setTitle && setTitle({
            main: 'SQWAD Services',
            sub: video ? video.name : null
        });

        teamActions.fetchVideo(idVideo)
    }
    componentWillReceiveProps = nextProps => {
        const { video } = nextProps, { setTitle } = this.props;
        if (!this.state.video || this.state.video.idVideo !== video.idVideo) {

            setTitle && setTitle({
                main: 'SQWAD Services',
                sub: video ? video.name : null
            });
        }
        this.setState({ video });
    }
    //

    onAction = e => { }

    onClip = e => {
        this.setState({ side: true, middle: parseInt(e, 10) })
    }
    onQuick = ({ length, middle }) => {
        this.setState({ quick: true, clipTitle: `${length}SEC CLIP`, clipLength: length, middle });
    }
    onSaveClip = ({ start, duration }) => {
        const fileName = `${generateRandomId(20)}.mp4`;
        const { video } = this.state;
        axios.post(`${config.wowzaEndpoint}/api/v4/video/clip/${video.idVideo}`, {
            start, duration, name: fileName
        }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('sportslogic.authtoken')}` },
        }).then(({ data }) => {
            this.setState({ quick: false, side: false, clipTitle: null, clipLength: null });
        });
    }
    toggleQuick = () => this.setState({ quick: !this.state.quick });
    onClipped = () => this.setState({ clipped: true });
    onRemoveClip = () => this.setState({ clipped: false });
    onTag = () => {
        const user = fakeAuth.getUser(), { video } = this.state;
        if (user && user.IdFamily === video.idAccount) {
            this.setState({ editing: !this.state.editing });
        }
    }
    onShare = () => { this.setState({ sharing: true }); }

    render() {
        const { video, bootstrapped, side, quick, middle, clipLength, clipped, editing } = this.state, { params, copySuccess } = this.props;
        let shareUrl = `https://matchreports.thesqwad.com/highlight2/${video.idVideo}` ;
        return (<section className={side ? 'd-flex flex-row' : ''}>

            <Animated animateIn="fadeIn" className="w-100 ">
                {bootstrapped && video && video.idVideo && video.idVideo === params.idVideo && (
                    <div style={{ margin: 'auto', maxWidth: side ? null : 1200 }} className="">
                        <div className="clearfix league-list margin-bottom d-flex flex-column">
                            <VideoPlayerFull onClip={this.onQuick} video={video}
                                onAction={this.onAction} onTag={this.onTag} onShare={this.onShare} />
                            {clipped && <div style={{ height: 100 }} />}
                        </div>
                    </div>
                )}
            </Animated>

            <div style={{ position: "fixed", right: '4em', bottom: 20, left: '13em', height: 'auto' }}>
                {clipped && <Animated animationIn="fadeInRightBig">
                    <div className="tile card p-2 mt-2 d-flex flex-row bg-gray" style={{ position: 'relative', height: 60, borderRadius: 30, overflow: "hidden" }}>
                        <div style={{ position: 'absolute', left: 0, width: `100%`, top: 0, bottom: 0, backgroundColor: 'green' }} onClick={this.onRemoveClip} />
                        <span className="ml-4 w-100 align-self-center white" style={{ zIndex: 1 }}>Video Clip is being generated.</span>
                        <Link className="ml-auto align-self-center white pl-4 pr-4 text-center mr-4" style={{ zIndex: 1, border: '1px solid white', height: 30, borderRadius: 15, width: 150 }} to={params.idTeam ? `/sqwad/team/${params.idTeam}/clips` : `/sqwad/family/clips`}>Go to Clips</Link>
                    </div>
                </Animated>}
            </div>


            {quick && <ClipModal onClipped={this.onClipped} isOpen={quick} toggle={this.toggleQuick} video={video} middle={middle} length={clipLength} />}
            {!bootstrapped && <Loader key="loader" message={`Loading Videos`} />}
            {editing && <EditModal {...this.props} toggle={() => this.setState({ editing: !this.state.editing })} isOpen={editing} video={this.state.video}
                selectedTeam={this.props.selectedTeam} />}

            <Modal isOpen={this.state.sharing} toggle={() => this.setState({ sharing: !this.state.sharing })} style={{ width: 450 }}>
                <ModalBody className="p-4 bg-white text-center w-100 d-flex flex-column justify-content-center" >
                    <img src={`https://api.sportslogic.net/api/v5/qrcodes/video/${this.state.video.IdVideo || this.state.video.idVideo}`} style={{ width: 300 }} alt="QR_Code" className="align-self-center mb-4" />
                    <div className="d-flex flex-row align-self-center mb-4">

                        <TwitterShareButton className="mr-2 align-self-start" title="Video" url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton className="mr-2 align-self-start" title={`Check out this video`} url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton className="mr-2 align-self-start" quote={`Check out this video`} url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <div className="w-auto d-flex flex-column">
                            <input className={`${copySuccess ? 'bg-success' : ''} form-control text-input w-auto`} disabled="disabled" ref={(i) => this.txtClipboard = i} value={shareUrl} />
                            {this.state.copySuccess && <span className="black font-10">Link copied in your clipboard!</span>}
                        </div>

                        <CopyToClipboard text={shareUrl}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className="btn btn-link align-self-start"><i className="fas fa-clipboard blue" /></button>
                        </CopyToClipboard>

                    </div>

                </ModalBody>
            </Modal>
        </section >)
    }
}

export default Video;