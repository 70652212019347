import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import Example from './example';
import request from 'superagent';
import config from '../../../../config';
import COLORS from '../../../../colors';
import { Alert } from 'reactstrap';
import Navigation from '../navigation';

import './points.css';

import TieBreaker from './TieBreaker';

class Points extends React.Component {

  state = {
    mode: null,
    tiebreakers: [],
    scoreSystemOptions: {
      win: 0,
      tie: 0,
      score: 0,
      scoreCap: 0,
      shutout: 0,
      differentialCap: 0
    }
  }

  // Lifecycle
  componentWillMount = () => {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Points / Tiebreakers',
      main: this.props.season.Name || null
    });

    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.seasonActions && this.props.seasonActions.fetchPointSystem(params.idSeason);

    // TODO: the season should have the Tiebreakers already. If not, then we should pre-populate
    // with 5 tiebreakers each with 'select' as option
    this.setState({
      tiebreakers: [
        {
          label: 'Select...',
          value: 0
        }, {
          label: 'Select...',
          value: 0
        }, {
          label: 'Select...',
          value: 0
        }, {
          label: 'Select...',
          value: 0
        }, {
          label: 'Select...',
          value: 0
        }
      ]
    })
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Points / Tiebreakers',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.points && nextProps.contest_type) {
      const { points, contest_type } = nextProps;
      this.setState({ points, contest_type, mode: points.IsPoint ? 1 : 2, bootstrapped: true }, () => { });
    }
  }
  componentWillUnmount() {
    this.props.seasonActions.clearPoints();
  }
  //

  switchMode = mode => this.setState({ mode }, () => {
    this.save();
  });

  removeTieBreaker = (index) => {
    let { tiebreakers } = this.state;
    tiebreakers = [
      ...tiebreakers.slice(0, index),
      ...tiebreakers.slice(index + 1)
    ];
    this.setState({ tiebreakers });
  }

  changeTieBreaker = (index, tiebreaker) => {
    let { tiebreakers } = this.state;
    tiebreakers = [
      ...tiebreakers.slice(0, index),
      tiebreaker,
      ...tiebreakers.slice(index + 1)
    ];
    this.setState({ tiebreakers });
  }

  save = () => {
    const { idSeason } = this.props.params, { mode, points } = this.state;
    // Create the 1st bracket ?

    this.setState({ saving: true }, () => {
      request[points.Id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/seasons/point_system/${idSeason}`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .send({
          PointForWin: (this.txtWin || {}).value,
          //PointForLoss: this.txt
          PointForTie: (this.txtTie || {}).value,
          PointForShutout: (this.txtShutout || {}).value,
          PointForDifferentialCap: (this.txtDifferentialCap || {}).value,
          PointForGoal: (this.txtScore || {}).value,
          PointGoalCap: (this.txtScoreCap || {}).value,
          IsPoint: mode === 1 ? true : false
        })
        .then(({ body: { Id } }) => {
          points.Id = points.Id || Id;
          this.setState({ saving: false, points });
        }, () => {
          this.setState({ saving: false, points });
        });
    });

  }

  render() {
    const { mode, points = {}, saving = false, contest_type = [], bootstrapped = false, updated, error } = this.state,
      { settings, league, season } = this.props;

    return (
      <section>

        <div className="w3-container mt-4">
          <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
            <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
              {this.state.error}
            </Alert>
            <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
              Season updated!
            </Alert>
          </div>

          {this.props.season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
            <div className="w-100 black">
              <h3>{this.props.season.Name || this.props.season.SeasonName}</h3>
              <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
            </div>
          </div>}

          <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
            <h5>Schedule Management</h5>
            {this.props.season && <Navigation season={this.props.season} />}
          </div>

          {season && bootstrapped && <Animated animationIn="slideInUp" className="my-3 card shadow w3-content pb-4" style={{ maxWidth: 1285, minHeight: 200 }}>

            <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>
              <div className="black">
                <h1>Standing Points System</h1>
              </div>
              <div className="ml-auto d-flex flex-row">
                {!saving && <button style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-2 py-2 px-3 align-self-center block" onClick={this.save}><i className="fa fa-save" />
                  {' '}Save Changes</button>}
                {saving && <button style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-2 py-2 px-3 align-self-center block"><i className="fa fa-save" />
                  {' '}Saving...</button>}
              </div>

            </div>

            { /* Work Area */}
            <div className="container-fluid no-margin no-padding">
              {bootstrapped && <div className="row no-margin no-padding">
                <div className="col-7 no-margin no-padding ">
                  
                  <div className="d-flex flex-row">
                    <span className="black ml-3 align-self-center">Calculate standings using:{' '}</span>
                    <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: mode === 2 ? COLORS.white : COLORS.jeff_blue, backgroundColor: mode === 2 ? COLORS.jeff_blue : COLORS.transparent }} className="ml-2 p-2 align-self-center block" onClick={() => this.switchMode(2)}>Games Back</button>
                    <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: mode === 1 ? COLORS.white : COLORS.jeff_blue, backgroundColor: mode === 1 ? COLORS.jeff_blue : COLORS.transparent }} className="ml-2 p-2 align-self-center block" onClick={() => this.switchMode(1)}>Points System</button>
                  </div>

                  {mode && mode === 1 && (
                    <div className="container-fluid margin-top-half">
                      <div className="row">
                        <div className="col-6">

                          <div className="form-group">
                            <label className="control-label black">Game Points</label>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">Win</span>
                              <input style={{ width: '100px' }} min="0"
                                defaultValue={points.PointForWin} type="number" className="form-control ml-auto align-self-center"
                                ref={(input) => this.txtWin = input}
                              />
                            </div>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">Tie</span>
                              <input style={{ width: '100px' }} min="0" defaultValue={points.PointForTie}
                                type="number" className="form-control ml-auto align-self-center" ref={(input) => this.txtTie = input}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label black">*Score* Points</label>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">*Score*</span>
                              <input style={{ width: '100px' }} min="0" defaultValue={points.PointForGoal} type="number" className="form-control ml-auto align-self-center" ref={(input) => this.txtScore = input}
                              />
                            </div>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">*Score Cap*</span>
                              <input style={{ width: '100px' }} min="0" defaultValue={points.PointGoalCap} type="number" className="form-control ml-auto align-self-center" ref={(input) => this.txtScoreCap = input}
                              />
                            </div>
                          </div>

                        </div>

                        <div className="col-6">
                          
                          <div className="form-group">
                            <label className="control-label black">Bonus Points</label>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">Shutout</span>
                              <input style={{ width: '100px' }} min="0" defaultValue={points.PointForShutout} type="number" className="form-control ml-auto align-self-center" ref={(input) => this.txtShutout = input}
                              />
                            </div>
                          </div>
                          
                          <div className="form-group">
                            <label className="control-label black">Others</label>
                            <div className="d-flex flex-row">
                              <span className="black align-self-center font-9">Differential Cap</span>
                              <input style={{ width: '100px' }} min="0" defaultValue={points.PointDifferentialCap} type="number" className="form-control ml-auto align-self-center" ref={(input) => this.txtDifferentialCap = input}
                              />
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </div>)}

                  {mode && mode === 2 && (<Example />)}

                </div>

                <div className="col-5 no-margin no-padding tie-breakers-wrapper">
                  <h2 className="text-center black col-12 no-margin">Tie-Breakers</h2>
                  <ul className="no-style-list no-margin no-padding">
                    {contest_type.map((tiebreaker, i) => <TieBreaker settings={settings} className="d-flex flex-row" idSeason={this.props.params.idSeason}
                      tiebreaker={tiebreaker} key={i} index={i} fnRemove={() => this.removeTieBreaker(i)} fnChange={this.changeTieBreaker}
                    />)}
                  </ul>
                </div>
              </div>}
            </div>
          </Animated>}
        </div>
        
      </section>)
  }
}

export default Points;
