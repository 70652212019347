import React from 'react';
import _ from 'lodash';
import TeamSelection from './TeamSelection';
import UserSelection from './UserSelection';
import './css.css';
import PlayerDetails from '../../common/modals/PlayerDetails';
import RedishHeader from '../../common/things/RedishHeader';
import request from 'superagent';
import config from '../../../config';
import Entity from './Entity';
import { Animated } from 'react-animated-css';
import { humanize_user } from '../../../helpers';

class Comp extends React.Component {

    state = {
        searching: false,
        bootstrapped: false,
        users: null,
        searchText: '',
        mode: 1,
        selectedPlayer: null,
        fetchingTeams: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam, selectedUser, family } = this.props;
        setTitle && setTitle({
            main: 'Add Existing User',
            sub: selectedTeam ? selectedTeam.TeamName : null,
            season: selectedTeam ? selectedTeam.SeasonName : null,
            division: selectedTeam ? selectedTeam.DivisionName : null
        });

        // Fetch family teams for roster
        if (selectedUser && !selectedTeam && family && family.length) {
            this.props.familyActions && this.props.familyActions.selectUser(_.first(family));
            this.setState({ fetchingTeams: true }, () => {
                this.props.rosterActions && this.props.rosterActions.fetchTeams(_.first(family).IdUser);
            });
        } else if (!selectedUser) {
            this.props.familyActions && this.props.familyActions.fetchMembers();
        } else {
            this.fetchRelated(this.props);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        const { setTitle } = this.props;

        // If the selectedUser has changed, search the teams of that user
        if ((!this.props.selectedUser && nextProps.selectedUser) ||
            (this.props.selectedUser && this.props.selectedUser.IdUser !== nextProps.selectedUser.IdUser)) {
            this.setState({ fetchingTeams: true }, () => {
                this.props.rosterActions && this.props.rosterActions.fetchTeams(nextProps.selectedUser.IdUser);
            });

        }

        // If the selectedTeam has changed, search its roster
        if (nextProps.selectedTeam &&
            ((this.props.selectedTeam || {}).IdTeamDivision !== nextProps.selectedTeam.IdTeamDivision)) {

            this.props.rosterActions && this.props.rosterActions.fetchRoster(_.first(nextProps.family).IdUser, nextProps.selectedTeam.IdTeamDivision);
            this.fetchRelated(nextProps);

            setTitle && setTitle({
                main: 'Add Existing User',
                sub: nextProps.selectedTeam ? nextProps.selectedTeam.TeamName : null,
                season: nextProps.selectedTeam ? nextProps.selectedTeam.SeasonName : null,
                division: nextProps.selectedTeam ? nextProps.selectedTeam.DivisionName : null
            });
        }

        if (nextProps.teams && nextProps.teams.length === 0 && this.state.fetchingTeams) {
            this.setState({ fetchingTeams: false, searching: false })
        }
    }
    //

    toogleTeamSelection = () => {
        this.props.teamActions && this.props.teamActions.toggleTeamSelection(false);
    }
    toggleUserSelection = () => {
        this.props.familyActions && this.props.familyActions.toggleUserSelection(false);
    }

    fetchRelated = (props) => {
        const { selectedTeam, selectedUser } = props;
        this.setState({
            searching: true,
            bootstrapped: false
        });

        const url = [config.apiEndpoint, 'api/v6/roster/related', selectedUser.IdUser, selectedTeam.IdTeamDivision].join('/');
        request.get(url)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { users }}) => {
                // Decrypt
                users.forEach(u => humanize_user(u));
                //
                this.setState({
                    searching: false,
                    bootstrapped: true,
                    users
                });
            }, reject => {
                this.setState({
                    searching: false,
                    bootstrapped: true,
                    users: null
                });
            });
    }

    added = (newUser) => {
        var { users } = this.state;

        // Search the user on the list, and update it
        var userIndex = _.findIndex(users, (u) => {
            return u.IdUser === newUser.IdUser
        });
        if (userIndex !== -1) {
            this.setState({
                users: [
                    ...users.slice(0, userIndex),
                    newUser,
                    ...users.slice(userIndex + 1)
                ],
                selectedPlayer: null,
                searchText: null
            }, () => {
                this.txtSearch.value = '';
            });
            this.props.rosterActions && this.props.rosterActions.fetchRoster(this.props.selectedUser.IdUser, this.props.selectedTeam.IdTeamDivision);
        }

    }

    selectPlayer = selectedPlayer => this.setState({ selectedPlayer });
    filter = () => this.setState({ searchText: this.txtSearch.value });
    toggleMode = mode => this.setState({ mode });
    deselectPlayer = () => this.setState({ selectedPlayer: null });

    render() {
        const { isTeamSelectionOpen, isUserSelectionOpen, selectedTeam, limits } = this.props,
            { searching, bootstrapped, users, searchText, selectedPlayer } = this.state;

        const filteredUsers = _.filter(users, (item) => {
            if (!searchText) return true;
            return item.NameFirst.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                item.NameLast.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        });

        const partition = _.partition(filteredUsers, (u) => {
            return u.overrides && u.overrides.length; // !== null
        });

        const coaches = _.filter(_.first(partition), (user) => {
            return _.find(user.overrides, (o) => o.IdRole === 4)
        }),
            players = _.reject(_.first(partition), (user) => {
                return _.find(user.overrides, (o) => o.IdRole === 4)
            });

        const totalPlayers = _.filter(users, user => _.find(user.overrides, (o) => o.IdRole === 3)).length,
            totalCoaches = _.filter(users, user => _.find(user.overrides, (o) => o.IdRole === 4)).length;

        return (<section>            
            { /* Loader */}
            {(!selectedTeam && searching) && <div className="d-flex justify-content-center centered-message">
                <h1 className="white align-self-center block text-center" key="loader"><i className="fa fa-spin fa-cog" /></h1></div>}

            {(!selectedTeam && !searching) && <div className="d-flex justify-content-center centered-message" key="loader">
                <h1 className="white align-self-center block text-center" key="no_teams">
                    Sorry you have no teams to roster</h1></div>}

            {/* dafuq */}
            {false && bootstrapped && limits && !limits.CanEditRoster_Allowed && <div className="d-flex justify-content-center centered-message">
                <h1 className="white align-self-center block text-center" key="not-allowed-message">{limits.Reason}</h1>
            </div>}

            { /* Header and Filter */}
            {bootstrapped && (users && users.length) && limits && (
                <section>
                    <div className="left-actions d-flex flex-column font-10" key="leftActions">
                        <span className="white">Coach / Admins ({totalCoaches})</span>
                        <span className="white">Players ({totalPlayers}{limits && limits.Roster_Max ? ` / ${limits.Roster_Max}` : ''})</span>
                    </div>
                    <div className="right-actions d-flex flex-row-reverse" key="rightActions">
                        <div className="input-group input-group-sm">
                            <input defaultValue={searchText} type="text" onChange={this.filter} className="form-control" placeholder="Search..." ref={(i) => this.txtSearch = i} />
                        </div>
                    </div>
                </section>
            )}

            { /* Users */}
            {bootstrapped && (filteredUsers && filteredUsers.length) && limits && (
                <div className="container margin-bottom">

                    <ul id="roster" className="row" style={{ paddingTop: '3em' }}>
                        {coaches.map((entity, i) => (
                            <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`}
                                style={{ flexBasis: 'auto' }}>
                                <button onClick={() => this.selectPlayer(entity)}>
                                    <Entity entity={entity} />
                                </button>
                            </li>))}
                        {players.map((entity, i) => (
                            <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`}
                                style={{ flexBasis: 'auto' }}>
                                <button onClick={() => this.selectPlayer(entity)}>
                                    <Entity entity={entity} />
                                </button>
                            </li>))}
                    </ul>
                    <hr />
                    {!limits.CanEditRoster_Allowed && <Animated animationIn="fadeIn" animationOut="fadeOutRight">
                        <RedishHeader message={`${limits.Reason || "Maximun number of Players has been reached"}`} />
                    </Animated>}
                    <ul id="others" className="row" style={{ paddingTop: '3em' }}>
                        {_.last(partition).map((entity, i) => (
                            <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`}
                                style={{ flexBasis: 'auto' }}>
                                <button onClick={() => this.selectPlayer(entity)}>
                                    <Entity entity={entity} />
                                </button>
                            </li>))}
                    </ul>
                </div>)}
            { /* None */}
            {bootstrapped && (!filteredUsers || !filteredUsers.length) && limits && (
                <div className="d-flex justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center" key="loader">Sorry, we could not find any players<br />{searchText ? 'with that search criteria' : 'related to your account'}</h1></div>)}
            { /* Modals */}
            {isUserSelectionOpen && <UserSelection {...this.props} isOpen={isUserSelectionOpen} toggle={this.toggleUserSelection} />}
            {isTeamSelectionOpen && <TeamSelection {...this.props} isOpen={isTeamSelectionOpen} toggle={this.toogleTeamSelection} />}
            {selectedPlayer && <PlayerDetails entity={selectedPlayer} isOpen={selectedPlayer ? true : false} toggle={this.deselectPlayer} roles={this.props.roles} selectedTeam={selectedTeam} fnSuccess={this.added}
                permissions={this.props.permissions} limits={limits} />}
        </section>)
    }
}

export default Comp;