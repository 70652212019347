import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../../config';
import request from 'superagent';
import _ from 'lodash';

export function* getCrossFlightRulesSaga() {
    yield* takeEvery("CROSS_FLIGHT_RULES/FETCH", function* (action) {

        try {
            const result = yield call(function () {
                const url = _.compact([config.apiEndpoint, 'api/v3/schedule_manager/cross_flight_rules', action.idSeason]).join('/');

                return request.get(url)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });

            if (result && result.success) {

                yield put({
                    type: 'CROSS_FLIGHT_RULES/SET',
                    rules: result.rules
                });

            } else {

                yield put({
                    type: 'CROSS_FLIGHT_RULES/FETCH_FAILED',
                    result
                });

            }
        } catch (e) {
            yield put({
                type: 'CROSS_FLIGHT_RULES/FETCH_FAILED',
                e
            });
        }
    });
}
