import React from 'react';
import { Link } from 'react-router';
import { Alert } from 'reactstrap';
import './css.css';
import Loader from '../../../common/Loader';
import DivisionsTable from './table';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import Wizzard from './partials/wizzard';
import request from 'superagent';
import config from '../../../../config';
import Navigation from '../navigation';
import { generateRandomId } from '../../../../helpers';

class Main extends React.Component {

    state = {
        bootstrapped: false,
        mode: 1,
        divisions: null,
        isWizzard: false,
        applying: false,
        error: null,
        search_text: null
    }

    // Lifecycle
    componentWillMount() {
        const { params, setTitle } = this.props;
        setTitle && setTitle({
            sub: 'Divisions',
            main: this.props.season ? this.props.season.Name : null
        });

        this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Divisions',
                main: nextProps.season.Name || null
            });
        }
        if (nextProps.divisions) {
            this.setState({ bootstrapped: true });
        }
    }
    componentWillUnmount() {
        this.props.divisionsActions &&
            this.props.divisionsActions.clearDivisions();
    }
    //

    addDivision = () => {
        this.props.divisionsActions &&
            this.props.divisionsActions.addDivision({
                editing: true,
                IsTryout: 0,
                IdGender: 1,
                Gender: "Male",
                isCapped: null,
                capType: null,
                cap: 0,
                CanDelete: 1,
                Reason: null,
                Teams: [],
                DivisionStatus: 'open',
                IdDivisionStatus: 1,
                TotalTeams: 0,
                Color: '#333',
                temporalId: generateRandomId(5)
            });
    }

    onTemplate = () => { }

    onApply = template => {
        const { params } = this.props;
        if (template.Divisions && template.Divisions.length) {
            this.setState({ applying: true }, () => {

                let done = _.after(template.Divisions.length, () => {
                    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
                    this.setState({ applying: false });
                });

                template.Divisions.forEach(division => {
                    division.editing = false;
                    division.IdSeason = params.IdSeason;
                    if (division.Name || division.DivisionName) {
                        this.setState({
                            microTransac: true
                        }, () => {
                            request['post'](`${config.apiEndpoint}/api/v4/divisions/${params.idSeason}`)
                                .send(division)
                                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                                .then(data => {
                                    done();
                                });
                        });
                    } else done();
                });
            });
        }
        //
    }

    onChange = () => { this.setState({ search_text: this.txtSearch.value }); }

    render() {
        const { bootstrapped, mode, isWizzard, error, search_text } = this.state,
            { divisions, season, league } = this.props;

        return (
            <section>

                <div className="w3-container mt-4">

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
                            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                        </div>
                    </div>

                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <h5>Administration</h5>

                        <Navigation season={season} />

                        {(bootstrapped && divisions) && <Animated animationIn="fadeInRightBig" animationInDuration={200} className="card shadow mt-3">

                            <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>

                                <div className="black">
                                    <h1>Divisions</h1>
                                </div>

                                <div className="ml-auto d-flex flex-row">
                                    <input placeholder="Search Divisions" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />

                                    <button onClick={() => this.setState({ isWizzard: !this.state.isWizzard })} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 300 }} className="green ml-2 p-2 align-self-center block">
                                        Apply Template</button>

                                    <button onClick={this.addDivision} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 300 }} className="green ml-2 p-2 align-self-center block">
                                        <i className="fas fa-plus margin-right-half" />Add Division</button>
                                </div>
                            </div>

                            { /* Divisions */}
                            <div className="ml-4 mr-4" key="table">
                                <DivisionsTable mode={mode} search={search_text} {...this.props} onError={(error) => this.setState({ error })} />
                            </div>
                            <div key="scrollTo" ref={(el) => { this.bracketsEnd = el; }} />
                        </Animated>}

                        <div style={{ height: 100 }} />
                    </div>

                </div>

                { /* Loader */}
                {!bootstrapped && <Loader key="loader" message={`Loading Divisions`} />}

                {isWizzard && this.props.season && <Wizzard fnApply={this.onApply} {...this.props} isOpen={isWizzard}
                    toggle={() => this.setState({ isWizzard: !this.state.isWizzard })} />}

                {bootstrapped && this.state.applying && <Animated animationIn="fadeIn" animationOut="fadeOut" className={`bg-black d-flex flex-column justify-content-center w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }} isVisible={this.state.applying}>
                    <i className="heart white align-self-center icon-logo-header" style={{ fontSize: '50pt' }} />
                    <span className="white align-self-center">Applying template, please wait...</span>
                </Animated>}
            </section>
        )
    }
}

export default Main;
