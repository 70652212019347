import React from 'react';
import { ItemTypes } from '../../../../../helpers';
import LeftWorkArea from '../LeftWorkArea';
import RightWorkArea from '../RightWorkArea';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import COLORS from '../../../../../colors';
import config from '../../../../../config';
import request from 'superagent';
import _ from 'lodash';
import BuildPreview from '../../../../common/modals/BuildPreview';
import BuildSchedule from '../../../../common/modals/BuildSchedule';

class Automatic extends React.Component {
    state = {
        currentDivisionsListMode: parseInt(localStorage.getItem('sportslogic.rules.tab') || 1) === 3 ? 2 : 1
    }
    componentWillMount() {
        const { params } = this.props;

        this.props.gameDayRulesActions &&
            this.props.gameDayRulesActions.fetchGameDayRules(params.idSeason);

        this.props.exceptionRulesActions &&
            this.props.exceptionRulesActions.fetchExceptionRules(params.idSeason);

        this.props.fieldAvailabilityRulesActions &&
            this.props.fieldAvailabilityRulesActions.fetchFieldAvailabilityRules(params.idSeason);

    }
    componentWillReceiveProps = nextProps => {
        nextProps.season && !nextProps.locations && this.props.fetchLocations(nextProps.season.IdLeague);
    }

    handleDivisionsListMode = tabNumber => {
        this.setState({ currentDivisionsListMode: (tabNumber === 3) ? 2 : 1 })
    };

    buildSchedule = () => {
        const { divisions } = this.props;
        this.setState({ buildingSchedule: true }, () => {

            const flightsChecked = _.chain(_.map(divisions, (division) => {
                return division.Flights
            })).flatten().filter((flight) => {
                return flight.checkedForSchedule;
            }).value();

            const url = [config.apiEndpoint, 'api/v4/schedule/build'].join('/');
            request.post(url)
                .send({
                    flights: _.map(flightsChecked, (flight) => {
                        return {
                            IdFlight: flight.IdFlight
                        };
                    })
                })
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(({ body }) => {

                    this.setState({
                        buildingSchedule: false,
                        modal: false
                    }, () => { this.toggleBuildPreview(); });
                    // TODO:

                    //this.props.router.push('/season/' + season.IdSeason + '/games_calendar'); //from.pathname);
                }, reject => {
                    alert('ERROR: Check the console!')
                    this.setState({ buildingSchedule: false });
                });
        });
    }

    toggleModal = () => this.setState({ modal: !this.state.modal });
    toggleBuildPreview = () => this.setState({ isBuildPreviewOpen: !this.state.isBuildPreviewOpen });
    toggleFlightedTeams = () => this.setState({ isFlightedTeamsOpen: !this.state.isFlightedTeamsOpen });


    render() {

        const { isBuildPreviewOpen, season } = this.state;

        const accept = [ItemTypes.GAMEDAYRULE, ItemTypes.EXCEPTIONRULE, ItemTypes.FIELDAVAILABILITYRULE /*,..., ItemTypes.TEAM*/],
            { currentDivisionsListMode } = this.state;

        return <section className="container-fluid p-0 m-0" id="schedule-manager">
            <div className="d-flex flex-row">
                <h2>Divisions with Flights</h2>
                <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} className="ml-auto py-2 px-3 align-self-center block" onClick={this.props.onSummary}><i className="fa fa-list"></i> Schedule Summary</button>
                <button style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-2 py-2 px-3 align-self-center block" onClick={this.toggleModal}><i className="fa fa-cog"></i> Build Schedule</button>
            </div>
            <div key="with-data" className="row d-flex align-items-stretch full-height p-0 m-0">
                <div className="col-4 m-0 p-0">
                    <LeftWorkArea {...this.props} accept={accept} currentDivisionsListMode={currentDivisionsListMode} />
                </div>
                <div className="col-8 m-0 p-0">
                    <RightWorkArea {...this.props} notifyTabChange={this.handleDivisionsListMode} />
                </div>
            </div>

            {(this.state.modal || this.state.buildingSchedule) && (
                <BuildSchedule isOpen={this.state.modal || this.state.buildingSchedule}
                    toggle={this.toggleModal} season={season}
                    onToggleSeasonCheckedForSchedule={this.toggleSeasonCheckedForSchedule}
                    onBuildSchedule={this.buildSchedule}
                    buildingSchedule={this.state.buildingSchedule}
                    {...this.props} automatic={true} />)}

            { /* Build Preview / Summary */}
            {isBuildPreviewOpen && <BuildPreview params={this.props.params} isOpen={isBuildPreviewOpen}
                toggleBuildPreview={this.toggleBuildPreview} fnOk={this.goToCalendar} season={season} />}
        </section>
    }
}


export default DragDropContext(HTML5Backend)(Automatic);
