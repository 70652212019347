import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getReceivedMessagesSaga() {
    yield* takeEvery("MESSAGES/RECEIVED/FETCH", function* (action) {
        try {
            const result = yield call(({ idLeague }) => {
                return request.get(`${config.apiEndpoint}/api/v4/messages/received`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'MESSAGES/RECEIVED/SET',
                    messages: result.messages
                });
            } else {
                yield put({
                    type: 'MESSAGES/RECEIVED/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'MESSAGES/RECEIVED/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getSentMessagesSaga() {
    yield* takeEvery("MESSAGES/SENT/FETCH", function* (action) {
        try {
            const result = yield call(({ idLeague }) => {
                return request.get(`${config.apiEndpoint}/api/v4/messages/sent`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {
                yield put({
                    type: 'MESSAGES/SENT/SET',
                    messages: result.messages
                });
            } else {
                yield put({
                    type: 'MESSAGES/SENT/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'MESSAGES/SENT/FETCH_FAILED',
                e
            });
        }
    });
}