import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class ScoutSelection extends React.Component {
    state = {
        family: []
    }

    componentWillMount() {
        const { family } = this.props;        
        // Clear
        family.forEach(u => u.selected = false);
        this.setState({ family: [...family] });
    }    
    selectUser = (idUser) => {
        const { family } = this.state;
        family.forEach(f => f.selected = f.IdUser === idUser);
        this.setState({ family });
    }
    onOk = () => {
        const { family } = this.state;
        let selectedUser = _.find(family, f => f.selected);
        if (selectedUser) {
            this.props.onOk && this.props.onOk({ selectedUser, mode: 1 });
        }
    }
    render() {
        const { scoutRegistration, cart, season } = this.props, { family } = this.state;
        let alreadyRegistered = season.IdSeasonType !== 4 ? [
            ..._.filter(family, user => (_.find(scoutRegistration, fr => fr.IdUser === user.IdUser && fr.IdSeason === season.IdSeason)))
        ] : [], inCart = [
            ..._.filter(family, f => _.find(cart, c => c.selectedUser.IdUser === f.IdUser && !c.selectedRole && c.selectedSeason.IdSeason === season.IdSeason))
        ], available = _.difference(family, [...alreadyRegistered, ...inCart]);

        return <div className=" p-4 h-100  montserrat">

            <div className="pointer tile card shadow-box p-4 d-flex flex-column justify-content-center m-auto bg-blue-ish" style={{ height: 160 }}>
                <i className="icon-icon_explorer white font-40 align-self-center" />
                <span className="white font-20 toBold align-self-center">SCOUT</span>
                <span className="font-10 white align-self-center">Registration</span>
            </div>

            <Animated animationIn="fadeInRight" className="d-flex flex-column">

                {/* AVAILABLE */}
                {available.length > 0 && <section className="d-flex flex-column">
                    <span className="black mt-4 align-self-center">Available</span>
                    {available.map((user, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectUser(user.IdUser)}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <div className="d-flex flex-column align-self-center">
                                <span className={`${user.selected ? 'white' : 'black'} font-12 line1`}>{user.NameFirst} {user.NameLast}</span>
                                {_.find(scoutRegistration, fr => fr.IdUser === user.IdUser && fr.IdSeason === season.IdSeason) && <span className={`${user.selected ? 'white' : 'green'} font-8 line1`}>Already Registered</span>}
                            </div>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}
                </section>}

                {/* IN CART */}
                {inCart.length > 0 && <section className="d-flex flex-column">
                    <span className="black mt-4 align-self-center">In Cart</span>
                    {inCart.map((user, i) => (
                        <div key={i} className="w-100 tile card shadow-box d-flex flex-row align-self-center mt-2" style={{ height: 60, marginRight: 0 }}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <div className="d-flex flex-column align-self-center">
                                <span className=" black font-12 line1">{user.NameFirst} {user.NameLast}</span>
                                <span className="green font-8 line1">In Cart</span>
                            </div>
                        </div>))}
                </section>}

                {/* REGISTERED */}
                {alreadyRegistered.length > 0 && <section className="d-flex flex-column">
                    <span className="black mt-4 align-self-center">Already Registered</span>
                    {alreadyRegistered.map((user, i) => (
                        <div key={i} className="w-100 tile card shadow-box d-flex flex-row align-self-center mt-2" style={{ height: 60, marginRight: 0 }}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <div className="d-flex flex-column align-self-center">
                                <span className=" black font-12 line1">{user.NameFirst} {user.NameLast}</span>
                                <span className="green font-8 line1">Already Registered</span>
                            </div>
                        </div>))}
                </section>}


                <button onClick={this.onOk} className={`btn btn-${_.find(family, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />

            </Animated>
        </div>
    }
}

export default ScoutSelection;
