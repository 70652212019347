// Merged to version 4 services 100%
import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';

// Get the games of a division (Ideally switch the parameters to call other services)
export function* getGamesSaga() {
    yield* takeEvery("GAMES/FETCH", function* (action) {

        try {
            const result = yield call(({ idDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v4/games/division/${idDivision}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => JSON.parse(data.text));
            }, action);

            if (result && result.success) {
                yield put({ type: 'GAMES/SET', games: result.games });
            } else {
                yield put({ type: 'GAMES/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'GAMES/FETCH_FAILED', e });
        }
    });
}

// Update the score of a game
export function* updateScoreSaga() {
    yield* takeEvery("GAMES/SCORE/UPDATE", function* (action) {
        try {
            yield call(({ game: { IdGame, home, away, IdLeague }, notify }) => {

                return request.post(`${config.apiEndpoint}/api/v4/games/score/${IdGame}/${IdLeague}`)
                    .send({ homeScore: home.Score, awayScore: away.Score })
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .then((data) => {

                        if (notify) {                            

                        }

                        return (JSON.parse(data.text))
                    });
            }, action);
        } catch (e) {
            yield put({ type: 'GAMES/FETCH_FAILED', e });
        }
    });
}

//  GAMES/FETCH/DETAILS
export function* getGameDetailsSaga() {
    yield* takeEvery("GAMES/FETCH/DETAILS", function* (action) {

        try {
            const result = yield call(({ idGame, idSeason }) => {
                return request.post(`${config.apiEndpoint}/api/v4/games/${idSeason}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({ searchTerm: idGame })
                    .then((data) => JSON.parse(data.text));
            }, action);

            if (result && result.success) {
                yield put({ type: 'GAME/SET', game: _.first(result.games) });
            } else {
                yield put({ type: 'GAME/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'GAME/FETCH_FAILED', e });
        }
    });
}