import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';

export function* getTryoutsSaga() {
  yield* takeEvery("TRYOUTS/FETCH", function* (action) {

    try {
      const result = yield call(({ idDivision }) => {
        return request.get(`${config.apiEndpoint}/api/v4/tryouts/${idDivision}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { tryouts, accepted } = result;
        yield put({
          type: 'TRYOUTS/SET',
          tryouts
        });
        yield put({
          type: 'ACCEPTED/SET',
          accepted
        });
      } else {
        yield put({
          type: 'TRYOUTS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TRYOUTS/FETCH_FAILED',
        e
      });
    }
  });
}

//TRYOUTS/ACCEPT
export function* acceptTryoutSaga() {
  yield* takeEvery("TRYOUTS/ACCEPT", function* (action) {

    try {
      yield call(({ item }) => {
        return request.post(`${config.apiEndpoint}/api/v4/tryouts/accept/${item.IdOrderItem}/${item.IdTeamUserRole}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'WAITLIST/ACCEPT', item: _.extend(action.item, { RoleStatus: 'Pending' }) });
    } catch (e) {
      yield put({
        type: 'TRYOUTS/ACCEPT_FAILED',
        e
      });
    }
  });
}

//TRYOUTS/RETURN
export function* returnToTryoutSaga() {
  yield* takeEvery("TRYOUTS/RETURN", function* (action) {
    try {
      yield call(({ item, idDivision }) => {
        return request.del(`${config.apiEndpoint}/api/v4/tryouts/remove/${item.IdOrderItem}/${item.IdTeamUserRole}/${idDivision}`)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
    } catch (e) {
      yield put({
        type: 'TRYOUTS/RETURN_FAILED',
        e
      });
    }
  });
}
