import React from 'react';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';
import moment from 'moment';

class Network extends React.Component {
    state = {
        searchTerm: null,
        searched: false,
        searching: false,
        users: null
    }
    componentWillMount() {
        const { game, guests } = this.props;
        let this_game_guests = _.chain(guests).filter(guest => _.find(guest.games, g => g.IdGame === parseInt(game.IdGame, 10))).value();
        this.setState({ roster: [...this.props.roster], roles: [], guests: this_game_guests });
    }

    search = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { team, season, division, roster, game } = this.props, { guests } = this.state;

        this.setState({
            searching: true,
            selectedPlayer: null
        }, () => {
            if (team) {
                request.post(`${config.apiEndpoint}/api/v4/spotlight`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send({
                        args: {
                            idSeason: team.IdSeason,
                            keyword: this.txtSearch.value
                        }
                    })
                    .then(({ body: { users } }) => {
                        // TODO: filter users based on rules of the division

                        // RULES: check the division rules for gender.
                        // Female divisions only accept females
                        // Male and COED divisions accept both genders
                        let the_users = [..._.chain(users).map(u => _.pick(u, 'IdUser', 'NameFirst', 'NameLast', 'IdGender', 'UserImageUrl', 'DateBirth')).uniqBy(u => u.IdUser).value()];

                        if (season && season.IdGender === 2) {
                            the_users = _.filter(the_users, u => u.IdGender === 2)
                        }

                        the_users.forEach(user => {
                            let rostered = null, color = null;
                            if (division && (division.DateBirthMax || division.DateBirthMin)) {
                                if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                                    .isAfter(moment(user.DateBirth, 'MM/DD/YYYY'))) {
                                    rostered = 'Too OLD';
                                    color = 'red';
                                } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                                    .isBefore(moment(user.DateBirth, 'MM/DD/YYYY'))) {
                                    rostered = 'Too YOUNG';
                                    color = 'red';
                                }
                            }
                            if (_.find(roster, u => u.IdUser === user.IdUser && u.IdRole === 3)) rostered = 'Already rostered';
                            if (_.find(guests, u => u.IdUser === user.IdUser && u.games.indexOf(parseInt(game.IdGame, 10)) !== -1)) rostered = 'Already rostered';

                            user.rostered = rostered;
                            user.color = color;
                        });

                        this.setState({ searching: false, users: the_users });
                    }, reject => {
                        this.setState({ searching: false, users: null });
                    });
            } else {
                this.setState({ bootstrapped: true, users: null })
            }
        });
    }

    onChange = () => this.setState({ searchTerm: this.txtSearch.value });

    select = (id) => {
        const { users } = this.state, { division } = this.props;
        let user = _.find(users, r => r.IdUser === id);

        if (division && (division.DateBirthMax || division.DateBirthMin)) {
            let user_dob = user.DateBirth.split('T')[0],
                mask = user_dob.indexOf('/') !== -1 ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
            if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                .isAfter(moment(user_dob, mask))) {
                alert(`This person is too OLD to be rostered on this division`);
                return;
            } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                .isBefore(user_dob, mask)) {
                alert(`This person is too YOUNG to be rostered on this division`);
                return;
            }
        }
        if (user.IsPlayerInThisSeason) {
            alert(`${user.NameFirst} already belongs to a roster on this season`);
            return;
        }

        if (user) user.selected = !user.selected;
        this.setState({ users });
    }

    doSave = () => {
        const { onSave } = this.props, { users } = this.state;
        onSave && onSave(_.filter(users, n => n.selected));
    }

    toggle = (user) => {
        const { users, guests } = this.state, { limits, roster, division } = this.props;

        let available_spots = (limits.Roster_Max || 0) - roster.length - guests.length;

        if (user.rostered) {
            alert(user.rostered);
            return;
        }

        if (user.selected) {
            // JUST UNSELECT
            _.find(users, u => u.IdUser === user.IdUser).selected = false;
        } else {

            // If the division has roster limits
            if (limits && limits.Roster_Max && _.filter(users, u => u.selected).length >= available_spots) {
                alert(`Roster can't have more than ${limits.Roster_Max} players`);
                return;
            }
            //

            /*
            RULE: 
            - If the division has a Range Limit, we have to check for the Age of the person in order to 'add it'
             */
            if (division && (division.DateBirthMin || division.DateBirthMinMax)) {
                let user_dob = user.DateBirth.split('T')[0],
                    mask = user_dob.indexOf('/') !== -1 ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
                if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                    .isAfter(moment(user_dob, mask))) {
                    alert(`This person is too OLD to be rostered on this division`);
                    return;
                } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                    .isBefore(user_dob, mask)) {
                    alert(`This person is too YOUNG to be rostered on this division`);
                    return;
                }
            }

            _.find(users, u => u.IdUser === user.IdUser).selected = true;
        }

        this.setState({ users });
    }

    renderNormal = (user, i) => {
        return (<figure onClick={() => user.rostered ? null : this.toggle(user, !user.selected)} key={i} className={`w-50 p${i % 2 ? 'l' : 'r'}-1 mx-0 mt-2 mb-0 pointer`}>
            <div className={`w-100 card tile shadow-box d-flex flex-row ${user.selected ? 'btn-success' : ''}`}>
                <div style={{ width: 60 }} className="d-flex flex-row justify-content-center align-self-center bg-black">
                    <div className="cover" style={{ width: 60, height: 80, background: `url(${user.Image || user.UserImage || user.UserImageUrl}?full=80) no-repeat center center` }} />
                </div>
                <div className="d-flex flex-column pl-2 pr-2 justify-content-center montserrat">
                    <span className="font-12 line1">{user.NameFirst} {user.NameLast}</span>
                    <span className="font-10 line1">{moment().diff(moment(user.DateBirth.split('T')[0], user.DateBirth.split('T')[0].indexOf('-') !== -1 ? 'YYYY-MM-DD' : 'MM/DD/YYYY'), 'years')} Years Old</span>
                    {user.rostered && <span className={`${user.color} toBold font-10 line1`}>{user.rostered}</span>}
                </div>
            </div>
        </figure>)
    }

    render() {
        const { users, searching, microTransact, rolling, asking } = this.state;

        return <section>
            {!asking && <section className="">
                {!rolling && <section>

                    <form className="pr-4 pl-4">
                        <div className="input-group shadow-box">
                            <input type="text" className="form-control" placeholder="Search for players" ref={(i) => this.txtSearch = i} />
                            {!searching && <button type="submit" className="btn btn-success" onClick={this.search}>Search</button>}
                            {searching && <button type="button" className="btn btn-success">Searching</button>}
                        </div>
                    </form>

                    <section className="d-flex flex-wrap mt-2 mx-3">
                        {users && users.map((user, i) => this.renderNormal(user, i))}
                        <div style={{ height: 250 }} />
                    </section>

                    {users && !users.length && !searching && <div style={{ position: 'fixed', top: 200, bottom: 0 }} className="h-100  d-flex flex-column justify-content-start">
                        <img alt="" src="/images/defaults/red_card.png" style={{ height: 170 }} className="align-self-center mt-4" />
                        <span className="mt-2 pl-4 pr-4 montserrat black text-center font-10 line1">Sorry, no users were found{this.txtSearch ? ` under an email address like '${this.txtSearch.value}'` : ''}</span>
                    </div>}

                    {/* SAVE PLAYER */}
                    {_.filter(users, u => u.selected).length > 0 && (
                        <div style={{ position: 'fixed', bottom: 0, height: 100 }} className="ease bg-black d-flex flex-row justify-content-center px-4 w-100">
                            <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.doSave}>
                                {!microTransact && `Save`}
                                {microTransact && <i className="fa fa-spin fa-cog" />}</button>
                        </div>)}

                </section>}

            </section>}

        </section>
    }
}

export default Network;
