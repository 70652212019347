import React from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { formatPrice, } from '../../../helpers';
import moment from 'moment';
import request from 'superagent';
import config from '../../../config';

class Manage extends React.Component {

    state = {
        target: null,
        mode: 1
    }

    // Lifecycle
    componentWillMount() {
        const { target, selectedTeam } = this.props;
        this.setState({ target, team: selectedTeam });        
    }
    //

    cancel = () => {
        const { target } = this.state;        
        request.del(`${config.apiEndpoint}/api/v4/video/subscription/${target.IdPlan}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then((data) => {
                target.Canceled = true;
                this.setState({ target });
            });
    }

    onMode = (mode) => this.setState({ mode });

    render() {
        const { isOpen, toggle, } = this.props,
            { target, mode, } = this.state;

        return (
            <Modal isOpen={isOpen} style={{ width: 400 }} toggle={toggle}>
                <ModalBody className="container-fluid no-margin no-padding" style={{ height: 800, background: `url(/images/backgrounds/grass.png) no-repeat center center`, backgroundSize: 'cover', border: '1px solid white', borderRadius: 5 }}>
                    <div className="h-100 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>
                        <div className="h-auto w-100 align-self-center">
                            <i className="fa icon-logo-header bigest-font white block text-center"></i>
                            <div style={{ height: 150, position: 'relative' }} className="w-100">
                                <div style={{ backgroundColor: '#FFFFFF', opacity: 0.2, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
                                <div className="h-100 w-100 d-flex flex-column justify-content-center">
                                    <span className="white toUpper font-16 align-self-center">{target.Name}</span>
                                    <span className="white font-16 align-self-center">{formatPrice(target.Amount * 100)} per month</span>
                                    <span className="white font-10 align-self-center">Auto-renewed every {target.Duration} days</span>
                                </div>
                            </div>
                            <ul className="white text-center mt-4">
                                <li><i className="icon-database mr-2" />{target.StorageLabel} Storage space</li>
                                <li><i className="icon-film-reel-1 mr-2" />Unlimited clips</li>
                                <li><i className="icon-video-player-1 mr-2" />Digital Team brochure for recruiting</li>
                            </ul>
                            <div style={{ height: 40 }} />

                            {!target.Canceled && mode === 1 && <div><div onClick={() => this.onMode(2)} style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 3, width: 250 }} className="m-auto p-2 text-center pointer" ><span className="white">CANCEL SUBSCRIPTION</span></div>
                                <span className="block font-10 mt-2 text-center white">Next billing on {moment(target.StartDate.split('T')[0], 'YYYY-MM-DD').add(target.Duration, 'days').format('MMM Do YYYY')}</span>
                            </div>}
                            {!target.Canceled && mode === 2 && <div>
                                <div onClick={this.cancel} style={{ border: '3px solid #dc3545', borderRadius: 3, width: 250 }} className="btn-danger m-auto p-2 text-center pointer" ><span className="white">CONFIRM CANCELATION</span></div>
                                <div onClick={() => this.onMode(1)} style={{ borderRadius: 3, width: 250 }} className="m-auto p-2 text-center pointer" ><span className="white">Nevermind</span></div>
                            </div>}
                            {target.Canceled && <div>
                                <span className="block font-14 mt-2 text-center white">Subscription cancelled.<br />It will be active until {moment(target.StartDate.split('T')[0], 'YYYY-MM-DD').add(target.Duration, 'days').format('MMM Do YYYY')}</span>
                            </div>}
                            <span className="mt-4 block font-10 text-center white">Terms of Service and Privacy Policy</span>
                        </div>
                        <i className="fa fa-times font-20 white" style={{ position: 'absolute', top: 10, right: 10 }} onClick={toggle} />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default Manage;
