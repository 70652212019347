import React from 'react';
import { DropTarget } from 'react-dnd';
import request from 'superagent';
import config from '../../../../config';

const homeTarget = {
    canDrop(props, monitor) {

        // Validate that is not trying to drop the looser of the same game        
        if (monitor) {

            if (monitor.getItem().game && monitor.getItem().game.IdGame === props.game.IdGame) {
                return false;
            }
        }

        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        // Drop Team
        if (monitor.getItem().team) {
            component.setState && component.setState({ team: { ...component.state.team, ...monitor.getItem().team } }, () => {
                props.fnAddHomeTeam && props.fnAddHomeTeam(component.state.team);
            });
            // Drop Custom Group Placement
        } else if (monitor.getItem().group) {

            const { team } = component.state;
            if (team.comesFrom.Id) { alert('Invalid operation. Placements for this team cannot be changed'); return; }
            team.placements = {
                Id: (team.placements || {}).Id,
                GameBracketPlacementName: `# ${monitor.getItem().index} in ${monitor.getItem().group.CustomGroupName || monitor.getItem().group.Name}`,
                Index: 0,
                PlacementSequence: monitor.getItem().index,
                IdGame: props.game.IdGame,
                IdDivision: props.idDivision,
                IdPlacementType: 4,
                IdCustomGroup: monitor.getItem().group.IdCustomGroup
            }
            component.setState && component.setState({ team }, () => {
                // call property to update the placement in the server
                request.patch(`${config.apiEndpoint}/api/v4/games/placement/${props.game.IdGame}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(team.placements)
                    .then(({ Id }) => {
                        team.placements.Id = Id;
                        team.placements.IdGameBracketPlacement = Id;
                        component.setState({ team }, () => { props.fnNotify && props.fnNotify(team, 0) });
                    });
            });
            // Drop Division Placement
        } else if (monitor.getItem().division) {
            const { team } = component.state;
            if (team.comesFrom.Id) { alert('Invalid operation. Placements for this team cannot be changed'); return; }
            team.placements = {
                Id: (team.placements || {}).Id,
                IdPlacementType: monitor.getItem().isUsingNextBest ? 3 : 2,
                GameBracketPlacementName: monitor.getItem().isUsingNextBest ? `# ${monitor.getItem().index} Next-Best` : `# ${monitor.getItem().index} in ${monitor.getItem().division.Name}`,
                Index: 0,
                PlacementSequence: monitor.getItem().index,
                IdGame: props.game.IdGame,
                IdDivision: props.idDivision
            }
            component.setState && component.setState({ team }, () => {
                // call property to update the placement in the server
                request.patch(`${config.apiEndpoint}/api/v4/games/placement/${props.game.IdGame}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(team.placements)
                    .then(({ Id }) => {
                        team.placements.Id = Id;
                        team.placements.IdGameBracketPlacement = Id;
                        component.setState({ team }, () => { props.fnNotify && props.fnNotify(team, 0) });
                    });
            });
            // Drop Flight Placement
        } else if (monitor.getItem().flight) {
            const { team } = component.state;
            if (team.comesFrom.Id) { alert('Invalid operation. Placements for this team cannot be changed'); return; }

            team.placements = {                
                GameBracketPlacementName: `# ${monitor.getItem().index} in ${monitor.getItem().flight.FlightName}`,
                Index: 0,
                IdDivisionFlight: monitor.getItem().flight.IdDivisionFlight,
                PlacementSequence: monitor.getItem().index,
                IdGame: props.game.IdGame,
                IdDivision: props.idDivision,
                IdPlacementType: monitor.getItem().isUsingNextBest ? 1 : 1
            }
            component.setState && component.setState({ team }, () => {
                request.patch(`${config.apiEndpoint}/api/v4/games/placement/${props.game.IdGame}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(team.placements)
                    .then(({ Id }) => {
                        team.placements.Id = Id;
                        team.placements.IdGameBracketPlacement = Id;
                        component.setState({ team }, () => { props.fnNotify && props.fnNotify(team, 0) });
                    });
            });
        } else if (monitor.getItem().game) {
            const { team } = component.state;
            if (team.comesFrom.Id) { alert('Invalid operation. Placements for this team cannot be changed'); return; }
            team.placements = {
                GameBracketPlacementName: `Loser of game ${monitor.getItem().game.IdGame}`,
                Index: 0,
                IdGameBracketFrom: monitor.getItem().game.IdGame,
                IdGame: props.game.IdGame,
                IdDivision: props.idDivision,
                IdPlacementType: 5
            }
            component.setState && component.setState({ team }, () => {
                request.patch(`${config.apiEndpoint}/api/v4/games/placement/${props.game.IdGame}`)
                    .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                    .send(team.placements)
                    .then(({ Id }) => {
                        team.placements.Id = Id;
                        component.setState({ team }, () => { props.fnNotify && props.fnNotify(team, 0) });
                    });
            });
        } else {
            alert('Invalid Operation');
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

class HomeTeamDropSection extends React.Component {
    state = { team: null }

    componentWillMount() {
        const { team } = this.props;
        this.setState({ team });
    }
    componentWillReceiveProps = nextProps => {
        const { team } = nextProps;
        this.setState({ team });
    }
    removePlacement = () => {
        const { team } = this.state;
        if (team.placements.IdGameBracketPlacement) {
            request.del(`${config.apiEndpoint}/api/v4/games/placement/${team.placements.IdGameBracketPlacement}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .then(() => { this.props.fnNotify && this.props.fnNotify(team, 0) });
        }
        team.placements = null;
        this.setState({ team });
    }
    removeTeam = () => {
        const { team } = this.state;
        request.del(`${config.apiEndpoint}/api/v4/games/team/${team.IdGame}/${team.IdTeamDivision}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(() => { this.props.fnNotify && this.props.fnNotify(team, 1) });
        team.TeamName = null;
        team.IdTeam = null;
        // TODO: call API
        this.setState({ team });
    }
    render() {
        const { connectDropTarget, isOver, canDrop } = this.props, { team } = this.state;
        return connectDropTarget(
            <div className={`team-droppable-area d-flex flex-row justify-content-center ${(isOver && canDrop) ? 'bg-success white' : ((isOver && !canDrop) ? 'bg-danger white' : (canDrop ? 'debug gray' : 'gray'))}`}>
                {(!isOver || (isOver && canDrop)) && (<i className="fa fa-shield align-self-center " />)}
                {(!isOver || (isOver && canDrop)) && (
                    <div className="d-flex idented-half flex-column justify-content-center">
                        <span className="font-10 black">{team.TeamName || '[TEAM]'} {team.TeamName && <i className="fa fa-times red" onClick={this.removeTeam} />}</span>
                        <span className={`font-8 ${(team.comesFrom.Id || (team.placements && team.placements.GameBracketPlacementName)) ? '' : 'red'}`}>{team.comesFrom.Id ? 'Winner of ' + team.comesFrom.Id : (
                            team.placements ? team.placements.GameBracketPlacementName : '[Placement]'
                        )}{team.placements && !team.comesFrom.Id && <i className="fa fa-times red idented-half" onClick={this.removePlacement} />}</span>
                    </div>)}
                {(isOver && !canDrop) && (
                    <div className="d-flex idented-half flex-column justify-content-center">
                        <span className="font-10 black">Incompatible</span>
                        <span className="font-8">Can't advance to the same game</span>
                    </div>
                )}
            </div>)
    }
}

export default DropTarget(props => props.accept, homeTarget, collect)(HomeTeamDropSection);
