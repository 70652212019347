import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import config from '../../../config';
import LineLoader from '../../common/LineLoader';
import VideoClipPreview from './VideoClipPreview';
import { generateRandomId } from '../../../helpers';

class ClipModal extends React.Component {

    state = {
        bootstrapped: false,
        coupon: null,
        mode: 1
    }

    // Lifecycle
    componentWillMount() {
        const { video, length, middle } = this.props;
        this.setState({ video, length, middle });
    }
    componentWillReceiveProps = nextProps => {

    }
    // 

    save = ({ start, duration, description }) => {
        const { toggle, video } = this.props;
        const fileName = `${generateRandomId(20)}.mp4`;
        axios.post(`${config.wowzaEndpoint}/api/v4/video/clip/${video.idVideo}`, {
            start, duration, name: fileName, description 
        }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('sportslogic.authtoken')}` },
        }).then(({ data }) => {
            this.setState({ quick: false, side: false, clipTitle: null, clipLength: null });
        });
        this.props.onClipped && this.props.onClipped();
        toggle && toggle();
    }

    render() {
        const { isOpen, toggle } = this.props,
            { microTransac, video, middle, length } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 600 }}>
                <ModalHeader toggle={toggle}>
                    Create a Clip
                </ModalHeader>
                <ModalBody className="container-fluid no-margin no-padding">
                    {microTransac && <LineLoader />}
                    <div style={{ height: 20 }} />
                    <VideoClipPreview onSaveClip={this.save} video={video} height="300" middle={middle} length={length} onUpdate={this.update} />
                </ModalBody>                
            </Modal >
        )
    }
}

export default ClipModal;
