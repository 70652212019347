import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import moment from 'moment';
import '../externals/signature-pad.css';
import fakeAuth from '../../fakeAuth';
import request from 'superagent';
import config from '../../config';
import { Jumbotron } from 'reactstrap';
import CreateUser from '../common/modals/CreateUser';


const SignaturePad = require('react-signature-pad');

class Waivers extends React.Component {

    state = {
        mode: 1, overlay: false,
        gente_mayor: _.filter(fakeAuth.getFamily(), f => f.IsAdult)
    }

    componentWillMount() {
        const { setTitle, params } = this.props;
        setTitle && setTitle({ main: '' });
        this.props.familyActions && this.props.familyActions.clearSelectedUser();
        this.props.familyActions && this.props.familyActions.fetchWaivers();
        this.setState({ mode: (params && params.mode) ? 2 : 1 })
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ gente_mayor: _.filter(fakeAuth.getFamily(), f => f.IsAdult) });
    }

    renderWrapper = () => {
        const { mode, waiver, signatureError, signatureDataURL, signed, gente_mayor, signer } = this.state, { waivers } = this.props;

        return <section id="waiver">
            {waiver && <div className="d-flex flex-column">

                <h2 className="m-auto text-center">{waiver.LeagueName}</h2>
                <hr />
                <h4 className="text-left blue">Participant (player) Information</h4>
                <div className="d-flex flex-row">
                    <span>Full Name</span>
                    <span className="ml-auto">{`${waiver.NameFirst} ${waiver.NameLast}`}</span>
                </div>
                <div className="d-flex flex-row">
                    <span>DOB</span>
                    <span className="ml-auto">{moment(waiver.DateBirth).utc().format('MMM Do YYYY')} </span>
                </div>
                <div className="d-flex flex-row">
                    <span>Gender</span>
                    <span className="ml-auto">{waiver.Gender}</span>
                </div>
                <div className="d-flex flex-row">
                    <span>Emergency Contact Number</span>
                    <span className="ml-auto">{waiver.PhoneNumber}</span>
                </div>
                <hr />
                <h4 className="blue">Terms and Conditions of Participation </h4>
                {waiver.IsPending && <h6 className="red">Read before Signing</h6>}
                <hr />
                <p>
                    {waiver.WaiverText}
                </p>
                {waiver && !waiver.IsPending && <div className="m-auto">
                    {waiver.SignatureImage.indexOf('base64') !== -1 && <img src={waiver.SignatureImage} />}
                    {waiver.SignatureImage.indexOf('base64') === -1 && <div dangerouslySetInnerHTML={{ __html: waiver.SignatureImage }} />}
                    <div className="text-center">Signed by{' '}{waiver.SignedName}
                    </div>
                    <div className="text-center">on{' '}{moment(waiver.DateModified).format('MMM Do YYYY')}
                    </div>
                </div>}
                {waiver && waiver.IsPending && <div>
                    <span className="font-18 toBold black mt-4">Who's signing?</span>

                    {/* WHOS SIGNING */}
                    {gente_mayor && gente_mayor.length > 0 && <section className="d-flex flex-wrap">
                        {gente_mayor.map((u, i) => <div onClick={() => this.setState({ signer: u })} key={i} style={{ width: 100, height: 120 }} className={`d-flex flex-column justify-content-center tile card p-4 ${this.state.signer && this.state.signer.IdUser === u.IdUser ? 'bg-green white' : 'black'}`}>
                            <img className="smallPic align-self-center" src={u.UserImage} loading='lazy' />
                            <span className="font-10 line1 text-center mx-2 mt-2 montserrat align-self-center">{`${u.NameFirst} ${u.NameLast}`}</span>
                        </div>)}
                    </section>}
                    {gente_mayor && gente_mayor.length === 0 && <Jumbotron>
                        <h3>There are no adults on this account to sign the waiver</h3>
                        <button onClick={() => this.setState({ modal: true, pending_waiver: waiver })} className="px-4 btn btn-success mt-2" style={{ borderRadius: 20 }}>Add one</button>
                    </Jumbotron>}

                    {/* SIGNATURE */}
                    {this.state.signer && <section className="mt-4">
                        {!signatureError && <span className="font-18 toBold black">Please sign here {this.state.signer.NameFirst}</span>}
                        {signatureError && <div className="bg-warning p-2 big-font text-center">Please Sign!</div>}
                        <div className="m-auto w-100 card tile shadow-box bg-white" style={{ position: 'relative' }}>
                            <SignaturePad onEnd={this.onSignatureEnd} ref="mySignature" className='w-100' />
                            <button className="btn btn-link float-right" onClick={this.onClearSignature} style={{ position: 'absolute', top: 0, right: 0 }}>Clear</button>
                        </div>
                        {!signatureDataURL && <button className={`mb-4 btn btn-lg btn-block btn-${signed ? 'success' : 'default bg-gray white'} bigest-font`} onClick={this.onSave}>
                            {!this.state.microTransac && <span>Save</span>}
                            {this.state.microTransac && <i className="fas fa-cog fa-spin white" />}
                        </button>}
                    </section>}
                </div>}
                <div className="p-4" />
            </div>}
        </section>
    }

    renderWaiver = (waiver, i) => {
        return <figure key={i} className="tile card p-4 shadow d-flex flex-row" onClick={() => this.setState({ waiver, overlay: true })}>
            <div className="d-flex flex-column">
                <span className="font-18 toBold">{waiver.LeagueName}</span>
                <span className="font-16 toBold">{`For ${waiver.NameFirst} ${waiver.NameLast}`}</span>
                {!waiver.IsPending && <span className="font-12">Signed by {waiver.SignedName}</span>}
                {!waiver.IsPending && <span className="font-12">{moment(waiver.DateModified).format('MMM Do YYYY')}</span>}
            </div>
            {!waiver.IsPending && <div className="ml-auto align-self-center">
                {waiver.SignatureImage.indexOf('base64') !== -1 && <img style={{ height: 120 }} src={waiver.SignatureImage} />}
                {waiver.SignatureImage.indexOf('base64') === -1 && <div dangerouslySetInnerHTML={{ __html: waiver.SignatureImage }} />}
            </div>}
            {waiver.IsPending && <div className="ml-auto align-self-center">
                <button className="btn btn-danger">SIGNATURE PENDING</button>
            </div>}
        </figure>
    }

    onSave = () => {
        this.setState({ microTransac: true }, () => {

            const signature = this.refs.mySignature;

            // CALL API
            const { waiver } = this.state;
            request.post(`${config.apiEndpoint}/api/v4/waivers/${waiver.IdLeague}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    idUserFor: waiver.IdUser,
                    SignatureImage: signature.toDataURL(),
                    idUser: this.state.signer.IdUser
                })
                .then((data) => {
                    if (data.body.success) {
                        this.props.familyActions && this.props.familyActions.fetchWaivers();
                        this.setState({ signer: null, waiver: null, overlay: false });
                    } else {

                    }
                }, ({ response: { body } }) => {

                });
        });
    }

    // Signature pad handle
    onSignatureEnd = () => this.setState({ signed: true });
    onClearSignature = () => {
        const signature = this.refs.mySignature;
        signature.clear();
        this.setState({ signatureDataURL: null, signed: false });
    }
    //


    render() {
        const { mode, waiver, signatureError, signatureDataURL, signed, gente_mayor, signer } = this.state, { waivers } = this.props;

        return (
            <div className="montserrat h-100 w-100">

                {/* LIST OF WAIVERS */}
                <Animated animationInDuration={300} animationOutDuration={300} isVisible={waivers ? true : false} animateOnMount={false} animationIn="fadeInLeft" animationOut="fadeOutLeft">
                    <section className="w3-content mx-auto dflex flex-wrap">
                        <div className="p-4" />
                        {waivers && waivers.map((waiver, i) => this.renderWaiver(waiver, i))}
                        <div className="p-4" />
                    </section>
                </Animated>


                {/* SIDE MENU */}
                {this.state.overlay && this.state.waiver && <div onClick={() => this.setState({ overlay: false, waiver: false })} className={`overlay-full ${this.state.overlay ? 'show' : ''}`} />}
                <Animated animationInDuration={200} animationOutDuration={200} animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.waiver ? true : false} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 500, zIndex: 1041, overflowY: 'auto' }} className="bg-white" >
                    {this.renderWrapper()}
                </Animated>

                {/* CREATE USER */}
                {this.state.modal && <CreateUser {...this.props} toggle={() => this.setState({ modal: false })} isOpen={this.state.modal} carriers={[]} fn={() => {
                    const { pending_waiver } = this.state;
                    this.setState({ waiver: pending_waiver, pending_waiver: null });
                }}
                    user={{}} />}

            </div>
        )
    }
}

export default Waivers;
