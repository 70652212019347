import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';
import { generateRandomId } from '../../../../helpers';

class CreateLocation extends React.Component {
    state = {
        location: {
            fields: []
        }
    }
    componentWillMount() {
        request.get(`${config.apiEndpoint}/api/v4/geo/countries`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body }) => {
                let { countries } = body;
                this.setState({ bootstrapped: true, countries }, () => {
                    if (this.props.entity) {
                        this.setState({ location: this.props.entity }, () => {
                            this.onCountry(this.state.location.IdCountry);
                        });                        
                    }
                });
            }, reject => {
                alert('An error has occurred');
                this.setState({ microTransac: false });
            });
    }
    onCountry = id => {        
        request.get(`${config.apiEndpoint}/api/v4/geo/states/${id}`)
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body }) => {
                let { states } = body;
                this.setState({ bootstrapped: true, states });
            }, reject => {
                alert('An error has occurred');
                this.setState({ microTransac: false });
            });
    }
    onSave = () => {

        const { location, states } = this.state;

        if (!this.txtName.value ||
            !this.txtAddress.value ||
            !this.txtCity.value ||
            !location.IdCountry ||
            //(!location.IdState && states.length) ||
            !this.txtZipCode.value
        ) {
            alert('Please complete the missing fields');
            return;
        }

        const { microTransac } = this.state;
        if (microTransac) return;

        // TODO: validate and call API                
        this.setState({ microTransac: true }, () => {

            request[location.IdLocation ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/family/location${location.IdLocation ? `/${location.IdLocation}` : ''}`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .send({
                    idCommunity: 1,
                    idState: location.IdState,
                    address: this.txtAddress.value,
                    city: this.txtCity.value,
                    zipCode: this.txtZipCode.value,
                    state: location.StateCode,
                    name: this.txtName.value,
                    fields: location.fields
                })
                .then(this.props.fnAdded, reject => {
                    alert('An error has occurred');
                    this.setState({ microTransac: false });
                });
        });

    }
    render() {
        const { location, countries, states } = this.state;
        return <section>

            <section className="fullscreen" style={{ zIndex: 10003 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10004, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">

                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">CREATE OPPONENT</span>
                </div>

                {/* NAME */}
                <div className="montserrat font-10 black mt-3 mx-4">Location's Name</div>
                <div className="mx-4">
                    <input type="text" defaultValue={location.LocationName} className={`form-control`} name="name" placeholder="Name" ref={(input) => this.txtName = input} />
                </div>

                {/* ADDRESS */}
                <div className="montserrat font-10 black mt-3 mx-4">Address</div>
                <div className="mx-4">
                    <input type="text" defaultValue={location.Line1} className={`form-control`} name="address" placeholder="Address" ref={(input) => this.txtAddress = input} />
                </div>

                {/* CITY */}
                <div className="montserrat font-10 black mt-3 mx-4">City</div>
                <div className="mx-4">
                    <input type="text" defaultValue={location.City} className={`form-control`} name="city" placeholder="City" ref={(input) => this.txtCity = input} />
                </div>

                {/* COUNTRY */}
                {countries && <section>
                    <div className="montserrat font-10 black mt-3 mx-4">Country</div>
                    <Dropdown isOpen={this.state.pick_country} toggle={() => this.setState({ pick_country: !this.state.pick_country })} className="tile card p-1 mx-4 font-16 bg-white black">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                            <span className="black align-self-center">{location.IdCountry ? _.find(countries, c => parseInt(c.IdCountry, 10) === parseInt(location.IdCountry, 10)).CountryName : 'Select'}</span>
                            <i className="ml-auto fas fa-caret-down align-self-center black" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {countries.map((c) => <DropdownItem key={c.IdCountry} onClick={() => {
                                const { location } = this.state;
                                location.IdCountry = c.IdCountry;
                                location.IdState = null;
                                this.setState({ location, states: null }, () => {
                                    this.onCountry(location.IdCountry);
                                });
                            }}>
                                {c.CountryName}
                            </DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </section>}

                {/* STATE */}
                {states && <section>
                    <div className="montserrat font-10 black mt-3 mx-4">State ({states.length})</div>
                    <Dropdown isOpen={this.state.pick_state} toggle={() => this.setState({ pick_state: !this.state.pick_state })} className="tile card p-1 mx-4 font-16 bg-white black">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                            <span className="black align-self-center">{location.IdState ? _.find(states, c => parseInt(c.IdState, 10) === parseInt(location.IdState, 10)).StateName : 'Select'}</span>
                            <i className="ml-auto fas fa-caret-down align-self-center black" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {states.map((c) => <DropdownItem key={c.IdState} onClick={() => {
                                const { location } = this.state;
                                location.IdState = c.IdState;
                                location.StateCode = c.StateCode;
                                this.setState({ location });
                            }}>
                                {c.StateName}
                            </DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </section>}

                {/* Postal */}
                <div className="montserrat font-10 black mt-3 mx-4">Zip | Postal Code</div>
                <div className="mx-4">
                    <input type="text" defaultValue={location.ZipCode} className={`form-control`} name="zip" placeholder="Zip | Postal Code" ref={(input) => this.txtZipCode = input} />
                </div>

                <hr />

                <h3 className="text-center">Fields</h3>

                <div onClick={() => {
                    const { location } = this.state;
                    location.fields.push({
                        TemporalId: generateRandomId(6)
                    });
                    this.setState({ location });
                }} className="pointer mx-4 card tile mt-2 px-2 py-4 d-flex flex-row justify-content-center">
                    <i className="fas fa-circle-plus black" />
                    <span className="align-self-center ml-2 line1">Create a Field</span>
                </div>
                {location.fields.map((f, i) =>
                    <div className="mx-4 mt-2 d-flex flex-row" key={f.IdLocation || f.TemporalId}>
                        <input type="text" defaultValue={f.LocationName} className={`form-control`} placeholder="Field name" onChange={e => {
                            const { location } = this.state;
                            location.fields[i].LocationName = e.target.value;
                            this.setState({ location });
                        }} />
                        <button className="btn btn-link" onClick={() => {
                            const { location } = this.state;
                            location.fields = [...location.fields.slice(0, i), ...location.fields.slice(i + 1)];
                            this.setState({ location });
                        }}><i className="fas fa-times red" /></button>
                    </div>
                )}

                {/* SAVE BUTTON */}
                <button onClick={this.onSave} className="btn btn-success mt-4 mb-4 mx-4" style={{ borderRadius: 30 }}>SAVE</button>
            </Animated>

        </section>
    }
}

export default CreateLocation;