import React from 'react';
import { Line } from 'react-chartjs-2';
import request from 'superagent';
import config from '../../../config';
import COLORS from '../../../colors';
import _ from 'lodash';
import moment from 'moment';

const options = {
    scales: {
        y: {
            beginAtZero: true
        }
    }
};

class Downloads extends React.Component {
    state = {
        total: 0
    }
    componentWillMount() {
        const { yearFrom, yearTo, from, to, activeTab } = this.props;
        this.setState({ yearFrom, yearTo, from, to, activeTab }, () => {
            this.fetch()
        });
    }
    componentWillReceiveProps = nextProps => {
        const { yearFrom, yearTo, from, to, activeTab } = this.props;
        if (yearFrom !== this.state.yearFrom || 
            yearTo !== this.state.yearTo || 
            activeTab !== this.state.activeTab ||
            from !== this.state.from ||
            to !== this.state.to) {
            this.setState({ yearFrom, yearTo, from, to, activeTab }, () => {
                this.fetch();
            });
        }
    }

    fetch = () => {
        const { yearFrom, yearTo, from, to, activeTab } = this.state;        

        request.post(`${config.apiEndpoint}/api/v5/system/new_accounts/yearly`)
            .send({
                start: (activeTab === '3' && from && to) ? `${from.format('YYYY-MM-DD')}` : `${yearFrom}-01-01`,
                end: (activeTab === '3' && from && to) ? `${to.format('YYYY-MM-DD')}` : `${yearTo}-12-31`,
                include_daily: activeTab === '3' ? 1 : 0
            })
            .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
            .then(({ body: { monthly_totals, daily } }) => {                
                const data = {
                    labels: activeTab === '3' ? _.map(daily, d => moment(d.DateCreation).format('MMM Do YYYY')) : _.map(monthly_totals, m => m.friendly),
                    datasets: [
                        {
                            label: '# Accounts created',
                            data: activeTab === '3' ? _.map(daily, d => d.NumberOfAccounts) : _.map(monthly_totals, m => m.value),
                            fill: true,
                            backgroundColor: COLORS.green,
                            borderColor: COLORS.greenDark,
                        },
                    ],
                };
                this.setState({ data, total: _.chain(monthly_totals).map(m => m.value).reduce((a, b) => a + b, 0).value() });
            });
    }

    render() {
        const { yearFrom, yearTo } = this.state, { data, total } = this.state;
        return <div className="w3-content sheet shadow card p-4">
            <div className="d-flex flex-row">
                <h2>Downloads {yearFrom}{yearFrom !== yearTo ? ` - ${yearTo}` : ''}</h2>                
            </div>
            <div className="d-flex flex-row">
                <div className="w-100">
                    {data && <Line data={data} options={options} />}
                </div>
                <div style={{ width: 100 }} className="text-center">
                    <h1 className="line1 mt-4">{total}</h1>
                    <span className="line1">Total</span>
                </div>
            </div>

        </div>
    }
}

export default Downloads;
