import React from 'react';
import moment from 'moment';
import config from '../../../../config';
import request from 'superagent';
import { UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';

class Event extends React.Component {
    state = {}
    componentWillMount() {
        const { event } = this.props;        
        this.setState({ event })
    }

    download = () => {

        const { downloading } = this.state;
        if (downloading) return;

        this.setState({
            downloading: true
        }, () => {

            // Fetch games with filters applied
            request.get(`${config.apiEndpoint}/api/v4/roster/game_day/download/${this.state.game.IdGame}/roster.pdf`)
                .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
                .responseType('arraybuffer')
                .then((data) => {
                    let file = new Blob([data.body], { type: 'application/pdf' });
                    require("downloadjs")(file, 'GAME_DAY.pdf');
                    this.setState({ downloading: false, });
                });
        });
    }

    render() {
        const { event } = this.state;
        return <div className="card shadow mb-2 p-2 pointer" onClick={this.props.onClick}>
            <div className="d-flex flex-row">
                <i className={`ml-2 align-self-center fas fa-circle font-14 ${event.IdEventType === 4 ? 'yellow' : 'blue'}`} />
                <div className="d-flex flex-column align-self-center ml-4">
                    <span className="blue montserrat-bold font-12">{moment(event.DateTimeStart_Date_Format, 'MM/DD/YYYY').format('dddd, MMM Do YYYY')}</span>
                    <span className="black font-16 montserrat">{event.IdEventType === 4 ? 'Training' : 'Event'}</span>
                    <div><span className="toBold black">{event.IdEventType === 4 ? (_.first(event.recipients) || { Name: '' }).Name : event.EventTypeOther}</span></div>
                    <div><span className="gray"><i className="fas fa-map-marker-alt mr-2" />{event.LocationName}</span></div>
                </div>
                <span className="align-self-center ml-auto font-30 black montserrat">{`${event.DateTimeStart_Time_Format} ${event.TimeZoneAbbreviation}`}</span>

            </div>            
        </div>
    }
}

export default Event;