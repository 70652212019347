import React from 'react';
import Flight from './Flight';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../../../helpers';


const target = {
  canDrop(props) {
    return true;
  },
  drop(props, monitor, component) {
    // Dropped in another Flighted Team (to replace it position)
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class Flights extends React.Component {

  renderFlights = () => {
    const { flights, selectedDivision, divisions } = this.props;
    return (
      <section className="" style={{ position: 'absolute', top: 0, bottom: 30, left: 0, right: 0, overflowX: 'auto', overflowY: 'auto', minHeight: 500 }}>
        {flights.map((flight, index) => (
          <Flight key={flight.IdFlight || index} index={index} flight={flight}
            fnRemoveFlight={this.props.removeFlight}
            flights={flights}
            divisions={divisions}
            selectedDivision={selectedDivision} {...this.props} />))}
      </section>
    )
  }

  render() {
    const { flights, selectedDivision, divisions, connectDropTarget, canDrop } = this.props;
    return connectDropTarget(<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} key="not-fetching" className={`${canDrop ? 'debug' : ''}`}>
      {(flights.length > 0 && selectedDivision) && this.renderFlights()}
      {(flights.length === 0 && !selectedDivision) &&
        <div className="d-flex justify-content-center centered-message" key="noDivisionSelected">
          {divisions && divisions.length && (<h1 className="black align-self-center block text-center">Please select a division<br />at the Top Menu</h1>)}
        </div>}
      {(flights.length === 0 && selectedDivision) &&
        <div className="d-flex justify-content-center centered-message" key="noFlightsDetected">
          {/*<h1 className="black align-self-center block text-center">There are no Flights<br />for {selectedDivision.Name}<br />yet!</h1>*/}
        </div>}
    </div>)
  }
}

export default DropTarget(ItemTypes.FLIGHT, target, collect)(Flights);
