import React from 'react';
import { Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../CustomDatePicker';
import CustomTimeScroller from '../CustomTimeScroller';
import moment from 'moment';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import Team from './Team';
import config from '../../../config';
import request from 'superagent';
import Select from 'react-select';


class GameDetails extends React.Component {

  state = {
    isOpenStartDatepicker: false,
    popoverStartTimeOpen: false,
    flight: null,
    deleting: false,
    activeTab: 1,
    microTransac: false,
    division: null,
    divisions: [],
    game: null
  }

  // Lifecycle
  componentWillMount() {
    this.setState({
      division: this.props.division,
      flight: this.props.flight,
      divisions: this.props.divisions,
      game: { ...this.props.game }
    });
  }
  //

  validateForm = () => {
    const { game } = this.state;
    let valid = true;

    // Game Duration
    if (!this.txtMinutes.value) {
      this.setState({ dirty: 'Please select the duration in minutes' });
      return false;
    }

    // Location
    if (!game.Location || !game.Location.IdLocation) {
      this.setState({ dirty: 'Please select a location' });
      return false;
    }

    // Home Team
    if (!game.HomeTeam.IdTeamDivision) {
      this.setState({ dirty: 'Please select the Home Team' });
      return false;
    }

    // Away Team
    if (!game.AwayTeam.IdTeamDivision) {
      this.setState({ dirty: 'Plese select the Away Team' });
      return false;
    }

    if (game.HomeTeam.IdTeamDivision && game.AwayTeam.IdTeamDivision &&
      game.HomeTeam.IdTeamDivision === game.AwayTeam.IdTeamDivision) {
      this.setState({ dirty: 'Home and Away teams cannot be the same' });
      return false;
    }

    this.setState({ dirty: null });
    return valid;
  }

  // CRUD
  delete = () => {
    const { fnDelete } = this.props, { game } = this.state;

    // Smart Call aPI    
    request.del(`${config.apiEndpoint}/api/v4/games/game/${game.IdGame || game.Id}`)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(() => { });

    // Delete the redux record and notify
    this.props.gamesActions && this.props.gamesActions.deleteGame(game.Id || game.IdGame);
    fnDelete && fnDelete(game.Id || game.IdGame);
  }

  save = () => {
    const { fnSave } = this.props, { game, division } = this.state;

    if (!this.validateForm()) {
      return;
    }

    let payload = _.extend({
      ...game
    }, {
      GameDate: game.GameDate.format('YYYY-MM-DD'),
      GameStartHour: game.GameStartHour.format('HHmm'), //game.GameStartHour.format('HHmm'),
      GameDurationMinutes: this.txtMinutes.value,
      IdDivision: division.IdDivision,
      Home_DivisionColor: division.Color,
      Away_DivisionColor: division.Color
    });

    this.setState({ microTransac: true });

    // Smart Call aPI    
    request[payload.Id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/games${payload.Id ? `/${payload.Id}` : ''}`)
      .send(payload)
      .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
      .then(data => {
        if (data.body.success) {
          if (payload.Id) {
            // UPDATE
            this.props.gamesActions && this.props.gamesActions.updateGame(payload);
          } else {
            // CREATE            
            payload.Id = data.body.Id;
            payload.IdGame = data.body.IdGame || '';
            this.props.gamesActions && this.props.gamesActions.addGame(payload);
          }
          fnSave && fnSave(payload);
        } else {
          alert((data.body.error || {}).message);
          this.setState({ microTransac: false });
        }
      }, ({ response: { body: { error } } }) => {
        alert(error.message);
        this.setState({ microTransac: false });
      });
  }

  //

  handleChangeStart = (date) => {
    const { game } = this.state;
    this.setState({ game: { ...game, GameDate: moment(date) } });
    this.toggleCalendarStart();
  }
  handleChangeNotes = (event) => {
    const { game } = this.state;
    this.setState({ game: { ...game, Notes: event.target.value } });
  }

  toggleCalendarStart = (e) => {
    e && e.preventDefault()
    this.setState({
      isOpenStartDatepicker: !this.state.isOpenStartDatepicker
    })
  }
  toggleTimeStart = () => {
    this.setState({
      popoverStartTimeOpen: !this.state.popoverStartTimeOpen
    });
  }

  selectLocation = (e) => {
    const { fields } = this.props, { game } = this.state;
    this.setState({
      game: {
        ...game,
        Location: _.find(fields, f => parseInt(f.IdLocation, 10) === parseInt(e.target.value, 10)),
        LocationName: (_.find(fields, f => parseInt(f.IdLocation, 10) === parseInt(e.target.value, 10)) || {}).Name
      }
    });
  }

  // Selects
  selectDivision = (obj) => {
    const { divisions } = this.props, { game } = this.state;
    let idDivision = obj ? obj.value : 0;
    game.IdDivision = idDivision;

    const division = _.find(divisions, function (d) {
      return d.IdDivision === idDivision;
    });

    this.setState({
      division,
      flight: null, game: { ...game, IdDivision: idDivision }
    });
  }
  selectFlight = (obj) => {
    const { game } = this.state,
      { division } = this.state;
    let idFlight = obj ? obj.value : 0;
    let flight = _.find(division.Flights, function (f) {
      return f.IdFlight === idFlight;
    });

    this.setState({
      flight, game: {
        ...game,
        IdFlight: idFlight,
        FlightName: (flight.FlightName || flight.Name)
      }
    });
  }
  selectHomeTeam = (obj) => {
    const { game } = this.state,
      { flight } = this.state;
    let idTeamDivision = obj ? obj.value : 0;
    const team = _.find(flight.FlightedTeams, function (team) {
      return team.IdTeamDivision === idTeamDivision;
    });
    this.setState({
      game: {
        ...game, HomeTeam: {
          IdTeamDivision: (team || {}).IdTeamDivision,
          Name: (team || {}).Name || (team || {}).TeamName,
          team
        }
      }
    });
  }
  selectAwayTeam = (obj) => {
    const { game } = this.state,
      { flight } = this.state;
    let idTeamDivision = obj ? obj.value : 0;
    const team = _.find(flight.FlightedTeams, function (team) {
      return team.IdTeamDivision === idTeamDivision;
    });
    this.setState({
      game: {
        ...game, AwayTeam: {
          IdTeamDivision: (team || {}).IdTeamDivision,
          Name: (team || {}).Name || (team || {}).TeamName,
          team
        }
      }
    });
  }
  //

  swapTeams = () => {
    const { game } = this.state;
    this.setState({
      game: _.extend(game, {
        HomeTeam: { ...game.AwayTeam },
        AwayTeam: { ...game.HomeTeam }
      })
    });
  }

  handleChangeTime = d => {
    const { game } = this.state;
    this.setState({ game: { ...game, GameStartHour: d } });
  }

  changeTab = activeTab => this.setState({ activeTab });
  toggleDeleteConfirm = () => this.setState({ deleting: !this.state.deleting });

  download = () => {
    const { downloading } = this.state;
    if (downloading) return;

    this.setState({
      downloading: true
    }, () => {

      // Fetch games with filters applied
      request.get(`${config.apiEndpoint}/api/v4/roster/game_day/download/${this.state.game.IdGame}/roster.pdf`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .responseType('arraybuffer')
        .then((data) => {
          let file = new Blob([data.body], { type: 'application/pdf' });
          require("downloadjs")(file, 'GAME_DAY.pdf');
          this.setState({ downloading: false, });
        });
    });
  }


  render() {
    const { isOpen, toggle, fields, divisions } = this.props,
      { activeTab, microTransac, division, flight, game, downloading } = this.state;

    const divisionOptions = [...divisions.map(division => (
      {
        label: division.Flights.length ? division.Name : division.Name + ' (no flights...)',
        value: division.IdDivision
      }
    ))];

    const teamOptions = flight ? [...flight.FlightedTeams.map(team => ({
      label: team.TeamName || team.Name,
      value: team.IdTeamDivision
    }))] : [];

    const flightOptions = division ? [...division.Flights.map(flight => ({
      label: flight.Name || flight.FlightName,
      value: flight.IdFlight
    }))] : [];



    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="">
        <div className="d-flex flex-row p-0 w-100">
          <span className='w-100 align-self-center'>{(game.Id || game.IdGame) ? 'Edit Game' : 'New Game'}{division ? ' for ' + division.Name : ''}</span>
          {downloading && <button className='ml-auto btn btn-success align-self-center'><i className="fas fa-spin fa-history" /></button>}
          {!downloading && <button onClick={this.download} className='btn btn-link ml-auto align-self-center'><i className="fas fa-print" /></button>}
        </div>
      </ModalHeader>
      <ModalBody className="container-fluid">
        <ul className="nav nav-tabs font-16">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
              <i className="fa fa-cog purple"></i> <span className="half-idented">Game{game.IdGame ? ` (${game.IdGame})` : ''}</span></button>
          </li>
          {game.HomeTeam.IdTeamDivision &&
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 2 ? 'active' : ''} d-flex flex-row`} onClick={() => this.changeTab(2)}>
                <span className="d-flex flex-column justify-content-center">
                  <i className="fa fa-home blue align-self-center line1"></i>
                  <span className="font-8 align-self-center line1">Home</span>
                </span>
                {(game.HomeTeam.TeamName || game.HomeTeam.Name) && (<span className="half-idented">{game.HomeTeam.TeamName || game.HomeTeam.Name}</span>)}
              </button>
            </li>}
          {game.AwayTeam.IdTeamDivision &&
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 3 ? 'active' : ''} d-flex flex-row`} onClick={() => this.changeTab(3)}>
                <span className="d-flex flex-column justify-content-center">
                  <i className="fa fa-road gray align-self-center line1"></i>
                  <span className="font-8 align-self-center line1">Away</span>
                </span>
                {(game.AwayTeam.TeamName || game.HomeTeam.Name) && (<span className="half-idented">{game.AwayTeam.TeamName || game.AwayTeam.Name}</span>)}
              </button>
            </li>}
        </ul>
        <Animated className="tab-content" animationIn="fadeIn">
          {activeTab === 1 && (
            <div className="row no-margin no-padding">
              <div className={`col-${flight ? '4' : '4'}`}>
                <FormGroup className="margin-top-half">
                  <Label>Date</Label>
                  <div className="d-flex flex-row">

                    <DatePicker customInput={<CustomDatePicker />} selected={game.GameDate} onChange={this.handleChangeStart} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                    />

                    <button onClick={this.toggleTimeStart} id="RandomIdStart" className={`btn btn-sm btn-info`}>
                      {"@ " + game.GameStartHour.format('h:mmA')}
                    </button>

                    <Popover placement="bottom" isOpen={this.state.popoverStartTimeOpen} target="RandomIdStart" toggle={this.toggleTimeStart}>
                      <PopoverBody className="d-flex flex-column justify-content-end">
                        <CustomTimeScroller time={game.GameStartHour} fnChange={this.handleChangeTime} />
                      </PopoverBody>
                    </Popover>
                  </div>
                </FormGroup>

                { /* LOCATION */}
                <div data-tip="Please select Location" data-for="modal-tooltip-right" ref="form_gd_location">
                  <FormGroup >
                    <Label>Location</Label>
                    <Input type="select" value={game.Location.IdLocation} onChange={this.selectLocation}>
                      <option value={null}>Select</option>
                      {fields.map((f, i) => <option value={f.IdLocation} key={i}>
                        {f.child && ' - '}
                        {f.Name}
                      </option>)}
                    </Input>
                  </FormGroup>
                </div>

                { /* GAME DURATION */}
                <FormGroup className="d-flex flex-row">
                  <input data-tip="Please fill this!" data-for="modal-tooltip-bottom" type="number" defaultValue={game.GameDurationMinutes} ref={(input) => this.txtMinutes = input} className="align-self-center form-control control-sm"
                    min="0" style={{ width: '80px' }} />
                  <span className=" align-self-center margin-left margin-right font-10">duration (minutes)</span>
                </FormGroup>
              </div>
              <div className="col-8 no-padding divider-vertical d-flex flex-column container-fluid">
                <div className="row no-margin no-padding">
                  { /* DIVISIONS */}
                  {!game.Id && (
                    <div className="col-12 margin-top-half">
                      <FormGroup className="no-padding">
                        <Label for="exampleEmail">Division</Label>
                        <Select clearable={false} options={divisionOptions} value={game.IdDivision} onChange={this.selectDivision} placeholder="Select a division" />
                      </FormGroup>
                    </div>)}
                  { /* FLIGHTS */}
                  <div className="col-12 margin-top-half">
                    <FormGroup className="no-padding">
                      <Label for="exampleEmail">Flight</Label>
                      <Select clearable={false} options={flightOptions} value={game.IdFlight} onChange={this.selectFlight} placeholder={`${division ? (division.Flights.length ? 'Select a flight' : 'This division has no Flights') : 'Select a division first'}`}
                      />
                    </FormGroup>
                  </div>
                  { /* HOME TEAM */}
                  <div className="col-5" data-tip="Please select Home Team" data-for="modal-tooltip-left" ref="form_gd_home_team">
                    {game.HomeTeam && <FormGroup className="no-padding">
                      <Label className="blue">Home</Label>
                      <Select clearable={false} options={teamOptions} value={game.HomeTeam.IdTeamDivision} onChange={this.selectHomeTeam} placeholder={`${flight ? 'Select a team' : 'Select a flight first'}`} />
                    </FormGroup>}
                  </div>
                  <div className="col-2 align-self-center text-center d-flex flex-column justify-content-center" data-tip="Teams must be different" data-for="modal-tooltip-top" ref="form_gd_different_teams">
                    <span className="align-self-center">VS</span>
                    {game.Id && <button className="btn btn-link btn-sm align-self-center" title="Switch teams">
                      <i className="fa fa-refresh align-self-center blue" onClick={() => this.swapTeams()}></i>
                    </button>}
                  </div>
                  { /* AWAY TEAM */}
                  <div className="col-5" data-tip="Please select Away Team" data-for="modal-tooltip-left" ref="form_gd_away_team">
                    {game.AwayTeam && <FormGroup className="no-padding">
                      <Label className="blue text-right">Away</Label>
                      <Select clearable={false} options={teamOptions} value={game.AwayTeam.IdTeamDivision} onChange={this.selectAwayTeam} placeholder={`${flight ? 'Select a team' : 'Select a flight first'}`} />
                    </FormGroup>}
                  </div>
                </div>
                <div className="row no-margin no-padding">
                  <div className="col-12">
                    <FormGroup>
                      <Label>Notes</Label>
                      <Input type="textarea" name="notes" defaultValue={game.Notes} onChange={this.handleChangeNotes} />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && <Team team={game.HomeTeam} />}
          {activeTab === 3 && <Team team={game.AwayTeam} />}
        </Animated>
        {/*<ReactTooltip effect="solid" place="left" id="modal-tooltip-left" />
        <ReactTooltip effect="solid" place="right" id="modal-tooltip-right" />
        <ReactTooltip effect="solid" place="top" id="modal-tooltip-top" />
                  <ReactTooltip effect="solid" place="bottom" id="modal-tooltip-bottom" />*/}
      </ModalBody>
      {this.state.dirty && <ModalBody className={`bg-danger white p-3`}>
        {this.state.dirty}
      </ModalBody>}
      {!microTransac && (
        <ModalFooter className="d-flex flex-row">
          {(game.Id || game.IdGame) && <button className="btn btn-sm btn-link red mr-auto" onClick={this.toggleDeleteConfirm} id={`btnDeleteGameModal-${game.Id || game.IdGame}`}>Delete</button>}
          {(game.Id || game.IdGame) && <Popover placement="bottom" isOpen={this.state.deleting} target={`btnDeleteGameModal-${game.Id || game.IdGame}`} toggle={this.toggleDeleteConfirm}>
            <PopoverBody className="d-flex flex-column justify-content-center">
              <span className="align-self-center">Are you sure?</span>
              <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
              <button className="btn btn-sm btn-success" onClick={this.delete}>Yes, delete it!</button>
            </PopoverBody>
          </Popover>}
          <button className="btn btn-sm btn-link" onClick={toggle}>Cancel</button>
          {' '}
          <button className="btn btn-sm btn-success" onClick={this.save}>Save</button>
        </ModalFooter>)}
      {microTransac && (
        <ModalFooter>
          <button className="btn btn-sm btn-success">Saving, please wait...</button>
        </ModalFooter>)}
    </Modal>)
  }
}


export default GameDetails;