import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, generateRandomId } from '../../../../../helpers';
import config from '../../../../../config';
import request from 'superagent';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../common/CustomDatePicker';
import CustomTimeScroller from '../../../../common/CustomTimeScroller';
import moment from 'moment';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const exceptionRuleSource = {
  beginDrag(props) {
    return props;
  }
};

class ExceptionRule extends React.Component {


  // For easyness on handling multiple dropdowns and popovers and dates, I 
  // find better to handle variables in the state of the component
  // rather than handle it through props and redux
  state = {
    editMode: false,
    microTransac: false,
    isOpenStartDatepicker: false,
    isOpenEndDatepicker: false,
    popoverStartTimeOpen: false,
    popoverEndTimeOpen: false,
    dateStart: null,
    dateEnd: null,
    timeStart: null,
    timeEnd: null,
    fullDay: false,
    homeTeam: null,
    awayTeam: null,
    randomIdEnd: null, randomIdStart: null
  }

  toggleEditMode = () => {
    const { editMode } = this.state,
      { readOnly } = this.props;
    if (!readOnly) {
      this.setState({
        editMode: !editMode
      });
    }
  }

  // Lifecycle 
  componentWillMount = () => {
    const { rule } = this.props;

    let randomIdStart = generateRandomId(5),
      randomIdEnd = generateRandomId(6);
    this.setState({
      editMode: rule.Id ? false : true,
      doubleHeadersChecked: rule.AllowDoubleHeaders ? true : false,
      dateStart: moment(rule.DateStart),
      dateEnd: moment(rule.DateEnd),
      timeStart: moment(rule.DateStart),
      timeEnd: moment(rule.DateEnd),
      randomIdStart, randomIdEnd
    });
  }

  componentDidMount = () => {
    const { editMode } = this.state;
    if (editMode) {
      this.txtRuleName.focus();
      this.txtRuleName.select();
    }

  }
  //

  saveRule = (e) => {
    e.preventDefault();
    const { rule, index } = this.props,
      { dateStart, dateEnd, timeStart, timeEnd } = this.state;

    if (true) {

      // Fill the object
      rule.Name = this.txtRuleName.value; //2004-05-23T14:25:10
      rule.DateStart = moment(dateStart.format('YYYY-MM-DD') + 'T' + timeStart.format('HH:mm:ss')).format('YYYY-MM-DDTHH:mm:ss');
      rule.DateEnd = moment(dateEnd.format('YYYY-MM-DD') + 'T' + timeEnd.format('HH:mm:ss')).format('YYYY-MM-DDTHH:mm:ss');      
      rule.HomeTeam = this.state.homeTeam;
      rule.AwayTeam = this.state.awayTeam;

      this.setState({
        editMode: false,
        microTransac: true
      });

      if (rule.Id) {

        // Update call API
        request.patch(`${config.apiEndpoint}/api/v4/schedule/rules/exceptions/${rule.Id}`)
          .send(rule)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(data => {
            // TODO: Handle possible errors
            this.setState({ microTransac: false });

            // Update with redux
            this.props.exceptionRulesActions.updateDateRule &&
              this.props.exceptionRulesActions.updateDateRule(index, rule)
          });

      } else {

        // Create call API
        request.post(`${config.apiEndpoint}/api/v4/schedule/rules/exceptions/${rule.IdSeason}`)
          .send(rule)
          .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
          .then(data => {
            // TODO: Handle possible errors
            rule.Id = data.body.InsertedId;
            this.setState({ microTransac: false });

            // Update with redux
            this.props.exceptionRulesActions.updateDateRule &&
              this.props.exceptionRulesActions.updateDateRule(index, rule)
          });

      }
    }
  }

  deleteRule = () => {
    const { rule } = this.props;
    this.setState({ microTransac: true }, () => {
      request.del(`${config.apiEndpoint}/api/v4/schedule/rules/exceptions/${rule.Id}`)
        .set('Authorization', `Bearer ${localStorage.getItem('sportslogic.authtoken')}`)
        .then(data => {
          // TODO: Handle possible errors
          this.setState({ microTransac: false });
          this.props.exceptionRulesActions.deleteExceptionRule &&
            this.props.exceptionRulesActions.deleteExceptionRule(rule.innerIndex);
        });
    });
  }

  cancelRule = () => {
    this.props.exceptionRulesActions.deleteExceptionRule &&
      this.props.exceptionRulesActions.deleteExceptionRule(this.props.index);
  }

  // Datepicker handlers
  handleChangeStart = (date) => {
    const { dateEnd } = this.state;
    this.setState({
      dateStart: moment(date),
      dateEnd: moment(date).isAfter(moment(dateEnd)) ? moment(date) : moment(dateEnd) // range validation
    }, function () {
      this.toggleCalendarStart();
    });
  }
  handleChangeEnd = (date) => {
    const { dateStart } = this.state;
    this.setState({
      dateStart: moment(date).isBefore(moment(dateStart)) ? moment(date) : moment(dateStart), // range validation
      dateEnd: moment(date)
    }, function () {
      this.toggleCalendarStart();
    });
  }
  toggleCalendarStart = (e) => {
    e && e.preventDefault()
    this.setState({
      isOpenStartDatepicker: !this.state.isOpenStartDatepicker
    })
  }
  toggleCalendarEnd = (e) => {
    e && e.preventDefault()
    this.setState({
      isOpenEndDatepicker: !this.state.isOpenEndDatepicker
    })
  }

  toggleTimeStart = () => {
    const { fullDay } = this.state;
    if (!fullDay) {
      this.setState({
        popoverStartTimeOpen: !this.state.popoverStartTimeOpen
      });
    }
  }
  toggleTimeEnd = () => {
    const { fullDay } = this.state;
    if (!fullDay) {
      this.setState({
        popoverEndTimeOpen: !this.state.popoverEndTimeOpen
      });
    }
  }
  toggleFullDay = () => {
    const { fullDay } = this.state;
    if (!fullDay) {
      this.setState({
        timeStart: moment('00:00', 'HH:mm'),
        timeEnd: moment('23:59', 'HH:mm'),
        fullDay: !this.state.fullDay
      });
    } else {
      this.setState({
        fullDay: !this.state.fullDay,
        timeStart: moment('01:00', 'HH:mm'),
        timeEnd: moment('23:55', 'HH:mm'),
      })
    }

  }

  selectHomeTeam = (team) => {
    this.setState({
      homeTeam: team
    })
  }
  selectAwayTeam = (team) => {
    this.setState({
      awayTeam: team
    })
  }

  render() {
    const { rule, connectDragSource, isDragging, connectDragPreview, readOnly, flightedTeams } = this.props,
      { editMode, microTransac, dateStart, dateEnd, timeStart, timeEnd, fullDay, homeTeam, awayTeam, randomIdEnd, randomIdStart } = this.state;

    return connectDragPreview(connectDragSource(
      <li className={`exception-rule d-flex flex-column ${isDragging ? 'dragging' : ''}`} style={{ width: 350, borderRadius: 5 }}>
        {!editMode && (
          <section className="d-flex flex-row header white" style={{ backgroundColor: '#dc3545' }}>
            <button className="btn btn-link mr-auto align-self-center" onClick={this.toggleEditMode}>
              {rule.Name}
            </button>
            {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.toggleEditMode}><i className="fas fa-pen"></i></button>}
            {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.deleteRule}><i className="fa fa-times"></i></button>}
            {microTransac && !readOnly &&
              <button className="btn-link align-self-center btn ">
                <i className="fa fa-spin fa-2x fa-hourglass-o font-12" />
              </button>}
          </section>)}
        {editMode && (
          <form className="d-flex flex-row header white" style={{ backgroundColor: '#dc3545' }}>
            <input type="text" defaultValue={rule.Name} ref={(input) => {
              this.txtRuleName = input;
            }} className="form-control align-self-center control-sm" placeholder="Name of the rule"
            />
            {!rule.Id && <button type="button" className="btn btn-danger btn-sm" onClick={this.cancelRule}>Cancel</button>}
            <button type="submit" className="btn btn-success btn-sm" onClick={this.saveRule}>Save</button>
          </form>
        )}

        {/* Exception Match */}
        {rule.type === 'exception/matchup' && (
          <div className="d-flex flex-column margin-top-half">
            {editMode && (
              <Dropdown size="sm" isOpen={this.state.isOpenStartDatepicker} toggle={this.toggleCalendarStart}>
                <DropdownToggle color="info" caret>
                  {homeTeam ? homeTeam.TeamName : 'Select home team'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Home Team</DropdownItem>
                  <DropdownItem divider />
                  {flightedTeams.map((team, i) => <DropdownItem key={i} onClick={() => this.selectHomeTeam(team)}>
                    {team.TeamName}
                  </DropdownItem>)}
                </DropdownMenu>
              </Dropdown>
            )}
            {!editMode && (
              <div><span className="black">{homeTeam.TeamName}</span></div>
            )}
            <div><span>VS</span></div>
            {!editMode && (
              <div><span className="black">{awayTeam.TeamName}</span></div>
            )}
            {editMode && (
              <Dropdown size="sm" isOpen={this.state.isOpenEndDatepicker} toggle={this.toggleCalendarEnd}>
                <DropdownToggle color="info" caret>
                  {awayTeam ? awayTeam.TeamName : 'Select away team'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Away Team</DropdownItem>
                  <DropdownItem divider />
                  {flightedTeams.map((team, i) => <DropdownItem key={i} onClick={() => this.selectAwayTeam(team)}>
                    {team.TeamName}
                  </DropdownItem>)}
                </DropdownMenu>
              </Dropdown>
            )}
            <div className="black font-10 margin-top-half">
              {editMode && <input className="align-self-center" type="checkbox" defaultValue={fullDay} onChange={this.toggleFullDay} checked={fullDay} />}
              <span className={`${editMode ? 'idented' : ''}`}>MUST {!editMode ? (fullDay ? '' : 'NOT ') : ''}happen</span></div>
          </div>
        )}

        {/* Exception Time */}
        {rule.type === 'exception/time' && (
          <div className="d-flex flex-column align-content-stretch justify-content-center">
            <div className="d-flex flex-row justify-content-center">
              No games between
                  </div>
            {editMode && (
              <div className="d-flex flex-row justify-content-center">
                <DatePicker customInput={<CustomDatePicker />} selected={dateStart} selectsStart startDate={dateStart} endDate={dateEnd} onChange={this.handleChangeStart}
                  showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} />
                <button type="button" onClick={this.toggleTimeStart} id={`${randomIdStart}-bola`} className={`btn btn-sm btn-${fullDay ? 'secondary' : 'info'}`}>@ {moment(timeStart).format('h:mmA')}</button>
                <Popover placement="bottom" isOpen={this.state.popoverStartTimeOpen} target={`${randomIdStart}-bola`} toggle={this.toggleTimeStart}>
                  <PopoverBody className="d-flex flex-column justify-content-end">
                    <CustomTimeScroller time={timeStart} fnChange={(d) => {
                      this.setState({
                        timeStart: d
                      })
                    }} />
                  </PopoverBody>
                </Popover>
              </div>
            )}
            {!editMode && (
              <div className="d-flex flex-row justify-content-center">
                <span className="jeff_blue font-12">{dateStart.format('MMM Do YYYY')} @ {timeStart.format('h:mmA')}</span>
              </div>
            )}
            <div className="d-flex flex-row justify-content-center">
              and
                  </div>
            {editMode && (
              <div className="d-flex flex-row justify-content-center">
                <DatePicker customInput={<CustomDatePicker />} selected={dateEnd} selectsEnd startDate={dateStart} endDate={dateEnd} onChange={this.handleChangeEnd}
                  showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} />
                <button onClick={this.toggleTimeEnd} id={randomIdEnd} className={`btn btn-sm btn-${fullDay ? 'secondary' : 'info'}`}>@
                        {moment(timeEnd).format('h:mmA')}
                </button>
                <Popover placement="bottom" isOpen={this.state.popoverEndTimeOpen} target={randomIdEnd} toggle={this.toggleTimeEnd}>
                  <PopoverBody className="d-flex flex-column justify-content-end">
                    <CustomTimeScroller time={timeEnd} fnChange={(d) => {
                      this.setState({ timeEnd: d })
                    }} />
                  </PopoverBody>
                </Popover>
              </div>
            )}
            {!editMode && (
              <div className="d-flex flex-row justify-content-center">
                <span className="jeff_blue font-12">{dateEnd.format('MMM Do YYYY')} @ {timeEnd.format('h:mmA')}</span>
              </div>
            )}
            {editMode && <div className="d-flex flex-row justify-content-center check-wrapper black font-8 margin-top-half">
              <input className="align-self-center" type="checkbox" defaultValue={fullDay} onChange={this.toggleFullDay} checked={fullDay} />
              <span className="idented">{editMode ? 'Apply ' : ''}Full Day</span>
            </div>}
          </div>
        )}
        {!readOnly && !editMode && connectDragSource(<span className="fas fa-grip-vertical gray font-14 grab" style={{ position: 'absolute', bottom: 4, left: 4 }} />)}
      </li>))
  }
}

export default flow(
  DragSource(ItemTypes.EXCEPTIONRULE, exceptionRuleSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(ExceptionRule);
